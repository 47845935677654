<template>
  <div class="text">
    {{ text }}
    <div class="text-shadow">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "FontTitle",
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.text {
  position: relative;
  color: #ffffff;
  text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
  z-index: 1;

  &-shadow {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;
    width: 100%;
  }
}
</style>
