<template>
  <!-- Modal -->
  <main-layout>
    <template v-slot:modals>
      <allowance-modal />
    </template>
  </main-layout>
  <div class="modal-mask" @click.exact="hideList">
    <div class="modal-mask-container">
      <header>
        You can stake your ATD here to get a higher ranking on the leaderboard.
        1 point is {{ ((coefficient * 12) / monthAmount).toFixed(2) }} ATD
        staked for {{ monthAmount }} months.
        {{
          monthAmount !== 3
            ? `${monthAmount === 12 ? 25 : 10}% will be unlocked immediately.`
            : ""
        }}
      </header>
      <div class="modal-mask-container-content">
        <div>Points to be received</div>
        <div style="display: flex; justify-content: space-around; width: 100%">
          <div style="display: flex; flex-direction: column">
            <div>Now</div>
            <div class="modal-mask-container-content-points">
              <tooltip-custom
                content="You'll get at least the specified points, with the exact amount depending on the token's price at each payment"
                style="font-size: 18px"
              >
                <span
                  style="
                    font-size: 30px;
                    line-height: 38px;
                    font-weight: 700;
                    cursor: help;
                  "
                  >{{
                    (
                      ((point *
                        (monthAmount === 3
                          ? 0
                          : monthAmount === 12
                          ? 0.25
                          : 0.1)) /
                        12) *
                      monthAmount
                    ).toFixed(4)
                  }}</span
                >
              </tooltip-custom>
            </div>
          </div>
          <div style="display: flex; flex-direction: column">
            <div>Over {{ monthAmount }} months</div>
            <div class="modal-mask-container-content-points">
              <tooltip-custom
                content="You'll get at least the specified points, with the exact amount depending on the token's price at each payment"
                style="font-size: 18px"
              >
                <span
                  style="
                    font-size: 30px;
                    line-height: 38px;
                    font-weight: 700;
                    cursor: help;
                  "
                  >{{
                    point
                      ? ">" +
                        (
                          ((point *
                            (monthAmount === 3
                              ? 1
                              : monthAmount === 12
                              ? 0.75
                              : 0.9)) /
                            12) *
                          monthAmount
                        ).toFixed(4)
                      : "" +
                        (
                          ((point *
                            (monthAmount === 3
                              ? 1
                              : monthAmount === 12
                              ? 0.75
                              : 0.9)) /
                            12) *
                          monthAmount
                        ).toFixed(4)
                  }}</span
                >
              </tooltip-custom>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class="dropdown-networks"
          @click="openList"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
        >
          <div style="display: flex; justify-content: flex-start; gap: 5px">
            <img
              :src="require(`../assets/icons/${currentNetwork}.svg`)"
              alt=""
            />
            {{ chosenNetwork }}
          </div>
          <img
            class="arrow"
            src="../assets/icons/angle-bottom.svg"
            :style="
              open
                ? ' transform: rotate(180deg); transition-duration: 100ms;'
                : 'transform: rotate(0deg); transition-duration: 100ms;'
            "
            alt=""
          />
        </div>
        <div
          v-if="open"
          class="dropdown-networks-content"
          style="position: absolute"
        >
          <ul>
            <li @click="chooseNetwork('BNB')">
              <img src="../assets/icons/BNB.svg" alt="" />BNB Smart Chain
            </li>
            <li @click="chooseNetwork('ETH')">
              <img src="../assets/icons/ETH.svg" alt="" />Ethereum Mainnet
            </li>
          </ul>
        </div>
      </div>
      <BurnedInput
        :atdContractData="atdContractData"
        @transferBurnToken="transferBurnToken"
        @culcPoint="culcPoint"
      />
      <div class="modal-mask-container-staking">
        <div class="modal-mask-container-staking-title">
          Select duration in months
        </div>
        <div class="modal-mask-container-staking-periods">
          <div
            v-for="item in periods"
            :key="item.id"
            class="modal-mask-container-staking-periods-period"
            :class="item.active ? 'active' : ''"
            @click="selectPeriod(item.id, item.amount)"
          >
            {{ item.amount }}
          </div>
        </div>
      </div>
      <div class="modal-mask-container-next-unlock">
        Next unlock will be
        <tooltip-custom
          content="You'll get at least the specified points, with the exact amount depending on the token's price at each payment"
          style="text-align: center"
        >
          <span
            >{{
              point
                ? ">" +
                  (
                    ((point *
                      (monthAmount === 3
                        ? 1
                        : monthAmount === 12
                        ? 0.75
                        : 0.9)) /
                      365) *
                    7
                  ).toFixed(4)
                : 0
            }}
            pts</span
          >
        </tooltip-custom>
        at
        <span>
          {{
            getEndDate.split(" ")[0].split("-")[1] +
            "/" +
            getEndDate.split(" ")[0].split("-")[2]
          }}</span
        >
      </div>

      <button
        class="modal-mask-container-button"
        :disabled="!(value && value <= balance && !stakeLoading && poolId)"
        @click="transferBurnToken"
      >
        <img src="../assets/icons/stake-icon.svg" alt="burn" />
        {{ !stakeLoading ? "Stake" : "Staking..." }}
      </button>
      <div v-if="firstThreeStakes && firstThreeStakes.length">
        <div class="modal-mask-container-title">Previous stakes</div>
        <div class="modal-mask-container-table">
          <div class="modal-mask-container-table-title">
            <div class="text-wrapper">date</div>
            <div class="text-wrapper">sum</div>
            <div class="text-wrapper">next unlock</div>
            <div class="text-wrapper">unlock points</div>
          </div>
          <div
            v-for="stake in firstThreeStakes"
            :key="stake"
            class="modal-mask-container-table-row"
          >
            <div class="text-wrapper">
              {{
                stake.dateStart.split(" ")[0].split("-")[1] +
                "/" +
                stake.dateStart.split(" ")[0].split("-")[2]
              }}
            </div>
            <div class="text-wrapper">
              {{ stake.stakeAmount }} {{ atdContractData.symbol }}
            </div>
            <div class="text-wrapper">
              {{ getNextUnlockDate(stake.dateStart) }}
            </div>
            <div class="text-wrapper">
              <tooltip-custom :content="stake.unlock_points.toString()">
                <div v-if="!stake.canUnstake">
                  {{ stake.unlock_points.toFixed(4) }} Pts
                </div>
                <button-component
                  v-else
                  class="cta unstake"
                  @click="unstake(stake)"
                  :disabled="unstakeLoading"
                  >Unstake</button-component
                >
<!--                <div v-else>Unstaked</div>-->
              </tooltip-custom>
            </div>
          </div>
          <div
            class="modal-mask-container-view"
            v-if="!isOpen && otherStakes.length"
            @click="openAll"
          >
            View more
          </div>
          <div v-if="isOpen">
            <div
              v-for="stake in otherStakes"
              :key="stake"
              class="modal-mask-container-table-row"
            >
              <div class="text-wrapper">
                {{
                  stake.dateStart.split(" ")[0].split("-")[1] +
                  "/" +
                  stake.dateStart.split(" ")[0].split("-")[2]
                }}
              </div>
              <div class="text-wrapper">
                {{ stake.stakeAmount }} {{ atdContractData.symbol }}
              </div>
              <div class="text-wrapper">
                {{ getNextUnlockDate(stake.dateStart) }}
              </div>
              <div class="text-wrapper">
                <tooltip-custom :content="stake.unlock_points.toString()">
                  <div v-if="!stake.canUnstake && stake.amount !== '0'">
                    {{ stake.unlock_points.toFixed(4) }} Pts
                  </div>
                  <button-component
                    v-else-if="stake.amount !== '0'"
                    class="cta unstake"
                    @click="unstake(stake)"
                    :disabled="unstakeLoading"
                    >Unstake</button-component
                  >
                  <div v-else>Unstaked</div>
                </tooltip-custom>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-mask-container-view" v-if="isOpen" @click="openAll">
          Hide
        </div>
      </div>
      <!--      <div class="modal-mask-container-close" @click="closeModal">X</div>-->
      <close-modal-button @click="closeModal"></close-modal-button>
    </div>
  </div>
</template>

<script>
import BurnedInput from "@/components/BurnedInput";
import { mapGetters, mapMutations, mapState } from "vuex";
import CloseModalButton from "@/components/buttons/CloseModalButton";
import TooltipCustom from "@/components/TooltipCustom";
import etherium from "@/util/constants/networks/etherium.json";
import binance from "@/util/constants/networks/binance.json";
import ATD_TOKEN_ABI from "@/util/constants/contractsABI/atdToken.json";
import { TOGGLE_MODAL } from "@/store/mutation-types";
import AllowanceModal from "@/components/AllowanceModal";
import MainLayout from "@/layouts/main";
import ButtonComponent from "@/components/Button";

export default {
  name: "StakeModal",
  components: {
    CloseModalButton,
    BurnedInput,
    TooltipCustom,
    AllowanceModal,
    MainLayout,
    ButtonComponent,
  },
  data() {
    return {
      point: 0,
      value: 0,
      periods: [
        {
          id: 3,
          amount: 3,
          active: false,
        },
        {
          id: 2,
          amount: 6,
          active: false,
        },
        {
          id: 1,
          amount: 12,
          active: true,
        },
      ],
      poolId: 1,
      monthAmount: 12,
      isOpen: false,
      open: false,
      endDate: "",
      firstThreeStakes: [],
      otherStakes: [],
      currentNetwork: "BNB",
      isMouseOver: false,
    };
  },
  props: {
    stakeCoefficientObject: Object,
  },
  computed: {
    ...mapState([
      "atdContractData",
      "stakeLoading",
      "userLeaderboardStakes",
      "currentNetworkDropdown",
      "userProfile",
      "userStakesAndPenalties",
      "unstakeLoading",
    ]),
    ...mapGetters(["web3"]),
    coefficient() {
      if (this.monthAmount === 12) {
        return this.stakeCoefficientObject.yearStakeCoefConstant;
      } else if (this.monthAmount === 6) {
        return this.stakeCoefficientObject.halfYearStakeCoefConstant;
      } else if (this.monthAmount === 3) {
        return this.stakeCoefficientObject.quarterStakeCoefConstant;
      } else {
        return this.stakeCoefficientObject.yearStakeCoefConstant;
      }
    },
    chosenNetwork() {
      if (this.currentNetwork === "BNB") {
        return "BNB Smart Chain";
      } else if (this.currentNetwork === "ETH") {
        return "Ethereum Mainnet";
      } else {
        return "BNB Smart Chain";
      }
    },

    balance() {
      return (
        this.atdContractData.balance /
        Math.pow(10, this.atdContractData.decimals)
      );
    },
    getEndDate() {
      let date = new Date();

      // Измененный код для добавления 7 дней
      date.setUTCDate(date.getUTCDate() + 7);

      let year = date.getUTCFullYear();
      let month = date.getUTCMonth() + 1;
      let day = date.getUTCDate();

      let endDate =
        year +
        "-" +
        this.twoDigits(month) +
        "-" +
        this.twoDigits(day) +
        " " +
        this.twoDigits(date.getUTCHours()) +
        ":" +
        this.twoDigits(date.getUTCMinutes()) +
        ":" +
        this.twoDigits(date.getUTCSeconds());

      return endDate;
    },
  },
  async mounted() {
    if ((await this.web3.eth.getChainId()) === 56) {
      this.currentNetwork = "BNB";
      await this.$store.dispatch("getReadMethods", this.currentNetwork);
    } else if ((await this.web3.eth.getChainId()) === 1) {
      this.currentNetwork = "ETH";
    } else {
      this.currentNetwork = "BNB";
      await this.$store.dispatch(
        "changeChainToBNB",
        await this.web3.eth.getChainId()
      );
    }
    await this.splitPreviousStakes();

    // await this.$store.dispatch("changeChainToETH", await this.web3.eth.getChainId());
    // await this.$store.dispatch("getReadMethods");
  },
  emits: ["closeStakeModal"],
  methods: {
    ...mapMutations({
      toggleModal: TOGGLE_MODAL,
    }),
    handleMouseOver() {
      this.isMouseOver = true;
    },
    handleMouseOut() {
      this.isMouseOver = false;
    },
    async chooseNetwork(network) {
      this.currentNetwork = network;
      this.open = false;

    },
    openList() {
      this.open = !this.open;
    },
    hideList() {
      setTimeout(() => {
        if (!this.isMouseOver) {
          this.open = false;
        }
      }, 100);
    },
    getNextUnlockDate(dateStart) {
      let date = new Date(dateStart);
      let nextUnlockDate;
      // let amount;

      // Измененный шаг с 7 дней вместо 3 месяцев

      for (let i = 7; i <= 365; i += 7) {
        // Измененный расчет даты
        // amount = i
        date.setDate(date.getDate() + 7);

        nextUnlockDate =
          date.getUTCFullYear() +
          "-" +
          this.twoDigits(date.getUTCMonth() + 1) +
          "-" +
          this.twoDigits(date.getUTCDate()) +
          " " +
          this.twoDigits(date.getUTCHours()) +
          ":" +
          this.twoDigits(date.getUTCMinutes()) +
          ":" +
          this.twoDigits(date.getUTCSeconds());

        if (
          new Date(dateStart).getTime() < new Date().getTime() &&
          new Date().getTime() < new Date(nextUnlockDate).getTime()
        ) {
          nextUnlockDate =
            nextUnlockDate.split(" ")[0].split("-")[1] +
            "/" +
            nextUnlockDate.split(" ")[0].split("-")[2];
          break;
        }
      }

      return nextUnlockDate;
    },
    isInTimeRange(targetValue, checkValue){
      const targetTime = new Date(targetValue).getTime() / 1000;
      return Math.abs(targetTime - checkValue) <= 86400
    },

    async splitPreviousStakes() {
      const self = this;
      await this.$store.dispatch("getReadMethodsStake", this.currentNetwork);
      let newArray = Object.values(self.userStakesAndPenalties).reduce((acc, obj) => {
        obj.stakeStarts.forEach((start, index) => {
          acc.push({
            stakeStart: start,
            stakeAmount: +this.web3.utils.fromWei(obj.stakeAmounts[index]),
            penalties: obj.penalties[index],
            period: obj.period,
            stakeId: index,
            poolId: obj.poolId,

          });
        });
        return acc;
      }, []);
      self.firstThreeStakes = []
      self.otherStakes = []
      newArray = newArray.sort((a, b) => a.stakeStart - b.stakeStart)
      newArray.forEach((obj, index) => {
        let canUnstake = false;
        const matchObj = this.userLeaderboardStakes.find((item) => item.period === obj.period && item.stakeAmount === obj.stakeAmount && this.isInTimeRange(item.dateStart, obj.stakeStart));

        if (matchObj) {
          obj.unlock_points = matchObj.unlock_points;
          obj.dateStart = matchObj.dateStart
          obj.dateEnd = matchObj.dateEnd
          if (obj.penalties == 0) {
            canUnstake = true;
          }
          obj.canUnstake = canUnstake
          if (index < 3) {
            self.firstThreeStakes.push(obj);
          } else {
            self.otherStakes.push(obj);
          }
        }
      });
    },
    openAll() {
      this.isOpen = !this.isOpen;
    },
    twoDigits(value) {
      if (value != undefined) {
        if (value.toString().length <= 1) {
          return "0" + value.toString();
        }
        return value.toString();
      } else {
        return "00";
      }
    },
    selectPeriod(id, amount) {
      this.periods.map((item) =>
        item.id === id ? (item.active = true) : (item.active = false)
      );
      this.poolId = id;
      this.monthAmount = amount;
      this.culcPoint(this.value);
    },
    closeModal() {
      this.$emit("closeStakeModal");
    },
    culcPoint(value) {
      this.value = value;
      this.point = value / this.coefficient;
    },
    async transferBurnToken() {
      let tokenContract;
      let allowance;
      const currentAccount = this.userProfile.userERCAddress;
      let currentAtdBalance;
      if (this.currentNetwork === "BNB") {
        tokenContract = new this.web3.eth.Contract(
          ATD_TOKEN_ABI,
          binance.atdToken
        );
        allowance = this.web3.utils.fromWei(
          await tokenContract.methods
            .allowance(currentAccount, binance.stakingAddress)
            .call()
        );
        currentAtdBalance = this.web3.utils.fromWei(
          await tokenContract.methods.balanceOf(currentAccount).call()
        );
      } else {
        tokenContract = new this.web3.eth.Contract(
          ATD_TOKEN_ABI,
          etherium.atdToken
        );
        allowance = this.web3.utils.fromWei(
          await tokenContract.methods
            .allowance(currentAccount, etherium.stakingAddress)
            .call()
        );
        currentAtdBalance = this.web3.utils.fromWei(
          await tokenContract.methods.balanceOf(currentAccount).call()
        );
      }
      if (+allowance >= +this.value) {
        this.$store.commit("STAKE_LOADING", true);

        await this.$store.dispatch("stakeAtdToken", {
          value: this.value,
          coefficient: this.coefficient,
          poolId: this.poolId,
          network: this.currentNetwork,
        });
        await this.$store.dispatch("fetchLeaderboard");
        this.$emit("closeStakeModal");
      } else {
        this.toggleModal({
          modalID: "modal-allowance",
          options: {
            atdBalance: currentAtdBalance,
            currentContractAddress:
              this.currentNetwork === "BNB"
                ? binance.stakingAddress
                : etherium.stakingAddress,
            // TODO rename atdContract to general naming
            atdContract: tokenContract,
            tokenName: this.atdContractData.symbol,
          },
        });
      }
    },
    async unstake(stake) {
        this.$store.commit("UNSTAKE_LOADING", true);
        await this.$store.dispatch("unStakeAtdToken", {
          poolId: stake.poolId,
          stakeId: stake.stakeId,
          amount: stake.stakeAmount,
          network: this.currentNetwork,
        });

    },
  },
  watch: {
    async currentNetwork() {
      if (this.currentNetwork === "BNB") {
        await this.$store.dispatch(
          "changeChainToBNB",
          await this.web3.eth.getChainId()
        );
        await this.$store.dispatch("getReadMethods", this.currentNetwork);
        await this.splitPreviousStakes();
      } else if (this.currentNetwork === "ETH") {
        await this.$store.dispatch(
          "changeChainToETH",
          await this.web3.eth.getChainId()
        );
        await this.$store.dispatch("getReadMethods", this.currentNetwork);
        await this.splitPreviousStakes();

      }
    },
    currentNetworkDropdown() {
      this.currentNetwork = this.currentNetworkDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: grid;
  place-items: center;
  /*overflow-y: scroll;*/
  header {
    padding: 0 1rem;
    line-height: 1.5rem;
    background: linear-gradient(142.91deg, #080607 -32.36%, #374544 68.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  z-index: 100;
  &-container {
    position: relative;
    max-width: calc(570px - 4rem);
    padding: 3rem 2rem;
    background: #e5e7eb;

    backdrop-filter: blur(20px);
    max-height: calc(100vh - 160px);
    /*overflow-y: scroll;*/
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 90%;
      padding-top: 100px;
      margin-top: 200px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
      background: transparent;
      margin-top: 20px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #78e1db;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

    &-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
    &-content {
      margin-top: 3rem;
      padding: 2rem 0;
      background: #ffffff;

      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 20px;
      &-points {
        margin-top: 10px;
        font-family: "Space Mono";
        font-style: normal;
        font-size: 30px;
        /* identical to box height */

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.025em;
      }
    }
    &-button {
      background: #78e1db;
      width: 100%;
      height: 60px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      border: none;
      line-height: 124%;
      /* or 25px */

      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;

      color: #fefefe;
      cursor: pointer;
      &:disabled {
        background: #777 !important;
        color: #ccc;
        cursor: help;
      }
      img {
        width: 28px;
        height: 24px;
        margin-right: 10px;
      }
    }
    &-staking {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 15px;
      &-title {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */

        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: -0.03em;
        margin-bottom: 25px;
        color: #000000;
      }
      &-periods {
        display: flex;
        justify-content: center;
        &-period {
          background: transparent;
          width: 95px;
          height: 25px;
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 124%;
          /* identical to box height, or 20px */

          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          text-align: center;
          border: 1px solid #a2a2a2;
          color: #000000;
          cursor: pointer;
          background: #ffffff;
          &.active {
            background: #78e1db;
          }
        }
      }
    }
    &-title {
      font-family: "Space Mono";

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      margin-top: 55px;
    }
    &-view {
      font-family: "Space Mono";

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #a2a2a2;
      text-decoration: underline;
      justify-content: center;
      margin-top: 5px;
      &:hover {
        color: #000000;
      }
    }
    &-next-unlock {
      font-family: "Space Mono";

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      margin-top: 27px;
      margin-bottom: 33px;
      span {
        cursor: help;
        padding: 0 10px;
      }
    }
    &-table {
      margin-top: 20px;
      &-title {
        font-family: "Space Mono";

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-row {
        font-family: "Space Mono";

        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        /* identical to box height, or 26px */

        display: flex;
        justify-content: space-between;

        align-items: center;
        color: #a2a2a2;
      }
    }
  }
}
.text-wrapper {
  min-width: 89px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  :last-child{
    width: 130px;
  }
  @media (max-width: 550px) {
    min-width: 75px;
    font-size: 12px;
    :last-child{
      width: 75px;
    }
  }
}
.dropdown-networks {
  width: 200px;
  height: 32px;
  border: 1px solid #dad9f7;
  padding: 4px 8px;
  gap: 5px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-top: 30px;
}
ul {
  font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  position: absolute;
  list-style: none;
  padding: 8px;
  background: #ffffff;
  z-index: 100000 !important;
  li {
    align-items: center;
    height: 32px;
    width: 200px;
    display: flex;
    gap: 5px;
    cursor: pointer;
    padding-left: 10px;
  }
  li:hover {
    background: #e5e7eb;
  }
}
.arrow {
  width: 13px;
  height: 13px;
}
.cta {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 130%;
  /* or 17px */

  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.105em;
  text-transform: uppercase;

  height: 26px;
  padding: 0 14px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 5px;
  width: 75px;
  &.burned {
    background: #fccf5d;
  }
  &.stake {
    background: linear-gradient(-45deg, #75e1db 27.47%, #cee3e0 128.48%);
  }
  &.unstake {
    background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
  }
}
</style>
