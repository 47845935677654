import {
  SET_WEB3,
  SET_USER_INVESTMENTS,
  SET_USER_OLD_INVESTMENTS,
  SET_USER_PROFILE,
  SET_PAGINATION_META,
  SET_PROJECTS,
  SET_I18N,
  RESET_STATE,
  SET_SOCIAL_NETWORKS,
  SET_PENDING_REQUEST_STATUS,
  SET_LOADED_REQUEST_STATUS,
  RESET_REQUEST_STATUS,
  SET_TOKEN_CONTRACT,
  TOGGLE_MODAL,
  SET_SWAP_ALLOWANCE_AMOUNT,
  SET_ALLOWANCE_INCREASE_STATUS,
  UPDATE_INVESTMENT_PROJECT_CONTRACT_INFO,
  SET_BLOCKCHAIN_INFO_WEB3,
  SET_TOKEN_CONTRACT_INFO,
  SET_OLD_TOKEN_CONTRACT_INFO,
  SET_BLOG_POSTS_BY_CATEGORY,
  SET_BLOG_CATEGORIES,
  RESET_PROJECTS,
  SET_BANNERS,
  SET_BLOG_POSTS_HOME_PAGE,
  SET_INDUSTRIES_INNOVATION_PAGE,
  SET_PROJECTS_INNOVATION_PAGE,
  SET_PARTNERS_DATA,
  SET_LEADERBOARD_DATA,
  SET_ATD_TOKEN_DATA,
  CLEAR_CURRENT_USER_LEADERBOARD,
  BURN_LOADING,
  STAKE_LOADING,
  IS_AGREE,
  SET_СURRENT_LIKES_FOR_PROJECT,
  SET_СURRENT_INTERESTS_FOR_PROJECT,
  SET_PROJECTS_OTC_PAGE,
  SET_CURRENT_NETWORK_DROPDOWN,
  WALLETCONNECT_PROVIDER,
  SET_ACCESS_TOKEN,
  SET_TOKEN_EXPIRATION,
  SET_USER_ACTIVITY,
  SET_USER_STAKES,
  UNSTAKE_LOADING,
} from "./mutation-types";

const mutations = {
  [SET_WEB3](state, payload) {
    state.web3 = payload.web3;
    state.web3ProviderName = payload.providerName;
    state.provider = payload.provider;
  },
  [SET_USER_INVESTMENTS](state, payload) {
    state.investments = payload
  },
  [SET_USER_OLD_INVESTMENTS](state, payload) {
    state.oldInvestments = payload;
  },
  [UPDATE_INVESTMENT_PROJECT_CONTRACT_INFO](state, payload) {
    let currentInvestments = state.investments;

    currentInvestments.forEach((curProject) => {
      if (curProject.id === payload.id) {
        curProject.contractInfo = payload.contractInfo;
      }
    });

    state.investments = currentInvestments;
  },
  [SET_USER_PROFILE](state, payload) {
    const statuses = ["verify", "pending", "approved", "rejected"];

    state.userProfile = payload;
    state.kycStatus = payload.kyc ? statuses[payload.kyc.status] : "verify";
  },
  [RESET_STATE](state) {
    state.investments = [];
    state.userProfile = {
      email: "",
      userERCAddress: "",
      wallets: null,
      social: null,
      kyc: null,
      interests: {},
    };
    state.web3ProviderName = "metamask";
  },
  [SET_PAGINATION_META](state, payload) {
    state.pagination = payload;
  },
  [SET_PROJECTS](state, payload) {
    // TODO deep merge
    state.projects = {
      ...state.projects,
      ...payload,
    };
  },
  [RESET_PROJECTS](state) {
    state.projects = [];
  },
  [SET_I18N](state, payload) {
    state.i18n = payload;
  },
  [SET_SOCIAL_NETWORKS](state, payload) {
    state.socialNetworks = payload;
  },
  [SET_PENDING_REQUEST_STATUS](state) {
    state.requestStatus = "pending";
  },
  [SET_LOADED_REQUEST_STATUS](state) {
    state.requestStatus = "loaded";
  },
  [RESET_REQUEST_STATUS](state) {
    state.requestStatus = "idle";
  },
  [SET_TOKEN_CONTRACT](state, payload) {
    state.tokenContract = payload;
  },
  [SET_TOKEN_CONTRACT_INFO](state, payload) {
    state.tokenContractInfo = payload;
  },
  [SET_OLD_TOKEN_CONTRACT_INFO](state, payload) {
    state.oldTokenContractInfo = payload;
  },
  [TOGGLE_MODAL](state, payload) {
    state.modalData = payload;
  },
  [SET_SWAP_ALLOWANCE_AMOUNT](state, payload) {
    state.swapAmount = payload;
  },
  [SET_ALLOWANCE_INCREASE_STATUS](state, payload) {
    state.allowanceIncreaseStatus = payload;
  },
  [SET_BLOCKCHAIN_INFO_WEB3](state, payload) {
    state.blockchainInfoWeb3 = payload.blockchainInfoWeb3;
  },
  [SET_BLOG_POSTS_BY_CATEGORY](state, payload) {
    state.blogPosts = payload;
  },
  [SET_BLOG_CATEGORIES](state, payload) {
    state.blogCategories = payload;
  },
  [SET_BANNERS](state, payload) {
    state.banners = payload;
  },
  [SET_BLOG_POSTS_HOME_PAGE](state, payload) {
    state.blogPostsHomePage = payload;
  },
  [SET_INDUSTRIES_INNOVATION_PAGE](state, payload) {
    state.industriesInnovationPage = payload;
  },
  [SET_PROJECTS_INNOVATION_PAGE](state, payload) {
    state.projectsInnovationPage = payload;
  },
  [SET_PROJECTS_OTC_PAGE](state, payload) {
    state.projectsOTCPage = payload;
  },
  [SET_PARTNERS_DATA](state, payload) {
    state.partnerTypes = payload.partnerTypes;
    state.partners = payload.partners;
  },
  [SET_LEADERBOARD_DATA](state, payload) {
    state.leaderboard = payload.allUsers;
    state.currentUserLeaderboard = {
      ...state.currentUserLeaderboard,
      ...payload.authUser,
    };
    state.userLeaderboardStakes = payload.userLeaderboardStakes;
    if (
      !payload.authUser.numberOfProjects &&
      !payload.allUsers.find(
        (item) => item.erc20Address === state.userProfile.userERCAddress
      )
    ) {
      state.leaderboard = [...state.leaderboard, state.currentUserLeaderboard];
    }
  },
  [SET_ATD_TOKEN_DATA](state, payload) {
    state.atdContractData = payload;
  },
  [CLEAR_CURRENT_USER_LEADERBOARD](state, payload) {
    state.currentUserLeaderboard = payload
  },
  [BURN_LOADING](state, payload) {
    state.burnLoading = payload
  },
  [IS_AGREE](state, payload) {
    state.isAgree = payload
  },
  [SET_СURRENT_LIKES_FOR_PROJECT](state, payload) {
    state.projectsInnovationPage = [...state.projectsInnovationPage.map((project) => {
      if (project.id === payload.projectId) {
        project.likes = payload.likes;
      }
      return project;
    })];
  },
  [SET_СURRENT_INTERESTS_FOR_PROJECT](state, payload) {
    state.projectsInnovationPage = [...state.projectsInnovationPage.map((project) => {
      if (project.id === payload.projectId) {
        project.interests = payload.interests;
      }
      return project;
    })]
  },
  [STAKE_LOADING](state, payload) {
    state.stakeLoading = payload;
  },
  [SET_CURRENT_NETWORK_DROPDOWN](state, payload) {
    state.currentNetworkDropdown = payload
  },
  [WALLETCONNECT_PROVIDER](state, payload) {
    state.walletConnectProvider = payload;
  },
  [SET_ACCESS_TOKEN](state, payload) {
    state.accessToken = payload
    localStorage.setItem("access_token", payload);
  },
  [SET_TOKEN_EXPIRATION](state, payload) {
    state.tokenExpiration = payload
    localStorage.setItem("token_expiration", payload);
  },
  [SET_USER_ACTIVITY](state, payload) {
    state.userActivity = payload;
  },
  [SET_USER_STAKES](state, payload){
    state.userStakesAndPenalties = payload
  },
  [UNSTAKE_LOADING](state, payload) {
    state.unstakeLoading = payload;
  },
};

export default mutations;
