<template>
  <div class="modal-mask">
    <div class="modal-mask-container">
      <header>
        I agree to the <router-link to="/terms-of-service">Terms of Service</router-link> conditions
      </header>
      <button-component
              @click="agreeFunc"
              class="cta"
      > I agree</button-component
      >
      <!--      <div class="modal-mask-container-close" @click="closeModal">X</div>-->
      <close-modal-button @click="closeModal"></close-modal-button>
    </div>
  </div>
</template>

<script>
  import ButtonComponent from "@/components/Button";
  import CloseModalButton from "@/components/buttons/CloseModalButton";
export default {
  name: "WarningModal",
  components:{
    CloseModalButton, ButtonComponent
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async agreeFunc(){
      await this.$emit("handleStake")
      this.$emit("closeModal");

    }
  },
};
</script>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    display: grid;
    place-items: center;
    header {
      padding: 15px 1rem 0;
      line-height: 1.5rem;
      margin-bottom: 2rem;
      a{
        color: black;
      }
    }
    z-index: 100;
    &-container {
      position: relative;
      max-width: calc(370px - 1rem);
      padding: 3rem 1rem 2rem;
      background: #d9d9d9;
      &-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }
    }
  }
  .cta {
    width: auto;
    display: flex;
    margin: auto;
  }
</style>
