<template>
  <mq-responsive target="sm-">
    <div class="blogposts-wrapper" v-if="blogPosts && blogPosts.length">
      <blog-post-card v-for="blogPost in blogPosts" :key="blogPost.slug"
        :slug="blogPost.slug"
        :img="`${staticEndpoint}${blogPost.cardBannerPath}`"
        :title="blogPost.title"
        :shortContent="blogPost.shortContent"
        :date="blogPost.date"
        :blogCategories="blogPost.blogCategories"
        :likes="blogPosts[0].likes"
        :minutesToRead="blogPosts[0].minutesToRead"
        :post-id="blogPosts[0].id"
      />
    </div>
  </mq-responsive>
  <mq-responsive target="md+">
    <div class="blogposts-wrapper" v-if="blogPosts && blogPosts.length">
      <blog-post-card-large
        :slug="blogPosts[0].slug"
        :img="`${staticEndpoint}${blogPosts[0].cardBannerPath}`"
        :title="blogPosts[0].title"
        :shortContent="blogPosts[0].shortContent"
        :date="blogPosts[0].date"
        :blogCategories="blogPosts[0].blogCategories"
        :likes="blogPosts[0].likes"
        :minutesToRead="blogPosts[0].minutesToRead"
        :post-id="blogPosts[0].id"
      />
      <template v-for="(blogPost, index) in blogPosts">
        <blog-post-card v-if="index" :key="blogPost.slug"
          :slug="blogPost.slug"
          :img="`${staticEndpoint}${blogPost.cardBannerPath}`"
          :title="blogPost.title"
          :shortContent="blogPost.shortContent"
          :date="blogPost.date"
          :blogCategories="blogPost.blogCategories"
          :minutes-to-read="blogPost.minutesToRead"
          :likes="blogPost.likes"
          :post-id="blogPost.id"
        />
      </template>
    </div>
  </mq-responsive>
</template>
<script>
import BlogPostCardLarge from '@/components/BlogPostCardLarge.vue';
import BlogPostCard from '@/components/BlogPostCard.vue';
import { MqResponsive } from 'vue3-mq';
import { mapState } from 'vuex';

export default {
  name: 'BlogPostList',
  components: {
    BlogPostCardLarge,
    BlogPostCard,
    MqResponsive
  },
  computed: {
    ...mapState([
      'blogPosts'
    ]),
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/styles/mixins";

  .blogposts-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 31px;

    @include respond-below(md) {
      gap: 17px;
      justify-content: center;
    }
  }
</style>
