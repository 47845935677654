<template>
  <div v-if="modalData && modalData.modalID === 'modal-profile'" class="overlay" @click.prevent="toggleModal">
    <div class="profile-modal" @click.stop="">
      <close-modal-button class="custom-close" />
      <div class="row no-fieldset">
        <div class="profile-label">
          <span class="label">
            Email
          </span>
        </div>
        <div class="profile-input">
          <input type="email" id="user-email" class="my-profile-input my-profile-input-email"
            placeholder="Enter Email" v-model="userProfile.email">
          <img v-if="userProfile.email" src="{{ asset('/images/svg/edit.svg') }}" alt="" class="my-profile-edit">
        </div>
      </div>
      <fieldset class="profile-fieldset">
        <legend>Wallets</legend>
        <div class="row">
          <div class="profile-label">
            <span class="label">
              ERC20
            </span>
          </div>
          <div class="profile-input">
            <input type="email" id="user-email" class="my-profile-input my-profile-input-email" disabled
              placeholder="Enter Email" v-model="userProfile.userERCAddress">
          </div>
        </div>
        <div v-for="(wallet, key) in userProfile.wallets" :key="wallet" class="row">
          <div class="profile-label">
            <span class="label">
              {{key}}
            </span>
          </div>
          <div class="profile-input">
            <input type="text" class="my-profile-input my-profile-input-email"
              :placeholder="`Enter ${key} wallet`" v-model="userProfile.wallets[key].value">
<!--            <img v-if="wallet" src="{{ asset('/images/svg/edit.svg') }}" alt="" class="my-profile-edit">-->
          </div>
        </div>
      </fieldset>
      <fieldset class="profile-fieldset social">
        <legend>Social Accounts</legend>
        <div v-for="(social, key) in userProfile.socials" :key="social"  class="row">
          <div class="profile-label">
            <span class="label">
              {{key}}
            </span>
          </div>
          <div class="profile-input">
            <input type="text" class="my-profile-input my-profile-input-email"
              :placeholder="`Enter ${key} username`"  v-model="userProfile.socials[key].value">
            <img v-if="social" src="{{ asset('/images/svg/edit.svg') }}" alt="" class="my-profile-edit">
          </div>
        </div>
      </fieldset>
<!--      <div class="row no-fieldset kyc">-->
<!--        <div class="profile-label">-->
<!--          <span class="label">-->
<!--            KYC-->
<!--          </span>-->
<!--        </div>-->
<!--        <div v-if="kycStatus === 'verify'" :class="`profile-status ${kycStatus}`">-->
<!--          <div class="verify-wrapper">-->
<!--            <a :href="verifyKycURL">-->
<!--              Verify-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else-if="kycStatus === 'approved'" :class="`profile-status ${kycStatus}`">-->
<!--          <img src="../assets/approved-kyc-icon.svg" alt="">-->
<!--          <span>Approved</span>-->
<!--        </div>-->
<!--        <div v-else-if="kycStatus === 'pending'" :class="`profile-status ${kycStatus}`">-->
<!--          <img src="../assets/progress-kyc-icon.svg" alt="">-->
<!--          <span>In progress</span>-->
<!--        </div>-->
<!--        <div v-else class="profile-status rejected">-->
<!--          <img src="../assets/rejected-kyc-icon.svg" alt="">-->
<!--          <span>Rejected</span>-->
<!--        </div>-->
<!--      </div>-->
      <button-component class="profile-save-button" @click="saveProfileInfo">
        Save
      </button-component>
    </div>
  </div>
</template>
<script>
import ButtonComponent from '@/components/Button.vue';
import CloseModalButton from '@/components/buttons/CloseModalButton.vue'
import { mapMutations, mapState } from 'vuex';
import { toRaw } from 'vue';
import { useToast } from "vue-toastification";
import { API } from '@/api';

export default {
  name: 'ProfileModal',
  components: {
    ButtonComponent,
    CloseModalButton
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  computed: {
    ...mapState([
      'modalData',
      'userProfile',
      'kycStatus',
      'verifyKycURL'
    ])
  },
  methods: {
    ...mapMutations({
      toggleModal: 'TOGGLE_MODAL',
    }),
    async saveProfileInfo() {
      let prepareFields = {};

      prepareFields['user-email'] = this.userProfile.email;
      Object.entries(toRaw(this.userProfile.wallets)).forEach((wallet) => {
        prepareFields[`user-${wallet[0].toLowerCase()}_address`] = wallet[1].value;
      });
      Object.entries(toRaw(this.userProfile.socials)).forEach((social) => {
        prepareFields[`user-${social[0].toLowerCase()}`] = social[1].value;
      });
      try {
        await API.post('/user/update', prepareFields);
        this.toast.success('Your account updated');
        this.toggleModal();
      } catch (error) {
        this.toast.error(error.response?.data?.message);
      }
    },
    closeModal() {
      this.toggleModal();
    },
  }
}
</script>
<style scoped lang="scss">
  @import "@/assets/styles/mixins";

  .overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 502;
    overflow-y: scroll;

    @include respond-below(sm) {
      padding: 20px;
    }

    .profile-modal {
      background: radial-gradient(55.22% 90.9% at 50.79% 44.82%, #F2F2F2 0%, #EFEFEF 100%);
      box-shadow: 0 0 65px rgba(0, 0, 0, 0.3);
      padding: 43px 18px 50px 48px;
      z-index: 200;
      text-align: center;
      margin: 40px auto;
      transform: none;
      position: relative;
      top: 0;
      width: 784px;

      @include respond-below(sm) {
        margin: 0 auto;
        padding: 30px 10px 33px 10px;
        text-align: left;
        width: unset;
      }

      .custom-close {
        @include respond-below(sm) {
          right: 10px;
        }
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 716px;
        padding: 0 20px;
        margin-bottom: 13px;

        @include respond-below(sm) {
          display: block;
          width: auto;
          padding: 0 7.5px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.close-btn-wrapper {
          position: relative;
        }

        &.no-fieldset {
          padding-left: 23px;
          margin-bottom: 15px;
          margin-top: 5px;

          @include respond-below(sm) {
            margin-top: 10px;
            padding-left: 7.5px;
          }
        }

        &.kyc {
          margin-top: 26px;

          @include respond-below(sm) {
            display: flex;
          }
        }
      }

      .label {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        align-items: center;
        letter-spacing: 0.05em;

        background: linear-gradient(180deg, #4BE9FF 0%, #2ABDD1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .profile-input {
        display: block;
        border: 1px solid #898D8F;
        filter: drop-shadow(0px 0px 65px rgba(0, 0, 0, 0.3));
        width: 560px;
        padding: 1px;

        @include respond-below(sm) {
          min-width: calc(100% - 2px);
          width: auto;
        }

        input,
        input:focus
        input:disabled {
          background: transparent;
          color: #212121;
          opacity: 1;
          line-height: 41px;
          padding-bottom: 0;
          border: none;
          width: 100%;
          border-radius: 4px;
          text-align: right;
          padding-left: 10px;
          padding-right: 22px;
          text-overflow: ellipsis;
          box-sizing: border-box;

          @include respond-below(sm) {
            width: 100%;
          }
        }

        input,
        input::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
        }

        ::placeholder {
          color: #898D8F;
        }
      }

      .profile-label,
      .profile-input {
        display: inline-block;

        @include respond-below(sm) {
          display: block;
        }
      }

      .profile-fieldset {
        padding: 30px 0;
        border: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(94.29deg, #B7B7B7 -2.43%, #CFCFCF 47.26%);


        @include respond-below(sm) {
          margin: 0;
        }

        &.social {
          margin-top: 55px;

          @include respond-below(sm) {
            margin-top: 20px;
          }
        }

        legend {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 130%;
          background: linear-gradient(72.12deg, #4D4D4D 2.57%, #121212 126.49%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          padding: 0 10px;
          margin-left: 80px;
          text-align: left;

          @include respond-below(sm) {
            margin-left: 27px;
          }
        }
      }

      .profile-status {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        /* or 23px */

        display: flex;
        align-items: center;
        letter-spacing: 0.05em;

        span {
          margin-left: 10px;
        }

        /* GY NFT+bttn */

        &.approved span {
          background: linear-gradient(90.3deg, #FF7F23 -4.55%, #FFF400 107.76%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .verify-wrapper {
          border-bottom: 1px dashed;
          border-image-slice: 1;
          border-width: 1px;
          border-image-source: linear-gradient(90.3deg, #AC3BFF -4.55%, #5ECFFF 107.76%);
        }

        &.verify {
          a {
            background: linear-gradient(90.3deg, #AC3BFF -4.55%, #5ECFFF 107.76%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        &.pending span {
          background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        &.rejected span {
          background: linear-gradient(93.1deg, #FF3F71 -7.52%, #FFAD72 107.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
</style>
