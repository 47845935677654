<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper class="pb-40">
        <top-section-content hide-image>
          <template v-slot:top-section-heading>
            <FontTitle text="Pipeline" class="heading"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            Pipeline is a place where earliest stage Web3 teams can meet their
            first community supporters
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="list">
      <div class="list-header">
        <ul class="list-header-menu">
          <router-link to="/pipeline">All</router-link>
          <router-link
            :to="`/pipeline/${item.key}`"
            v-for="item of industriesInnovationPage"
            :key="item.key"
            >{{ item.name }}</router-link
          >
        </ul>
        <div class="list-header">
          <div class="list-header-search">
            <input
              type="text"
              class="list-header-search-field"
              v-model="text"
              @input="filterBySearch($event)"
            />
            <img
              src="../assets/icons/search.svg"
              alt=""
              class="list-header-search-icon"
            />
          </div>
          <!--        <div class="list-header-filter">-->
          <!--          <img src="../assets/icons/filter.svg" alt="" />-->
          <!--          <div>Filter</div>-->
          <!--          <img-->
          <!--            class="list-header-filter-angle"-->
          <!--            src="../assets/icons/angle-right.svg"-->
          <!--            alt=""-->
          <!--          />-->
          <!--        </div>-->
        </div>
      </div>
      <innovation-list
        v-if="projectsInnovationPage"
        :industries="industriesInnovationPage"
        :projects="filteredProjects"
      />
      <div class="meteors-bg"></div>
    </div>
  </main-layout>
</template>

<script>
import InnovationList from "@/components/InnovationList";
import MainLayout from "@/layouts/main";
import TopSectionWrapper from "@/components/TopSectionWrapper";
import TopSectionContent from "@/components/TopSectionContent";

import { mapActions, mapState } from "vuex";
import FontTitle from "@/components/FontTitle";
export default {
  name: "InnovationLabsPage",
  components: {
    FontTitle,
    InnovationList,
    TopSectionWrapper,
    TopSectionContent,
    MainLayout,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      filteredProjectsBySearch: [],
      text: "",
    };
  },
  watch: {
    $route() {
      if (this.$route.path.includes("/pipeline")) {
        this.fetchProjectsInnovationPage(this.$route.params.slug);
        this.text = "";
      }
    },
  },
  mounted() {
    // if (!this.currentUserLeaderboard.totalScore && this.currentUserLeaderboard.totalScore < 10){
    //   this.$router.push("/")
    // }
    this.fetchIndustriesInnovationPage();
    this.fetchProjectsInnovationPage(this.$route.params.slug);
  },
  computed: {
    ...mapState([
      "industriesInnovationPage",
      "projectsInnovationPage",
      "userProfile",
      "currentUserLeaderboard"
    ]),
    filteredProjects() {
      return this.text
        ? this.filteredProjectsBySearch
        : this.projectsInnovationPage;
    },
  },
  methods: {
    ...mapActions([
      "fetchIndustriesInnovationPage",
      "fetchProjectsInnovationPage",
      "connectWallet",
    ]),
    filterBySearch() {
      this.filteredProjectsBySearch = this.projectsInnovationPage.filter(
        (item) => item.name.toLowerCase().includes(this.text.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

:deep(.top-section-heading) {
  @include respond-below(lg) {
    padding-bottom: 11px;
  }
}

$animation-speed: 1s;
.top-section {
  &-wrapper {
    background: transparent;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    position: relative;
    justify-content: center;

    transition: 0.5s ease all;
    min-height: 470px;
    padding-left: 0;
    padding-right: 0;

    @include respond-below(lg) {
      min-height: unset;
    }

    @include respond-to(xs) {
      padding-top: 25px;
      overflow: hidden;
      background-size: cover;
      background-position: 50% 50%;
    }

    @include respond-between(sm, xl) {
      padding-left: 0;
      padding-right: 0;
    }

    &.no-bg {
      box-shadow: none;
      background: none;
      min-height: inherit;
    }
  }

  &-bg {
    position: absolute;
    background: url("../assets/top-section-content-bg.svg");
    top: 155px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    &.hide {
      display: none;
    }

    @include respond-to(sm) {
      top: 0;
    }
  }
}
.meteors-bg {
  display: none;
  background-image: url("../assets/meteors-body-right.svg");
  width: 153px;
  height: 80px;
  position: absolute;
  right: 10%;
  top: 275px;
  z-index: -1;

  @include respond-to(md) {
    width: 153px;
    height: 80px;
    right: 10%;
    top: 175px;
  }
  @include respond-to(sm) {
    width: 153px;
    height: 80px;
    right: 10%;
    top: 150px;
  }
  @include respond-to(xs) {
    width: 153px;
    height: 80px;
    right: 10%;
    top: 100px;
  }
}
.figure-bg {
  background-image: url("../assets/figure.svg");
  position: absolute;
  max-width: 360px;
  width: 100%;
  height: 400px;
  right: 0;
  top: 6%;
  @include respond-to(xs) {
    display: none;
  }
}
.figure-bg-top {
  background-image: url("../assets/figure-top.svg");
  position: absolute;
  overflow: hidden;
  width: 452px;
  height: 139px;
  right: 0;
  top: 0;
  @include respond-to(xs) {
    display: none;
  }
}
.figure-bg-rotate {
  background-image: url("../assets/figure.svg");
  position: absolute;
  width: 410px;
  height: 400px;
  left: -2%;
  top: -6%;
  transform: rotate(225deg);
  @include respond-to(xs) {
    display: none;
  }
}
.figure-bg-revert-x {
  background-image: url("../assets/figure.svg");
  position: absolute;
  width: 410px;
  height: 400px;
  left: 0;
  top: 6%;
  transform: scaleX(-1);
  @include respond-to(xs) {
    display: none;
  }
}
.innovation {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  &-top {
    max-width: 800px;
    width: 100%;
    @include respond-to(sm) {
      max-width: 400px;
    }
    @include respond-to(xs) {
      /*max-width: 300px;*/
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-title {
      font-family: "Press Start 2P";
      font-style: normal;
      font-weight: 700;
      font-size: 75px;
      line-height: 92%;
      /* or 69px */
      align-items: center;
      text-align: center;
      letter-spacing: -0.09em;
      text-transform: uppercase;
      background: linear-gradient(
        109.91deg,
        #eecbd4 25.24%,
        #edcfdb 30.19%,
        #eadcf1 38.03%,
        #eadff6 39.27%,
        #d7dff3 45.87%,
        #bde1f1 54.13%,
        #bff6e7 66.51%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #0b2349;
      display: table;
      margin: 20px auto;
      @include respond-to(sm) {
        font-size: 50px;
      }
      @include respond-to(xs) {
        font-size: 40px;
      }
    }
    &-text {
      width: 500px;
      margin: auto;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;

      color: #d6d6d6;
      @include respond-to(sm) {
        width: 400px;
      }
      @include respond-to(xs) {
        width: 300px;
      }
    }
    &-buttons {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
    a {
      text-decoration: none;
    }
    &-button-left {
      max-width: 178px;
      height: 47px;
      color: #ffffff;
      text-align: center;
      background: radial-gradient(
        168.5% 66.62% at 113.7% 69.06%,
        #5f5997 0%,
        #f46a8e 100%
      );
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */
      padding-left: 46px;
      padding-right: 46px;
      margin-right: 10px;
      @include respond-to(xs) {
        max-width: 150px;
        width: 100%;
        padding-right: 33px;
        padding-left: 33px;
      }
    }
    &-button-right {
      max-width: 178px;
      height: 47px;
      background: transparent;
      text-align: center;
      border: 1px solid #f46a8e;
      color: #ffffff;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      padding-left: 37px;
      padding-right: 37px;
      /* or 23px */

      display: flex;
      align-items: center;
      margin-right: 10px;
      @include respond-to(xs) {
        width: 100%;
        max-width: 150px;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}

.list {
  @include respond-above(md) {
    max-width: 1280px;
    margin: 0 auto;
  }

  @include respond-above(sm) {
    height: auto;
    margin-bottom: 100px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-menu {
      display: flex;
      justify-content: space-between;

      padding-left: 0;
      a {
        text-decoration: none;
        list-style-type: none;
        margin-right: 20px;
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        align-items: center;
        color: #9b9b9b;
      }
      .router-link {
        text-decoration: none;

        &-active {
          border-bottom: 1px #e5e5e5 solid;
          color: #000;
        }
      }

      :hover {
        color: #000;
        cursor: pointer;
      }
    }

    &-search {
      height: 30px;
      display: inline-flex;
      border-radius: 20px;
      padding: 5px 5px 5px 10px;
      background: #d9d9d9;
      align-items: center;
      margin-right: 20px;
    }

    &-search-icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
      order: -1;
    }

    &-search-field {
      width: 200px;
      border: 0;
      background: #d9d9d9;
      color: #ffffff;
      font-size: 22px;
    }

    &-search-field:focus {
      outline: none;
    }

    &-search-field:focus + .search-icon {
      /* стили для иконки при фокусе поля */
    }

    &-filter {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      align-items: center;
      color: #9b9b9b;
      min-width: 70px;
      cursor: pointer;

      img {
        margin: auto 5px;
      }

      &-angle {
        width: 15px;
        height: 15px;
        transform: rotate(90deg);
      }
    }

    @include respond-below(md) {
      display: none;
    }
  }
}
</style>
