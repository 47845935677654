<template>
  <button-component
    v-if="!userProfile.userERCAddress"
    :onClick="openWalletModal"
    class="cta"
  >
    Connect
  </button-component>
  <button-component
    v-else
    :onClick="proceedLogout"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    class="cta user-erc20-address"
  >
    {{ hoverButtonLabel ? hoverButtonLabel : userERCTrimmed }}
  </button-component>
  <WalletModal v-if="isShow" @closeModal="closeModal"></WalletModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ButtonComponent from "@/components/Button";
import WalletModal from "@/components/WalletModal";

export default {
  name: "ConnectWalletButton",
  components: {
    ButtonComponent,
    WalletModal,
  },
  data() {
    return {
      hoverButtonLabel: null,
      isShow: false,
    };
  },
  computed: {
    ...mapGetters(["userERCTrimmed"]),
    ...mapState(["userProfile"]),
  },
  methods: {
    ...mapActions(["connectWallet", "logOut"]),
    async handleConnect() {
      await this.connectWallet();
      this.$router.push({ name: "Active" });
    },
    proceedLogout() {
      this.$gtm.trackEvent({
        event: "ConnectWalletButton_Logout", // Event type [default = 'interaction'] (Optional)
        category: "ConnectWalletButton",
        action: "Logout",
        label: this.userProfile.userERCAddress,
        value: 1,
      });

      if (!this.$isMobile()) {
        this.logOut();
      }
      this.$router.push("/");
      this.$store.commit("CLEAR_CURRENT_USER_LEADERBOARD", {
        numberOfProjects: 0,
        totalInvested: 0,
        daoValue: 0,
        totalScore: 0,
        atdBoost: 0,
        erc20Address: "",
      });
    },
    onMouseOver() {
      if (!this.$isMobile()) {
        this.hoverButtonLabel = "Disconnect";
      }
    },
    onMouseOut() {
      this.hoverButtonLabel = this.userERCTrimmed;
    },
    closeModal() {
      this.isShow = !this.isShow;
    },
    openWalletModal() {
      this.isShow = !this.isShow;
      // this.connectWallet()
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

button {
  @include respond-to(sm) {
    font-size: 14px;
    margin-top: 5px;
  }

  @include respond-above(md) {
    width: 197px;
  }
}
  .cta{
    padding: 10px 10px;
    max-width: 180px;
    @include respond-to(sm) {
      max-width: 140px;

    }
  }
</style>
