<template v-once>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper>
        <top-section-content show-right-side>
          <template v-slot:top-section-heading>
            <FontTitle text="FAQ" ></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            The most common questions that may arise when interacting with the A2DAO platform
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="faq-wrapper">
      <p class="content-block">
      <ol>
        <li>
          <strong>How can I invest in projects on A2DAO?</strong><br>
          Connect your wallet, navigate to the Active or OTC tab, select a project, review it, and send USDT over the ETH/BSC network.
        </li>
        <li>
          <strong>How do participants get their allocations?</strong><br>
          All users can participate, but allocation is not guaranteed. If there is insufficient allocation or the project issues a partial refund, users at the top of the leaderboard will have priority. The higher your position, the more likely you are to get an allocation.
        </li>
        <li>
          <strong>Do I need to hold ATD tokens to invest?</strong><br>
          No, it's not essential. However, if you'd like, you can boost your position on the leaderboard with ATD Boost. Keep in mind that you can still climb the leaderboard without the token.
        </li>
        <li>
          <strong>How can I climb the leaderboard?</strong><br>
          Learn more about the leaderboard <a href="https://a2dao.com/blog/a2dao-leaderboard" target="_blank">here</a>. Your Total Score is composed of four components: Investment Score, Project Score, DAO Score, and ATD Boost. The more frequently you participate in projects, invite friends, and take part in various activities the higher your Total Score.
        </li>
        <li>
          <strong>Does being on the leaderboard guarantee me access to allocations, participation in deals, or other advantages?</strong><br>
          No, being on the leaderboard doesn't guarantee this. It just increases your chances of taking part in the project you want.
        </li>
        <li>
          <strong>How does ATD Boost work?</strong><br>
          Boost your position on the leaderboard with a one-time payment in ATD tokens. Each $10 in ATD equals 1 point. You have two options: burn tokens to get points right away or stake them to get points gradually.
        </li>
        <li>
          <strong>Where can I purchase ATD tokens?</strong><br>
          You can purchase ATD tokens exclusively on <a href="https://www.gate.io/trade/ATD_USDT" target="_blank">Gate</a>. To see ATD in your wallet, import the token using the smart contract: <br>
          - 0x8052327F1BAF94A9DC8B26b9100f211eE3774f54 (ETH) <br>
          - 0x1ce440d1a64eea6aa1db2a5aa51c9b326930957c (BSC)
        </li>
        <li>
          <strong>Is there a fee to participate?</strong><br>
          The fee is typically 10%, but it can vary depending on market conditions and other factors.
        </li>
        <li>
          <strong>How can I stay informed about new deals?</strong><br>
          Stay informed about events by joining the <a href="https://t.me/joinchat/ZX-3BN5_VN82MjJi" target="_blank">announcement channel</a> and chat (<a href="https://t.me/+BRm3fB3qQqdlMzY0" target="_blank">EN</a>, <a href="https://t.me/+jUSAZg37dZIyMzk0" target="_blank">CIS</a>), as well as following updates on the website.
        </li>
        <li>
          <strong>What is the minimum and maximum amount to invest in a project?</strong><br>
          The minimum and maximum amount for each project will be determined individually in advance, based on the allocation size and demand.
        </li>
        <li>
          <strong>Where can I find my ranking on the leaderboard?</strong><br>
          Connect the wallet from which you have already invested in A2DAO, and go to the Leaderboard tab.
        </li>
        <li>
          <strong>I feel my ranking on the leaderboard is inaccurate.</strong><br>
          You may have connected the wrong wallet, one that you didn't make investments from previously. If you're certain you've done everything correctly but your data displays incorrectly, contact one of our admins in chat.
        </li>
        <li>
          <strong>Can I share information about the terms outside the chats?</strong><br>
          No, it is strictly forbidden. Breaking this rule may cause allocation problems and can lead to a ban from chats and confiscation of any remaining tokens.
        </li>
      </ol>
      </p>
    </div>
  </main-layout>
</template>
<script>
import TopSectionWrapper from '@/components/TopSectionWrapper';
import MainLayout from '@/layouts/main';
import TopSectionContent from '@/components/TopSectionContent';
import FontTitle from "@/components/FontTitle";

export default {
  name: 'FAQPage',
  components: {
    FontTitle,
    TopSectionWrapper,
    MainLayout,
    TopSectionContent
  },
  head: {
    meta: [
      {
        name: 'description',
        content: 'Frequently asked questions about the interaction with the A2DAO'
      },
    ],
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";

.faq-wrapper {
  border-radius: 10px;
  padding: 0 100px 115px 120px;
  margin-top: -100px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  @include respond-below(md) {
    padding: 55px 28px;
    margin-top: -50px;

  }
  @include respond-below(sm) {
    padding: 55px 12px;
    margin-top: 0;

  }
  @include respond-below(xs) {
    padding: 55px 0;
    margin-top: 0;

  }

  a {
    color: #000;

    &:visited {
      color: #000;
    }
  }

  .title {
    font-family: "Space Mono";
    font-style: normal;
    letter-spacing: 0.05em;
    font-size: 41px;
    line-height: 49px;

    @include respond-below(sm) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .subtitle {
    font-size: 24px;
    line-height: 29px;
  }

  .title,
  .subtitle {
    display: inline-block;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    margin: 0;
    letter-spacing: 0.05em;
    color: #000;
  }

  .content-block {
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 45px;
    margin-top: 45px;
    li{
      padding-bottom: 16px;
    }

    @include respond-below(sm) {
      font-size: 16px;
      line-height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
