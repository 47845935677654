const getters = {
  accessToken: (state) => state.accessToken,
  tokenExpiration: (state) => state.tokenExpiration,
  web3: (state) => state.web3,
  blockchainInfoWeb3: (state) => state.blockchainInfoWeb3,
  userInvestments: (state) => {
    return state.investments.filter((project) => {
      return (project.contractInfo?.userParticipated || project.currentUserParticipated);
    });
  },
  userOldInvestments: (state) => {
    return state.oldInvestments.filter((project) => {
      if (project.price.includes('$')) {
        project.earned = Number(project.invested / project.price.split('$')[1]).toFixed(2);
        project.claimed = Number(project.earned * project.percentage_sent / 100).toFixed(2);
      } else {
        project.earned = 'TBA';
        project.claimed = '-';
      }

      return project;
    });
  },
  userERCTrimmed: (state) => {
    let ERCAddress = state.userProfile.userERCAddress;

    return ERCAddress.slice(0, 6) + '...' + ERCAddress.slice(-5);
  },
  projects: (state) => state.projects,
  i18n: (state) => state.i18n,
  socialNetworks: (state) => state.socialNetworks,
};

export default getters;
