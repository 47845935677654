<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper no-bg> </top-section-wrapper>
    </template>
    <div class="top-section-wrapper">
      <div class="app-content-wrapper">
        <img
          src="../assets/icons/back.svg"
          @click="$router.go(-1)"
          alt="account"
          class="back-icon"
        />
        <div class="content-header">
          <div class="content-header-left">
            <div class="content-header-left-types">
              <div v-if="project.type" class="content-header-left-type usual">
                {{ project.type?.name }}
              </div>
              <div
                v-if="project.multiplier"
                class="content-header-left-type usual"
              >
                {{ project.multiplier }}
              </div>
<!--              <div-->
<!--                v-if="project.isInnovationZone"-->
<!--                class="content-header-left-type"-->
<!--              >-->
<!--                Pipeline-->
<!--              </div>-->
            </div>

            <div class="content-header-left-title">
              <FontTitle :text="project.name" class="title"></FontTitle>

              <!--              <span class="content-header-left-title-text"> {{ project.name }}-->
              <!--              {{-->
              <!--                project.type?.name.includes("IDO") ? "IDO" : project.type?.name-->
              <!--              }}<span class="content-header-left-title-text-shadow"> {{ project.name }}-->
              <!--              {{-->
              <!--                project.type?.name.includes("IDO") ? "IDO" : project.type?.name-->
              <!--              }}</span></span>-->
            </div>
            <ul
              v-if="project.isInnovationZone"
              class="content-main-social-networks-wrapper"
            >
              <li
                v-for="socialNetwork in project.socialNetworks"
                :key="socialNetwork.id"
              >
                <a :href="socialNetwork.url" target="_blank">
                  <img
                    :src="`${staticEndpoint}${socialNetwork.img}`"
                    :alt="socialNetwork.name"
                  />
                </a>
              </li>
            </ul>
            <div class="content-header-left" v-if="project.isInnovationZone">
              <div class="content-header-left-status">
                Status: <span>{{ project.status }}</span>
              </div>
              <div class="content-header-left-industry">
                <div class="content-header-left-industry-title">Industry:</div>
                <div class="content-header-left-industry-blocks">
                  <industry-badge
                    v-for="item of project.industries"
                    :key="item"
                    :industry="item"
                  />
                </div>
              </div>
              <div class="content-header-left-stage">
                Stage:
                <div class="content-header-left-stage-btn">
                  {{ project.stage.name }}
                </div>
              </div>
              <div v-if="project.isVoteAvailable">
                <div class="content-header-left-status">
                  Voted:
                  <img
                    src="../assets/icons/users.svg"
                    alt="account"
                    class="icon-users"
                  />
                  <span>
                    {{ project.interests }}
                  </span>
                  <img
                    src="../assets/icons/interest-pts.svg"
                    alt="account"
                    class="icon-interest-pts"
                  />
                  <span>
                    {{ formatedInterestTotalScore }}
                  </span>
                  &nbsp;Points
                </div>
                <div class="likes-and-interest">
                  <!--<button-component class="cta likes" @click="handleLike">
                    <img
                      :src="require(`@/assets/icons/${likeIcon}`)"
                      alt=""
                      class="like-icon"
                    />
                    <span class="likes-text">{{ project.likes }} Like</span>
                  </button-component>-->
                  <button-component
                    class="cta interests"
                    :class="{ voted: userInterestedProject }"
                    :disabled="isInterestBtnDisabled"
                    @click="handleInterest"
                  >
                    <span class="interest-text">{{ interestBtnTxt }}</span>
                  </button-component>
                </div>
              </div>
            </div>
            <p
              v-if="!project.isInnovationZone"
              class="about-tab"
              v-html="project?.teamContent"
            ></p>
            <div
              v-if="!project.isInnovationZone"
              class="content-header-left-timer"
              style="margin-bottom: 30px"
            >
              {{ project.dateStart }} - {{ project.dateEnd }}
            </div>
            <div v-if="!project.isInnovationZone && !project.subProject">
              <project-timer
                v-if="project.isActive || project.isUpcoming"
                :projectType="getProjectType"
                :state="
                  project.isActive
                    ? 'active'
                    : project.isUpcoming
                    ? 'upcoming'
                    : project.isRefunded
                    ? 'refunded'
                    : project.isFull
                    ? 'completed'
                    : 'completed'
                "
                :timestamp="project.timer"
              />
              <project-timer
                v-else-if="
                  Object.keys(project).length > 0 &&
                  !(project.isActive || project.isUpcoming)
                "
                :ended="getActiveDaysLeft"
                :projectType="getProjectType"
              />
            </div>
            <div v-if="!project.isInnovationZone && project.subProject">
              <NewProjectTimer
                :projectType="getProjectType"
                :project="project"
                :state="
                  new Date(project.subProject.timer).getTime() >
                    new Date().getTime() && project.state === 'active'
                    ? 'active'
                    : new Date(project.subProject.timer).getTime() >
                      new Date().getTime()
                    ? 'upcoming'
                    : 'completed'
                "
                :deadline="project.subProject.timer"
                :roundAllocation="project.hardCap"
                typeName="og round"
                style="margin-bottom: 2px"
              />
              <NewProjectTimer
                :projectType="getProjectType"
                :project="project"
                :state="
                  new Date(project.subProject.timer).getTime() <
                  new Date().getTime()
                    ? 'active'
                    : 'upcoming'
                "
                :deadline="
                  new Date(project.subProject.timer).getTime() <
                    new Date().getTime() && project.state !== 'active'
                    ? project.timer
                    : new Date(project.subProject.timer).getTime() <
                      new Date().getTime()
                    ? project.timer
                    : project.subProject.timer
                "
                :roundAllocation="project.subProject.hardCap"
                typeName="public round"
              />
            </div>
          </div>
          <div class="content-header-right">
            <img :src="`${staticEndpoint}/${project.cardBannerPath}`" alt="" />
            <div
              v-if="
                (!project.isInnovationZone &&
                  !project.isOTC &&
                  !project.isOTCOnMarket &&
                  project.state !== 'completed') ||
                ((project.isOTC || project.isOTCOnMarket) && project.isActive)
              "
              class="content-header-right-header"
            >
              <div v-if="!project.isInnovationZone && project.isActive">
                {{ totalStakedLabel }}
              </div>
              <div
                v-if="
                  !project.isInnovationZone &&
                  project.state !== 'completed' &&
                  !project.isActive
                "
              >
                <colored-text class="usual" style="font-size: 14px">
                  {{ totalStaked }}
                </colored-text>
                out of
                <colored-text class="usual" style="font-size: 14px">
                  {{ project.isUnlimitedHardCap ? "Unlimited" : hardCap }}
                  {{ getTokenName() }}
                </colored-text>
              </div>
              <div v-else>
                <colored-text class="usual" style="font-size: 14px">
                  {{ totalStaked }}
                </colored-text>
                out of
                <colored-text class="usual" style="font-size: 14px">
                  {{
                    project.hardCap
                      ? project.isUnlimitedHardCap
                        ? "Unlimited"
                        : hardCap
                      : softCap
                  }}
                  {{ getTokenName() }}
                </colored-text>
              </div>
            </div>
            <div
              v-if="
                (!project.isInnovationZone &&
                  !project.isOTC &&
                  !project.isOTCOnMarket &&
                  project.state !== 'completed') ||
                ((project.isOTC || project.isOTCOnMarket) && project.isActive)
              "
              class="content-header-right-scale"
            >
              <div
                class="content-header-right-scale-full"
                :style="{
                  width: project.isUnlimitedHardCap
                    ? project.softCap
                      ? getStakedPercentSoftCap * 0.95 + '%'
                      : '100%'
                    : project.hardCap
                    ? getStakedPercent + '%'
                    : getStakedPercentSoftCap + '%',
                }"
              ></div>
              <div>
                <div
                  v-if="
                    project.softCap != null &&
                    (project.isActive || project.isUpcoming)
                  "
                >
                  <div
                    class="content-header-right-scale-soft"
                    :style="{ width: softCapPercent + '%' }"
                  ></div>
                  <div
                    class="content-header-right-scale-soft-arrows"
                    :style="{
                      left:
                        getStakedPercent > softCapPercent
                          ? getStakedPercent + '%'
                          : softCapPercent + 0.4 + '%',
                      width:
                        getStakedPercent > softCapPercent
                          ? 100 - getStakedPercent + '%'
                          : 100 - softCapPercent - 0.3 + '%',
                    }"
                  ></div>
                  <div
                    v-if="softCapPercent > 50"
                    class="content-header-right-scale-soft-text"
                    :style="{ right: 100 - softCapPercent + 2 + '%' }"
                  >
                    Soft Cap {{ project.softCap ? softCap : totalStaked }}
                    {{ getTokenName() }}
                  </div>
                  <div
                    v-else
                    class="content-header-right-scale-soft-text"
                    :style="{ left: softCapPercent + 2 + '%' }"
                  >
                    Soft Cap {{ project.softCap ? softCap : totalStaked }}
                    {{ getTokenName() }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!project.isInnovationZone"
              class="content-header-right-general-info-block"
              :style="
                project.softCap != null
                  ? 'margin-top: 25px'
                  : 'margin-top: 10px'
              "
            >
              <div
                class="content-header-right-general-info-staking-info-wrapper"
                :class="{ 'disabled-sign': !project.isActive }"
              >
                <div
                  class="content-header-right-general-info-staking-info-balance-wrapper"
                >
                  <div
                    class="content-header-right-general-info-staking-info-balance"
                    :class="{
                      'set-atd-btn':
                        poolInfo &&
                        poolInfo.atdBalance !== '--' &&
                        project.isActive,
                    }"
                    @click="setMaxAtd"
                  >
                    Balance
                    <span style="margin-left: 10px; font-weight: 600"
                      >{{ currentBalance }} {{ getTokenName() }}
                    </span>
                    <span
                      style="
                        margin-left: 10px;
                        background: #c4c4c4;
                        color: #6f7173;
                        padding: 0 10px;
                      "
                      v-if="userProfile.userERCAddress"
                    >
                      My Investment
                      <span style="font-weight: 600">
                        {{
                          parseFloat(Number(poolInfo?.yourStake).toFixed(2))
                            ? parseFloat(Number(poolInfo?.yourStake).toFixed(2))
                            : 0
                        }}
                        {{ getTokenName() }}
                      </span>
                    </span>
                  </div>
                  <div
                    class="content-header-right-general-info-staking-info-input"
                  >
                    {{ getTokenName() }}
                    <input
                      v-model="stakeAmount"
                      :class="{ 'disabled-sign': !project.isActive }"
                      type="number"
                      placeholder="0"
                      :disabled="!project.isActive"
                    />
                  </div>
                </div>
                <!--                <template-->
                <!--                  v-if="userProfile.userERCAddress && project.isUpcoming"-->
                <!--                >-->
                <!--                  <tooltip-custom-->
                <!--                    content="You can update interest amount at any time"-->
                <!--                  >-->
                <!--                    <stake-button-->
                <!--                      class="express-interest"-->
                <!--                      @click.prevent="handleExpressInterest"-->
                <!--                    >-->
                <!--                      Express interest-->
                <!--                      <img-->
                <!--                        src="../assets/icons/question.svg"-->
                <!--                        alt="account"-->
                <!--                        class="question-mark"-->
                <!--                      />-->
                <!--                    </stake-button>-->
                <!--                  </tooltip-custom>-->
                <!--                </template>-->
                <tooltip-custom
                  :content="handleTooltip()"
                  :disabled="
                    (isKYCVerified() && project.isRequirementsFullFilled) ||
                    project.isFull ||
                    !project.userRankInfo ||
                    (project.subProject
                      ? !project.userRankInfo &&
                        new Date(project.subProject?.timer).getTime() <
                          new Date().getTime()
                      : false)
                  "
                >
                </tooltip-custom>
                <template v-if="userProfile.userERCAddress && project.isActive">
                  <tooltip-custom
                    :content="handleTooltip()"
                    :disabled="
                      project.isFull ||
                      !project.userRankInfo ||
                      (project.subProject
                        ? !project.userRankInfo &&
                          new Date(project.subProject?.timer).getTime() <
                            new Date().getTime()
                        : false)
                    "
                  >
                    <stake-button
                      :disabled="
                        !stakeAmount ||
                        isStakeBtnDisabled ||
                        !(
                          isKYCVerified() && project.isRequirementsFullFilled
                        ) ||
                        !project.userRankInfo ||
                        (project.subProject
                          ? !project.userRankInfo &&
                            new Date(project.subProject?.timer).getTime() >
                              new Date().getTime()
                          : false)
                      "
                      :animation="animation"
                      @click="viewModal"
                    >
                      <!--                    <stake-button-->
                      <!--                      :disabled="project.subProject ? !project.userRankInfo && new Date(project.subProject.timer).getTime() >-->
                      <!--                  new Date().getTime() : false"-->
                      <!--                      :animation="animation"-->
                      <!--                      @click="viewModal"-->
                      <!--                    >-->
                      {{ stakeBtnTxt }}
                      <img
                        v-if="
                          !(
                            isKYCVerified() && project.isRequirementsFullFilled
                          ) || !stakeAmount
                        "
                        src="../assets/icons/question.svg"
                        alt="account"
                        class="question-mark"
                      />
                    </stake-button>
                  </tooltip-custom>
                </template>
                <stake-button
                  v-else-if="
                    userProfile.userERCAddress &&
                    !project.isActive &&
                    Number(poolInfo?.yourStake) &&
                    !project.isIDO
                  "
                  :project-type="projectType(project)"
                  font-size="16px"
                  font-weight="600"
                  :disabled="isUnstakeBtnDisabled"
                  @click="handleUnstake"
                >
                  {{ unstakeBtnTxt }}
                </stake-button>
                <stake-button
                  v-else-if="!userProfile.userERCAddress"
                  :project-type="projectType(project)"
                  font-size="16px"
                  font-weight="600"
                  @click="proceedConnectWallet"
                >
                  CONNECT
                </stake-button>
              </div>
              <div
                class="content-header-right-general-info-staking-min-max"
              >
                <div
                  class="content-header-right-general-info-staking-min-max-label"
                  :class="{ 'set-atd-btn': project.isActive }"
                  @click="setStakeAmountByRange(userRankInfoMin)"
                >
                  min {{ projectMin }}
                </div>
                <div
                  class="content-header-right-general-info-staking-min-max-label"
                  :class="{ 'set-atd-btn': project.isActive }"
                  @click="setStakeAmountByRange(userRankInfoMax)"
                >
                  max {{ projectMax }}
                </div>
              </div>
            </div>
            <div
              v-if="!project.isInnovationZone"
              class="content-header-right-general-info-block"
              :style="
                project.softCap != null
                  ? 'margin-top: 25px'
                  : 'margin-top: 10px'
              "
            >
<!--              <div-->
<!--                v-if="project.isOTC || project.isOTCOnMarket"-->
<!--                class="content-header-right-general-info-staking-info-otc-wrapper"-->
<!--                :class="{ 'disabled-sign': !project.isActive }"-->
<!--              >-->
<!--                <div-->
<!--                  class="content-header-right-general-info-staking-info-balance-wrapper"-->
<!--                >-->
<!--                  <div-->
<!--                    class="content-header-right-general-info-staking-info-otc-balance"-->
<!--                    :class="{-->
<!--                      'set-atd-btn':-->
<!--                        poolInfo &&-->
<!--                        poolInfo.atdBalance !== '&#45;&#45;' &&-->
<!--                        project.isActive,-->
<!--                    }"-->
<!--                    @click="setMaxAtd"-->
<!--                  >-->
<!--                    Balance-->
<!--                    <span>{{ currentBalance }} {{ getTokenName() }} </span>-->
<!--                    <span-->
<!--                      v-if="userProfile.userERCAddress"-->
<!--                      style="margin-left: 20px"-->
<!--                    >-->
<!--                      My Investment-->
<!--                      <span>-->
<!--                        {{-->
<!--                          parseFloat(Number(poolInfo?.yourStake).toFixed(2))-->
<!--                            ? parseFloat(Number(poolInfo?.yourStake).toFixed(2))-->
<!--                            : 0-->
<!--                        }}-->
<!--                        {{ getTokenName() }}-->
<!--                      </span>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="content-header-right-general-info-staking-info-otc-input"-->
<!--                  >-->
<!--                    {{ getTokenName() }}-->
<!--                    <input-->
<!--                      v-model="stakeAmount"-->
<!--                      type="number"-->
<!--                      placeholder="0"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div-->
<!--                  class="content-header-right-general-info-otc-staking-min-max"-->
<!--                >-->
<!--                  <div-->
<!--                    class="content-header-right-general-info-staking-min-max-label"-->
<!--                    :class="{ 'set-atd-btn': project.isActive }"-->
<!--                    @click="setStakeAmountByRange(userRankInfoMin)"-->
<!--                  >-->
<!--                    min {{ projectMin }}-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="content-header-right-general-info-staking-min-max-label"-->
<!--                    :class="{ 'set-atd-btn': project.isActive }"-->
<!--                    @click="setStakeAmountByRange(userRankInfoMax)"-->
<!--                  >-->
<!--                    max {{ projectMax }}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div-->
<!--                  v-if="project.isActive"-->
<!--                  class="content-header-right-general-info-otc-staking-otc-fee"-->
<!--                >-->
<!--                  <div-->
<!--                    class="content-header-right-general-info-otc-staking-otc-fee-left"-->
<!--                  >-->
<!--                    <div>-->
<!--                      <div-->
<!--                        class="content-header-right-general-info-otc-staking-otc-fee-left-top"-->
<!--                      >-->
<!--                        Service fee-->
<!--                      </div>-->
<!--                      <div-->
<!--                        class="content-header-right-general-info-otc-staking-otc-fee-left-bot"-->
<!--                      >-->
<!--                        Total-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <div-->
<!--                        class="content-header-right-general-info-otc-staking-otc-fee-left-top"-->
<!--                      >-->
<!--                        8%-->
<!--                      </div>-->
<!--                      <div-->
<!--                        class="content-header-right-general-info-otc-staking-otc-fee-left-bot"-->
<!--                      >-->
<!--                        {{-->
<!--                          (stakeAmount * 1.08 - +stakeAmount > 50-->
<!--                            ? stakeAmount * 1.08-->
<!--                            : +stakeAmount + 50-->
<!--                          ).toFixed(2)-->
<!--                        }}-->
<!--                        USDT-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <tooltip-custom-->
<!--                    v-if="userProfile.userERCAddress"-->
<!--                    :content="handleTooltip()"-->
<!--                    :disabled="-->
<!--                      (isKYCVerified() && project.isRequirementsFullFilled) ||-->
<!--                      project.isFull ||-->
<!--                      !project.userRankInfo ||-->
<!--                      project.subProject-->
<!--                        ? !project.userRankInfo &&-->
<!--                          new Date(project.subProject?.timer).getTime() <-->
<!--                            new Date().getTime()-->
<!--                        : false-->
<!--                    "-->
<!--                  >-->
<!--                    <stake-button-->
<!--                      :disabled="-->
<!--                        !stakeAmount ||-->
<!--                        isStakeBtnDisabled ||-->
<!--                        !(-->
<!--                          isKYCVerified() && project.isRequirementsFullFilled-->
<!--                        ) ||-->
<!--                        !project.userRankInfo ||-->
<!--                        (project.subProject-->
<!--                          ? !project.userRankInfo &&-->
<!--                            new Date(project.subProject?.timer).getTime() >-->
<!--                              new Date().getTime()-->
<!--                          : false)-->
<!--                      "-->
<!--                      :animation="animation"-->
<!--                      @click="viewModal"-->
<!--                      style="min-width: 120px; margin: 0"-->
<!--                    >-->
<!--                      &lt;!&ndash;                    <stake-button&ndash;&gt;-->
<!--                      &lt;!&ndash;                      :disabled="project.subProject ? !project.userRankInfo && new Date(project.subProject.timer).getTime() >&ndash;&gt;-->
<!--                      &lt;!&ndash;                  new Date().getTime() : false"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      :animation="animation"&ndash;&gt;-->
<!--                      &lt;!&ndash;                      @click="viewModal"&ndash;&gt;-->
<!--                      &lt;!&ndash;                    >&ndash;&gt;-->
<!--                      {{ stakeBtnTxt }}-->
<!--                    </stake-button>-->
<!--                  </tooltip-custom>-->
<!--                  <stake-button-->
<!--                    v-else-->
<!--                    :project-type="projectType(project)"-->
<!--                    font-size="16px"-->
<!--                    font-weight="600"-->
<!--                    @click="proceedConnectWallet"-->
<!--                  >-->
<!--                    CONNECT-->
<!--                  </stake-button>-->
<!--                </div>-->
<!--              </div>-->
              <div class="content-header-right-investments-all">
                <div
                  v-if="project.topRankInfo"
                  class="content-header-right-investments"
                >
                  <div class="content-header-right-investments-header">
                    Investments from tops
                  </div>
                  <div class="content-header-right-investments-content">
                    <div style="width: 55px; position: relative">
                      <div class="content-header-right-investments-content-top">
                        TOP10
                      </div>
                      <div
                        class="content-header-right-investments-content-top-shadow"
                      ></div>
                    </div>
                    <div
                      class="content-header-right-investments-content-amount"
                    >
                      <div style="width: 50px">
                        <div
                          class="content-header-right-investments-content-amount"
                        >
                          <img src="../assets/icons/whale.svg" alt="" />
                          {{ project.topRankInfo.top10UsersAmount }}
                        </div>
                      </div>
                    </div>
                    <div class="content-header-right-investments-content-price">
                      {{ project.topRankInfo.top10Invested.toFixed(2)
                      }}<span>USDT</span>
                    </div>
                  </div>
                  <div class="content-header-right-investments-content">
                    <div style="width: 55px; position: relative">
                      <div class="content-header-right-investments-content-top">
                        TOP25
                      </div>
                      <div
                        class="content-header-right-investments-content-top-shadow"
                      ></div>
                    </div>
                    <div
                      class="content-header-right-investments-content-amount"
                    >
                      <div style="width: 50px">
                        <div
                          class="content-header-right-investments-content-amount"
                        >
                          <img src="../assets/icons/shark.svg" alt="" />{{
                            project.topRankInfo.top25UsersAmount
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="content-header-right-investments-content-price">
                      {{ project.topRankInfo.top25Invested.toFixed(2)
                      }}<span>USDT</span>
                    </div>
                  </div>
                  <div class="content-header-right-investments-content">
                    <div style="width: 55px; position: relative">
                      <div class="content-header-right-investments-content-top">
                        TOP100
                      </div>
                      <div
                        class="content-header-right-investments-content-top-shadow"
                        style="width: 65px"
                      ></div>
                    </div>
                    <div
                      class="content-header-right-investments-content-amount"
                    >
                      <div style="width: 50px">
                        <div
                          class="content-header-right-investments-content-amount"
                        >
                          <img src="../assets/icons/dolphin.svg" alt="" />{{
                            project.topRankInfo.top100UsersAmount
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="content-header-right-investments-content-price">
                      {{ project.topRankInfo.top100Invested.toFixed(2)
                      }}<span>USDT</span>
                    </div>
                  </div>
                  <div class="content-header-right-investments-content">
                    <div style="width: 55px; position: relative">
                      <div class="content-header-right-investments-content-top">
                        OTHER
                      </div>
                      <div
                        class="content-header-right-investments-content-top-shadow"
                      ></div>
                    </div>
                    <div
                      class="content-header-right-investments-content-amount"
                    >
                      <div style="width: 50px">
                        <div
                          class="content-header-right-investments-content-amount"
                        >
                          <img src="../assets/icons/prawn.svg" alt="" style="height: 16px; margin-top: 5px"/>{{
                            project.topRankInfo.otherUsersAmount
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="content-header-right-investments-content-price">
                      {{ project.topRankInfo.otherInvested.toFixed(2)
                      }}<span>USDT</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    project.isUnlimitedHardCap &&
                    project.keyMetrics.find((item) => item.id === 6) &&
                    parseFloat(
                      project.keyMetrics
                        .find((item) => item.id === 6)
                        .value.replace('$', '')
                        .replace(',', '')
                    ) && project.isActive
                  "
                  class="content-header-right-investments-right"
                >
                  <div class="content-header-right-investments-right-header">
                    Estimated allocation:
                  </div>
                  <div
                    class="content-header-right-investments-right-allocation"
                  >
                    {{
                      project.userProjectInfo.invested
                        ? (
                            (currentUserLeaderboard.totalScore /
                              project.totalInvestedUsersRankPoints) *
                            parseFloat(
                              project.keyMetrics
                                .find((item) => item.id === 6)
                                .value.replace("$", "")
                                .replace(",", "")
                            )
                          ).toFixed(2)
                        : (
                            (currentUserLeaderboard.totalScore /
                              (project.totalInvestedUsersRankPoints +
                                currentUserLeaderboard.totalScore)) *
                            parseFloat(
                              project.keyMetrics
                                .find((item) => item.id === 6)
                                .value.replace("$", "")
                                .replace(",", "")
                            )
                          ).toFixed(2)
                    }}
                    <span style="color: #a1a1a1">USDT</span>
                  </div>
                  <div class="content-header-right-investments-right-price">
                    ({{
                      (
                        parseFloat(
                          project.keyMetrics
                            .find((item) => item.id === 6)
                            .value.replace("$", "")
                            .replace(",", "")
                        ) /
                        (project.userProjectInfo.invested
                          ? project.totalInvestedUsersRankPoints
                          : project.totalInvestedUsersRankPoints +
                            currentUserLeaderboard.totalScore)
                      ).toFixed(2)
                    }}
                    USDT per 1 point)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-main">
          <div v-if="!project.isInnovationZone" class="content-main-title">
            About {{ project.name }}
          </div>
          <ul
            v-if="!project.isInnovationZone"
            class="content-main-social-networks-wrapper"
          >
            <li
              v-for="socialNetwork in project.socialNetworks"
              :key="socialNetwork.id"
            >
              <a :href="socialNetwork.url" target="_blank">
                <img
                  :src="`${staticEndpoint}${socialNetwork.img}`"
                  :alt="socialNetwork.name"
                />
              </a>
            </li>
          </ul>
          <div class="content-main-details">
            <div class="about-tab" v-html="project?.about"></div>
            <div class="key-metrics">
              <ul>
                <li :class="{ active: showMetrics }" @click="openMetrics">
                  Key Metrics
                </li>
                <li
                  v-if="project?.eventRules"
                  :class="{ active: showRules }"
                  @click="openRules"
                >
                  Event Rules
                </li>
              </ul>
              <div v-show="showMetrics" class="colored-border usual">
                <div
                  v-for="metric in allMetrics"
                  class="metric-wrapper"
                  :key="metric.id"
                >
                  <div class="metric-wrapper-element">
                    <div class="metric-name">
                      {{ metric.name }}
                    </div>
                    <div class="metric-value">
                      {{ metric.value }}
                    </div>
                  </div>
                  <hr />
                </div>
                <div class="colored-border-squares-one"></div>
                <!--                <div class="colored-border-squares-second"></div>-->
              </div>
              <div class="colored-border usual" v-show="showRules">
                <div v-html="project?.eventRules"></div>
                <div class="colored-border-squares-one"></div>
                <!--                <div class="colored-border-squares-second"></div>-->
              </div>

              <iframe
                v-if="youtubeLink"
                :src="`https://www.youtube.com/embed/${youtubeLink}`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div class="carousel-wrapper" v-if="loadCarousel">
                <agile
                  ref="carousel"
                  class="carousel-main"
                  :options="carouselOptions"
                  @click="toggleGallery"
                >
                  <div
                    class="carousel-cell"
                    v-for="image in project.images"
                    :key="image"
                  >
                    <img :src="`${imgPath}/${image}`" alt="" />
                  </div>
                </agile>
                <agile ref="nav" :options="carouselNavOptions">
                  <div
                    class="carousel-nav"
                    v-for="(image, index) in project.images"
                    :key="`nav-${image}`"
                  >
                    <img
                      :src="`${imgPath}/${image}`"
                      alt=""
                      @click="this.$refs.carousel.goTo(index)"
                    />
                  </div>
                </agile>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modals>
      <allowance-modal />
    </template>
    <div class="gallery" v-show="galleryOpened">
      <div class="gallery-slide-wrapper">
        <div
          class="gallery-prev"
          :class="{ disabled: !loadCarousel }"
          style="color: white"
          @click="previousSlide(loadCarousel)"
        >
          <img src="@/assets/icons/angle-left.svg" alt="" />
        </div>
        <div class="gallery-close" @click="toggleGallery(loadCarousel)"></div>
        <img :src="currentSlideUrl" class="gallery-slide" alt="" />
        <div class="gallery-close" @click="toggleGallery(loadCarousel)"></div>
        <div
          class="gallery-next"
          :class="{ disabled: !loadCarousel }"
          style="color: white"
          @click="nextSlide(loadCarousel)"
        >
          <img src="@/assets/icons/angle-right.svg" alt="" />
        </div>
      </div>
      <div class="gallery-overlay" @click="toggleGallery(loadCarousel)"></div>
    </div>
    <warning-modal
      @handleStake="handleStake"
      @closeModal="closeModal"
      v-if="isShow"
    />
  </main-layout>
</template>

<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import { API } from "@/api";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import {
  projectType,
  capitalize,
  checkChainId,
  scrollToSmoothly,
  PROJECT_TYPE_TYS,
  calculateEstimation,
} from "@/helpers";
import WarningModal from "@/components/WarningModal";
import ProjectTimer from "@/components/ProjectTimer";
import StakeButton from "@/components/buttons/StakeButton";
import ButtonComponent from "@/components/Button.vue";
import { useToast } from "vue-toastification";
import TooltipCustom from "@/components/TooltipCustom";
import AllowanceModal from "@/components/AllowanceModal";
import { toRaw } from "vue";
import ProjectService from "@/services/project";
import {
  SET_ALLOWANCE_INCREASE_STATUS,
  SET_SWAP_ALLOWANCE_AMOUNT,
  TOGGLE_MODAL,
} from "@/store/mutation-types";
import ColoredText from "@/components/ColoredText";
import { VueAgile } from "vue-agile";
import IndustryBadge from "@/components/IndustryBadge";
import FontTitle from "@/components/FontTitle";
import NewProjectTimer from "@/components/NewProjectTimer";

export default {
  name: "InnovationProjectPage",
  components: {
    NewProjectTimer,
    FontTitle,
    StakeButton,
    ProjectTimer,
    TooltipCustom,
    MainLayout,
    TopSectionWrapper,
    ButtonComponent,
    ColoredText,
    agile: VueAgile,
    IndustryBadge,
    WarningModal,
    AllowanceModal,
  },
  head() {
    const curProject = toRaw(this.project);

    if (
      curProject &&
      Object.keys(curProject).length !== 0 &&
      this.project.type
    ) {
      let projectStatus = "Active";

      if (this.project.isUpcoming) {
        projectStatus = "Upcoming";
      }

      if (!this.project.isActive) {
        projectStatus = "Completed";
      }

      return {
        title: `${this.project.name} | ${projectStatus} crypto project deals | Participate in the ${this.project.type.name}`,
        meta: [
          {
            name: 'description',
            content: `Buy and stake CPLT token to invest in ${this.project.name}. Grow young crypto projects with Catapult.`,
          },
        ],
      };
    }

    return {};
  },
  mounted() {
    window.addEventListener("orientationchange", this.backgroundImage);
    if (!this.project.isAuction) {
      clearInterval(this.intervalForAuction);
      clearInterval(this.intervalForWinner);
    }
    this.$store.dispatch("fetchLeaderboard");
  },
  unmounted() {
    window.removeEventListener("orientationchange", this.backgroundImage);
  },
  computed: {
    interestBtnTxt() {
      return this.userInterestedProject ? "Retract vote" : "I want to invest";
    },
    currentUserIndex() {
      const result = this.leaderboard?.filter((user, index) => {
        if (user.erc20Address === this.userProfile?.userERCAddress) {
          user.index = index + 1;
          return user;
        }
      });
      return result[0]?.index || this.leaderboard.length;
    },
    isLeaderInvestmentsActive() {
      return (
        new Date(this.project.subProject.timer).getTime() > new Date().getTime()
      );
    },
    softCapPercent() {
      let softCap = !this.project?.hardCap
        ? 100
        : this.project?.softCap
        ? (this.project?.softCap * 100) /
          (this.project?.subProject
            ? this.project?.subProject?.hardCap + this.project?.hardCap
            : this.project?.hardCap)
        : 100;
      return this.project.isUnlimitedHardCap ? 95 : softCap;
    },
    userRankInfoMin() {
      if (this.project.userRankInfo && this.project.userRankInfo.min && +this.poolInfo?.yourStake < +this.project.userRankInfo.min) {
        return +this.project.userRankInfo.min;
      } else if (this.project.userRankInfo && +this.poolInfo?.yourStake < +this.project.min) {
        return +this.project.min;
      } else {
        return 0;
      }
    },
    userRankInfoMax() {
      if (this.project.userRankInfo && this.project.userRankInfo.max) {
        return +this.project.userRankInfo.max;
      } else if (this.project.userRankInfo) {
        return +this.project.max;
      } else {
        return 0;
      }
    },
    allMetrics() {
      let metrics = this.project.keyMetrics;
      if (this.project.blockchain) {
        metrics.unshift(this.project.blockchain);
      }
      return metrics;
    },
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    likeIcon() {
      return this.userLikedProject ? "like-filled-white.svg" : "like-white.svg";
    },
    interestIcon() {
      return this.userInterestedProject
        ? "interest-filled-white.svg"
        : "interest-white.svg";
    },
    userLikedProject() {
      return this.userProfile?.projectLikes?.includes(this.project.id);
    },
    userInterestedProject() {
      return this.userProfile?.projectInterests?.includes(this.project.id);
    },
    imgPath() {
      return `${this.staticEndpoint}/public/storage/project-images/en/${this.project.hashId}/media`;
    },
    totalStaked() {
      return this.project.isOTC || this.project.isOTCOnMarket
        ? new Intl.NumberFormat("en-US").format(
            Number(this.project.totalInvestments).toFixed(0)
          )
        : this.poolInfo
        ? new Intl.NumberFormat("en-US").format(
            Number(this.poolInfo.totalStaked).toFixed(0)
          )
        : 0;
    },
    softCap() {
      return this.project
        ? new Intl.NumberFormat("en-US").format(
            Number(this.project.softCap).toFixed(0)
          )
        : 0;
    },
    hardCap() {
      let hardCap =
        this.project && this.project.hardCap
          ? new Intl.NumberFormat("en-US").format(
              Number(
                this.project?.subProject
                  ? this.project?.hardCap + this.project?.subProject?.hardCap
                  : this.project?.hardCap
              ).toFixed(0)
            )
          : 0;
      return this.project.isUnlimitedHardCap ? "Unlimited" : hardCap;
    },
    projectMax() {
      return this.project
        ? new Intl.NumberFormat("en-US").format(
            Number(this.userRankInfoMax).toFixed(0)
          )
        : 0;
    },
    projectMin() {
      return this.project
        ? new Intl.NumberFormat("en-US").format(
            Number(this.userRankInfoMin).toFixed(0)
          )
        : 0;
    },
    currentBalance() {
      return this.poolInfo
        ? new Intl.NumberFormat("en-US").format(
            Math.trunc(this.poolInfo.atdBalance * 100) / 100
          )
        : 0;
    },
    currentBalanceNormalized() {
      return this.poolInfo
        ? Math.trunc(this.poolInfo.atdBalance * 100) / 100
        : 0;
    },
    yourStake() {
      if (this.poolInfo && this.poolInfo.yourStake !== "--") {
        return new Intl.NumberFormat("en-US").format(
          parseFloat(Number(this.poolInfo.yourStake).toFixed(2))
        );
      }

      return "--";
    },
    formatedInterestTotalScore() {
      return (
        new Intl.NumberFormat("en-US").format(
          parseFloat(Number(this.project.interestTotalScore).toFixed(2))
        ) ?? 0
      );
    },
    estimatedAllocation() {
      if (this.poolInfo && this.poolInfo.earned !== "--") {
        return new Intl.NumberFormat("en-US").format(
          parseFloat(Number(this.poolInfo.earned).toFixed(2))
        );
      }

      return "--";
    },
    projectState() {
      return this.project.state;
    },
    loadCarousel() {
      return this.project.images && this.project.images.length > 0;
    },
    youtubeLink() {
      let videoId = null;

      if (this.project.youtubeLink && this.project.youtubeLink) {
        videoId = this.project.youtubeLink.split("=")[1];
      }

      return videoId;
    },
    getActiveDaysLeft() {
      return this.project?.activeDaysLeftParsed
        ? this.project.activeDaysLeftParsed
        : {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
    },
    getStakedPercent() {
      return !(this.project.isOTC || this.project.isOTCOnMarket)
        ? this.project.state === "completed"
          ? 100
          : (this.poolInfo?.totalStaked * 100) / this.project?.hardCap
        : !this.project.isActive
        ? 100
        : (this.poolInfo?.totalStaked * 100) / this.project?.hardCap;
    },
    getStakedPercentSoftCap() {
      return this.project.state === "completed"
        ? 100
        : (this.poolInfo?.totalStaked * 100) / this.project?.softCap;
    },
    getProjectType() {
      return this.project?.type?.name ? this.project.type.name : "The event";
    },
    showTotalStakedInTopSection() {
      return this.projectState === "active";
    },
    totalStakedLabel() {
      return this.project.isIDO ||
        this.project.isIDOAdvanced ||
        this.project.isOTC ||
        this.project.isOTCOnMarket
        ? this.i18n["project-total-invested-label"]
        : this.i18n["project-total-staked-label"];
    },
    yourStakeLabel() {
      return this.project.isIDO
        ? this.i18n["project-your-investment-label"]
        : this.i18n["project-your-stake-label"];
    },
    stakeBtnDefaultTxt() {
      return this.project.isOTC || this.project.isOTCOnMarket
        ? "INVEST"
        : this.project.isIDO
        ? this.i18n["project-card-cta-ido"]
        : this.i18n["project-card-cta-lockdrop"];
    },
    projectTypeTys() {
      return PROJECT_TYPE_TYS;
    },
    ...mapState([
      "userProfile",
      "CPLTContractAbi",
      "CPLTContractAddress",
      "allowanceIncreaseStatus",
      "newTokenName",
      "kycStatus",
      "leaderboard",
      "currentUserLeaderboard",
    ]),
    ...mapGetters(["web3", "i18n"]),
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    allowanceIncreaseStatus(newVal, oldVal) {
      if (newVal === "success") {
        this.proceedStake();
      }

      if (newVal === "failure") {
        this.enableStakeButton();
      }
    },
    //eslint-disable-next-line no-unused-vars
    userProfile(newVal, oldVal) {
      const rawProjectData = { ...this.project };

      if (
        Object.keys(rawProjectData).length &&
        !rawProjectData?.isInnovationZone
      ) {
        this.fetchPoolInfo();
      }
    },
    galleryOpened: function () {
      if (this.galleryOpened) {
        document.body.style.overflow = "hidden";
        return;
      }

      document.body.style.overflow = "auto";
    },
    poolInfo() {
      if (
        this.userProfile.userERCAddress &&
        this.poolInfo &&
        this.poolInfo.yourStake !== "--"
      ) {
        this.handleUserStakeInconsistent();
      }
    },
  },
  setup() {
    const toast = useToast();

    return { toast, projectType, calculateEstimation };
  },
  data() {
    return {
      tooltipHover: false,
      project: {},
      poolInfo: {
        totalStaked: 0,
        yourStake: "--",
        earned: "--",
        atdBalance: 0,
        idoTokenAddress: "",
      },
      carouselOptions: {
        fade: false,
        dots: false,
        navButtons: false,
      },
      carouselNavOptions: {
        fade: false,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
      },
      tabsOptions: {
        useUrlFragment: false,
      },
      leftSideMetrics: [],
      rightSideMetrics: [],
      stakeAmount: "",
      stakeBtnTxt: "STAKE",
      isStakeBtnDisabled: false,
      unstakeBtnDefaultTxt: "UNSTAKE",
      unstakeBtnTxt: "UNSTAKE",
      isUnstakeBtnDisabled: false,
      currentAccount: null,
      currentContract: null,
      galleryOpened: false,
      currentSlideUrl: "",
      isYouTubeInGallery: false,
      intervalForAuction: null,
      intervalForWinner: null,
      winner: "--",
      showMetrics: true,
      showRules: false,
      isShow: false,
      animation: false,
      isInterestBtnDisabled: false,
    };
  },
  methods: {
    ...mapActions([
      "connectWallet",
      "fetchProjectContractInfo",
      "setProjectForUser",
      "updateProjectEndTime",
      "likeProject",
      "dislikeProject",
      "interestProject",
      "uninterestProject",
    ]),
    async handleUserStakeInconsistent() {
      const currentContractAddress = this.project.contractAddress;
      const currentContractAbi = JSON.parse(this.project.contractAbi);
      this.currentContract = new this.web3.eth.Contract(
        currentContractAbi,
        currentContractAddress
      );
      const yourStakeFromContract = this.web3.utils.fromWei(
        await this.currentContract.methods
          .userStaked(this.project.poolId, this.userProfile.userERCAddress)
          .call()
      );

      if (
        Number(yourStakeFromContract) &&
        parseFloat(Number(yourStakeFromContract).toFixed(2)) >
          this.poolInfo.yourStake
      ) {
        const missedInvestment =
          yourStakeFromContract - this.poolInfo.yourStake;

        await this.$store.dispatch("setProjectForUser", {
          projectId: this.project.id,
          stakeAmount: missedInvestment,
        });
      }
    },
    async handleLike() {
      if (this.userProfile.userERCAddress) {
        if (this.userLikedProject) {
          await this.dislikeProject(this.project.id);
          this.project.likes--;
        } else {
          await this.likeProject(this.project.id);
          this.project.likes++;
        }
      }
    },
    async handleInterest() {
      this.isInterestBtnDisabled = true;
      if (this.userProfile.userERCAddress) {
        if (this.userInterestedProject) {
          await this.uninterestProject(this.project.id);
          this.project.interests--;
          this.project.interestTotalScore -= this.userProfile.totalScore;
        } else {
          await this.interestProject(this.project.id);
          this.project.interests++;
          this.project.interestTotalScore += this.userProfile.totalScore;
        }
      }
      this.isInterestBtnDisabled = false;
    },
    ...mapMutations({
      toggleModal: TOGGLE_MODAL,
      setSwapAmount: SET_SWAP_ALLOWANCE_AMOUNT,
      setAllowanceIncreaseStatus: SET_ALLOWANCE_INCREASE_STATUS,
    }),
    openMetrics() {
      this.showMetrics = true;
      this.showRules = false;
    },
    openRules() {
      this.showMetrics = false;
      this.showRules = true;
    },
    projectStateProp(project) {
      if (project.isActive && project.isFull) {
        return "full";
      }
      if (project.isRefunded) {
        return "refunded";
      }
      return this.projectState;
    },
    projectTxtState(project) {
      if (project.isActive && project.isFull) {
        return "Full";
      }
      if (project.isRefunded) {
        return this.i18n["investments-table-protected-label"];
      }
      return this.projectState;
    },
    getTokenName() {
      if (
        this.project.isIDOAdvanced &&
        this.poolInfo?.yourStake !== "--" &&
        Number(this.poolInfo?.yourStake).toFixed(2) > 0
      ) {
        return this.project.subProject.currency ?? this.newTokenName;
      }

      return this.project.currency ?? this.newTokenName;
    },
    proceedConnectWallet() {
      this.connectWallet();
      this.isStakeBtnDisabled = this.project.isFull;
    },
    isKYCVerified() {
      return !(this.project.isKYCRequired && this.kycStatus !== "approved");
    },
    handleTooltip() {
      let finalized = [];

      if (this.project.isFull) {
        return "Pool limit reached";
      }

      if (!this.project.isRequirementsFullFilled) {
        const requiredFields = this.project.requirementsList || [];
        finalized = requiredFields.map((field) => {
          if (field === "polkadot_address") {
            return "Polkadot";
          }
          if (field === "near_address") {
            return "Near";
          }
          if (field === "solana_address") {
            return "Solana";
          }
          if (field === "kusama_address") {
            return "Kusama";
          }
          if (field === "sui_address") {
            return "Sui";
          }
          if (field === "cosmos_address") {
            return "Cosmos";
          }

          return capitalize(field);
        });
      }
      if (!this.project.userRankInfo) {
        return "Improve your ranking on the leaderboard to participate in this activity";
      }
      if (this.project.isKYCRequired && this.kycStatus !== "approved") {
        finalized.push("KYC");
      }
      if (finalized.length) {
        return `Please fill ${finalized.join(
          ", "
        )} in your profile to participate`;
      }
      if (!this.stakeAmount){
        return 'Enter the amount'
      }

      // return "Please note that 1% of staked tokens will be deducted during the unstake";
    },
    async proceedStake() {
      if (this.project.isOTC || this.project.isOTCOnMarket) {
        this.stakeBtnTxt = "Investing...";
      } else if (this.project.isIDO) {
        this.stakeBtnTxt = "Investing...";
      } else {
        this.stakeBtnTxt = "Staking...";
      }
      this.setAllowanceIncreaseStatus("");
      let poolId;
      if (this.project.subProject) {
        if (this.isLeaderInvestmentsActive) {
          poolId = this.project.subProject.poolId;
        } else {
          poolId = this.project.poolId;
        }
      } else {
        poolId = this.project.poolId;
      }
      await this.stake(poolId);
    },
    async fetchPoolInfo() {
      try {
        if (this.web3.currentProvider) {
          const chainId = await this.web3.eth.getChainId();
          checkChainId(chainId);
        }
      } catch (e) {
        console.error(e.message);
      }

      this.poolInfo = await this.$store.dispatch(
        "fetchProjectContractInfo",
        this.project
      );
    },
    async fetchProject() {
      const self = this;
      this.fetchProjectData(this.$route.params.slug).then(async function () {
        try {
          self.$refs.carousel?.settings.asNavFor.push(self.$refs.nav);
          self.$refs.nav?.settings.asNavFor.push(self.$refs.carousel);
          self.$refs.carousel?.reload();
          self.$refs.nav?.reload();

          if (!self.project.isInnovationZone) {
            await self.$store.dispatch("initWeb3");
            await self.fetchPoolInfo();

            if (
              Number(self.project.hardCap) <=
                Number(self.poolInfo?.totalStaked) &&
              !self.project.isFull && !self.project.isUnlimitedHardCap
            ) {
              await ProjectService.setIsFull(self.$route.params.slug);
              self.project.isFull = true;
            }

            if (
              !self.project.isActive &&
              self.poolInfo?.yourStake !== "--" &&
              Number(self.poolInfo?.yourStake).toFixed(2) > 0
            ) {
              self.stakeAmount = parseFloat(
                Number(self.poolInfo?.yourStake).toFixed(2)
              );
            }

            self.isStakeBtnDisabled =
              self.project.isFull && !!self.userProfile.userERCAddress;

            // if (self.project.keyMetrics) {
            //   if (self.project.blockchain) {
            //     self.project.keyMetrics.unshift(self.project.blockchain);
            //   }
            //   self.splitKeyMetrics(self.project.keyMetrics);
            // }

            if (self.project.isAuction) {
              self.getWinner();
            }

            self.stakeBtnTxt =
              self.project.isOTC || self.project.isOTCOnMarket
                ? "INVEST"
                : self.project.isWC2022
                ? "BET"
                : self.project.isIDO
                ? self.i18n["project-card-cta-ido"]
                : self.i18n["project-card-cta-lockdrop"];
          }
        } catch (e) {
          console.log(e);
        }
      });
    },
    async fetchProjectData(slug) {
      const response = await API.get(`/projects/${slug}/full`);
      this.project = response.data;
      if (this.project.hideForUser) {
        this.$router.push("/");
      }
    },
    splitKeyMetrics(metrics) {
      const self = this;
      metrics.forEach(function (metric, index) {
        if (index % 2 === 0) {
          self.leftSideMetrics.push(metric);
        } else {
          self.rightSideMetrics.push(metric);
        }
      });
    },
    enableStakeButton() {
      this.setAllowanceIncreaseStatus("");
      this.isStakeBtnDisabled = false;
      this.stakeBtnTxt = this.stakeBtnDefaultTxt;
    },
    async stake(poolId) {
      let stakeAmount = this.stakeAmount
        // this.project.isOTC || this.project.isOTCOnMarket
        //   ? this.stakeAmount * 1.08 - +this.stakeAmount > 50
        //     ? this.stakeAmount * 1.08
        //     : +this.stakeAmount + 50
        //   : this.stakeAmount;
      const gasPrice = await this.web3.eth.getGasPrice();
      const gasLimit = await this.currentContract.methods
        .stake(poolId, this.web3.utils.toWei(stakeAmount.toString()))
        .estimateGas({ from: this.userProfile.userERCAddress });
      await this.currentContract.methods
        .stake(poolId, this.web3.utils.toWei(stakeAmount.toString()))
        .send({
          from: this.userProfile.userERCAddress,
          gasLimit: Math.round(gasLimit * 1.1),
          gasPrice: gasPrice,
        });

      const curTime = Math.floor(new Date().getTime() / 1000);
      const projectEndTime = Math.floor(
        new Date(this.project.timer).getTime() / 1000
      );

      if (this.project.isAuction && curTime <= projectEndTime) {
        await this.updateProjectEndTime(this.$route.params.slug);
        await this.fetchProjectData(this.$route.params.slug);
      }

      this.enableStakeButton();
      await this.$store.dispatch("setProjectForUser", {
        projectId: this.project.id,
        stakeAmount: this.stakeAmount,
      });
      await this.fetchProjectData(this.$route.params.slug);
      await this.fetchPoolInfo();
      this.toast.success("Staking transaction completed");
    },

    async validateStakeAmount(project, currentAtdBalance) {
      let stakeAmount = this.stakeAmount
        // this.project.isOTC || this.project.isOTCOnMarket
        //   ? this.stakeAmount * 1.08 - +this.stakeAmount > 50
        //     ? this.stakeAmount * 1.08
        //     : +this.stakeAmount + 50
        //   : this.stakeAmount;
      let min = this.userRankInfoMin;
      let max = this.userRankInfoMax;
      let yourStake = this.web3.utils.fromWei(
        await this.currentContract.methods
          .userStaked(project.poolId, this.userProfile.userERCAddress)
          .call()
      );

      if (project.isIDOAdvanced) {
        min = project.subProject.min;
        max = project.subProject.max;
      }

      if (!stakeAmount) {
        this.toast.error("Please, enter staking amount.");
        return false;
      }

      if (+stakeAmount > +currentAtdBalance) {
        this.toast.error("Insufficient funds.");
        return false;
      }

      if (stakeAmount && stakeAmount >= min && stakeAmount <= max - yourStake) {
        return true;
      }

      this.toast.error(this.i18n["out-of-range-stake-validation"]);
      return false;
    },
    async handleExpressInterest() {
      await this.$store.dispatch("setExpressInterest", {
        projectId: this.project.id,
        amount: this.stakeAmount,
      });
      this.toast.success("Success");
    },
    viewModal() {
      this.isShow = true;
      this.animation = !this.animation;
      setTimeout(() => {
        this.animation = !this.animation;
      }, 300);
    },
    closeModal() {
      this.isShow = false;
    },
    async handleStake() {
      // evt.preventDefault();
      let poolId;
      if (this.project.subProject) {
        if (this.isLeaderInvestmentsActive) {
          poolId = this.project.subProject.poolId;
        } else {
          poolId = this.project.poolId;
        }
      } else {
        poolId = this.project.poolId;
      }
      const project = this.project;
      const isIDO = project.isIDO;
      const isOTC = project.isOTC;
      const isOTCOnMarket = project.isOTCOnMarket;
      const isIDOAdvanced = project.isIDOAdvanced;
      let currentContractAddress = project.contractAddress;
      let currentContractAbi = JSON.parse(project.contractAbi);
      this.currentContract = new this.web3.eth.Contract(
        currentContractAbi,
        currentContractAddress
      );
      const currentAccount = this.userProfile.userERCAddress;
      let tokenContract = new this.web3.eth.Contract(
        this.CPLTContractAbi,
        this.CPLTContractAddress
      );
      let yourStake = 0;
      let tokenName = this.getTokenName();
      let stakeAmount = this.stakeAmount
        // isOTC || isOTCOnMarket
        //   ? this.stakeAmount * 1.08 - +this.stakeAmount > 50
        //     ? this.stakeAmount * 1.08
        //     : +this.stakeAmount + 50
        //   : this.stakeAmount;

      try {
        yourStake =
          parseFloat(
            this.web3.utils.fromWei(
              await this.currentContract.methods
                .userStaked(poolId, currentAccount)
                .call()
            )
          ) || 0;
      } catch (e) {
        console.error(e.message);
      }

      // if (isIDOAdvanced && yourStake > 0) {
      //   currentContractAddress = project.subProject.contractAddress;
      //   poolId = project.subProject.poolId;
      //   currentContractAbi = JSON.parse(project.subProject.contractAbi);
      //   this.currentContract = new this.web3.eth.Contract(
      //     currentContractAbi,
      //     currentContractAddress
      //   );
      //   this.poolInfo = await this.currentContract.methods
      //     .poolInfo(poolId)
      //     .call();
      //   tokenContract = new this.web3.eth.Contract(
      //     this.CPLTContractAbi,
      //     this.poolInfo.token
      //   );
      // }

      if (
        (isIDO && this.currentBalanceNormalized > 0) ||
        isOTC ||
        isOTCOnMarket
      ) {
        tokenContract = new this.web3.eth.Contract(
          this.CPLTContractAbi,
          this.poolInfo.idoTokenAddress
        );
      }

      if (currentAccount) {
        await this.$store.dispatch(
          "changeChain",
          await this.web3.eth.getChainId()
        );

        const currentAtdBalance = this.web3.utils.fromWei(
          await tokenContract.methods.balanceOf(currentAccount).call()
        );

        if (await this.validateStakeAmount(project, currentAtdBalance)) {
          try {
            this.isStakeBtnDisabled = true;

            const allowance = this.web3.utils.fromWei(
              await tokenContract.methods
                .allowance(currentAccount, currentContractAddress)
                .call()
            );

            this.setSwapAmount(stakeAmount);

            if (Number(allowance) < Number(stakeAmount)) {
              this.stakeBtnTxt = "Approving...";
              this.toggleModal({
                modalID: "modal-allowance",
                options: {
                  atdBalance: currentAtdBalance,
                  currentContractAddress: currentContractAddress,
                  // TODO rename atdContract to general naming
                  atdContract: tokenContract,
                  tokenName,
                },
              });
            } else {
              if (isOTC || isOTCOnMarket) {
                this.stakeBtnTxt = "Investing...";
              } else if (isIDO || (isIDOAdvanced && yourStake > 0)) {
                this.stakeBtnTxt = "Investing...";
              } else {
                this.stakeBtnTxt = "Staking...";
              }
              await this.stake(poolId);
            }
          } catch (e) {
            this.toast.error(e.message);
            this.enableStakeButton();
          }
        }
      }
    },
    async handleUnstake(evt) {
      evt.preventDefault();

      const project = this.project;
      let currentContractAddress = project.contractAddress;
      let poolId = project.poolId;
      const isIDO = project.isIDO;
      let currentContractAbi = JSON.parse(project.contractAbi);
      let currentContract = new this.web3.eth.Contract(
        currentContractAbi,
        currentContractAddress
      );
      const currentAccount = this.userProfile.userERCAddress;
      let yourStake = this.poolInfo.yourStake;

      yourStake = yourStake.replace(/,/g, "");

      if (currentAccount) {
        await this.$store.dispatch(
          "changeChain",
          await this.web3.eth.getChainId()
        );

        try {
          this.isUnstakeBtnDisabled = true;
          this.unstakeBtnTxt = "Unstaking...";
          //await changeChain(chainId);
          if (isIDO) {
            await currentContract.methods.withdraw(poolId).send({
              from: currentAccount,
            });
          } else {
            await currentContract.methods
              .withdraw(poolId, this.web3.utils.toWei(yourStake))
              .send({
                from: currentAccount,
              });
          }
          this.unstakeBtnTxt = this.unstakeBtnDefaultTxt;
          this.toast.success("Unstaking transaction completed");

          await this.fetchPoolInfo();
        } catch (e) {
          this.isUnstakeBtnDisabled = false;
          this.unstakeBtnTxt = this.unstakeBtnDefaultTxt;
          this.toast.error(e.message);
        }
      } else {
        this.toast.error(this.i18n["wallet-is-not-connected"]);
      }
    },
    backgroundImage(isBanner = false) {
      const staticEndpoint = this.staticEndpoint;
      let path = "";

      if (isBanner) {
        if (this.$isMobile()) {
          path += this.project.bannerMobilePath
            ? `${staticEndpoint}/${this.project.bannerMobilePath}`
            : "";
        } else {
          path += this.project.bannerPath
            ? `${staticEndpoint}/${this.project.bannerPath}`
            : "";
        }
      } else {
        path += this.project.cardBannerPath
          ? `${staticEndpoint}/${this.project.cardBannerPath}`
          : "";
      }

      return path;
    },
    scrollToStakeInput() {
      const offsetTop =
        this.$refs.projectPageContentWrapper.getBoundingClientRect()?.top;

      scrollToSmoothly(offsetTop);
    },
    setMaxAtd() {
      if (this.project.isActive) {
        const yourBalance = this.poolInfo.atdBalance;
        const allowedAmount = this.project.max - this.yourStake;

        if (yourBalance >= allowedAmount) {
          this.stakeAmount = allowedAmount;
        } else {
          this.stakeAmount = yourBalance;
        }
      }
    },
    setStakeAmountByRange(amount) {
      if (
        this.project.isActive &&
        this.poolInfo.yourStake < this.userRankInfoMax
      ) {
        if (amount === this.userRankInfoMax) {
          if (
            this.poolInfo.atdBalance > 0 &&
            this.poolInfo.atdBalance <= amount &&
            this.poolInfo.atdBalance >= this.userRankInfoMin
          ) {
            this.stakeAmount = this.poolInfo.atdBalance;
          } else {
            this.stakeAmount = amount;
          }
        } else {
          this.stakeAmount = amount;
        }
      }
    },
    toggleGallery(isCarousel = true) {
      this.switchSlide(isCarousel);
      this.galleryOpened = !this.galleryOpened;
    },
    switchSlide(isCarousel) {
      if (isCarousel) {
        const currentSlide = this.$refs.carousel.getCurrentSlide();
        const currentSlidePath =
          this.project?.images[
            this.youtubeLink ? currentSlide - 1 : currentSlide
          ];
        this.isYouTubeInGallery = !!(
          this.youtubeLink && currentSlidePath === undefined
        );
        this.currentSlideUrl = `${this.imgPath}/${currentSlidePath}`;
      } else {
        this.currentSlideUrl = this.backgroundImage();
      }
    },
    nextSlide(isCarousel = true) {
      if (isCarousel) {
        this.$refs.carousel.goToNext();
      }
      this.switchSlide(isCarousel);
    },
    previousSlide(isCarousel = true) {
      if (isCarousel) {
        this.$refs.carousel.goToPrev();
      }
      this.switchSlide(isCarousel);
    },
    async getWinner() {
      const { startBlock = "999999999", endBlock = "999999999" } = this.project;
      const response = await API.get(
        `https://api.bscscan.com/api?module=account&action=txlist&address=0x507a7c9e4d6e35c496b67951ee675741cafa0617&startblock=${startBlock}&endblock=${endBlock}&page=1&offset=10&sort=desc&apikey=2NQWMXAUQI2TIVIUXKMD3BD6UC5YPWQHEW`,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
        }
      );
      const winnerAddress = response.data.result[0]["from"];

      this.winner =
        winnerAddress?.slice(0, 6) + "....." + winnerAddress?.slice(-5) || "--";
    },
  },
  async created() {
    await this.fetchProject();

    this.intervalForAuction = setInterval(async () => {
      if (this.project.isAuction) {
        await this.fetchProjectData(this.$route.params.slug);
        await this.fetchPoolInfo();
      }
    }, 5000);

    this.intervalForWinner = setInterval(async () => {
      if (this.project.isAuction) {
        await this.getWinner();
      }
    }, 15000);
  },
  beforeUnmount() {
    document.body.style.overflow = "auto";
    clearInterval(this.intervalForAuction);
    clearInterval(this.intervalForWinner);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

$animation-speed: 1s;
.app-content-wrapper {
  padding-right: 30px;
}
.top-section {
  &-wrapper {
    background: transparent;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    position: relative;
    justify-content: center;
    /*padding-top: 125px;*/

    transition: 0.5s ease all;
    min-height: 100vh;

    @include respond-to(xs) {
      padding-top: 25px;
      overflow: hidden;
      background-size: cover;
      background-position: 50% 50%;
    }

    @include respond-between(sm, xl) {
      padding-left: 0;
      padding-right: 0;
    }

    &.no-bg {
      box-shadow: none;
      background: none;
      min-height: inherit;
    }
  }

  &-bg {
    position: absolute;
    background: url("../assets/top-section-content-bg.svg");
    top: 155px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    &.hide {
      display: none;
    }

    @include respond-to(sm) {
      top: 0;
    }
  }
}

.figure-bg {
  background-image: url("../assets/figure.svg");
  position: absolute;
  max-width: 360px;
  width: 100%;
  height: 400px;
  right: 0;
  top: 6%;
  @include respond-to(xs) {
    display: none;
  }
}
.figure-bg-top {
  background-image: url("../assets/figure-top.svg");
  position: absolute;
  overflow: hidden;
  width: 452px;
  height: 139px;
  right: 0;
  top: 0;
  @include respond-to(xs) {
    display: none;
  }
}
.figure-bg-rotate {
  background-image: url("../assets/figure.svg");
  position: absolute;
  width: 410px;
  height: 400px;
  left: -2%;
  top: -6%;
  transform: rotate(225deg);
  @include respond-to(xs) {
    display: none;
  }
}
.figure-bg-revert-x {
  background-image: url("../assets/figure.svg");
  position: absolute;
  width: 410px;
  height: 400px;
  left: 0;
  top: 6%;
  transform: scaleX(-1);
  @include respond-to(xs) {
    display: none;
  }
}
.content-header {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  column-gap: 30px;
  margin-bottom: 50px;

  @include respond-to(sm) {
    flex-direction: column;
    row-gap: 30px;
  }
  @include respond-to(xs) {
    flex-direction: column;
    row-gap: 30px;
  }
  &-left {
    display: flex;
    flex-direction: column;
    min-width: 520px;
    max-width: 520px;
    row-gap: 10px;
    position: relative;
    @include respond-to(xs) {
      min-width: 100%;
    }
    &-types {
      display: flex;
      column-gap: 14px;
    }
    &-type {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 130%;
      /* identical to box height, or 17px */

      text-align: center;
      text-transform: uppercase;

      color: #000;
      border-radius: 6px;
      border: 1px solid;
      width: fit-content;
      padding: 3px 13px 2px;
      margin-bottom: 14px;
      border-image-slice: 1;
      border-image-source: #000;

      &.lockdrop {
        border-image-source: linear-gradient(to left, #00b1d2, #1eb676);
      }

      &.ido {
        border-image-source: linear-gradient(to left, #ff7f23, #fff400);
      }

      &.nft {
        border-image-source: linear-gradient(to left, #ff3f71, #ffad72);
      }

      &.true-yield-staking {
        border-image-source: linear-gradient(to left, #ac3bff, #7ccaf7);
      }
    }
    &-title {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 58px;
      line-height: 102%;
      color: #ffffff;
      text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
      /* identical to box height, or 59px */

      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      min-width: 620px;
      &-text {
        position: relative;
        &-shadow {
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: -1;
          width: 100%;
        }
      }

      @include respond-to(md) {
        min-width: 480px;
        font-size: 50px;
      }

      @include respond-to(xs) {
        font-size: 40px;
        min-width: 100%;
      }
      /* Style */

      /*background: linear-gradient(
        165.48deg,
        #eecbd4 0.69%,
        #edcfdb 12.57%,
        #eadcf1 31.37%,
        #eadff6 34.34%,
        #d7dff3 50.17%,
        #bde1f1 69.96%,
        #bff6e7 99.65%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;*/
    }
    p {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      /* or 5px */

      display: flex;
      align-items: center;

      color: #000;
      max-width: 470px;
    }
    &-timer {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      color: #000;
      /* or 23px */

      display: flex;
      align-items: center;
    }
    &-status {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;

      /* #A2A2A2 */

      color: #a2a2a2;
      .icon {
        &-users,
        &-interest-pts {
          margin-left: 10px;
        }
      }
      span {
        font-weight: bold;
        margin-left: 5px;
        color: #000;
      }
    }
    &-industry {
      display: flex;
      justify-content: flex-start;
      max-width: 400px;
      width: 100%;
      align-items: center;

      &-title {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        /* or 23px */

        display: flex;
        align-items: center;
        text-align: center;

        /* #A2A2A2 */

        color: #a2a2a2;
      }
      &-blocks {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
        padding-top: 8px;
        margin-left: 8px;
      }
    }
    &-stage {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;

      /* #A2A2A2 */

      color: #a2a2a2;
      &-btn {
        display: flex;
        align-items: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        background: linear-gradient(180deg, #92d0ac 0%, #18b4e9 100%);
        padding: 3px 10px;
        margin-left: 10px;
        @include respond-to(sm) {
          font-size: 14px;
        }
      }
    }
  }
  &-right {
    img {
      max-width: 570px;
      max-height: 400px;
      min-height: auto;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      @include respond-to(sm) {
        max-width: 100%;
        max-height: 100%;
        min-width: auto;
        min-height: auto;
        flex-direction: column;
      }
      @include respond-to(xs) {
        max-width: 100%;
        max-height: 100%;
        min-width: auto;
        min-height: auto;
        flex-direction: column;
      }
    }
    &-header {
      display: flex;
      justify-content: space-between;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      align-items: center;
      margin: 3px 0 3px;
      /* 2 */

      color: #000;
      @media (max-width: 450px) {
        font-size: 11px !important;
      }
      span {
        font-weight: 700;

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media (max-width: 450px) {
          font-size: 11px !important;
        }
      }
    }
    &-scale {
      position: relative;
      width: 100%;
      height: 14px;
      box-sizing: border-box;
      border: 1px solid gray;
      &-full {
        position: absolute;
        max-width: 100%;
        height: 12px;
        background: linear-gradient(129.36deg, #75e1db 27.47%, #cee3e0 128.48%);
      }
      &-soft {
        position: absolute;
        background: transparent;
        border-right: 2px dashed #000000;
        height: 21px;
        top: -4px;
        &-text {
          position: absolute;
          top: 15px;
          font-size: 14px;
          @include respond-to(xs) {
            font-size: 11px;
          }
        }
        &-arrows {
          position: absolute;
          background: linear-gradient(180deg, #b7b7b7 0%, #cfcfcf 100%);
          opacity: 0.2;
          height: 100%;
        }
      }
    }
    &-general-info {
      flex: 1;
      margin-left: 45px;

      @include respond-to(xs) {
        margin-left: 0;
      }

      @include respond-to(sm) {
        width: 50%;
      }

      &-project-name {
        font-size: 24px;

        &-wrapper {
          max-width: 240px;
        }
      }

      &-meta {
        margin: 15px 0;

        :first-child {
          margin-right: 15px;
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          flex-flow: column-reverse;
        }
      }

      &-label {
        margin-bottom: 10px;
      }

      &-block {
        /*margin-top: 30px;*/
      }

      &-staking {
        &-ends-in,
        &-min-max {
          display: flex;
          justify-content: space-between;

          &-label {
            color: #a2a2a2;
            font-size: 14px;
            font-weight: 400;
          }
        }

        &-info {
          &-wrapper {
            border: 1px solid #a2a2a2;
            filter: drop-shadow(0 0 65px rgba(0, 0, 0, 0.3));
            border-radius: 4px;
            padding: 9px 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            /*<!--@include respond-to(xs) {-->*/
            /*<!--  padding: 5px 15px;-->*/
            /*<!--}-->*/
          }
          &-otc-wrapper {
            border: 1px solid #a2a2a2;
            filter: drop-shadow(0 0 65px rgba(0, 0, 0, 0.3));
            border-radius: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
            position: relative;
            font-family: "Space Mono";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 130%;
            /* identical to box height, or 16px */

            letter-spacing: -0.02em;

            /* #353535 */

            color: #353535;
            height: auto;
          }
          &-otc-balance {
            background: linear-gradient(
              180deg,
              rgb(159, 218, 230, 0.2) 0%,
              rgb(126, 172, 182, 0.2) 100%
            );
            border-bottom: 1px solid #a2a2a2;
            height: 25px;
            width: calc(100% - 30px);
            padding: 0 15px;
            &-wrapper {
              @include respond-to(xs) {
                font-size: 10px;
              }
            }
          }
          &-otc-input {
            font-weight: 600;
            font-size: 32px;
            color: #000;
            padding: 15px 15px 0;
            @include respond-to(xs) {
              font-size: 21px;
              bottom: 10px;
            }

            input {
              font-family: "Space Mono", sans-serif;
              font-weight: 600;
              background: transparent;
              border: 0;
              color: #000;
              -moz-appearance: textfield;
              width: 65%;
              @include respond-to(md) {
                width: 50%;
              }
              @include respond-to(xs) {
                width: 58%;
              }

              &:focus,
              &:active {
                border: 0;
                outline: 0;
              }

              &::placeholder {
                color: #000;
                opacity: 0.6; /* Firefox */
              }

              &:-ms-input-placeholder {
                color: #000;
              }

              *::-ms-input-placeholder {
                color: #000;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }

          &-balance {
            font-family: "Space Mono";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            /* or 18px */

            display: flex;
            align-items: center;

            /* #A2A2A2 */

            color: #6f7173;
            @include respond-to(md) {
              font-size: 11px;
            }
            @include respond-to(xs) {
              position: absolute;
              font-size: 10px;
            }

            &-wrapper {
              display: flex;
              flex-direction: column;
              height: 60px;
              justify-content: space-between;
              @include respond-to(xs) {
                font-size: 10px;
              }
            }
          }

          &-input {
            font-weight: 600;
            font-size: 32px;
            color: #000;
            padding-top: 5px;
            max-width: 380px;
            @include respond-to(xs) {
              font-size: 21px;
              position: absolute;
              bottom: 10px;
            }

            input {
              font-family: "Space Mono", sans-serif;
              font-weight: 600;
              background: transparent;
              border: 0;
              color: #000;
              -moz-appearance: textfield;
              width: 65%;
              @include respond-to(md) {
                width: 50%;
              }
              @include respond-to(xs) {
                width: 58%;
              }

              &:focus,
              &:active {
                border: 0;
                outline: 0;
              }

              &::placeholder {
                color: #000;
                opacity: 0.6; /* Firefox */
              }

              &:-ms-input-placeholder {
                color: #000;
              }

              *::-ms-input-placeholder {
                color: #000;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }
      }
      &-otc-staking {
        &-ends-in,
        &-min-max {
          display: flex;
          justify-content: space-between;
          margin: 20px 15px 20px 15px;
          padding-top: 5px;
          border-top: 1px solid #a2a2a2;

          &-label {
            color: #a2a2a2;
            font-size: 14px;
            font-weight: 400;
          }
        }

        &-info {
          &-otc-wrapper {
            border: 1px solid #a2a2a2;
            filter: drop-shadow(0 0 65px rgba(0, 0, 0, 0.3));
            border-radius: 4px;
            margin-top: 5px;
            margin-bottom: 5px;
            position: relative;
            font-family: "Space Mono";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 130%;
            /* identical to box height, or 16px */

            letter-spacing: -0.02em;

            /* #353535 */

            color: #353535;
            height: 200px;
          }
          &-otc-balance {
            background: linear-gradient(
              180deg,
              rgb(159, 218, 230, 0.2) 0%,
              rgb(126, 172, 182, 0.2) 100%
            );
            border-bottom: 1px solid #a2a2a2;
            height: 25px;
            width: calc(100% - 30px);
            padding: 0 15px;
            &-wrapper {
              @include respond-to(xs) {
                font-size: 10px;
              }
            }
          }
          &-otc-input {
            font-weight: 600;
            font-size: 32px;
            color: #000;
            padding: 15px 35px 0 15px;
            @include respond-to(xs) {
              font-size: 21px;
              position: absolute;
              bottom: 10px;
            }

            input {
              font-family: "Space Mono", sans-serif;
              font-weight: 600;
              background: transparent;
              border: 0;
              color: #898d8f;
              -moz-appearance: textfield;
              width: 65%;
              @include respond-to(md) {
                width: 50%;
              }
              @include respond-to(xs) {
                width: 58%;
              }

              &:focus,
              &:active {
                border: 0;
                outline: 0;
              }

              &::placeholder {
                color: #000;
                opacity: 0.6; /* Firefox */
              }

              &:-ms-input-placeholder {
                color: #000;
              }

              *::-ms-input-placeholder {
                color: #000;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }
        &-otc-fee {
          display: flex;
          justify-content: space-between;
          padding: 15px 18px 15px 15px;
          gap: 20px;
          &-left {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 230px;
            font-family: "Space Mono";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 130%;
            align-items: center;
            /* identical to box height, or 16px */

            letter-spacing: -0.02em;
            &-top {
              color: #6f7173;
            }
            &-bot {
              margin-top: 5px;
            }
          }
        }
      }
    }
    &-investments {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 15px;
      margin-bottom: 10px;

      @include respond-to(xs) {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      @include respond-to(sm) {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      &-header {
        font-family: "Space Mono";
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-align: left;
        text-transform: uppercase;
      }
      &-content {
        display: flex;
        flex-direction: row;
        gap: 36px;
        &-top {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #ffffff;
          background: #a1a1aa;
          padding: 2px 4px;
          width: fit-content;
          z-index: 0;
          position: relative;
          border: 1px solid #000000;

          &-shadow {
            position: absolute;
            top: 1px;
            left: 3px;
            width: 100%;
            height: 100%;
            z-index: -1;
            border: 1px solid #000000;
            opacity: 0.4;
          }
        }
        &-amount {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.02em;
          text-align: left;
          width: fit-content;
          img {
            width: 19px;
            height: 20px;
            margin-right: 4px;
            margin-top: 3px;
          }
        }
        &-price {
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.02em;
          text-align: left;
          span {
            color: #a2a9bd;
            padding: 0 4px;
          }
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        &-header {
          font-family: Space Mono;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.02em;
          text-align: left;
          text-transform: uppercase;
        }
        &-allocation {
          font-family: Space Mono;
          font-size: 20px;
          font-weight: 700;
          text-align: left;
          letter-spacing: -0.02em;
        }
        &-price {
          font-family: Space Mono;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #6f7173;
        }
      }
    }
  }
}
.content-main {
  display: flex;
  flex-direction: column;
  /*margin-top: 100px;*/
  &-title {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 92%;
    /* identical to box height, or 59px */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    @include respond-to(md) {
      font-size: 42px;
    }

    /* Linear */

    color: #000;
    @include respond-to(xs) {
      font-size: 40px;
    }
  }
  &-social-networks {
    &-wrapper {
      display: flex;
      list-style-type: none;
      padding-left: 5px;
      padding-bottom: 15px;
      li {
        &:not(:last-child) {
          margin-right: 22px;
        }

        img {
          max-width: 21px;
        }
      }
    }
  }
}
.content-main-details {
  display: flex;
  justify-content: space-between;
  @include respond-to(sm) {
    flex-direction: column-reverse;
    row-gap: 60px;
  }
  @include respond-to(xs) {
    flex-direction: column-reverse;
    row-gap: 60px;
  }
}

.key-metrics {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 460px;
  position: relative;
  /*row-gap: 30px;*/

  ul {
    margin: 0;

    text-decoration: none;
    list-style-type: none;
    display: flex;
    @include respond-to(sm) {
      padding-left: 0;
    }
    @include respond-to(xs) {
      padding-left: 0;
    }
    li {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      letter-spacing: -0.035em;
      text-transform: capitalize;
      padding: 0 13px 0 0;
      color: #a2a2a2;
    }
    .active {
      color: #000;
      /*background: #d9d9d9;*/
      /*border-left: 1px solid #000000;*/
      /*border-top: 1px solid #000000;*/
      /*border-right: 1px solid #000000;*/
      padding: 0 13px 0 0;
      height: 46px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      letter-spacing: -0.04em;
      text-transform: capitalize;

      /* Linear */

      background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .colored-border {
    position: relative;
    background: #d9d9d9;
    /*border: 1px solid;*/
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
    border-image-slice: 1;
    padding: 42px;
    width: calc(100% - 84px);
    margin-left: 40px;
    color: #000;
    line-height: 22px;
    letter-spacing: 0.05em;
    div {
      p {
        a {
          color: #000;
        }
      }
    }
    &-squares-one {
      position: absolute;
      background-image: url("../assets/squares-no-borders.svg");
      bottom: -24px;
      left: -25px;
      width: 224px;
      height: 208px;
      z-index: -1;
    }
    &-squares-second {
      position: absolute;
      background-image: url("../assets/squares-no-borders.svg");
      bottom: -20px;
      right: -21px;
      width: 224px;
      height: 208px;
      z-index: -1;
    }
    @include respond-to(sm) {
      margin-left: 0;
    }
    @include respond-to(xs) {
      box-sizing: border-box;
      margin-left: 0;
      width: 100%;
    }

    &:first-child {
      margin-right: 20px;

      @include respond-to(xs) {
        border-bottom: none;
      }
    }

    &:last-child {
      @include respond-to(xs) {
        /*border-top: none;*/
      }
    }

    &.usual {
      border-image-source: #000;
    }

    &.lockdrop {
      border-image-source: linear-gradient(to left, #00b1d2, #1eb676);
    }

    &.ido {
      border-image-source: linear-gradient(to left, #ff7f23, #fff400);
    }

    &.nft {
      border-image-source: linear-gradient(to left, #ff3f71, #ffad72);
    }

    &.true-yield-staking {
      border-image-source: linear-gradient(to left, #ac3bff, #7ccaf7);
    }
  }

  .metrics {
    display: flex;
    flex-direction: column;

    &-label {
      font-size: 20px;
      font-weight: 400;
      color: #000;
      margin-bottom: 8px;
    }

    &-wrapper {
      display: flex;

      @include respond-to(xs) {
        flex-flow: column;
      }
    }
  }

  .metric {
    &-wrapper {
      display: flex;
      flex-direction: column;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      &-element {
        display: flex;
        justify-content: space-between;
      }
      hr {
        width: 100%;
        height: 1px;
        background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
        border: none;
      }
      @include respond-to(xs) {
        font-size: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &-name {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      letter-spacing: -0.05em;

      /* #272727 */

      color: #272727;
      margin-right: 20px;
      text-align: left;
    }

    &-value {
      text-align: right;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      letter-spacing: -0.025em;

      /* Linear */

      background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &-images {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    img {
      width: 100%;
      height: 100%;
      max-width: 420px;
      max-height: 260px;
    }
    @include respond-to(sm) {
      margin-left: 0;
    }
    @include respond-to(xs) {
      margin-left: 0;
    }
  }
}
iframe {
  width: 100%;
  height: 100%;
  max-height: 259px;
  margin-left: 40px;
  margin-top: 30px;
  @include respond-to(sm) {
    margin-left: 0;
  }
  @include respond-to(xs) {
    margin-left: 0;
  }
  @media (max-width: 460px) {
    width: 360px;
    height: 200px;
  }
}
.carousel {
  &-wrapper {
    margin-top: 100px;
    width: 100%;
    min-height: 500px;
    max-width: 420px;
    margin-left: 40px;
    @include respond-to(sm) {
      margin-left: 0;
      width: 100%;
      min-height: 340px;
    }

    @include respond-to(xs) {
      margin-left: 0;
      width: 100%;
      min-height: 340px;
    }
  }

  &-main {
    border-radius: 10px;
    cursor: pointer;

    @include respond-to(xs) {
      box-shadow: none;
      filter: none;
    }
  }

  &-cell {
    img {
      border-radius: 10px;
      width: 100%;
      max-width: 460px;
      max-height: 240px;
    }
  }

  &-nav {
    margin-top: 15px;
    cursor: pointer;
    max-width: 146px;
    max-height: 75px;

    img {
      width: 95%;
      border-radius: 4px;
    }

    &.agile__slide--active {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 10px;
        left: 0;
        bottom: 0;
        -webkit-box-shadow: inset 0 0 50px 50px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 50px 50px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.gallery {
  &-slide {
    max-width: 90%;
    max-height: 500px;
    position: relative;
    z-index: 20;

    @include respond-below(md) {
      width: 75%;
    }

    &-wrapper {
      top: 20%;
      left: 0;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 20;
      padding: 0 20px;
      width: 100%;
      box-sizing: border-box;
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 15;
  }

  &-close {
    width: 100%;
    position: absolute;
    height: 100%;
  }

  &-prev,
  &-next {
    cursor: pointer;
    z-index: 20;
    padding: 100px 20px;

    &.disabled {
      cursor: unset;
    }

    img {
      width: 15px;
    }
  }
}
:deep(.popper) {
  --popper-theme-background-color: #000000;
  --popper-theme-background-color-hover: #000000;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 16px;
  z-index: 2;
}
.content-header-right-investments-all {
  display: flex;
  justify-content: space-between;
  @include respond-to(md) {
    flex-direction: column;
    margin-bottom: 60px;
  }

  @include respond-to(xs) {
    flex-direction: column;
    margin-bottom: 60px;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/mixins";

.about-tab {
  color: #000;
  padding-left: 0;
  padding-right: 0;
  line-height: 22px;
  letter-spacing: 0.05em;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;

  @include respond-to(md) {
    max-width: 500px;
  }

  @include respond-to(xs) {
    padding-left: 0;
    padding-right: 0;
  }

  h2 {
    /*padding: 25px 0 5px !important;*/
    font-family: "Space Mono", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    margin-top: 20px;

    //background: linear-gradient(92.82deg, #ac3bff 0%, #7ccaf7 50.7%);
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    background: #000;
    background-clip: text;
    text-fill-color: transparent;

    @include respond-to(xs) {
      /*padding: 30px 0 !important;*/
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  img {
    width: 100% !important;
  }
  a {
    color: #000;
  }
  ul {
    margin: 0 0 20px 0;
  }
}
.content-header-left {
  p {
    a {
      color: #000;
    }
  }

  .likes-and-interest {
    display: flex;
    margin-top: 30px;

    img {
      margin-right: 8px;
    }
  }
  .likes,
  .interests {
    display: flex;
    min-width: 160px;
    width: auto;
    align-items: center;
    letter-spacing: 0;

    span {
      font-size: 18px;
      word-spacing: -4px;
    }
  }
  .likes {
    min-width: 150px;
    margin-right: 10px;
  }
}
.question-mark {
  position: relative;
  width: 17px !important;
  left: 5px;
}

.set-atd-btn {
  cursor: pointer;
}

.disabled-sign {
  cursor: no-drop;
}
</style>
<style lang="scss">
.colored-border {
  div {
    p {
      a {
        color: #000 !important;
      }
    }
  }
}
.back-icon {
  cursor: pointer;
  margin-top: 30px;
}

</style>
