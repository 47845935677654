<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper>
        <top-section-content show-right-side show-social-networks>
          <template v-slot:top-section-heading>
            <span class="heading">
              KYC submitted
            </span>
          </template>
          <template v-slot:top-section-sub-heading>
            <div class="sub-heading">
              Your KYC application has been submitted. Please wait for approval.
            </div>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
  </main-layout>
</template>
<script>
import TopSectionWrapper from '@/components/TopSectionWrapper';
import MainLayout from '@/layouts/main';
import TopSectionContent from '@/components/TopSectionContent';
import { useRoute } from 'vue-router';
import { API } from '@/api';
import { useToast } from 'vue-toastification';

export default {
  name: 'KycFinishedPage',
  components: {
    TopSectionWrapper,
    MainLayout,
    TopSectionContent
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const code = route.query.code;

    API.post('/user/create-kyc', {
      code
    }).catch(e => {
      toast.error(e.response.data.message);
    })
  }
}
</script>
