<template>
  <!-- Modal -->
  <div class="modal-mask" @click.exact="hideList">
    <div class="modal-mask-container">
      <header>
        You can burn your ATD here to get a higher ranking on the leaderboard. 1
        point is {{ coefficient }} ATD burned.
      </header>
      <div class="modal-mask-container-content">
        <div class="modal-mask-container-content-left">
          <div class="modal-mask-container-content-left-torch"></div>
        </div>
        <div class="modal-mask-container-content-right">
          <div>Points to be received</div>
          <div class="modal-mask-container-content-right-points">
            {{ point ? point.toFixed(10) : 0 }}
          </div>
        </div>
      </div>
      <div>
        <div
          class="dropdown-networks"
          @click="openList"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
        >
          <div style="display: flex; justify-content: flex-start; gap: 5px">
            <img
              :src="require(`../assets/icons/${currentNetwork}.svg`)"
              alt=""
            />
            {{ chosenNetwork }}
          </div>
          <img
            class="arrow"
            src="../assets/icons/angle-bottom.svg"
            :style="
              isOpen
                ? ' transform: rotate(180deg); transition-duration: 100ms;'
                : 'transform: rotate(0deg); transition-duration: 100ms;'
            "
            alt=""
          />
        </div>
        <div
          v-if="isOpen"
          class="dropdown-networks-content"
          style="position: absolute"
        >
          <ul>
            <li @click="chooseNetwork('BNB')">
              <img src="../assets/icons/BNB.svg" alt="" />BNB Smart Chain
            </li>
            <li @click="chooseNetwork('ETH')">
              <img src="../assets/icons/ETH.svg" alt="" />Ethereum Mainnet
            </li>
          </ul>
        </div>
      </div>
      <BurnedInput
        :atdContractData="atdContractData"
        @transferBurnToken="transferBurnToken"
        @culcPoint="culcPoint"
      />
      <button
        class="modal-mask-container-button"
        :disabled="!(value && value <= balance && !burnLoading)"
        @click="transferBurnToken"
      >
        <img src="../assets/icons/burn-icon.svg" alt="burn" />
        {{ !burnLoading ? "Burn" : "Burning..." }}
      </button>
      <!--      <div class="modal-mask-container-close" @click="closeModal">X</div>-->
      <close-modal-button @click="closeModal"></close-modal-button>
    </div>
  </div>
</template>

<script>
import BurnedInput from "@/components/BurnedInput";
import { mapGetters, mapState } from "vuex";
import CloseModalButton from "@/components/buttons/CloseModalButton";
export default {
  name: "BernedModal",
  components: { CloseModalButton, BurnedInput },
  data() {
    return {
      point: 0,
      value: 0,
      currentNetwork: "BNB",
      isOpen: false,
      isMouseOver: false,
    };
  },
  props: {
    coefficient: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  computed: {
    ...mapState(["atdContractData", "burnLoading", "currentNetworkDropdown"]),
    ...mapGetters(["web3"]),
    balance() {
      return (
        this.atdContractData.balance /
        Math.pow(10, this.atdContractData.decimals)
      );
    },
    chosenNetwork() {
      if (this.currentNetwork === "BNB") {
        return "BNB Smart Chain";
      } else if (this.currentNetwork === "ETH") {
        return "Ethereum Mainnet";
      } else {
        return "BNB Smart Chain";
      }
    },
  },
  async mounted() {
    if ((await this.web3.eth.getChainId()) === 56) {
      this.currentNetwork = "BNB";
    } else if ((await this.web3.eth.getChainId()) === 1) {
      this.currentNetwork = "ETH";
    } else {
      this.currentNetwork = "BNB";
      await this.$store.dispatch(
        "changeChainToBNB",
        await this.web3.eth.getChainId()
      );
    }
    // let network = 'BSC'
    // if (network === 'BSC'){
    //   await this.$store.dispatch("changeChainToETH", await this.web3.eth.getChainId());
    //   await this.$store.dispatch("getReadMethods");
    // } else {

    // }
  },
  methods: {
    handleMouseOver() {
      this.isMouseOver = true;
    },
    handleMouseOut() {
      this.isMouseOver = false;
    },
    chooseNetwork(network) {
      this.currentNetwork = network;
      this.isOpen = false;
    },
    openList() {
      this.isOpen = !this.isOpen;
    },
    hideList() {
      setTimeout(() => {
        if (!this.isMouseOver) {
          this.isOpen = false;
        }
      }, 100);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    culcPoint(value) {
      this.value = value;
      this.point = value / this.coefficient;
    },
    async transferBurnToken() {
      this.$store.commit("BURN_LOADING", true);
      await this.$store.dispatch("getAtdToken", {
        value: this.value,
        coefficient: this.coefficient,
        network: this.currentNetwork,
      });
      await this.$store.dispatch("fetchLeaderboard");
      this.$emit("closeModal");
    },
  },
  watch: {
    async currentNetwork() {
      if (this.currentNetwork === "BNB") {
        await this.$store.dispatch(
          "changeChainToBNB",
          await this.web3.eth.getChainId()
        );
        await this.$store.dispatch("getReadMethods", this.currentNetwork);
      } else if (this.currentNetwork === "ETH") {
        await this.$store.dispatch(
          "changeChainToETH",
          await this.web3.eth.getChainId()
        );
        await this.$store.dispatch("getReadMethods", this.currentNetwork);
      }
    },
    currentNetworkDropdown() {
      this.currentNetwork = this.currentNetworkDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: grid;
  place-items: center;
  header {
    padding: 0 1rem;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;

    /* Linear */

    background: linear-gradient(142.91deg, #080607 -32.36%, #374544 68.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  z-index: 100;
  &-container {
    position: relative;
    max-width: calc(570px - 4rem);
    padding: 3rem 2rem;
    background: #e5e7eb;
    &-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
    &-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      max-width: calc(450px - 2rem);
      width: 100%;
      margin: 2rem auto;
      padding: 2rem;
      background: #ffffff;
      &-left {
        position: relative;
        width: 100px;
        &-torch {
          background: url("../assets/torch.gif") no-repeat center;
          width: 80px;
          height: 130px;
          position: absolute;
          top: -35px;
          background-size: cover;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.5rem;
        &-points {
          margin-top: 10px;
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 38px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.025em;

          /* Style */

          background: linear-gradient(180deg, #4f5463 0%, #1e1f23 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
    &-button {
      margin-top: 35px;
      background: linear-gradient(180deg, #fcb655 0%, #fbb234 100%);
      width: 100%;
      height: 60px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 124%;
      border: none;

      /* or 25px */

      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;

      color: #fefefe;
      cursor: pointer;
      &:disabled {
        background: #777 !important;
        color: #ccc;
        cursor: help;
      }
      img {
        width: 28px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
}
.dropdown-networks {
  width: 200px;
  height: 32px;
  border: 1px solid #dad9f7;
  padding: 4px 8px;
  gap: 5px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
}
ul {
  font-family: Space Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  position: absolute;
  list-style: none;
  padding: 8px;
  background: #ffffff;
  z-index: 100000 !important;
  li {
    align-items: center;
    height: 32px;
    width: 200px;
    display: flex;
    gap: 5px;
    cursor: pointer;
    padding-left: 10px;
  }
  li:hover {
    background: #e5e7eb;
  }
}
.arrow {
  width: 13px;
  height: 13px;
}
</style>
