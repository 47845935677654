<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper class="pb-40">
        <top-section-content show-right-side style="height: 200px">
          <template v-slot:top-section-heading>
            <FontTitle text="Settings"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            <div class="sub-heading">
              Specify your social networks and other blockchain addresses to
              receive tokens
            </div>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="input">
      <div class="input-left">
        <img src="../assets/icons/mail.svg" alt="" />Email
      </div>
      <div class="input-right">
        <input
          type="email"
          placeholder="Enter Email"
          v-model="userProfile.email"
        />
        <img
          v-if="
            userProfile.email !== email &&
            (userProfile.email !== null || email !== '')
          "
          src="../assets/icons/save-icon.svg"
          alt=""
          @click="saveProfileInfo"
        />
      </div>
    </div>
    <div class="title-wrapper">
      <div class="title-wrapper-text">My wallets</div>
    </div>
    <div class="inputs-wrapper">
      <div :key="wallet" class="input">
        <div class="input-left">
          <img src="../assets/wallets/erc20.svg" alt="" />ERC20
        </div>
        <div class="input-right">
          <input
            type="text"
            class="my-profile-input my-profile-input-email"
            :disabled="true"
            :placeholder="`Enter ERC20 wallet`"
            v-model="userProfile.userERCAddress"
          />
        </div>
      </div>
      <div
        v-for="(wallet, key) in userProfile.wallets"
        :key="wallet"
        class="input"
      >
        <div class="input-left">
          <img
            v-if="key.toLowerCase() !== 'venom' && key.toLowerCase() !== 'sui'"
            :src="require(`../assets/wallets/${key.toLowerCase()}.svg`)"
            alt=""
          />          <div v-else style="width: 18px; height: 16px"></div>
          {{ key }}
        </div>
        <div class="input-right">
          <input
            type="text"
            class="my-profile-input my-profile-input-email"
            :placeholder="`Enter ${key} wallet`"
            v-model="userProfile.wallets[key].value"
          />
          <img
            v-if="
              userProfile.wallets[key].value !==
                walletsArray[key.toLowerCase()] &&
              (userProfile.wallets[key].value !== null ||
                walletsArray[key.toLowerCase()] !== '')
            "
            src="../assets/icons/save-icon.svg"
            alt=""
            @click="saveProfileInfo"
          />
        </div>
      </div>
    </div>
    <div class="title-wrapper">
      <div class="title-wrapper-text">Social accounts</div>
    </div>
    <div class="inputs-wrapper">
      <div
        v-for="(social, key) in userProfile.socials"
        :key="social"
        class="input"
      >
        <div class="input-left" style="justify-content: center">
          {{ key }}
        </div>
        <div class="input-right">
          <input
            type="text"
            class="my-profile-input my-profile-input-email"
            :placeholder="`Enter ${key}`"
            v-model="userProfile.socials[key].value"
          />
          <img
            v-if="
              userProfile.socials[key].value !==
                socialArray[key.toLowerCase()] &&
              (userProfile.socials[key].value !== null ||
                socialArray[key.toLowerCase()] !== '')
            "
            src="../assets/icons/save-icon.svg"
            alt=""
            @click="saveProfileInfo"
          />
        </div>
      </div>
      <div class="your-referral">
        <div class="your-referral-left">
          <div class="your-referral-left-title">Your referral link</div>
          <div class="your-referral-left-input">
            <input v-model="referralLink" type="text" />
            <div class="your-referral-left-input-copy" @click="getReferralLink">
              <img src="../assets/icons/copy-icon.svg" alt="" />Copy link
            </div>
          </div>
          <div class="your-referral-left-text">
            Referee should sign up using your link and participate in any activity
          </div>
        </div>
<!--        <div class="your-referral-right">-->
<!--          <div class="your-referral-right-text">Score</div>-->
<!--          <div class="your-referral-right-amount">0</div>-->
<!--        </div>-->
      </div>
      <div class="input">
        <div class="kyc">
          <div>KYC</div>
          <div
            v-if="kycStatus === 'verify'"
            :class="`profile-status ${kycStatus}`"
          >
            <div class="verify-wrapper">
              <a :href="verifyKycURL"> Verify </a>
            </div>
          </div>
          <div
            v-else-if="kycStatus === 'approved'"
            :class="`profile-status ${kycStatus}`"
          >
            <img src="../assets/approved-kyc-icon.svg" alt="" />
            <span>Approved</span>
          </div>
          <div
            v-else-if="kycStatus === 'pending'"
            :class="`profile-status ${kycStatus}`"
          >
            <img src="../assets/pending-kyc-icon.svg" alt="" />
            <span>Pending</span>
          </div>
          <div v-else class="profile-status rejected">
            <img src="../assets/rejected-kyc-icon.svg" alt="" />
            <span>Rejected</span>
          </div>
        </div>
      </div>
      <div class="title-wrapper">
        <div class="title-wrapper-text" style="margin: 0">
          <button-component
            class="account-btn"
            @click="saveProfileInfo(true)"
            :style="
              animation
                ? 'transform: translate(6px, 6px);transition-duration: 200ms;'
                : ''
            "
            >SAVE
            <div
              class="button-shadow"
              :style="
                !animation
                  ? 'transform: translate(0, 0);'
                  : 'transform: translate(-6px, -6px); transition-duration: 200ms;'
              "
            ></div>
          </button-component>
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
// import { mapState } from "vuex";
// import { trimMiddle } from "@/helpers";
// import { API } from "@/api";
// import TooltipCustom from "@/components/TooltipCustom";
// import FooterComponent from "@/components/Footer";
import MainLayout from "@/layouts/main";
import { mapActions, mapMutations, mapState } from "vuex";
import { toRaw } from "vue";
import { API } from "@/api";
import { useToast } from "vue-toastification";
import ButtonComponent from "@/components/Button";
import FontTitle from "@/components/FontTitle";
import TopSectionWrapper from "@/components/TopSectionWrapper";
import TopSectionContent from "@/components/TopSectionContent";
import { b64EncodeUnicode } from "@/helpers";


export default {
  name: "AccountPage",
  data() {
    return {
      walletsArray: {
        cosmos: "",
        kusama: "",
        near: "",
        polkadot: "",
        solana: "",
        sui: "",
        venom: "",
      },
      socialArray: {
        discord: "",
        // starly: "",
        telegram: "",
      },
      email: "",
      animation: false,
      referralLink: "",
    };
  },
  components: {
    TopSectionContent,
    TopSectionWrapper,
    FontTitle,
    ButtonComponent,
    MainLayout,
    // FooterComponent,
    // TooltipCustom,
    // HeaderComponent,
  },
  computed: {
    ...mapState(["modalData", "userProfile", "kycStatus", "verifyKycURL", "userProfile"]),
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  watch: {
    userProfile() {
      this.updateWalletsArray();
    },
  },
  created() {
    const data = {
      ref: this.userProfile.userERCAddress,
      proj: "A2DAO",
    };
    this.encryptedData = b64EncodeUnicode(JSON.stringify(data));
    this.referralLink = `${window.location.origin}/?click=${this.encryptedData}`;
  },
  mounted() {
    this.updateWalletsArray();
  },
  methods: {
    ...mapMutations({
      toggleModal: "TOGGLE_MODAL",
    }),
    ...mapActions({
      fetchUserProfile: "fetchUserProfile",
    }),
    async getReferralLink() {
      try {
        await navigator.clipboard.writeText(
                `${window.location.origin}/?click=${this.encryptedData}`
        );
        this.toast.success("Referral link copied to clipboard");
      } catch (err) {
        this.toast.success("Failed to copy: " + err);
      }
      //return `${window.location.origin}/?click=${encryptedData}`;
    },
    updateWalletsArray() {
      if (this.userProfile.userERCAddress) {
        this.email = this.userProfile.email ? this.userProfile.email : "";
        this.walletsArray.cosmos = this.userProfile.wallets["Cosmos"].value
          ? this.userProfile.wallets["Cosmos"].value
          : "";
        this.walletsArray.kusama = this.userProfile.wallets["Kusama"].value
          ? this.userProfile.wallets["Kusama"].value
          : "";
        this.walletsArray.near = this.userProfile.wallets["Near"].value
          ? this.userProfile.wallets["Near"].value
          : "";
        this.walletsArray.polkadot = this.userProfile.wallets["Polkadot"].value
          ? this.userProfile.wallets["Polkadot"].value
          : "";
        this.walletsArray.solana = this.userProfile.wallets["Solana"].value
          ? this.userProfile.wallets["Solana"].value
          : "";
        this.walletsArray.sui = this.userProfile.wallets["Sui"].value
          ? this.userProfile.wallets["Sui"].value
          : "";
        this.walletsArray.venom = this.userProfile.wallets["Venom"].value
          ? this.userProfile.wallets["Venom"].value
          : "";
        this.socialArray.discord = this.userProfile.socials["Discord"].value
          ? this.userProfile.socials["Discord"].value
          : "";
        // this.socialArray.starly = this.userProfile.socials["Starly"].value
        //   ? this.userProfile.socials["Starly"].value
        //   : "";
        this.socialArray.telegram = this.userProfile.socials["Telegram"].value
          ? this.userProfile.socials["Telegram"].value
          : "";
      }
    },
    async saveProfileInfo(state) {
      if (state === true) {
        this.animation = !this.animation;
        setTimeout(() => {
          this.animation = !this.animation;
        }, 500);
      }
      let prepareFields = {};

      prepareFields["user-email"] = this.userProfile.email;
      Object.entries(toRaw(this.userProfile.wallets)).forEach((wallet) => {
        prepareFields[`user-${wallet[0].toLowerCase()}_address`] =
          wallet[1].value;
      });
      Object.entries(toRaw(this.userProfile.socials)).forEach((social) => {
        prepareFields[`user-${social[0].toLowerCase()}`] = social[1].value;
      });
      try {
        await API.post("/user/update", prepareFields);
        this.toast.success("Your account updated");
        this.toggleModal();
        this.fetchUserProfile();
      } catch (error) {
        this.toast.error(error.response?.data?.message);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";
:deep(.popper) {
  --popper-theme-background-color: #000000;
  --popper-theme-background-color-hover: #000000;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  width: fit-content !important;
  text-align: center;
  font-size: 16px;
}
.input {
  display: flex;
  justify-content: center;
  &-left {
    border: 1px solid #474646;
    border-radius: 7px 0 0 7px;
    padding: 15px 20px 16px 20px;
    align-items: center;
    display: flex;
    justify-content: start;
    gap: 10px;
    max-width: 120px;
    width: 100%;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;

    /* #141414 */

    color: #141414;
    img {
      width: 18px;
      height: 16px;
    }
  }
  &-right {
    background: #3a3b43;
    border: 1px solid #474646;
    border-radius: 0 7px 7px 0;
    max-width: 640px;
    width: 100%;
    padding: 15px 22px 16px 20px;
    display: flex;
    justify-content: center;
    gap: 20px;

    input {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      letter-spacing: 0.03em;

      color: #ffffff;
      background: #3a3b43;
      color: #ffffff;
      border: none;
      outline: none;
      width: 100%;
    }
    img {
      cursor: pointer;
    }
  }
}
.title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  &-text {
    display: flex;
    justify-content: start;
    width: 100%;
    max-width: 836px;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;

    color: #393a42;

    margin: 75px 0 30px;
  }
}
.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.kyc {
  margin-top: 75px;
  border: 1px solid #474646;
  border-radius: 7px;
  padding: 15px 20px 16px 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  text-align: center;
  letter-spacing: 0.01em;

  color: #3a3b43;

  max-width: calc(846px - 40px);
}
.profile-status {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  display: flex;
  align-items: center;
  letter-spacing: 0.03em;

  color: #6d6d6d;

  span {
    margin-left: 10px;
  }

  /* GY NFT+bttn */

  &.approved span {
    background: linear-gradient(
      192.17deg,
      #3ee0f6 22.25%,
      rgba(35, 127, 139, 0.7) 222.98%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .verify-wrapper {
    border-bottom: 1px #6d6d6d solid;
    border-image-slice: 1;
  }

  &.verify {
    a {
      color: #6d6d6d;
      text-decoration: none;
    }
  }
  &.pending span {
    background: linear-gradient(180deg, #fcb655 0%, #fbb234 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &.rejected span {
    color: #fa6162;
  }
}
.account-btn {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */
  position: relative;
  background: linear-gradient(37.78deg, #08fcab -27.24%, #1bd597 141.76%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 138px;
  height: 43px;
  margin-top: 48px;
}
.button-shadow {
  position: absolute;
  width: 100%;
  max-width: 136px;
  height: 43px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  @include respond-to(xs) {
    height: 48px;
  }

  @include respond-to(sm) {
    height: 44px;
  }
}
.sub-heading {
  margin-top: 10px;
}

.your-referral {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  @include respond-to(xs) {
    flex-direction: column;
  }
  &-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #39393a;
    border-radius: 7px;
    padding: 34px 47px 24px 41px;
    max-width: 756px;
    margin-top: 20px;
    width: 100%;
    @include respond-to(xs) {
      width: calc(100% - 88px);
    }
    &-title {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;

      /* Linear */

      background: linear-gradient(183.74deg, #fcfbfb 15.05%, #bcbcbc 42.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &-input {
      background: #3a3b43;
      border: 1px solid #474646;
      box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 13px 0 16px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #bcbcbc;
      padding-right: 47px;
      /* or 23px */

      text-align: center;

      /* Linear */

      input {
        background: #3a3b43;
        border: none;
        outline: none;
        color: #ffffff;
        border-radius: 10px;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        align-items: center;
        letter-spacing: 0.03em;
        max-width: 500px;
        width: 100%;
      }
      div {
        display: flex;
        align-items: center;
        min-width: 107px;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
    }
    &-text {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;

      color: #969696;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #39393a;
    filter: drop-shadow(0px 4px 12px rgba(249, 100, 225, 0.25));
    border-radius: 7px;
    max-width: 253px;
    width: 100%;
    @include respond-to(xs) {
      max-width: 706px;
      height: 200px;
    }
    &-text {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      justify-content: center;
      align-items: center;

      /* Linear */

      background: linear-gradient(183.74deg, #fcfbfb 15.05%, #bcbcbc 42.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    &-amount {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 130%;
      /* identical to box height, or 52px */

      display: flex;
      justify-content: center;
      align-items: center;

      /* Linear */

      background: linear-gradient(183.74deg, #fcfbfb 15.05%, #bcbcbc 42.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}
</style>
