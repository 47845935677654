<template>
  <button class="btn" @click="onClick" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  name: "BorderButton",
  props: {
    onClick: {
      type: Function,
    },
    fontSize: {
      type: String,
      default: "18px",
    },
    fontWeight: {
      type: String,
      default: "500",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  height: 47px;
  background: transparent;
  text-align: center;
  border: 1px solid #f46a8e;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  padding-left: 37px;
  padding-right: 37px;
  text-decoration: none;
  /* or 23px */

  display: flex;
  align-items: center;
  margin-right: 10px;
  &:disabled {
    border: 1px solid #9b9b9b;
    color: #9b9b9b;
    cursor: help;
  }
}
</style>
