<template>
  <div class="logo-wrapper">
    <img class="project-participated-logo" :src="logoSrc" :alt="projectName" />
    <div
      v-if="isExistNetworkImg"
      class="project-participated-logo-blockchain-bg"
    >
      <img
        v-if="isExistNetworkImg"
        class="project-participated-logo-blockchain"
        :src="require(`@/assets/icons/net/${projectBlockchain}.svg`)"
        :alt="projectBlockchain"
        :title="projectBlockchain"
      />
    </div>
  </div>
  <span>{{ projectName }}</span>
</template>

<script>
export default {
  name: "LogoCell",
  props: {
    logoSrc: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    projectBlockchain: {
      type: String,
    },
  },
  computed: {
    isExistNetworkImg() {
      return (
        this.projectBlockchain === "BNB Smart Chain" ||
        this.projectBlockchain === "Cosmos" ||
        this.projectBlockchain === "Ethereum" ||
        this.projectBlockchain === "Harmony" ||
        this.projectBlockchain === "Kusama" ||
        this.projectBlockchain === "Minima" ||
        this.projectBlockchain === "Moonriver" ||
        this.projectBlockchain === "Near" ||
        this.projectBlockchain === "Polygon" ||
        this.projectBlockchain === "Solana"

      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.logo-wrapper {
  position: relative;
  top: 0;
  left: 0;
}

.project-participated-logo {
  margin-right: 15px;
  height: 45px;
  border-radius: 50%;

  &-blockchain {
    width: 75%;

    &-bg {
      border-radius: 50%;
      background-color: #aaa;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 12px;
      bottom: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
