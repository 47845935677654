<template>
  <div class="carousel" ref="carousel" v-touch:swipe.left="increaseIndex" v-touch:swipe.right="decreaseIndex">
    <slot />
  </div>
  <div class="carousel-nav" ref="carouselNav" v-if="slides && slides.length > 1">
    <div class="dot"
         v-for="(_, index) in slides"
         :key="index"
         :class="index === 0 ? 'dot-active' : ''"
         @click="switchSlide(index); disableAutoPlay();">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouselComponent',
  emits: ['slideChange'],
  props: {
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slides: null,
      nav: null,
      currentIndex: 0,
      autoplayInterval: null
    };
  },
  mounted() {
    this.slides = Object.values(this.$refs.carousel.children).filter(el => {
      return !el.classList.contains('carousel-nav');
    });

    if (this.slides.length > 0) {
      this.slides[0].classList.add('slide-active');
    }

    this.toggleAutoPlay();
  },
  beforeUnmount() {
    this.disableAutoPlay();
  },
  watch: {
    autoplay() {
      this.toggleAutoPlay();
    }
  },
  methods: {
    disableAutoPlay() {
      clearInterval(this.autoplayInterval)
      this.autoplayInterval = null
    },
    toggleAutoPlay() {
      if (this.autoplay && this.slides.length > 1) {
        this.autoplayInterval = setInterval(() => {
          this.increaseIndex();
        }, 6000);
      } else {
        this.disableAutoPlay();
      }
    },
    increaseIndex() {
      this.currentIndex++;
      this.switchSlide(this.currentIndex);
    },
    decreaseIndex() {
      this.currentIndex--;
      this.switchSlide(this.currentIndex);
    },
    switchSlide(index) {
      const amountOfSlides = this.slides.length;

      if (this.currentIndex > amountOfSlides - 1) {
        this.currentIndex = 0;
        index = 0;
      }
      if (this.currentIndex < 0) {
        this.currentIndex = amountOfSlides - 1;
        index = this.currentIndex;
      }

      Object.values(this.slides).map((slide, key) => {
        if (index !== key) {
          slide.classList.remove('slide-active');
        } else {
          slide.classList.add('slide-active');
        }
      });
      Object.values(this.$refs.carouselNav.children).map(navItem => {
        navItem.classList.remove('dot-active');
      });
      this.$refs.carouselNav.children[index].classList.add('dot-active');

      this.$emit('slideChange', index);
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.carousel {
  position: relative;
  height: 100%;
  z-index: 11;

  &-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    color: #fff;
    right: 50px;
    bottom: -50px;
    z-index: 30;

    @include respond-below(sm) {
      left: 50%;
      transform: translate(-50%, 0);
    }

    .dot {
      width: 80px;
      height: 8px;
      background: #C4C4C4;
      border-radius: 5px;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 10px;
      }

      @include respond-below(sm) {
        width: 45px;
        height: 4px;
      }

      &-active {
        background: linear-gradient(72.12deg, #4D4D4D 2.57%, #121212 126.49%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
</style>
