<template>
  <button @click="onClick" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    onClick: {
      type: Function,
    },
    fontSize: {
      type: String,
      default: '18px'
    },
    fontWeight: {
      type: String,
      default: '500'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

button {
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  padding: 11px 24px;
  font-family: 'Space Mono', sans-serif;
  font-weight: v-bind(fontWeight);
  font-size: v-bind(fontSize);
  line-height: 130%;
  color: #FFFFFF;
  max-height: 45px;

  &:disabled {
    background: #777 !important;
    color: #ccc;
    cursor: help;
  }
}

.cta {
  background: linear-gradient(72.12deg, #4D4D4D 2.57%, #121212 126.49%);
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.105em;
  text-transform: uppercase;
  width: 210px;
  margin-top: 0;

  @include respond-to(xs) {
    padding: 6px 18px;
  }

  @include respond-to(sm) {
    font-size: 12px;
    width: 170px;
  }
}

.secondary {
  background: linear-gradient(72.12deg, #121212 2.57%, #4D4D4D 126.49%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.blue {
  background: linear-gradient(180deg, #00B1D2 0%, rgba(0, 177, 210, 0) 138.88%) !important;
}
.voted {
  background: rgba(0, 0, 0, 0) !important;
  border: 1px solid #a2a2a2;
  color: #000;
}


.launch {
  max-height: 52px;
  line-height: 32px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-left: 48px;
  padding-right: 48px;

  @include respond-to(xs) {
    padding: 6px 18px;
  }

  @include respond-to(sm) {
    padding: 9px 41px;
  }
}

.faq {
  width: 140px;
  font-weight: 400;
  font-size: 16px;
  margin-right: 30px;
  letter-spacing: 0.105em;
}

.stake {
  height: 40px;
  padding: initial;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}
.black {
  background-color: black;
}

.nft {
  background: linear-gradient(93.1deg, #FF3F71 -7.52%, #FFAD72 107.59%);
}

.true-yield-staking {
  background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
}

.ido {
  background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
}

.lockdrop {
  background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
}

.cta-swap {
  background: linear-gradient(180deg, #08FCAB 0%, #1BD597 100%);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  font-weight: 700;
  width: 100%;
  font-size: 24px;
  line-height: 67px;
  padding: 0;

  color: #FFFFFF;
  max-height: 67px;
}

.cta-approve-modal {
  color: #FFFFFF;
  line-height: 50px;
  margin-top: 20px;
  max-height: 50px;
}

.profile-save-button {
  background: linear-gradient(180deg, #08FCAB 0%, #1BD597 100%);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 73px;
  color: #FFFFFF;
  margin-top: 46px;
  width: 560px;
  max-height: 73px;
  padding: 0;

  @include respond-below(sm) {
    width: 100%;
    line-height: 64px;
    max-height: 64px;
  }
}
</style>
