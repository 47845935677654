<template>
  <div class="list">
    <div class="list-content">
      <div v-for="item of projects" :key="item.id">
        <innovation-card :project="item" :stage="item.stage" />
      </div>
      <div v-if="!userProfile.userERCAddress" class="list-join-innovation">
        <div class="list-join-innovation-title">Pipeline</div>
        <div class="list-join-innovation-text">
          <p>
            Join A2DAO now to get access to the best deals and receive the
            latest information on projects we are currently negotiating
          </p>
        </div>
        <button-component
          :onClick="connectWallet"
          class="list-join-innovation-btn"
          >Join</button-component
        >
      </div>
      <!--      <div v-for="n in 3" :key="n">-->
      <!--        <innovation-card />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import InnovationCard from "@/components/InnovationCard";
import ButtonComponent from "@/components/Button";
import { mapActions, mapState } from "vuex";
export default {
  name: "InnovationList",
  components: { ButtonComponent, InnovationCard },
  data() {
    return {};
  },
  props: {
    industries: {
      type: Array,
      default() {
        return [];
      },
    },
    projects: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    ...mapActions(["connectWallet"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.list {
  &-content {
    margin-top: 25px;
    @include respond-below(md) {
      display: flex;
      flex-direction: column;
    }
  }
  &-join-innovation {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../assets/join-innovation-back.jpeg");
    background-size: cover;
    background-position-x: 70%;
    box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    min-height: 300px;
    width: 100%;
    &-title {
      margin-left: 10%;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #353535;
      span {
        margin-right: 10px;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          radial-gradient(
            168.5% 66.62% at 113.7% 69.06%,
            #5f5997 0%,
            #f46a8e 100%
          );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-text {
      max-width: 600px;
      margin-left: 10%;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;

      color: #353535;

    }
    &-btn {
      width: 180px;
      margin-left: 10%;
      height: 47px;
      color: #ffffff;
      text-align: center;
      background: #75E1DB;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */
      padding-left: 50px;
      padding-right: 50px;
      margin-right: 10px;
    }
  }
}
</style>
