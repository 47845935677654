<template v-once>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper class="pb-40">
        <top-section-content show-right-side>
          <template v-slot:top-section-heading>
            Privacy
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="privacy-wrapper">
      <h3 class="subtitle">1. General Provisions</h3>
      <p class="content-block">
        This personal data processing policy has been drawn up in accordance with the requirements of the Federal Law of July 27, 2006. No. 152-FZ “On Personal Data” (hereinafter referred to as the Law on Personal Data) and determines the procedure for processing personal data and measures to ensure the security of personal data taken by Mikhailov Ivan Sergeevich (hereinafter referred to as the Operator).
      </p>
      <p class="content-block">
        1.1. The operator sets as its most important goal and condition for the implementation of its activities the observance of the rights and freedoms of a person and a citizen in the processing of his personal data, including the protection of the rights to privacy, personal and family secrets.
      </p>
      <p class="content-block">
        1.2. This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator can receive about visitors to the website httpsː//thismywebsite·com.
      </p>
      <h3 class="subtitle">2. Basic concepts used in the Policy</h3>
      <p class="content-block">
        2.1. Automated processing of personal data - processing of personal data using computer technology.
      </p>
      <p class="content-block">
        2.2. Blocking of personal data is a temporary suspension of the processing of personal data (unless the processing is necessary to clarify personal data).
      </p>
      <p class="content-block">
        2.3. Website - a set of graphic and informational materials, as well as computer programs and databases that ensure their availability on the Internet at the network address httpsː//thismywebsite·com.
      </p>
      <p class="content-block">
        2.4. Personal data information system - a set of personal data contained in databases, and information technologies and technical means that ensure their processing.
      </p>
      <p class="content-block">
        2.5. Depersonalization of personal data - actions as a result of which it is impossible to determine, without the use of additional information, the ownership of personal data by a specific User or other subject of personal data.
      </p>
      <p class="content-block">
        2.6. Processing of personal data - any action (operation) or a set of actions (operations) performed with or without the use of automation tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.
      </p>
      <p class="content-block">
        2.7. Operator - a state body, municipal body, legal entity or individual, independently or jointly with other persons organizing and (or) carrying out the processing of personal data, as well as determining the purposes of processing personal data, the composition of personal data to be processed, the actions (operations) performed with personal data.
      </p>
      <p class="content-block">
        2.8. Personal data - any information relating directly or indirectly to a specific or identifiable User of the website httpsː//thismywebsite·com.
      </p>
      <p class="content-block">
        2.9. Personal data permitted by the subject of personal data for dissemination - personal data, access to an unlimited number of persons to which is provided by the subject of personal data by giving consent to the processing of personal data permitted by the subject of personal data for distribution in the manner prescribed by the Law on Personal Data (hereinafter referred to as personal data). data allowed for distribution).
      </p>
      <p class="content-block">
        2.10. User - any visitor to the website httpsː//thismywebsite·com.
      </p>
      <p class="content-block">
        2.11. Providing personal data - actions aimed at disclosing personal data to a certain person or a certain circle of persons.
      </p>
      <p class="content-block">
        2.12. Dissemination of personal data - any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or familiarizing with personal data of an unlimited number of persons, including the disclosure of personal data in the media, placement in information and telecommunication networks or providing access to personal data in any other way.
      </p>
      <p class="content-block">
        2.13. Cross-border transfer of personal data is the transfer of personal data to the territory of a foreign state to an authority of a foreign state, a foreign individual or a foreign legal entity.
      </p>
      <p class="content-block">
        2.14. Destruction of personal data - any actions as a result of which personal data is irretrievably destroyed with the impossibility of further restoration of the content of personal data in the information system of personal data and (or) material carriers of personal data are destroyed.
      </p>
    </div>
  </main-layout>
</template>
<script>
import TopSectionWrapper from '@/components/TopSectionWrapper';
import MainLayout from '@/layouts/main';
import TopSectionContent from '@/components/TopSectionContent';

export default {
  name: 'PrivacyPolicyPage',
  components: {
    TopSectionWrapper,
    MainLayout,
    TopSectionContent
  },
  head: {
    meta: [
      {
        name: 'description',
        content: 'Private and seed round deals of various crypto projects that have already been completed.'
      },
    ],
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";

  .privacy-wrapper {
    margin: 0 auto;
    max-width: 1280px;
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    align-items: center;
    letter-spacing: -0.05em;

    color: #000000;

    @include respond-below(lg) {
      font-size: 14px;
      line-height: 21px;
      max-width: 700px;
      padding: 0 25px;
    }

    .title,
    .subtitle {
      margin: 0;
    }

    .content-block {
      font-weight: 400;
      margin-bottom: 45px;
      margin-top: 45px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
