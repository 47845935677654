<template>
  <div class="project" :class="state">
    <div class="project-timer">
      <div class="project-timer-left">
        <div class="project-timer-left-title">{{typeName}}</div>
        <div class="project-timer-left-text">
          Round allocation <span>{{roundAllocation}} USDT</span>
        </div>
      </div>
      <div class="project-timer-right" v-if="state">
        <div v-if="state === 'active'">Ends in: <span v-for="data in timeData" :key="data.label"
          >{{ data.current }}{{ data.label }}
        </span>
        </div>
      <div v-else-if="state === 'upcoming' && (typeName !== 'public round' ||  project.state === 'active')">Starts in: <span v-for="data in timeData" :key="data.label"
      >{{ data.current }}{{ data.label }}
        </span>
      </div>
        <div v-else-if="state === 'upcoming'&& project.state === 'upcoming'">Coming soon</div>
        <div v-else>Ended</div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  watch,
} from "vue";

import moment from "moment";
const fmt = "YYYY-MM-DD HH:mm:ss";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "NewProjectTimer",
  emits: ["timeElapsed"],
  setup(props, { emit }) {
    const uuid = uuidv4();
    const {
      deadline,
      stop,
      showDays,
      showHours,
      showMinutes,
      showSeconds,
      // labels,
      deadlineDate,
      deadlineISO,
    } = toRefs(props);

    let now = ref(Math.trunc(new Date().getTime() / 1000));
    let date = ref(null);
    let interval = ref(null);
    let diff = ref(0);
    let show = ref(false);
    let timeData = ref([
      {
        current: 0,
        previous: 0,
        label: "d",
        elementId: "flip-card-days-" + uuid,
        show: showDays.value,
      },
      {
        current: 0,
        previous: 0,
        label: "h",
        elementId: "flip-card-hours-" + uuid,
        show: showHours.value,
      },
      {
        current: 0,
        previous: 0,
        label: "m",
        elementId: "flip-card-minutes-" + uuid,
        show: showMinutes.value,
      },
      {
        current: 0,
        previous: 0,
        label: "s",
        elementId: "flip-card-seconds-" + uuid,
        show: showSeconds.value,
      },
    ]);

    const updateAllCards = () => {
      updateTime(0, days);
      updateTime(1, hours);
      updateTime(2, minutes);
      updateTime(3, seconds);
    };

    const twoDigits = (value) => {
      if (value != undefined) {
        if (value.toString().length <= 1) {
          return "0" + value.toString();
        }
        return value.toString();
      } else {
        return "00";
      }
    };

    const updateTime = (idx, newValue) => {
      if (idx >= timeData.value.length || newValue === undefined) {
        return;
      }

      // if (window['requestAnimationFrame']) {
      //     frame = requestAnimationFrame(updateTime.bind(this));
      // }

      const d = timeData.value[idx];
      const val = newValue.value < 0 ? 0 : newValue.value;
      const el = document.querySelector(`#${d.elementId}`);

      if (val !== d.current) {
        d.previous = d.current;
        d.current = val;

        if (el) {
          el.classList.remove("flip");
          void el.offsetWidth;
          el.classList.add("flip");
        }

        if (idx === 0) {
          const els = el.querySelectorAll("span b");
          if (els) {
            for (let e of els) {
              const cls = e.classList[0];
              if (newValue.value / 1000 >= 1) {
                if (!cls.includes("-4digits")) {
                  const newCls = cls + "-4digits";
                  e.classList.add(newCls);
                  e.classList.remove(cls);
                }
              } else {
                if (cls.includes("-4digits")) {
                  const newCls = cls.replace("-4digits", "");
                  e.classList.add(newCls);
                  e.classList.remove(cls);
                }
              }
            }
          } else {
            //
          }
        }
      }
    };

    watch(deadline, (newVal) => {
      const endTime = newVal;
      date.value = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
      if (!date.value) {
        throw new Error("Invalid props value, correct the 'deadline'");
      }
    });

    watch(now, () => {
      diff.value = date.value - now.value;
      if (diff.value <= 0 || stop.value) {
        diff.value = 0;
        updateTime(3, 0);
      } else {
        updateAllCards();
      }
    });

    watch(diff, (newVal) => {
      if (newVal == 0) {
        emit("timeElapsed");
        updateAllCards();
      }
    });

    let seconds = computed(() => {
      return Math.trunc(diff.value) % 60;
    });

    let minutes = computed(() => {
      return Math.trunc(diff.value / 60) % 60;
    });
    let hours = computed(() => {
      return Math.trunc(diff.value / 60 / 60) % 24;
    });
    let days = computed(() => {
      return Math.trunc(diff.value / 60 / 60 / 24);
    });


    onMounted(() => {
      if (diff.value !== 0) {
        show.value = true;
      }
    });

    onBeforeMount(() => {
      if (!deadline.value) {
        throw new Error("Missing props 'deadline'");
      }
      const endTime = deadline.value;
      let epoch = Date.parse(endTime.replace(/-/g, "/"));
      if (deadlineDate.value != null) {
        epoch = Date.parse(deadlineDate.value);
      }
      if (deadlineISO.value) {
        epoch = Date.parse(deadlineISO.value);
      }
      date.value = Math.trunc(epoch / 1000);
      if (!date.value) {
        throw new Error("Invalid props value, correct the 'deadline'");
      }
      interval.value = setInterval(() => {
        now.value = Math.trunc(new Date().getTime() / 1000);
      }, 1000);
    });

    onUnmounted(() => {
      // if (window['cancelAnimationFrame']) {
      //     cancelAnimationFrame(frame);
      // }
      clearInterval(interval.value);
    });

    return {
      now,
      date,
      interval,
      diff,
      show,
      timeData,
      twoDigits,
      emit,
    };
  },
  props: {
    project:{
      type: Object,
      default(){
        return {}
      }
    },
    deadline: {
      type: String,
      required: false,
      default: moment().add(32, "d").add(10, "s").format(fmt),
    },
    roundAllocation: {
      type: String,
      default : "Upcoming"
    },
    typeName:{
      type: String
    },
    state: {
      type: String,
    },
    deadlineISO: {
      type: String,
      required: false,
    },
    deadlineDate: {
      type: Date,
      required: false,
    },
    countdownSize: {
      type: String,
      required: false,
      // default:"2.2rem"
    },

    labelSize: {
      type: String,
      required: false,
      // default:"2.2rem"
    },
    stop: {
      type: Boolean,
      required: false,
    },
    flipAnimation: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDays: {
      type: Boolean,
      required: false,
      default: true,
    },
    showHours: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMinutes: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSeconds: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: true,
    },
    labels: {
      type: Object,
      required: false,
      default: function () {
        return {
          days: "days",
          hours: "hours",
          minutes: "min",
          seconds: "sec",
        };
      },
    },
    mainColor: {
      type: String,
      default: "#EC685C",
    },
    secondFlipColor: {
      type: String,
      default: (props) => props.mainColor,
    },
    mainFlipBackgroundColor: {
      type: String,
      default: "#222222",
    },
    secondFlipBackgroundColor: {
      type: String,
      default: "#393939",
    },
    labelColor: {
      type: String,
      default: "#222222",
    },
    numbersSize: {
      type: String,
      default: "2rem",
    },
  },
};
</script>

<style scoped lang="scss">
.project {
  padding: 2px;
  border-radius: 14px;
  &.active{
    background: #87e2dc;
  }
  &.upcoming{
    background: #C7CECE;
  }
  &.completed{
    background: #C7CECE;
  }
  &-timer {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 27px;
    background: #f5f5f5;
    border-radius: 12px;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50px;
      &-title {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 124%;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #272727;
      }
      &-text {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */
        align-items: center;
        letter-spacing: -0.02em;
        color: #6f7173;
        span {
          font-weight: 600;
        }
      }
    }
    &-right {
      font-family: "Space Mono" dateStart;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */
      align-items: center;
      letter-spacing: -0.02em;
      color: #353535;
      span {
        padding: 0 3px;
      }
    }
  }
}
</style>
