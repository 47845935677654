<template>
  <button-component class="stake" :class="projectType" :font-size="fontSize" :disabled="disabled"
                    :font-weight="fontWeight" :style="animation ? 'transform: translate(6px, 6px);transition-duration: 200ms;':''">

    <slot></slot>
    <div :style="!animation ? 'transform: translate(6px, 6px);':'transform: translate(0px, 0px);'" class="button-shadow"></div>

  </button-component>
</template>

<script>
import ButtonComponent from '@/components/Button';

export default {
  name: 'StakeButton',
  components: {
    ButtonComponent
  },
  props: {
    projectType: {
      type: String,
    },
    fontSize: {
      type: String,
      default: '18px'
    },
    fontWeight: {
      type: String,
      default: '500'
    },
    disabled: {
      type: Boolean,
    },
    animation: Boolean
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.stake {
  position: relative;
  background: linear-gradient(-129.36deg, #75E1DB 27.47%, #CEE3E0 128.48%);
  border: 0.7px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.105em;
  text-transform: uppercase;
  color: #FFFFFF;
  height: 50px;
  margin: -6px 6px 0 0;

  @include respond-between(sm, lg) {
    font-size: 10px;
  }
  @include respond-to(xs) {
    font-size: 10px;
    height: 32px;
    margin: 16px 6px 0 0;

  }
}
.button-shadow {
  position: absolute;
  max-width: 320px;
  width: 100%;
  border: 1px solid #000;
  /*left: 6px;*/
  /*top: 6px;*/
    z-index: -1;
  height: 42px;
  @include respond-to(xs) {
    height: 32px;
  }

  @include respond-to(sm) {
    height: 45px;
  }

}
</style>
