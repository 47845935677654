<template>
  <header>
    <div class="logo">
      <router-link to="/">
        <img src="../assets/logo.svg" alt="catapult" />
      </router-link>
    </div>
    <div
      class="right-side-wrapper"
      :class="userProfile.userERCAddress ? 'logged-in' : ''"
    >
      <div
        class="overlay"
        v-if="isMobileMenuOpened"
        @click="toggleMobileMenu"
      ></div>
      <ul
        class="main-menu"
        v-if="mainMenu && mainMenu.length"
        :style="isMobileMenuOpened ? 'left: -20px' : ''"
      >
        <li v-if="!isMobileMenuOpened" class="main-menu-deals">
          <div
            v-if="userProfile.userERCAddress"
            class="main-menu-dropdown"
            @click="openDeals"
          >
            Deals
            <img class="arrow" src="../assets/icons/angle-bottom.svg" alt="" />
          </div>
          <div v-if="isOpenDeals" class="main-menu-content">
            <ul>
              <li>
                <router-link to="/active">
                  <img src="../assets/menu-icons/active.svg" alt="" />Active
                </router-link>
              </li>
              <!--              <li>-->
              <!--                <img src="../assets/menu-icons/private.svg" alt="" />Private-->
              <!--                Deals-->
              <!--              </li>-->
              <li>
                <router-link to="/otc">
                  <img src="../assets/menu-icons/otc.svg" alt="" />
                  OTC Deals</router-link
                >
              </li>
              <li>
                <router-link to="/otc-on-the-market">
                  <img src="../assets/menu-icons/otc.svg" alt="" />
                  OTC (on the market)</router-link
                >
              </li>
              <li
                v-if="
                  currentUserLeaderboard.totalScore &&
                  currentUserLeaderboard.totalScore >= 10
                "
              >
                <router-link to="/pipeline">
                  <img
                    src="../assets/menu-icons/innovation.svg"
                    alt=""
                  />Pipeline
                </router-link>
              </li>
              <li>
                <router-link to="/completed">
                  <img src="../assets/menu-icons/completed.svg" alt="" />
                  Completed</router-link
                >
              </li>
            </ul>
          </div>
        </li>
        <mq-responsive target="xs">
          <li v-if="userProfile.userERCAddress">
            Deals
            <ul>
              <li v-if="userProfile.userERCAddress">
                <router-link to="/active"> Active </router-link>
              </li>
              <!--          <li>Private Deals</li>-->
              <li
                v-if="
                  userProfile.userERCAddress &&
                  currentUserLeaderboard.totalScore &&
                  currentUserLeaderboard.totalScore >= 10
                "
              >
                <router-link to="/pipeline"> Pipeline</router-link>
              </li>
              <li v-if="userProfile.userERCAddress">
                <router-link to="/otc"> OTC Deals</router-link>
              </li>
              <li v-if="userProfile.userERCAddress">
                <router-link style="width: 200px" to="/otc-on-the-market">
                  OTC (on the market)</router-link
                >
              </li>
              <li v-if="userProfile.userERCAddress">
                <router-link to="/completed"> Completed</router-link>
              </li>
            </ul>
          </li>
        </mq-responsive>
        <li v-for="menuItem in mainMenu" :key="menuItem.path">
          <router-link
            v-if="userProfile.userERCAddress || menuItem.name !== 'Leaderboard'"
            :to="menuItem.path"
            >{{ menuItem.name }}</router-link
          >
        </li>
        <li v-if="!isMobileMenuOpened">
          <div
            v-if="userProfile.userERCAddress"
            class="main-menu-dropdown"
            @click="openSettings"
          >
            <img class="wallet" src="../assets/menu-icons/wallet.svg" alt="" />
            {{ userERCTrimmed }}
            <img class="arrow" src="../assets/icons/angle-bottom.svg" alt="" />
          </div>
          <div v-else>
            <ConnectWalletButton />
          </div>
          <div v-if="isOpenSettings" class="main-menu-content">
            <ul>
              <li>
                <router-link
                  to="/my-investments"
                  style="display: flex; align-items: center"
                >
                  <img src="../assets/menu-icons/my-investment.svg" alt="" />
                  My Investments
                </router-link>
              </li>
              <li>
                <router-link
                        to="/my-activity"
                        style="display: flex; align-items: center"
                >
                  <img src="../assets/menu-icons/my-investment.svg" alt="" />
                  My Activity
                </router-link>
              </li>
              <!--              <li>-->
              <!--                <a-->
              <!--                  class="link"-->
              <!--                  to="/my-profile"-->
              <!--                  @click.prevent="toggleProfileModal"-->
              <!--                  style="display: flex; align-items: center"-->
              <!--                >-->
              <!--                  <img src="../assets/menu-icons/settings.svg" alt="" />-->
              <!--                  Settings</a-->
              <!--                >-->
              <!--              </li>-->
              <!--              <li>-->
              <!--                <router-link-->
              <!--                  to="/account"-->
              <!--                  style="display: flex; align-items: center"-->
              <!--                >-->
              <!--                  <img src="../assets/menu-icons/settings.svg" alt="" />-->
              <!--                  Settings</router-link-->
              <!--                >-->
              <!--              </li>-->
              <li v-if="userProfile.userERCAddress" key="profile">
                <router-link
                  to="/account"
                  style="display: flex; align-items: center"
                >
                  <img src="../assets/menu-icons/settings.svg" alt="" />
                  Settings
                </router-link>
              </li>
              <!--              <li>-->
              <!--                <router-link-->
              <!--                  to="/referral"-->
              <!--                  style="display: flex; align-items: center"-->
              <!--                >-->
              <!--                  <img src="../assets/menu-icons/referral-program.svg" alt="" />-->
              <!--                  Referral Program-->
              <!--                </router-link>-->
              <!--              </li>-->
              <li @click="proceedLogout">
                <router-link to="/" style="display: flex; align-items: center">
                  <img src="../assets/menu-icons/disconnect.svg" alt="" />
                  Disconnect
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!--        <mq-responsive target="sm+">-->
        <!--          <li v-if="userProfile.userERCAddress" key="profile">-->
        <!--            <a-->
        <!--              class="link"-->
        <!--              to="/my-profile"-->
        <!--              @click.prevent="toggleProfileModal"-->
        <!--              style="display: flex; align-items: center"-->
        <!--            >-->
        <!--              <img-->
        <!--                src="../assets/icons/user.svg"-->
        <!--                alt="account"-->
        <!--                class="user-icon"-->
        <!--              />-->
        <!--            </a>-->
        <!--          </li>-->
        <!--          <li v-if="userProfile.userERCAddress" key="investments">-->
        <!--            <router-link-->
        <!--              to="/my-investments"-->
        <!--              style="display: flex; align-items: center"-->
        <!--            >-->
        <!--              <img-->
        <!--                src="../assets/icons/wallet.svg"-->
        <!--                alt="account"-->
        <!--                class="user-icon"-->
        <!--              />-->
        <!--            </router-link>-->
        <!--          </li>-->
        <!--        </mq-responsive>-->
        <mq-responsive target="xs">
          <li v-if="userProfile.userERCAddress">
            My Account
            <ul>
              <li v-if="userProfile.userERCAddress" key="profile">
                <router-link
                  to="/account"
                  style="display: flex; align-items: center"
                >
                  Settings
                </router-link>
              </li>
              <li v-if="userProfile.userERCAddress" key="investments">
                <router-link
                  to="/my-investments"
                  style="display: flex; align-items: center"
                >
                  My Investments
                </router-link>
              </li>
              <li v-if="userProfile.userERCAddress" key="activity">
                <router-link
                        to="/my-activity"
                        style="display: flex; align-items: center"
                >
                  My Activity
                </router-link>
              </li>
              <li
                v-if="userProfile.userERCAddress"
                key="logout"
                @click="logOut"
              >
                Disconnect
              </li>
            </ul>
          </li>
        </mq-responsive>
      </ul>
      <!--      <mq-responsive target="sm+">-->
      <!--        <ConnectWalletButton />-->
      <!--      </mq-responsive>-->
      <mq-responsive target="xs">
        <button
          type="button"
          class="show-menu"
          :class="isMobileMenuOpened ? 'active' : ''"
          @click="toggleMobileMenu"
        >
          Open menu
          <span></span>
        </button>
      </mq-responsive>
    </div>
    <mq-responsive target="xs">
      <div
        class="mobile-bottom-bar"
        :style="!userProfile.userERCAddress ? 'justify-content: flex-end' : ''"
      >
        <div class="logged-actions-wrapper" v-if="userProfile.userERCAddress">
          <!--          <a class="link" to="/my-profile" @click.prevent="toggleProfileModal">-->
          <!--            <img-->
          <!--              src="../assets/icons/user.svg"-->
          <!--              alt="account"-->
          <!--              class="user-icon profile"-->
          <!--            />-->
          <!--          </a>-->
          <router-link to="/account">
            <img
              src="../assets/icons/user.svg"
              alt="account"
              class="user-icon profile"
            />
          </router-link>
          <router-link to="/my-investments" style="margin-left: 35px">
            <img
              src="../assets/icons/wallet.svg"
              alt="account"
              class="user-icon investments"
            />
          </router-link>
        </div>
        <ConnectWalletButton isMobile />
      </div>
    </mq-responsive>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { MqResponsive } from "vue3-mq";
import ConnectWalletButton from "@/components/buttons/ConnectWalletButton";

export default {
  name: "HeaderComponent",
  components: {
    ConnectWalletButton,
    MqResponsive,
  },
  data() {
    return {
      isMobileMenuOpened: false,
      isOpenDeals: false,
      isOpenSettings: false,
      click: false,
    };
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    userProfile(newVal, oldVal) {
      if (newVal?.userERCAddress) {
        this.$store.dispatch("fetchLeaderboard");
      }
    },
  },
  computed: {
    mainMenu() {
      return this.$router.options.routes.filter(
        (menuItem) => menuItem.meta?.inMenu
      );
    },
    ...mapGetters(["accessToken", "tokenExpiration", "userERCTrimmed"]),
    ...mapState(["userProfile", "provider", "currentUserLeaderboard"]),
  },
  async created() {
    document.addEventListener("click", this.closeDropdown);
  },
  async mounted() {
    const currentTime = Date.now();
    const FIVE_MINUTES = 5 * 60 * 1000;

    if (this.accessToken) {
      if (currentTime < this.tokenExpiration) {
        if (this.tokenExpiration - currentTime < FIVE_MINUTES) {
          this.refreshToken();
        } else {
          if (this.provider === null) {
            await this.initWeb3();
          }
          if (!this.userProfile.userERCAddress) {
            this.fetchUserProfile();
            this.setTokenContract();
          }
        }
      } else {
        this.logOut();
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },

  methods: {
    closeDropdown() {
      this.isOpenDeals = false;
      this.isOpenSettings = false;
    },
    proceedLogout() {
      this.$gtm.trackEvent({
        event: "ConnectWalletButton_Logout", // Event type [default = 'interaction'] (Optional)
        category: "ConnectWalletButton",
        action: "Logout",
        label: this.userProfile.userERCAddress,
        value: 1,
      });
      this.logOut();
      this.isOpenDeals = false;
      this.isOpenSettings = false;
    },
    openDeals() {
      if (this.isOpenDeals) {
        this.isOpenDeals = !this.isOpenDeals;
      } else {
        setTimeout(() => {
          this.isOpenDeals = !this.isOpenDeals;
        }, 10);
      }
    },
    openSettings() {
      if (this.isOpenSettings) {
        this.isOpenSettings = !this.isOpenSettings;
      } else {
        setTimeout(() => {
          this.isOpenSettings = !this.isOpenSettings;
        }, 10);
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuOpened = !this.isMobileMenuOpened;
    },
    toggleProfileModal() {
      this.toggleModal({
        modalID: "modal-profile",
      });
    },
    ...mapActions([
      "refreshToken",
      "logOut",
      "fetchUserProfile",
      "setTokenContract",
      "initWeb3",
    ]),
    ...mapMutations({
      toggleModal: "TOGGLE_MODAL",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.link {
  cursor: pointer;
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 50;
  @include respond-to(xs) {
    z-index: 20;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 34.45px;

    @include respond-to(sm) {
      width: 42px;
      height: 35px;
    }

    @include respond-to(xs) {
      width: auto;
      height: 40px;
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(34, 34, 34, 0.75);
  z-index: 502;
}

.main-menu {
  list-style-type: none;
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: center;
  margin: 0;
  /*height: 40px;*/

  &-dropdown {
    border-radius: 9px;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    /* #141414 */

    color: #141414;
    /*padding: 4px 13px;*/
    cursor: pointer;
    .wallet {
      margin-right: 10px;
    }
    .arrow {
      width: 12px;
      height: 6px;
      margin-left: 8px;
    }
    @include respond-to(md) {
      font-size: 16px;
    }

    @include respond-to(sm) {
      font-size: 14px;
      height: auto;
      padding-top: 1px;
      padding-bottom: 0;
    }
  }
  @include respond-above(xs) {
    height: 40px;
  }
  &-content {
    position: absolute;
    padding: 20px 22px 20px 7px;
    background: #f3f3f3;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.07);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 9px;
    width: fit-content;
    border: 0.2px solid rgba(143, 219, 252, 0.1);
    margin-top: 5px;
    ul {
      list-style: none;
      padding: 0;
      li {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        /* or 23px */

        display: flex;
        align-items: center;
        letter-spacing: -0.03em;

        /* #141414 */

        color: #141414;
        width: 100%;
        max-width: 250px;
        height: 45px;
        padding-left: 15px;
        margin: 0;
        @include respond-to(sm) {
          font-size: 14px;
        }
        a {
          display: inline-flex;
          align-items: center;
          width: 100%;
          height: 45px;
        }
        &:hover {
          cursor: pointer;
          background: #eae7e7;
        }
        img {
          margin-right: 10px;
          width: 16px;
          height: 17px;
        }
      }
    }
  }

  @include respond-to(xs) {
    background: #f3f3f3;
    flex-direction: column;
    left: -100%;
    padding: 40px 0 30px 69px;
    position: fixed;
    top: -16px;
    width: calc(100% - 115px);
    z-index: 503;
  }

  li {
    margin-right: 40px;
    ul {
      list-style: none;
      @include respond-to(xs) {
        padding-top: 20px;
      }
    }

    @include respond-to(xs) {
      align-items: start;
      margin-bottom: 25px;
      font-size: 18px;
    }

    @include respond-to(sm) {
      align-items: start;
      margin-right: 30px;
    }
    a {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      /* #141414 */

      color: #141414;
      gap: 10px;

      text-decoration: none;
      @include respond-to(md) {
        font-size: 16px;
      }

      @include respond-to(sm) {
        font-size: 13px;
      }
    }
  }

  @include respond-to(xs) {
    height: auto;
    align-items: start;
  }

  @include respond-to(sm) {
    /*align-items: start;*/
  }
}

.user-icon {
  width: 16px;
  height: 17px;

  .profile {
    height: 19px;
    width: 25px;
  }

  .investments {
    height: 22px;
    width: 25px;
  }
}

.right-side-wrapper {
  display: flex;

  &.logged-in {
    .main-menu {
      div {
        @include respond-above(sm) {
          display: flex;
        }
      }

      li {
        @include respond-to(sm) {
          margin-right: 17px;
        }
      }
    }
  }
}

.mobile-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f3f3f3;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.3);
  padding: 23px 32px 18px 23px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .logged-actions-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.show-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 0;
  z-index: 501;
  border: none;
  right: 0;
  top: -5px;
  height: 20px;
  width: 18px;
  background: url(https://a2dao.com/img/menu.b471b170.svg) no-repeat center;
  position: absolute;

  &:focus,
  &:active {
    outline: none;
  }

  &.active {
    span {
      width: 0;
    }

    &:before,
    &:after {
      width: 31px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
.main-menu-deals {
  @include respond-to(sm) {
    margin-right: 0;
  }
}
</style>
