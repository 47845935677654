<template>
  <div class="blog-categories-wrapper">
    <ul class="blog-categories-tabs" v-if="blogCategories && blogCategories.length">
      <li :class="{active: !activeCategory}" @click="handleClickCategory()">
        All
      </li>
      <li v-for='blogCategory in blogCategories' :class="{active: activeCategory === blogCategory.key}" :key="blogCategory.key" @click="handleClickCategory(blogCategory.key)">
        {{ blogCategory.name }}
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'BlogPostCategories',
  data() {
    return {
      activeCategory: null
    };
  },
  computed: {
    ...mapState([
      'blogCategories',
    ])
  },
  methods: {
    ...mapActions([
      'fetchBlogPosts'
    ]),
    handleClickCategory(category = null) {
      this.activeCategory = category;
      this.fetchBlogPosts(category)
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/mixins";
  .blog-categories-tabs {
    li {
      color: #9B9B9B;
      cursor: pointer;
      display: inline;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;

      @include respond-below(md) {
        font-size: 14px;
        line-height: 18px;
      }

      &:not(:first-child) {
        margin-left: 19px;
      }

      &:hover, &.active {
        color: #000000;
      }
    }
  }
</style>
