<template>
  <div
    class="top-section-wrapper"
    :class="{ 'no-bg': noBg, 'about-us': aboutUs }"
    :style="bgImage ? `background-image: url(${bgImage})` : ''"
  >
    <div class="layer" v-if="showShadeLayer && bgImage"></div>
    <div class="ufo">
      <img
        :src="require('../assets/ufo.gif')"
        v-if="aboutUs"
        alt=""
        class="ufo-one"
      />
      <img
        :src="require('../assets/ufo.gif')"
        v-if="aboutUs"
        alt=""
        class="ufo-two"
      />
    </div>
    <div class="app-content-wrapper">
      <header-component />
      <slot />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header";

export default {
  name: "TopSectionWrapper",
  components: {
    HeaderComponent,
  },
  props: {
    bgImage: {
      type: String,
      default: "",
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    aboutUs: {
      type: Boolean,
      default: false,
    },
    noDotsBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showShadeLayer: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.ufo {
  position: absolute;
  right: 20%;
  background-size: cover;
  width: 440px;
  height: 270px;
  display: flex;
  justify-content: start;
  overflow: hidden;
  @include respond-to(md) {
    right: 10%;
  }
  @include respond-to(sm) {
    width: 220px;
    height: 200px;
    right: 15%;
    top: 10%
  }
  @include respond-to(xs) {
    width: 220px;
    height: 200px;
    right: 15%;
    top: 10%
  }

  &-one {
    position: relative;
    width: 240px;
    height: 240px;
    margin-top: 50px;
    margin-right: -120px;

    transform: rotate(20deg);
    @include respond-to(sm) {
      width: 120px;
      height: 120px;
      margin-top: 25px;
      margin-right: -40px;
    }
    @include respond-to(xs) {
      width: 120px;
      height: 120px;
      margin-top: 25px;
      margin-right: -40px;
    }
  }
  &-two{
    position: relative;
    /*transform: translate(50%, 100%);*/
    width: 300px;
    height: 300px;
    transform: rotate(20deg);
    margin-right: 50px;
    @include respond-to(sm) {
      width: 120px;
      height: 120px;
      margin-top: 25px;
      margin-right: -40px;
    }
    @include respond-to(xs) {
      width: 150px;
      height: 150px;
    }
  }


}

$animation-speed: 1s;
.top-section {
  &-wrapper {
    background-size: cover;
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 85px;
    width: 100%;
    align-self: center;
    transition: 0.5s ease all;


    &.about-us {
      background-image: url("../assets/about-us.jpg");
      max-height: 838px;
      padding: 84px 0 200px;
      /*background-size: 100%;*/
      background-repeat: no-repeat;
      /*height: fit-content;*/
      @media (min-width: 1800px) {
        padding: 84px 0 340px;

      }
      /*<!--@include respond-to(lg){-->*/
      /*<!--  padding: 83px 0 100px;-->*/
      /*<!--}-->*/

      /*<!--@include respond-to(md) {-->*/
      /*<!--  background-image: url("../assets/about-us.jpg");-->*/
      /*<!--  padding: 44px 0 150px;-->*/

      @include respond-to(sm) {
        background-image: url("../assets/about-us.jpg");
        padding-top: 39px;

      }
      @include respond-to(xs) {
        background-image: url("../assets/about-us.jpg");
        padding-top: 39px;

      }

      /*<!--@include respond-to(xs) {-->*/
      /*<!--  background-image: url("../assets/about-us.jpg");-->*/
      /*<!--  background-size: cover;-->*/
      /*<!--  padding: 44px 0 94px;-->*/

      /*<!--}-->*/

      :deep(.top-section-image) {
        display: none;
      }
    }

    @include respond-to(xs) {
      padding-top: 37px;
      /*padding-bottom: 80px;*/
      overflow: hidden;
      background-size: cover;
      background-position: 59% 50%;
    }

    @include respond-to(sm) {
      background-position: 50% 0;
      padding-top: 39px;
      /*padding-bottom: 171px;*/
    }

    &.no-bg {
      box-shadow: none;
      background: none;
      min-height: inherit;
    }
  }

  &-bg {
    position: absolute;
    background: url("../assets/top-section-content-bg.svg");
    top: 155px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    &.hide {
      display: none;
    }

    @include respond-to(sm) {
      top: 0;
    }
  }
}

.layer {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
</style>
