<template>
  <div class="slide" ref="slide">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlideComponent',
  mounted() {
  }
};
</script>

<style scoped lang="scss">
$animation-speed: 0.2s;
.slide {
  animation: fadeOutAnimation ease $animation-speed;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: none;

  &-active {
    display: block;
    animation: fadeInAnimation ease $animation-speed;
    animation-iteration-count: 1;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
