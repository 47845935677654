<template>
  <main-layout about-us>
    <template v-slot:top-section>
      <top-section-wrapper about-us>
        <top-section-content show-right-side>
          <template v-slot:top-section-heading>
            <span class="about-us-heading">
              <span class="about-us-heading-text"
                >A2DAO
                <span class="about-us-heading-text-shadow">A2DAO </span>
              </span>
            </span>
          </template>
          <template v-slot:top-section-sub-heading>
            <span class="about-us-subheading">
              Easy investments for everyone
            </span>
          </template>
          <template v-slot:top-section-footer>
            <button-component
              v-if="!userProfile.userERCAddress"
              @click="handleConnect"
              class="secondary connectBtn"
              :style="
                animation
                  ? 'transform: translate(6px, 6px);transition-duration: 200ms;'
                  : 'transform: translate(0, 0); transition-duration: 200ms;'
              "
            >
              <FontTitle text="Join" class="connectBtn-text"></FontTitle>
<!--              <span class="connectBtn-text"-->
<!--                >Join-->
<!--                <span class="connectBtn-text-shadow">Join</span>-->
<!--              </span>-->
            </button-component>
            <div v-if="!userProfile.userERCAddress" class="button-shadow"></div>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="about-us-content" @scroll="scrollFunc">
      <div class="container brief">
        <div class="images-one">
          <div class="images-two"></div>
        </div>
        <div class="text-block">
          <h3>
            We are building a vibrant,<br />decentralized community<br />powered
            by DAO governance
          </h3>
          <span>
            where everyone can contribute to the future success<br />
            of crypto projects. We give anyone the opportunity<br />
            to invest in Web3 startups at VC prices.
          </span>
        </div>
      </div>
      <div id="portfolio" class="container partners">
        <div class="container partners-bg">
          <partners-list />
        </div>
      </div>
      <div id="features" class="container features">
        <div class="features-wrapper">
          <img
            v-if="show"
            :src="require('../assets/ufo-blurred.svg')"
            :style="`left:${1.5*x}px; top:-${x / 2}px;`"
            alt=""
            class="ufo-blurred"
          />
          <h2>Features</h2>
          <div class="features-list">
            <!--<div class="arrow-left"></div>-->
            <div class="features-item">
              <FontTitle text="On the market since 2017" class="features-item-text"></FontTitle>
              <span>
                Since 2017, we have successfully invested in more than 150
                projects. Over $25 million raised.
              </span>
            </div>
            <div class="features-item">
              <FontTitle text="We have No token" class="features-item-text"></FontTitle>
              <span>
                which means no additional risk and zero entry threshold to our
                users. Access to deals is open to all interested investors.
              </span>
            </div>
            <div class="features-item">
              <FontTitle text="OTC deals" class="features-item-text"></FontTitle>
              <span>
                Buy the allocations you are interested in or sell yours at any
                time and at any price using a smart contract. OTC marketplace
                soon.
              </span>
            </div>
            <!--<div class="arrow-right"></div>-->
          </div>
          <div class="features-list-bottom">
            <!--<div class="arrow-left"></div>-->
            <div class="features-item">
              <FontTitle text="Exclusive deals" class="features-item-text"></FontTitle>
              <span>
                Investing in DeFi, NFT, liquidity, nodes, and more on any
                blockchain: SUI, Aptos, Near, Polkadot, Solana, Cosmos,
                Algorand, and others.
              </span>
            </div>
            <div class="features-item">
              <FontTitle text="Handy tools" class="features-item-text"></FontTitle>
              <span>
                User-friendly web interface with portfolio tracking, token
                unlock schedule and detailed project information.
              </span>
            </div>
            <!--<div class="arrow-right"></div>-->
          </div>
          <div class="features-image">
            <div class="features-image-alien-one"></div>
            <div class="features-image-alien-two"></div>
            <div class="features-image-alien-three"></div>
            <div class="features-image-alien-four"></div>
            <div class="features-image-chest"></div>
            <div class="features-image-alien-smiles"></div>
            <div class="features-image-torch"></div>
            <div class="features-image-coin-one"></div>
            <div class="features-image-coin-two"></div>
            <div class="features-image-coin-three"></div>
            <div class="features-image-coin-four"></div>
            <div class="features-image-coin-five"></div>
            <div class="features-image-coin-six"></div>
            <div class="features-image-ufo-light"></div>
            <div class="features-image-ufo-sheep"></div>
            <div class="features-image-ufo-sheep-left"></div>
            <div class="features-image-ufo-sheep-right"></div>
          </div>
        </div>
      </div>
      <div class="container banner">
        <div class="banner-wrapper">
          <FontTitle text="See you in the A2DAO community!" class="text"></FontTitle>
          <span> Feel free to contact us at <b>info@a2dao.com</b> </span>
        </div>
        <div class="banner-smiles-top"></div>
        <div class="banner-smiles-bottom"></div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import TopSectionContent from "@/components/TopSectionContent";
import ButtonComponent from "@/components/Button";
import PartnersList from "@/components/PartnersList";
import { mapState, mapActions } from "vuex";
import FontTitle from "@/components/FontTitle";

export default {
  name: "AboutUs",
  components: {
    FontTitle,
    ButtonComponent,
    TopSectionContent,
    TopSectionWrapper,
    MainLayout,
    PartnersList,
  },
  head: {
    meta: [
      {
        name: 'description',
        content: 'Launchpad and ambassador platform for crypto projects. Invest in tokens and NFT.'
      },
    ],
  },
  created() {
    window.addEventListener("scroll", this.scrollFunc);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollFunc);
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    ...mapState(["userProfile"]),
  },
  data() {
    return {
      aboutUsBanner: "",
      show: false,
      animation: false,
      x: 0,
      y: 0,
      count: 0,
    };
  },
  methods: {
    ...mapActions(["connectWallet"]),
    scrollFunc() {
      if (window.pageYOffset > 1300 && window.pageYOffset < 2500) {
        if (!this.count) {
          this.show = true;
          this.count++;
        }
        this.x = window.pageYOffset - 1800;
      } else if (window.pageYOffset < 500 || window.pageYOffset > 2500) {
        this.show = false;
      }
    },
    async handleConnect() {
      this.animation = !this.animation;

      setTimeout(() =>{
        this.animation = !this.animation;

      }, 300)

      await this.connectWallet();
      this.$router.push({ name: "Active" });

    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

img {
  width: 100%;
}

.ufo-blurred {
  position: absolute;
  width: initial;
  top: 0;
  left: 0;
  z-index: 5;
  animation: sheeps 1s infinite linear;
  animation-iteration-count: 1;
  overflow: hidden;

  @include respond-to(sm) {
    width: 214px;
    top: -40px;
    left: -50px;
  }

  @include respond-to(xs) {
    width: 191px;
    top: -60px;
    left: -70px;
  }
}

.connectBtn {
  background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: none;
  max-width: 212px;
  height: 60px;


  &-text {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    /* identical to box height, or 29px */

    letter-spacing: 0.105em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;

  }
  /*<!--@include respond-to(xs) {-->*/
  /*<!--  font-size: 16px;-->*/
  /*<!--  padding: 13px 30px;-->*/
  /*<!--}-->*/

  /*<!--@include respond-to(sm) {-->*/
  /*<!--  font-size: 16px;-->*/
  /*<!--  padding: 11px 25px;-->*/
  /*<!--  max-width: 140px;-->*/
  /*<!--}-->*/
}

.about-us {
  &-heading {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 140px;
    line-height: 207px;
    letter-spacing: -0.005em;

    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;

    &-text {
      position: relative;
      &-shadow {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: -1;

        @include respond-below(lg) {
          top: 14px;
          left: 3px;
          z-index: -1;
        }
      }
    }

    /*border: 1px solid #000000;*/
    /*<!--text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,-->*/
    /*<!--  1px 1px 0 #000, 1px 0px 0px #fff, 0px 1px 0px #fff, 2px 1px 0px #fff,-->*/
    /*<!--  1px 2px 0px #fff, 3px 2px 0px #fff, 2px 3px 0px #fff, 4px 3px 0px #fff,-->*/
    /*<!--  3px 4px 0px #fff, 5px 4px 0px #fff, 3px 5px 0px #000, 6px 5px 0px #000,-->*/
    /*<!--  -1px 2px 0 black, 0 3px 0 #000, 1px 4px 0 #000, 2px 5px 0px #000,-->*/
    /*<!--  2px -1px 0 #000, 3px 0 0 #000, 4px 1px 0 #000, 5px 2px 0px #000,-->*/
    /*<!--  6px 3px 0 #000, 7px 4px 0 #000;-->*/

    @include respond-to(xs) {
      font-size: 44px;
      line-height: 44px;
    }

    @include respond-to(sm) {
      font-size: 50px;
      line-height: 50px;
    }
    @include respond-to(md) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  &-subheading {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.055em;
    color: #272727;

    @include respond-to(xs) {
      font-size: 18px;
      line-height: 125.6%;
    }

    @include respond-to(sm) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;

    @include respond-below(xs-plus) {
      flex-direction: column;
    }

    @include respond-above(sm) {
      flex-wrap: wrap;
    }

    @include respond-between(xs, sm) {
      flex-wrap: wrap;
    }

    .container {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .brief {
      /*padding-top: 120px;*/
      padding-bottom: 228px;
      position: relative;

      @include respond-to(xs) {
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 130px;
      }

      @include respond-to(sm) {
        padding-top: 100px;
        padding-bottom: 116px;
      }
      .images-one {
        height: 396px;
        width: 400px;
        /*background: url("../assets/bg-pixel.svg") no-repeat center;*/
        @include respond-to(xs) {
          top: 0;
          left: 50%;
          position: absolute;
          height: 80px;
          width: 80px;
          /*background: url("../assets/bg-pixel.svg") no-repeat center;*/
          background-size: cover;
        }

        @include respond-to(sm) {
          /*background: url("../assets/bg-pixel.svg") no-repeat center;*/
          height: 144px;
          width: 156px;
          background-size: cover;
        }

        .images-two {
          height: 396px;
          width: 400px;
          animation: spin 4s steps(3, start) 0s infinite normal;
          background: url("../assets/logo-a.svg") no-repeat center;
          position: absolute;
          top: 90px;
          left: 9%;
          @include respond-to(md) {
            left: -2%;
          }
          @include respond-to(xs) {
            position: absolute;
            height: 135px;
            width: 135px;
            top: -50px;
            left: -15px;
            background: url("../assets/logo-a.svg") no-repeat center;
            background-size: cover;
          }
          @include respond-to(sm) {
            background: url("../assets/logo-a.svg") no-repeat center;
            height: 206px;
            width: 211px;
            top: 50px;
            left: 20px;
            background-size: cover;
          }
          @keyframes spin {
            0%,
            2% {
              transform: translate(0, 0);
            }
            50% {
              transform: translate(0, 2%);
            }
          }
        }
      }

      .text-block {
        margin-left: 100px;
        margin-top: 150px;
        max-width: 700px;
        color: #272727;

        @include respond-to(xs) {
          max-width: none;
          margin: 0 auto;
          padding: 0 23px;
          margin-top: 80px;
        }

        @include respond-to(sm) {
          margin-left: 25px;
          margin-top: 0;
        }

        h3 {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          line-height: 42px;
          /* or 124% */

          display: flex;
          align-items: center;
          letter-spacing: -0.07em;

          /* Linear */

          background: linear-gradient(
            142.91deg,
            #080607 -32.36%,
            #374544 68.18%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          @include respond-to(xs) {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 24px;
          }

          @include respond-to(sm) {
            font-size: 16px;
            line-height: 26px;
          }
        }

        span {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          /* or 130% */

          display: flex;
          align-items: center;
          letter-spacing: -0.025em;

          @include respond-to(xs) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.025em;
          }

          @include respond-to(sm) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.025em;
          }
        }
      }
    }

    .partners {
      background: #e5e7eb;

      &-bg {
        background: url("../assets/partners-art.svg") no-repeat center;

        @include respond-to(xs) {
          background: url("../assets/partners-art_mobile.svg") no-repeat;
          min-height: 595px;
          background-size: 100% 100%;
        }

        @include respond-to(sm) {
          background: url("../assets/partners-art_pad.svg") no-repeat;
          min-height: 410px;
          width: 100%;
          background-size: 100% 100%;
        }
      }
    }
    .features {
      &-wrapper {
        text-align: center;
        padding-top: 120px;
        width: 100%;
        position: relative;

        @include respond-to(xs) {
          padding-top: 109px;
          text-align: inherit;
        }

        @include respond-to(sm) {
          padding: 90px 30px 0;
        }

        h2 {
          letter-spacing: -0.065em;
          color: #272727;
          margin-top: 0;
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 130%;
          /* or 62px */

          align-items: center;
          text-align: center;
          @include respond-to(xs) {
            font-size: 18px;
            margin-left: 32px;
          }

          @include respond-to(sm) {
            font-size: 22px;
          }
        }
      }

      &-list {
        display: flex;
        justify-content: space-around;
        max-width: 1200px;
        margin: 120px auto 0;

        @include respond-to(xs) {
          margin: 25px auto 0;
          flex-direction: column;
          align-items: center;
          //overflow-x: scroll;
        }

        @include respond-to(sm) {
          margin: 45px auto 0;
        }

        &-bottom {
          display: flex;
          justify-content: space-evenly;
          margin: 45px auto 0;
          max-width: 900px;

          @include respond-to(xs) {
            flex-direction: column;
            align-items: center;
            //overflow-x: scroll;
          }
        }

        .arrow {
          &-left,
          &-right {
            height: 42px;
            width: 42px;
            align-self: center;

            @include respond-to(xs) {
              display: none;
            }
          }

          &-left {
            background: url("../assets/arrow-left.svg") no-repeat center;
          }

          &-right {
            background: url("../assets/arrow-right.svg") no-repeat center;
          }
        }
      }

      &-item {
        max-width: 270px;
        z-index: 10;

        &:not(:nth-child(2)) {
          @include respond-to(xs) {
            //margin-left: 8px;
          }
        }

        @include respond-to(xs) {
          background: #e5e7eb;
          padding: 30px 15px;
          min-width: 205px;
          margin-top: 25px;
        }

        @include respond-to(sm) {
          text-align: initial;
          max-width: 180px;
        }

        h3 {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 113.5%;
          letter-spacing: -0.045em;
          margin: 25px 0;
          text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
          @include respond-to(xs) {
            font-size: 20px;
            line-height: 26px;
          }

          @include respond-to(sm) {
            font-size: 24px;
            line-height: 26px;
          }
        }

        span {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 146.5%;
          letter-spacing: -0.05em;
          color: #272727;

          @include respond-to(xs) {
            font-size: 14px;
            line-height: 146.5%;
          }

          @include respond-to(sm) {
            font-size: 14px;
            line-height: 146.5%;
          }
        }
        &-text {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 113.5%;
          letter-spacing: -0.045em;
          margin: 25px 0;
          text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
          @include respond-to(xs) {
            font-size: 20px;
            line-height: 26px;
          }

          @include respond-to(sm) {
            font-size: 24px;
            line-height: 26px;
          }
          &-shadow {
            width: 100%;
            position: absolute;
            top: -23px;
            left: 1.5px;
            z-index: -1;
            color: #f3fdf8;
          }
        }
      }

      &-image {
        background: url("../assets/feature-bg.png") no-repeat center;
        height: 500px;
        max-width: 1200px;
        max-height: 500px;
        margin: 200px auto 0;
        width: 100%;
        position: relative;
        &-ufo-light {
          background: url("../assets/ufo-light.svg") no-repeat center;
          width: 67px;
          height: 105px;
          position: absolute;
          left: 58.8%;
          top: 38%;
          mix-blend-mode: overlay;
          background-size: cover;

          @include respond-to(md) {
            left: 62%;
          }
          @include respond-to(sm) {
            width: 47px;
            height: 75px;
            position: absolute;
            left: 58.8%;
            top: 38%;
          }
          @include respond-to(xs) {
            width: 40px;
            height: 60px;
            left: 41.8%;
            top: 34%;
          }
        }
        &-ufo-sheep {
          background: url("../assets/ufo_home_last.png") no-repeat center;
          width: 124px;
          height: 158px;
          position: absolute;
          left: 56.2%;
          top: 17%;
          background-size: 68%;
          transform: rotate(-21deg);
          @include respond-to(md) {
            left: 58.2%;
          }
          @include respond-to(sm) {
            left: 52.5%;
            top: 12%;
            background-size: 34%;
          }
          @include respond-to(xs) {
            left: 31%;
            top: -2%;
            background-size: 31%;
            width: 31%;
            height: 67%;
          }
        }
        &-ufo-sheep-left {
          background: url("../assets/ufo.gif") no-repeat center;
          width: 260px;
          height: 200px;
          position: absolute;
          left: 7.2%;
          top: 1%;
          background-size: 32%;
          transform: rotate(-20deg);
          @include respond-to(xs) {
            display: none;
          }
          @include respond-to(sm) {
            width: 200px;
            height: 160px;
          }
        }
        &-ufo-sheep-right {
          background: url("../assets/ufo.gif") no-repeat bottom;
          width: 157px;
          height: 151px;
          position: absolute;
          left: 87.2%;
          top: -13%;
          transform: rotate(20deg);
          background-size: 175%;
          @include respond-to(xs) {
            display: none;
          }
        }
        &-alien-one {
          background: url("../assets/alien.svg") no-repeat center;
          width: 35px;
          height: 72px;
          position: absolute;
          left: 50%;
          top: 48%;
          background-size: contain;
          @include respond-to(md) {
            left: 52%;
          }
          @include respond-to(sm) {
            height: 42px;
          }
          @include respond-to(xs) {
            width: 19px;
            height: 72px;
            left: 32%;
            top: 40%;
          }
        }
        &-alien-two {
          background: url("../assets/alien-two.svg") no-repeat center;
          width: 33px;
          height: 57px;
          position: absolute;
          left: 56%;
          top: 46%;
          background-size: contain;
          @include respond-to(md) {
            left: 58%;
          }
          @include respond-to(sm) {
            height: 38px;
          }
          @include respond-to(xs) {
            width: 17px;
            height: 72px;
            left: 38%;
            top: 38%;
          }
        }
        &-alien-three {
          background: url("../assets/alien-left-arm.svg") no-repeat center;
          width: 61px;
          height: 113px;
          position: absolute;
          left: 71%;
          top: 52%;
          background-size: contain;
          @include respond-to(md) {
            left: 80%;
          }
          @include respond-to(sm) {
            height: 67px;
          }
          @include respond-to(xs) {
            width: 32px;
            height: 72px;
            left: 62%;
            top: 49%;
          }
        }
        &-alien-four {
          background: url("../assets/alien-right-arm.svg") no-repeat center;
          width: 36px;
          height: 113px;
          position: absolute;
          left: 90%;
          top: 42%;
          background-size: contain;
          @include respond-to(md) {
            display: none;
          }
          @include respond-to(sm) {
            width: 36px;
            height: 46px;
            position: absolute;
            left: 90%;
            top: 45%;
          }
          @include respond-to(xs) {
            width: 36px;
            height: 35px;
            position: absolute;
            left: 86%;
            top: 44%;
          }
        }
        &-alien-smiles {
          background: url("../assets/smiles.gif") no-repeat center;
          width: 68px;
          height: 37px;
          position: absolute;
          left: 67.5%;
          top: 50%;
          animation: smile 2s infinite linear;
          background-size: cover;
          @include respond-to(md) {
            left: 75.5%;
          }
          @include respond-to(sm) {
            width: 40px;
            height: 25px;
            left: 69%;
          }
          @include respond-to(xs) {
            width: 36px;
            height: 17px;
            left: 57.5%;
            top: 49%;
          }
        }
        &-torch {
          background: url("../assets/torch.gif") no-repeat center;
          width: 20px;
          height: 45px;
          position: absolute;
          left: 92%;
          top: 41%;
          background-size: cover;
          @include respond-to(md) {
            display: none;
          }
          @include respond-to(sm) {
            width: 7px;
            height: 29px;
            left: 94%;
            top: 40%;
          }
          @include respond-to(xs) {
            width: 13px;
            height: 24px;
            left: 91%;
            top: 38%;
          }
        }
        &-coin-one {
          background: url("../assets/animation-coin.gif") no-repeat center;
          width: 30px;
          height: 30px;
          position: absolute;
          left: 59%;
          top: 53%;
          background-size: cover;
          @include respond-to(md) {
            left: 63%;
          }
          @include respond-to(sm) {
            width: 20px;
            height: 20px;
            left: 60%;
          }
          @include respond-to(xs) {
            width: 20px;
            height: 20px;
            left: 43%;
            top: 51%;
          }
        }
        &-coin-two {
          background: url("../assets/animation-coin.gif") no-repeat center;
          width: 30px;
          height: 30px;
          position: absolute;
          left: 61%;
          top: 51%;
          background-size: cover;
          @include respond-to(md) {
            left: 65%;
          }
          @include respond-to(sm) {
            width: 20px;
            height: 20px;
            left: 62%;
          }
          @include respond-to(xs) {
            width: 20px;
            height: 20px;
            left: 45%;
            top: 49%;
          }
        }
        &-coin-three {
          background: url("../assets/animation-coin.gif") no-repeat center;
          width: 40px;
          height: 40px;
          position: absolute;
          left: 59.5%;
          top: 48%;
          background-size: cover;
          @include respond-to(md) {
            left: 63.5%;
          }
          @include respond-to(sm) {
            width: 25px;
            height: 25px;
            left: 60.5%;
          }
          @include respond-to(xs) {
            width: 22px;
            height: 22px;
            left: 43.5%;
            top: 45%;
          }
        }
        &-coin-four {
          background: url("../assets/animation-coin.gif") no-repeat center;
          width: 35px;
          height: 35px;
          position: absolute;
          left: 61%;
          top: 44%;
          background-size: cover;
          @include respond-to(md) {
            left: 65%;
          }
          @include respond-to(sm) {
            width: 23px;
            height: 23px;
            left: 62%;
          }
          @include respond-to(xs) {
            width: 23px;
            height: 23px;
            left: 45%;
            top: 40%;
          }
        }
        &-coin-five {
          background: url("../assets/animation-coin.gif") no-repeat center;
          width: 25px;
          height: 25px;
          position: absolute;
          left: 60%;
          top: 41%;
          background-size: cover;
          @include respond-to(md) {
            left: 64%;
          }
          @include respond-to(sm) {
            width: 15px;
            height: 15px;
            left: 61%;
          }
          @include respond-to(xs) {
            width: 20px;
            height: 20px;
            left: 83.7%;
            top: 50%;
          }
        }
        &-coin-six {
          background: url("../assets/animation-coin.gif") no-repeat center;
          width: 36px;
          height: 36px;
          position: absolute;
          left: 87.4%;
          top: 53%;
          background-size: cover;
          @include respond-to(md) {
            display: none;
          }
          @include respond-to(sm) {
            width: 23px;
            height: 23px;
            left: 87.3%;
            top: 50.8%;
          }
          @include respond-to(xs) {
            width: 15px;
            height: 15px;
            left: 44%;
            top: 39%;
          }
        }
        &-chest {
          background: url("../assets/golden-chest.svg") no-repeat center;
          width: 35px;
          height: 30px;
          position: absolute;
          left: 87.5%;
          top: 55%;
          background-size: cover;
          @include respond-to(md) {
            display: none;
          }
          @include respond-to(sm) {
            width: 20px;
            height: 20px;
            left: 87.5%;
            top: 53%;
          }
          @include respond-to(xs) {
            width: 15px;
            height: 15px;
            left: 84.5%;
            top: 53%;
          }
        }

        & > img {
        }

        @include respond-to(xs) {
          max-height: 233px;
          background: url("../assets/feature-bg.png") no-repeat right;
          margin: 70px auto 0;
          background-size: 140%;

          max-width: 400px;
        }

        @include respond-to(sm) {
          max-height: 352px;
          background: url("../assets/feature-bg.png") no-repeat center;
          margin: 120px auto 0;
          background-size: contain;
        }
      }
    }

    .banner {
      position: relative;
      &-smiles-top {
        position: absolute;
        background: url("../assets/smiles.gif");
        width: 100px;
        height: 60px;
        background-size: cover;
        top: 30%;
        left: 19%;
        @include respond-to(sm) {
          top: 19%;
        }
        @include respond-to(xs) {
          top: 25%;
        }
      }
      &-smiles-bottom {
        position: absolute;
        background: url("../assets/smiles.gif");
        width: 100px;
        height: 60px;
        background-size: cover;
        top: 89%;
        right: 17%;
        @include respond-to(md) {
          top: 95%;
        }
        @include respond-to(sm) {
          top: 90%;
        }
        @include respond-to(xs) {
          top: 80%;
        }
        @media (max-width: 420px) {
          top: 90%;
        }
        @media (max-width: 290px) {
          top: 100%;
        }
      }
      &-wrapper {
        max-width: 809px;
        margin-top: 120px;
        height: 300px;
        width: 100%;
        /*background: url("../assets/banner-wrapper.svg") no-repeat center;*/
        text-align: center;
        /*@keyframes banner {*/
        /*  5%, 0% {*/
        /*    transform: translate(0, 0);*/
        /*  }*/
        /*  50% {*/
        /*    transform: translate(5%,0%);*/
        /*  }*/
        /*}*/

        /*<!--@include respond-to(xs) {-->*/
        /*<!--  background: url("../assets/banner-wrapper_mobile.svg") no-repeat center;-->*/
        /*<!--  margin-top: 60px;-->*/
        /*<!--  height: 213px;-->*/
        /*<!--}-->*/

        /*<!--@include respond-to(sm) {-->*/
        /*<!--  background: url("../assets/banner-wrapper_pad.svg") no-repeat center;-->*/
        /*<!--  height: 175px;-->*/
        /*<!--}-->*/

        h2,
        span {
          max-width: 805px;
        }

        h2 {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 113.5%;
          letter-spacing: -0.045em;
          color: #fff;
          text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
          margin: 0 auto;
          margin-top: 65px;
          max-width: 400px;
        }
        .text {
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 113.5%;
          letter-spacing: -0.045em;
          color: #fff;
          text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
          margin: 0 auto;
          margin-top: 65px;
          max-width: 400px;
        }

        span {
          display: inline-block;
          font-family: "Space Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 130%;
          color: #000000;
          margin-top: 70px;

          @include respond-to(xs) {
            font-size: 12px;
            max-width: 200px;
            margin-top: 15px;
          }

          @include respond-to(sm) {
            font-size: 22px;
            max-width: 400px;
            margin-top: 35px;
          }
        }
      }
    }
  }
}

.button-shadow {
  position: absolute;
  max-width: 210px;
  width: 100%;
  height: 58px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  /*<!--@include respond-to(xs) {-->*/
  /*<!--  height: 45px;-->*/
  /*<!--}-->*/

  /*<!--@include respond-to(sm) {-->*/
  /*<!--  max-width: 138px;-->*/
  /*<!--  height: 42px;-->*/
  /*<!--}-->*/
}
</style>