<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper>
        <top-section-content show-right-side show-social-networks>
          <template v-slot:top-section-heading>
            <span class="heading">
              Error 404
            </span>
          </template>
          <template v-slot:top-section-sub-heading>
            <div class="sub-heading">
              The page you are looking for does not exist.
            </div>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
  </main-layout>
</template>
<script>
import TopSectionWrapper from '@/components/TopSectionWrapper';
import MainLayout from '@/layouts/main';
import TopSectionContent from '@/components/TopSectionContent';
import { mapGetters } from 'vuex';

export default {
  name: 'NotFoundPage',
  components: {
    TopSectionWrapper,
    MainLayout,
    TopSectionContent
  },
  computed: {
    ...mapGetters([
      'i18n'
    ])
  },
}
</script>