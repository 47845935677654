<template>
  <div class="blog-card-large-bg-wrapper">
    <div class="blog-card-large-img" :style="{backgroundImage: `url(${img})`}" @click="goToBlogPostPage"></div>
    <div class="blog-card-large-content-block">
      <div class="blog-card-large-main-content">
        <blog-categories-list :categories="blogCategories" />
        <h3 class="title" @click="goToBlogPostPage">{{ title }}</h3>
        <div class="short-content" v-html="shortContent" @click="goToBlogPostPage"></div>
<!--        <div class="link">-->
<!--          <router-link class="read-more" :to="`/blog/${slug}`">read more</router-link>-->
<!--        </div>-->
      </div>
      <blog-meta-info
          :slug="slug"
          :date="date"
          :minutes-to-read="minutesToRead"
          :likes="likes"
          :post-id="postId"
          space-after-date
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import BlogCategoriesList from '@/components/BlogCategoriesList';
import { useRouter } from 'vue-router';
import BlogMetaInfo from '@/components/blog/BlogMetaInfo';

const props = defineProps({
  title: String,
  shortContent: String,
  img: String,
  date: String,
  blogCategories: Array,
  likes: Number,
  slug: {
    type: String,
    required: true
  },
  minutesToRead: Number,
  postId: {
    type: Number,
    required: true
  }
});
const router = useRouter();

const goToBlogPostPage = () => {
  const slug = props.slug;
  router.push(`/blog/${slug}`);
};
</script>
<script>
export default {
  name: 'BlogPostCardLarge',
};
</script>
<style lang="scss">
.blog-card-large {
  &-main-content {
    cursor: pointer;
  }
  &-bg-wrapper {
    display: flex;
    background: #292C32;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.17);
    border-radius: 9px;
    padding: 6px;
    width: 100%;
    height: 447px;
  }

  &-img {
    cursor: pointer;
    width: 100%;
    border-radius: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-content-block {
    padding: 33px 36px;
    min-width: 363px;
    position: relative;
    width: 363px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      margin: 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #E5E5E5;
      opacity: 0.9;
    }

    .short-content {
      p {
        margin-top: 11px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #BABABA;
        opacity: 0.9;
      }
    }

    .link {
      .read-more {
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        background: linear-gradient(79.42deg, #5F4184 -38.96%, #F46A8E 84.27%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}
</style>
