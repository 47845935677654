<template>
  <div class="top-section-content"
       :class="{
    'with-right-side-content': topSectionRightContent,
    'content-to-left': contentToLeft
  }"
  >
    <div class="top-section-left-side" @mouseover="leftSideMouseOver" @mouseout="leftSideMouseOut">
      <div class="top-section-pre-heading">
        <slot name="top-section-pre-heading" />
      </div>
      <div class="top-section-heading">
        <slot name="top-section-heading" />
      </div>
      <div class="top-section-sub-heading">
        <slot name="top-section-sub-heading" />
      </div>
      <div class="top-section-social-networks-wrapper">
        <div class="top-section-social-networks" v-if="socialNetworks?.length > 0">
          <a v-for="socialNetwork in socialNetworks" :href="socialNetwork.url" :key="socialNetwork.id" target="_blank">
            <img
                :src="`${staticEndpoint}/${socialNetwork.img}`"
                alt="" class="top-section-social-networks-img"
            >
          </a>
        </div>
      </div>
      <div class="top-section-image" v-if="!hideImage">
        <slot name="top-section-image" />
      </div>
      <div class="top-section-footer" v-if="hasFooterSlot">
        <slot name="top-section-footer">
          <!--<router-link :to="{name: 'FAQ'}">
            <button-component class="faq secondary">
              FAQ
            </button-component>
          </router-link>
          <a href="https://pancakeswap.finance/swap?outputCurrency=0x1Ce440d1A64eeA6AA1dB2A5Aa51C9B326930957C" target="_blank" class="buy-atd">
            Buy ATD
          </a>-->
        </slot>
      </div>
    </div>
    <div
        class="top-section-right-side"
        v-if="showRightSide"
        :style="!topSectionRightContent ? `background-image: none` : ''"
        :class="topSectionRightContent ? 'with-content' : ''"
    >
      <slot name="top-section-right-side" />
    </div>
  </div>
</template>

<script>
//import ButtonComponent from '@/components/Button';

export default {
  name: 'TopSectionContent',
  emits: ['leftSideMouseOver', 'leftSideMouseOut'],
  components: {
    //ButtonComponent,
  },
  props: {
    showRightSide: Boolean,
    topSectionRightContent: Boolean,
    socialNetworks: Array,
    bgImage: String,
    contentToLeft: {
      type: Boolean,
      default: false
    },
    hideImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    }
  },
  methods: {
    leftSideMouseOver() {
      this.$emit('leftSideMouseOver');
    },
    leftSideMouseOut() {
      this.$emit('leftSideMouseOut');
    },
    hasFooterSlot() {
      return !!this.$slots['top-section-footer'];
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins";

$mobile-right-side-moon-right: -100px;
$mobile-right-side-moon-top: -67px;
$tablet-right-side-moon-right: 115px;
$tablet-right-side-moon-top: -100px;
$tablet-right-side-moon-size: 242px;

.top-section {
  &-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    z-index: 10;

    @include respond-to(sm) {
      margin-top: 47px;
    }

    @include respond-below(md) {
      justify-content: space-between;
    }

    @include respond-to(xs) {
      margin-top: 44px;
      justify-content: center;
    }

    &.content-to-left {
      justify-content: left;
    }

    &.with-right-side-content {
      @include respond-below(md) {
        flex-flow: column;
      }
    }
  }

  &-left-side {
    max-width: 900px;
    z-index: 20;

    @include respond-below(md) {
      width: 100%;
    }
  }

  &-right-side {
    background: url("../assets/project_bg.svg") no-repeat center;
    width: 577px;
    height: 378px;
    z-index: 11;

    @include respond-below(lg) {
      background: url("../assets/project_bg_pad.svg") no-repeat center;
      width: 341px;
      height: 221px;
    }

    @include respond-below(md) {
      display: none;
      /*background-size: contain;
      position: absolute;
      top: 75px;
      &:not(&.with-content) {
        top: $mobile-right-side-moon-top;
        right: $mobile-right-side-moon-right;
        transform: rotate(365deg);
      }*/
    }

    .main-img {
      width: 484px;
      height: 335px;
      position: absolute;
      right: 25px;
      widows: auto;
      object-fit: cover;

      @include respond-below(lg) {
        width: 282px;
        height: 185px;
        right: 15px;
      }
    }
  }

  &-pre-heading {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.1em;
    margin-left: 3px;
    margin-top: 2px;

    @include respond-below(md) {
      display: none;
      font-size: 16px;
    }
  }

  &-heading {
    color: #353535;
    font-family: 'Space Mono', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 98%;
    letter-spacing: -0.06em;
    padding-bottom: 10px;
    white-space: nowrap;

    @include respond-to(xs) {
      font-size: 34px;
      white-space: initial;
      max-width: 251px;
    }

    @include respond-below(lg) {
      font-size: 34px;
      margin-top: 0;
      padding-bottom: 0px;
    }
  }

  &-sub-heading {
    color: #1C1D23;
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    max-width: 600px;

    @include respond-to(xs) {
      font-size: 20px;
      margin-top: 0;
      max-width: 251px;
    }

    @include respond-below(lg) {
      font-size: 15px;
      line-height: 22px;
    }

    @include respond-below(md) {
      max-width: 400px;
    }
  }

  &-social-networks {
    display: none;
    margin-top: 15px;
    margin-left: 5px;

    @include respond-below(sm) {
      margin-bottom: 15px;
    }

    &-img {
      width: 20px;
      height: 20px;
      margin-right: 30px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;

      @include respond-below(sm) {
        flex-direction: column;
      }
    }
  }

  &-image {
    position: relative;
    display: none;
    @include respond-below(md) {
      margin-top: 20px;
      background: url("../assets/project_bg_pad.svg") no-repeat center;
      width: 341px;
      height: 221px;
    }

    @include respond-below(sm) {
      background: url("../assets/project_bg_mobile.svg") no-repeat center;
      width: 305px;
      height: 200px;
    }

    img {
      position: absolute;

      @include respond-below(md) {
        width: 282px;
        height: 185px;
        right: 15px;
      }

      @include respond-below(sm) {
        width: 256px;
        height: 177px;
        right: 15px;
      }
    }
  }

  &-footer {
    position: relative;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include respond-to(xs) {
      margin-top: 20px;
    }

    @include respond-to(sm) {
      margin-top: 30px;

    }

    a:not(:first-child) {
      //margin-left: 30px;
    }
  }
}
.moon-bg {
  @include respond-below(md) {
    background: url("../assets/moon.png") no-repeat center;
    position: absolute;
    background-size: contain;
    z-index: 10;
  }

  @include respond-to(xs) {
    top: $mobile-right-side-moon-top;
    right: $mobile-right-side-moon-right;
    transform: rotate(345deg);
    width: 450px;
    height: 450px;
  }

  @include respond-to(sm) {
    top: $tablet-right-side-moon-top;
    width: $tablet-right-side-moon-size;
    height: $tablet-right-side-moon-size;
    right: $tablet-right-side-moon-right;
  }

  img {
    @include respond-to(xs) {
      position: absolute;
      left: -144px;
      top: 240px;
      transform: rotate(13deg);
      height: 397px;
      width: 496px;
    }

    @include respond-to(sm) {
      position: absolute;
      width: 256px;
      height: 181px;
      top: 78px;
      left: -27px;
    }
  }
}

.buy-atd {
  background: linear-gradient(91.07deg, #FF5F72 -7.91%, #FFAD72 117.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 13px;
  position: relative;
  letter-spacing: 0.05em;
  padding: 0;

  &::after {
    content: '';
    background: url("../assets/icons/home-arrow-right.svg");
    width: 42px;
    height: 7.36px;
    position: absolute;
    right: -55px;
    top: 5px;
  }
}
</style>
