<template>
  <div class="blog-card-bg-wrapper">
    <div class="blog-card-main-content" @click="goToBlogPostPage(slug)">
      <div class="blog-card-img" :style="{backgroundImage: `url(${img})`}"></div>
      <div class="blog-card-content-block">
        <blog-categories-list :categories="blogCategories" />
        <h3 class="title">{{ title }}</h3>
        <div class="short-content" v-html="shortContent"></div>
      </div>
    </div>
    <div class="bottom-content">
      <blog-meta-info
          :post-id="postId"
          :slug="slug"
          :date="date"
          :likes="likes"
          :minutes-to-read="minutesToRead"
      />
<!--      <div class="link">-->
<!--        <router-link class="read-more" :to="`/blog/${slug}`">read more</router-link>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script setup>
import BlogCategoriesList from '@/components/BlogCategoriesList';
import BlogMetaInfo from '@/components/blog/BlogMetaInfo';
</script>
<script>
import router from '@/router';

export default {
  name: 'BlogPostCard',
  props: {
    title: String,
    shortContent: String,
    img: String,
    date: String,
    blogCategories: Array,
    slug: {
      type: String,
      required: true
    },
    postId: {
      type: Number,
      required: true
    },
    likes: Number,
    minutesToRead: Number
  },
  methods: {
    // TODO move to helpers
    goToBlogPostPage(slug) {
      router.push(`/blog/${slug}`);
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/mixins";

  .blog-card {
    &-main-content {
      cursor: pointer;
    }
    &-bg-wrapper {
      flex: 0 1 auto;
      background: #292C32;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.17);
      border-radius: 9px;
      max-width: 394px;
      padding: 6px;
      position: relative;
      width: 394px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include respond-below(md) {
        width: 312px;
      }

      .bottom-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 21px;
        padding-right: 21px;
        padding-bottom: 10px;

        @include respond-below(md) {
          width: 270px;
          bottom: 20px;
          flex-direction: column-reverse;
          align-items: flex-start;
        }

        .link {
          @include respond-below(md) {
            margin-bottom: 10px;
          }
          .read-more {
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            background: linear-gradient(79.42deg, #5F4184 -38.96%, #F46A8E 84.27%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }

    &-img {
      height: 219px;
      border-radius: 9px 9px 0 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include respond-below(md) {
        height: 166px;
      }
    }

    &-content-block {
      padding: 14px 21px 42px;

      @include respond-below(md) {
        padding-bottom: 10px;
      }

      .title {
        margin: 0;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #E5E5E5;
        opacity: 0.9;

        @include respond-below(md) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .short-content {
        @include respond-below(md) {
          display: none;
        }

        p {
          margin-top: 11px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #BABABA;
          opacity: 0.9;
        }
      }
    }
  }
</style>
