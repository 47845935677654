import { API } from '@/api';

const ProjectService = {
  setIsFull: async slug => {
    try {
      await API.post(`/projects/${slug}/set-is-full`);
    } catch (e) {
      console.error(e.message);
    }
  }
};

export default ProjectService;
