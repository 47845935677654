<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper no-bg />
    </template>
    <div class="blog-post-wrapper">
      <div class="blog-post-back">
        <router-link :to="{name: 'Blog'}">
          <img src="../assets/icons/back.svg" alt="account" class="back-icon">
        </router-link>
      </div>
      <div class="blog-post-image">
        <img :src="`${staticEndpoint}${blogPost?.cardBannerPath}`" alt="">
      </div>
      <div class="blog-post-container">
        <blog-categories-list :categories="blogPost?.blogCategories" />
        <div class="blog-post-title">
          {{ blogPost?.title }}
        </div>
        <div class="blog-post-text" v-html="blogPost?.content" />
        <div class="blog-post-bottom-bar">
          <blog-meta-info v-if="blogPost"
              :slug="blogPost.slug"
              :date="blogPost.date"
              :minutes-to-read="blogPost.minutesToRead"
              :likes="blogPost.likes"
              :post-id="blogPost.id"
              show-share-text
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script setup>
import MainLayout from '@/layouts/main';
import TopSectionWrapper from '@/components/TopSectionWrapper';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { API } from '@/api';
import BlogCategoriesList from '@/components/BlogCategoriesList';
import BlogMetaInfo from '@/components/blog/BlogMetaInfo';

const route = useRoute();
const blogPost = ref(null);

const staticEndpoint = process.env.VUE_APP_STATIC_ENDPOINT;

onMounted(async () => {
  const { data } = await API.get(`/blog-posts/${route.params.slug}`);
  blogPost.value = data;
});
</script>

<script>
export default {
  name: 'BlogPostPage'
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.blog-post {
  &-wrapper {
    padding: 100px 20px 0;
    @include respond-below(xs) {
      padding: 0;
    }
  }

  &-back {
    margin-bottom: 15px;
  }

  &-image {
    img {
      width: 100%;
    }
  }

  &-container {
    margin: 68px auto;
    padding: 0 15%;

    @include respond-below(lg) {
      padding: 0;
    }

    @include respond-below(sm) {
      padding: 0 5%;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 44px;
    line-height: 107.5%;
    color: #000000;
    opacity: 0.9;
    margin-top: 25px;

    @include respond-below(xs) {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 152.5%;
    color: #BABABA;
    word-break: break-word;

    :deep(h2, h3, h4) {
      margin-top: 1.5em;
    }

    :deep(img) {
      max-width: 100%;
      width: 100%;
    }

    :deep(a) {
      color: #BABABA;
      text-decoration: underline;
    }

    @include respond-below(md) {
      font-size: 16px;
      line-height: 152.5%;
    }

    @include respond-below(xs) {
      font-size: 14px;
    }
  }

  &-bottom-bar {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}
</style>
