<template>
  <main-layout>
    <div class="image"></div>
    <div class="rocket"></div>

    <template v-slot:top-section>
      <top-section-wrapper class="pb-40">
        <top-section-content hide-image>
          <template v-slot:top-section-heading>
            <FontTitle text="Leaderboard" class="title"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            The score on the leaderboard depends on your investment and social
            activity. The higher your ranking, the better deals you can have.
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="leaderboard-wrapper">
      <div class="leaderboard-my-score">
        <div class="leaderboard-my-score-title">My Score</div>
        <div class="leaderboard-my-score-block">
          <div class="leaderboard-my-score-block-name">
            Rank:<span>{{ currentUserIndex }}</span>
          </div>
          <div class="leaderboard-my-score-block-name">
            Address:<span>
              {{ trimMiddle(currentUserLeaderboard?.erc20Address) }}
            </span>
          </div>
          <div class="leaderboard-my-score-block-name">
            Invest Score:<span>{{
            (currentUserLeaderboard?.totalInvested / 1000).toFixed(2)
            }} - {{(currentUserLeaderboard?.totalRefunded / 1000).toFixed(2)}}</span>
          </div>
          <div class="leaderboard-my-score-block-name">
            Projects Participated:<span>{{
              currentUserLeaderboard?.numberOfProjects
            }}</span>
          </div>
          <div class="leaderboard-my-score-block-name">
            Projects Refunded:<span>{{
              currentUserLeaderboard?.numberOfRefundedProjects
            }}</span>
          </div>
          <div class="leaderboard-my-score-block-name">
            DAO Value:<span>{{ currentUserLeaderboard?.daoValue }}</span>
          </div>
          <div class="leaderboard-my-score-block-name">
            ATD Boost:
            <tooltip-custom :content="currentUserLeaderboard?.atdBoost.toString()">
              <span style="margin-left: 0">{{
                getCorrectScore(currentUserLeaderboard?.atdBoost)
              }}</span>
            </tooltip-custom>
            <div class="btn-container">
              <button-component class="cta stake" @click="showStakeModal"
                >Stake</button-component
              >
              <button-component class="cta burned" @click="showModal"
                >Burn</button-component
              >
            </div>
          </div>
          <div class="leaderboard-my-score-block-name">
            Total Score:
            <tooltip-custom :content="currentUserLeaderboard?.totalScore.toString()">
              <span style="margin-left: 0">{{
                getCorrectScore(currentUserLeaderboard?.totalScore)
              }}</span>
            </tooltip-custom>
          </div>
        </div>
        <div class="leaderboard-my-score-squares-one"></div>
        <div class="leaderboard-my-score-squares-second"></div>
      </div>
      <!--      <table-->
      <!--        class="leaderboard-table"-->
      <!--        v-if="currentUserLeaderboard?.erc20Address"-->
      <!--      >-->
      <!--        <thead>-->
      <!--          <tr>-->
      <!--            <th>Your Rank</th>-->
      <!--            <th>Address</th>-->
      <!--            <th>Investment Score</th>-->
      <!--            <th>Projects Participated</th>-->
      <!--            <th>DAO Value</th>-->
      <!--            <th>ATD Boost</th>-->
      <!--            <th>Total Score</th>-->
      <!--          </tr>-->
      <!--        </thead>-->
      <!--        <tbody>-->
      <!--          <tr key="current-user">-->
      <!--            <td>{{ currentUserIndex }}</td>-->
      <!--            <td class="address">-->
      <!--              {{ trimMiddle(currentUserLeaderboard?.erc20Address) }}-->
      <!--            </td>-->
      <!--            <td>{{ convertToIntl(currentUserLeaderboard?.totalInvested) }}</td>-->
      <!--            <td>{{ currentUserLeaderboard?.numberOfProjects }}</td>-->
      <!--            <td>{{ currentUserLeaderboard?.daoValue }}</td>-->
      <!--            <td>-->
      <!--              <tooltip-custom :content="currentUserLeaderboard?.atdBoost">-->
      <!--                <button-component class="cta" @click="showModal">{{-->
      <!--                  currentUserLeaderboard.atdBoost-->
      <!--                    ? getCorrectScore(currentUserLeaderboard?.atdBoost) + " + "-->
      <!--                    : "Boost"-->
      <!--                }}</button-component>-->
      <!--              </tooltip-custom>-->
      <!--            </td>-->
      <!--            <td>-->
      <!--              <tooltip-custom :content="currentUserLeaderboard?.totalScore">-->
      <!--                {{ getCorrectScore(currentUserLeaderboard?.totalScore) }}-->
      <!--              </tooltip-custom>-->
      <!--            </td>-->
      <!--            &lt;!&ndash;            <td>{{ getCorrectScore(currentUserLeaderboard?.totalScore) }}</td>&ndash;&gt;-->
      <!--          </tr>-->
      <!--        </tbody>-->
      <!--      </table>-->
      <div class="leaderboard">
        <div class="leaderboard-title">Leaderboard</div>
        <table class="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Address</th>
              <th>Total Score</th>
            </tr>
          </thead>
        </table>
        <table class="leaderboard-table">
          <tbody>
            <tr
              v-for="(user, index) in leaderboard"
              :key="user.erc20Address"
              :class="{
                'current-user':
                  user.erc20Address === userProfile.userERCAddress,
              }"
            >
              <td>{{ index + 1 }}</td>
              <td class="address">{{ user?.erc20Address ? trimMiddle(user.erc20Address) : '' }}</td>
              <td>
                <tooltip-custom :content="user.totalScore.toString()">
                  <!--              <div class="tooltip">-->
                  <!--                <span class="tooltiptext" >{{-->
                  <!--                  user.totalScore-->
                  <!--                }}</span>-->

                  {{ getCorrectScore(user.totalScore) }}
                </tooltip-custom>
                <!--              </div>-->
              </td>
            </tr>
            <!--        <tr v-if="userProfile.userERCAddress">-->
            <!--          <td>??</td>-->
            <!--          <td class="address">{{ userProfile.userERCAddress }}</td>-->
            <!--          <td>0</td>-->
            <!--          <td>0</td>-->
            <!--          <td>0</td>-->
            <!--          <td>0</td>-->
            <!--          <td>0</td>-->
            <!--        </tr>-->
          </tbody>
        </table>
      </div>
    </div>
  </main-layout>
  <StakeModal
    :stakeCoefficientObject="stakeCoefficientObject"
    @closeStakeModal="closeStakeModal"
    v-if="isShowStake"
  />
  <BurnedModal
    :coefficient="coefficient"
    @closeModal="closeModal"
    v-if="isShow"
  />
</template>
<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import TopSectionContent from "@/components/TopSectionContent";
import ButtonComponent from "@/components/Button";
import { mapState } from "vuex";
import { trimMiddle } from "@/helpers";
import BurnedModal from "@/components/BurnedModal";
import { API } from "@/api";
import TooltipCustom from "@/components/TooltipCustom";
import FontTitle from "@/components/FontTitle";
import StakeModal from "@/components/StakeModal";

export default {
  name: "LeaderboardPage",
  components: {
    FontTitle,
    TopSectionWrapper,
    MainLayout,
    TopSectionContent,
    ButtonComponent,
    BurnedModal,
    TooltipCustom,
    StakeModal,
  },
  data() {
    return {
      // currentUserLeaderboard: {
      //   erc20Address: "0x2bc9572f1e7540612fc04c5299bee44742da632c"
      // },
      isShow: false,
      isShowStake: false,
      coefficient: 725,
      stakeCoefficientObject: {
        halfYearStakeCoefConstant: "170.88",
        quarterStakeCoefConstant: "225.56",
        yearStakeCoefConstant: "136.7",
      },
    };
  },

  created() {
    this.$store.dispatch("fetchLeaderboard");
    this.getBurncoefficient();
    this.getStakeCoefficient();
  },

  computed: {
    ...mapState(["leaderboard", "currentUserLeaderboard", "userProfile"]),
    currentUserIndex() {
      const result = this.leaderboard?.filter((user, index) => {
        if (user.erc20Address === this.userProfile?.userERCAddress) {
          user.index = index + 1;
          return user;
        }
      });
      return result[0]?.index || this.leaderboard.length;
    },
  },
  methods: {
    trimMiddle,
    getCorrectScore(score) {
      return parseFloat(Number(score).toFixed(2));
    },
    convertToIntl(num) {
      return num
        ? new Intl.NumberFormat("en-US").format(Number(num).toFixed(0))
        : 0;
    },
    showModal() {
      this.isShow = !this.isShow;
    },
    closeModal() {
      this.isShow = false;
    },
    closeStakeModal() {
      this.isShowStake = false;
    },
    showStakeModal() {
      this.isShowStake = !this.isShowStake;
    },
    async getBurncoefficient() {
      const { data } = await API.get("/burn-coefficient");
      this.coefficient = +data[0].value;
    },
    async getStakeCoefficient() {
      const { data } = await API.get("/stakes-coefficients");
      this.stakeCoefficientObject = data;
    },
  },
  watch: {
    isShow(){
      if (this.isShow){
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = '';
        document.documentElement.style.overflowY = '';
      }
    },
    isShowStake(){
      if (this.isShowStake){
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = '';
        document.documentElement.style.overflowY = '';
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";
:deep(.popper) {
  --popper-theme-background-color: #000000;
  --popper-theme-background-color-hover: #000000;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  width: fit-content !important;
  text-align: center;
  font-size: 16px;
}
.current-user {
  background-color: #a4c7a4;
}

.leaderboard-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 250px auto 0;
  font-family: "Space Mono", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.05em;

  color: #000000;

  @include respond-below(lg) {
    font-size: 14px;
    line-height: 21px;
    max-width: 840px;
    margin: 0 25px;

    min-width: initial;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }
  @include respond-to(md) {
    max-width: 800px;
  }
  @include respond-to(xs) {
    margin: 0;
  }
}
.leaderboard-my-score {
  position: relative;
  height: 360px;
  &-title {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 114.5%;
    /* identical to box height, or 23px */

    letter-spacing: -0.04em;

    color: #262626;
    margin-bottom: 15px;
  }

  &-block {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    background: #fafafa;
    border: 1px solid #000000;
    max-width: 370px;
    padding: 28px 34px;

    &-name {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 114.5%;
      /* identical to box height, or 23px */

      letter-spacing: -0.04em;

      color: #000000;
      display: -webkit-box;
      span {
        font-weight: 600;
        margin-left: 12px;
      }
    }
  }
  &-squares-one {
    position: absolute;
    background-image: url("../assets/squares.svg");
    bottom: -24px;
    left: -25px;
    width: 224px;
    height: 208px;
    z-index: -1;
  }
  &-squares-second {
    position: absolute;
    background-image: url("../assets/squares.svg");
    bottom: -20px;
    left: -21px;
    width: 224px;
    height: 208px;
    z-index: -1;
  }
}
.leaderboard {
  display: flex;
  flex-direction: column;
  min-width: 700px;
  @include respond-to(sm) {
    min-width: 600px;
  }
  @include respond-to(xs) {
    min-width: 300px;
  }
  &-title {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 114.5%;
    /* identical to box height, or 23px */

    letter-spacing: -0.04em;

    color: #262626;
    margin-bottom: 15px;
  }
  .leaderboard-table {
    border-collapse: collapse;
    margin: 0 auto;
    min-width: 800px;
    overflow: hidden;
    z-index: 10;
    position: relative;

    /*&:not(:first-child) {*/
    /*  min-width: 50%;*/
    /*}*/

    /*<!--@include respond-below(lg) {-->*/
    /*<!--  min-width: 840px;-->*/
    /*<!--}-->*/
    @include respond-to(sm) {
      min-width: 700px;
    }
    @include respond-to(xs) {
      min-width: 300px;
      max-width: 700px;
      width: 100%;
    }
    thead {
      background: #ffffff;
      border-top: 1px solid #000000;
      border-left: 1px solid #000000;
      border-right: 1px solid #000000;

      height: 51px;

      th {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.05em;
        color: #272727;
        font-style: normal;
        width: 170px;

        @include respond-to(xs) {
          font-size: 16px;
          padding: 0 25px;
        }
      }
    }

    tbody {
      background: #e5e7eb;
      border: 1px solid #000000;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      align-items: center;
      text-align: center;
      letter-spacing: -0.05em;

      color: #272727;
      /*height: 850px;*/
      /*overflow-x: auto;*/
      display: block;
      td {
        text-align: center;
        font-weight: normal;
        position: relative;
        width: 16%;
      }

      .address {
        //font-size: 18px;
      }

      @keyframes sweep {
        100% {
          transform: translateX(100%);
        }
      }

      .px-25 {
        padding-left: 25px !important;
        padding-right: 25px !important;
      }

      .skeleton-loading {
        position: relative;
        overflow: hidden;
        background-color: #ccc;
        background-size: 1000%;
        background-position: right;
        border-radius: 20px;
        max-width: 150px;
        width: 100%;
        height: 12px;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
          );
          animation: sweep 2s infinite;
          content: "";
        }

        &--secondary {
          width: 70%;
          max-width: 100px;
          margin-top: 10px;
        }

        &--circle {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      tr.sep {
        hr {
          width: 92%;
          border: 0;
          height: 1px;
          background: #e8e8e8;
        }

        &:last-child {
          display: none;
        }
      }

      tr:not(.sep) {
        height: 44px;

        .project-participated-cell {
          font-size: 18px;
          line-height: 27px;
          text-align: center;

          @include respond-to(xs) {
            font-size: 14px;
          }

          .block {
            display: block;
          }
        }

        .empty-row {
          &__text {
            font-size: 18px;
            line-height: 27px;
            align-items: center;
            text-align: center;
          }
        }

        .actions-cell {
          padding-right: 46px;
          text-align: center;

          .project-participated-button {
            height: 30px;
            width: 100px;
            text-transform: uppercase;
            padding: initial;
            background: linear-gradient(
              72.12deg,
              #4d4d4d 2.57%,
              #121212 126.49%
            );
            box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

            &.investment-manage-button {
              //background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
            }

            &.investment-unstake-button {
              //background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
            }

            &.investment-claim-button {
              //background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
            }
          }
        }
      }
    }
  }
}
.cta {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 130%;
  /* or 17px */

  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.105em;
  text-transform: uppercase;

  height: 26px;
  padding: 0 14px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 5px;
  width: 75px;
  &.burned {
    background: #fccf5d;
  }
  &.stake {
    background: linear-gradient(-45deg, #75e1db 27.47%, #cee3e0 128.48%);
  }
}
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  left: -40px;
  right: 0;
  bottom: 120%;
  /*margin: auto;*/
  padding: 5px 10px;
  height: auto;
  letter-spacing: 1px;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.title {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 114.5%;
  /* identical to box height, or 46px */

  letter-spacing: -0.006em;
  @include respond-to(xs) {
    font-size: 40px;
  }
}
.image {
  position: absolute;
  background-image: url("../assets/clouds-rocket.png");
  background-size: cover;
  height: 865px;
  top: -400px;
  width: 97%;
  z-index: 0;
  background-position-x: 0%;
  background-position-y: -19px;
  overflow: hidden;
  white-space: nowrap;
  @include respond-to(sm) {
    background-position-x: 40%;
  }
  @include respond-to(xs) {
    background-position-x: 25%;
  }
}
.rocket {
  position: absolute;
  background-image: url("../assets/rocket.png");
  width: 73px;
  height: 149px;
  left: 61.6%;
  top: -185px;
  z-index: 0;
  @include respond-to(md) {
    left: 76%;
  }
  @include respond-to(sm) {
    left: 67.6%;
  }
  @include respond-to(xs) {
    left: 85.6%;
  }
  @media (max-width: 700px) {
    display: none;
  }
}
.btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-left: 15px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
}
</style>
