<template>
  <div class="moon">
    <footer>
      <div class="logo-wrapper">
        <router-link to="/">
          <img src="../assets/logo.svg" alt="A2DAO">
        </router-link>
        <mq-responsive target="sm+">
          <span class="sub-logo" v-html="i18n['copyright-text']"></span>
        </mq-responsive>
      </div>
      <div class="footer-menu-wrapper">
        <div class="column">
          <ul class="footer-menu information">
            <li>
              <h3>Information</h3>
            </li>
            <li>
              <router-link :to="{name: 'About Us'}">About Us</router-link>
            </li>
            <li>
              <router-link to="/#portfolio">Portfolio</router-link>
            </li>
            <li>
              <router-link to="/#features">Features</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Blog'}">Blog</router-link>
            </li>
          </ul>
          <ul class="footer-menu activities" v-if="userProfile.userERCAddress">
            <li>
              <h3>Activities</h3>
            </li>
            <li>
              <router-link :to="{name: 'Active'}">Active</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Completed'}">Completed</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Investments'}">My Investments</router-link>
            </li>
          </ul>
          <ul class="footer-menu socials">
            <li>
              <h3>Socials</h3>
            </li>
            <li>
              <a href="https://t.me/joinchat/ZX-3BN5_VN82MjJi" target=_blank>Announcements</a>
            </li>
            <li>
              <a href="https://t.me/+6cfqoookC0o0ZTU0" target=_blank>Level C EN Chat</a>
            </li>
            <li>
              <a href="https://t.me/+jUSAZg37dZIyMzk0" target=_blank>Level C CIS Chat</a>
            </li>
          </ul>
        </div>
        <mq-responsive target="xs">
          <div class="img"></div>
        </mq-responsive>
      </div>
      <ul class="social-networks">
        <li v-for="socialNetwork in socialNetworks" :key="socialNetwork.id">
          <a :href="socialNetwork.link" target="_blank">
            <img :src="`${staticEndpoint}/public/storage/social_links/${socialNetwork.file}`" alt="">
          </a>
        </li>
      </ul>
      <mq-responsive target="xs">
          <span class="sub-logo">
            {{ i18n['copyright-text'] }}
          </span>
      </mq-responsive>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { MqResponsive } from 'vue3-mq';
import { scrollToSmoothly } from '@/helpers';
import { useToast } from 'vue-toastification';

export default {
  name: 'FooterComponent',
  components: {
    MqResponsive
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    ...mapGetters([
      'i18n',
      'socialNetworks'
    ]),
    ...mapState([
      'userProfile',
      'kycStatus',
      'verifyKycURL'
    ])
  },
  methods: {
    scrollToSmoothly,
    ...mapActions([
      'connectWallet'
    ]),
    handleParticipate() {
      if (this.kycStatus === 'approved') {
        this.toast.info('Your KYC has already been approved.');
      } else {
        window.open(this.verifyKycURL);
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.meteors-bg {
  background: url("../assets/meteors-body-left.svg");
  width: 496px;
  height: 266px;
  position: absolute;
  z-index: 5;
  top: 270px;
  left: -145px;

  @include respond-to(xs) {
    width: 288px;
    height: 171px;
    top: 162px;
    left: -15px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @include respond-to(sm) {
    top: 200px;
    left: -165px;
  }
}

.moon {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;

  @include respond-to(xs) {
    background-position: -45px 75px;
    background-size: 660px;
    height: 515px;
    margin-top: 0;
  }

  @include respond-below(lg) {
    padding: 0;
  }

  @include respond-between(xs, sm) {
    background-position-x: center;
  }
}

.bottom-section-content-wrapper {
  background: radial-gradient(55.22% 90.9% at 50.79% 44.82%, #3C3E45 0%, #2A2F37 100%);
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 75px 0;
  width: 100%;
  text-align: center;
  margin-top: 115px;
  z-index: 10;

  @include respond-to(xs) {
    padding: 43px 0;
    margin-top: 340px;
    border-radius: 0;
  }

  @include respond-to(sm) {
    padding: 42px 0;
    margin-top: 175px;
  }
}

.text {
  font-family: 'Goldman', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 41px;
  line-height: 49px;
  letter-spacing: 0.05em;
  background: linear-gradient(90.59deg, #F23A6A -11.29%, #FFAD72 86.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @include respond-to(xs) {
    font-size: 20px;
    line-height: 24px;
  }

  @include respond-to(sm) {
    font-size: 28px;
    line-height: 32px;
  }

  .white {
    color: #fff;
    -webkit-text-fill-color: initial;
  }
}

.white {
  color: #fff;
}

.grey {
  color: #ccc;
}

footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 20;

  @include respond-to(xs) {
    flex-flow: column;
    box-sizing: border-box;
    top: initial;
    align-items: center;
    margin-top: 0;
    padding: 0 44px 80px 65px;
  }

  @include respond-to(sm) {
    margin: 0 82px;
    justify-content: center;
  }

  .img {
    @include respond-to(xs) {
      background: url("../assets/footer-img_mobile.svg") no-repeat center;
      height: 277px;
      width: 88px;
    }
  }
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @include respond-to(xs) {
    margin-bottom: 45px;
  }

  @include respond-to(sm) {
    margin-top: 0;
    align-self: start;
    align-items: end;
    margin-left: 20px
  }

  img {
    @include respond-to(sm) {
      width: 30px;
      height: 25px;
    }

    @include respond-to(xs) {
      width: 35px;
    }
  }
}

.sub-logo,
.sub-note {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16%;
  /* or 3px */

  color: #1A1A1A;
}

.sub-logo {
  display: inline-block;
  margin-top: 15px;

  @include respond-to(sm) {
    font-size: 12px;
    line-height: 16%;
    left: 17px;
    position: absolute;
  }
}

.sub-note {
  display: block;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: #ccc;
}

.footer-menu {
  display: inline-block;
  list-style-type: none;
  padding-inline-start: 0;
  margin-top: 0;

  &:not(:first-child) {
    margin-left: 20px;

    @include respond-to(xs) {
      margin-left: 0;
    }
  }

  @include respond-to(xs) {
    flex-flow: column;
    padding-left: 0;
    margin-bottom: 24px;
  }

  &-wrapper {
    display: flex;
    position: relative;
    top: -15px;
    margin-left: 120px;

    @include respond-to(xs) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0;
      width: 100%;
    }

    @include respond-to(sm) {
      margin-left: 70px;
    }

    .column {
      display: flex;

      @include respond-to(xs) {
        flex-direction: column;
      }
    }
  }

  li {
    @include respond-to(xs) {
    }

    &:last-child a {
      margin-right: 0;
    }

    h3 {
      font-family: 'Space Mono';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      /* identical to box height, or 26px */
      text-transform: uppercase;

      color: #353535;

      @include respond-to(xs) {
        display: none;
      }

      @include respond-to(sm) {
        font-size: 12px;
      }
    }
  }

  a {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    color: #555555;
    text-decoration: none;
    margin-right: 60px;

    @include respond-to(xs) {
      font-size: 12px;
      margin-right: 0;
    }

    @include respond-to(sm) {
      font-size: 12px;
    }
  }
}

.social-networks {
  display: flex;
  list-style-type: none;

  @include respond-to(xs) {
    margin: 0 0 25px;
    padding-left: 0;
  }

  @include respond-to(sm) {
    justify-content: start;
    flex-direction: column;
    margin-top: 0;
  }

  li {
    margin-right: 30px;

    @include respond-to(xs) {
      &:last-child {
        margin-right: 0;
      }
    }

    @include respond-to(sm) {
      &:nth-child(1) {
        order: 2;
      }
    }
  }
}
</style>
