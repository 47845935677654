<template>
  <li :class="classes">
    <slot />
  </li>
</template>
<script>
export default {
  name: 'BlogCategoryLabel',
  props: {
    classes: String
  }
};
</script>

<style scoped lang="scss">
li {
  display: inline;
  background: #9B9B9B;
  border-radius: 4px;
  padding: 4px 6px;

  font-weight: 600;
  font-size: 10px;
  line-height: 13px;

  color: #292C32;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-left: 6px;
  }
}
</style>
