<template>
  <div class="pagination-wrapper">
    <ul class="pagination-list" v-if="totalPages && totalPages > 1">
      <li
        class="pagination-link"
        :class="{disabled: currentPage === 1}"
        @click="currentPage !== 1 ? navigate(currentPage - 1) : null">
        <img src="../assets/icons/angle-left.svg" alt="account" class="prev-icon">
      </li>
      <li v-for="index in pages"
        :key="`pagination-link-${index}`"
        class="pagination-link"
        :class="{active: index === currentPage}"
        @click="navigate(index)"
        >
        {{ index }}
      </li>
      <li
        class="pagination-link"
        :class="{disabled: currentPage === totalPages}"
        @click="currentPage !== totalPages ? navigate(currentPage + 1) : null">
        <img src="../assets/icons/angle-right.svg" alt="account" class="next-icon">
      </li>
    </ul>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router'

const router = useRouter();

//eslint-disable-next-line no-unused-vars
const props = defineProps({
  currentPage: Number,
  totalPages: Number,
  url: Object
});
const pages = computed(() => {
  const totalPages = props.totalPages;
  const currentPage = props.currentPage;
  if (totalPages) {
    if (totalPages > 2 && totalPages - currentPage < 3) {
      return [...Array(totalPages+1).keys()].slice(totalPages - 2);
    }

    if (totalPages < 3) {
      return [...Array(totalPages+1).keys()].slice(1);
    }

    return [...Array(3 + currentPage).keys()].slice(currentPage);
  }

  return [];
});
const navigate = (index) => {
  let params = {};

  if (props.url.params) {
    for (let i=0; i<props.url.params.length; i++) {
      params[props.url.params[i]] = index
    }
  }

  router.push({
    name: props.url.name,
    query: params
  })
}
</script>
<script>
export default {
  name: "GenericPagination",
}
</script>
<style lang="scss">
@import "@/assets/styles/mixins";
  .pagination {
    &-list {
      margin-top: 25px;
      padding-left: 0;
    }

    &-link {
      display: inline;
      cursor: pointer;
      color: #9B9B9B;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      &:not(:first-child) {
        margin-left: 15px;
      }

      &.active,
      &:hover {
        color: #353535;
      }

      img:disabled path {
        fill: #6B6B6B;
      }

      .prev-icon,
      .next-icon {
        width: 10px;
      }
    }
  }
</style>