<template>
  <div class="item" v-if="project">
    <div class="item-image">
      <router-link :to="`/project/${project.slug}`">
        <img :src="`${staticEndpoint}${project.cardBannerPath}`" alt="" />
        <div class="stage-btn">{{ stage.name }}</div>
      </router-link>
    </div>
    <div class="card-wrapper">
      <div class="item-project-title-likes">
        <div class="item-project-title">{{ project.name }}</div>
        <div class="likes-and-interest">
          <div class="likes" @click="handleLike">
            <tooltip-custom content="Like">
              <img
                :src="require(`@/assets/icons/${likeIcon}`)"
                alt=""
                class="like-icon"
              />
            </tooltip-custom>
            <span class="likes-text">{{ project.likes }}</span>
          </div>
          <div class="interests" @click="handleInterest">
            <tooltip-custom class="tooltip-interest" content="Want to invest">
              <img
                :src="require(`@/assets/icons/${interestIcon}`)"
                alt=""
                class="interest-icon"
              />
            </tooltip-custom>
            <span class="interest-text">{{ project.interests }}</span>
          </div>
        </div>
      </div>
      <div class="card-wrapper-details">
        <div class="item-project">
          <div class="item-project-status">
            Status: <span>{{ project.status }}</span>
          </div>
          <div class="item-project-stage">
            Stage:
            <div class="item-project-stage-btn">{{ stage.name }}</div>
          </div>
        </div>
        <div class="item-industry">
          <div class="item-industry-title">Industry:</div>
          <div class="item-industry-blocks">
            <industry-badge
              v-for="item of project.industries"
              :key="item"
              :industry="item"
            />
          </div>
        </div>
        <div class="item-networks">
          <div
            class="item-networks-icons"
            v-for="item of project.socialNetworks"
            :key="item.id"
          >
            <a :href="item.url" target="_blank">
              <img :src="`${staticEndpoint}${item.img}`" :alt="item.name" />
            </a>
          </div>
        </div>
        <div class="item-details">
          <router-link :to="`/project/${project.slug}`">
            <border-button class="item-details-btn">
              <FontTitle
                text="View Details"
                class="item-details-btn-text"
              ></FontTitle>
            </border-button>
          </router-link>
          <div class="button-shadow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndustryBadge from "@/components/IndustryBadge";
import TooltipCustom from "@/components/TooltipCustom";
import BorderButton from "@/components/buttons/BorderButton";
import { mapState, mapActions } from "vuex";
import FontTitle from "@/components/FontTitle";
export default {
  name: "InnovationCard",
  components: { FontTitle, BorderButton, IndustryBadge, TooltipCustom },
  data() {
    return {
      industries: [
        { name: "DeFi", key: "defi" },
        { name: "GameFi", key: "gamefi" },
        { name: "Dex", key: "dex" },
        { name: "Layer 1", key: "layer-1" },
        { name: "Layer 2", key: "layer-2" },
        { name: "NFT", key: "nft" },
        { name: "Lending", key: "lending" },
      ],
      networks: [
        "instagram",
        "twitter",
        "telegram",
        "medium",
        "instagram",
        "twitter",
        "telegram",
        "medium",
        "instagram",
        "twitter",
        "telegram",
        "medium",
      ],
    };
  },
  props: {
    project: {
      type: Object,
      default() {
        return {};
      },
    },
    stage: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    likeIcon() {
      return this.userLikedProject ? "like-filled.svg" : "like.svg";
    },
    interestIcon() {
      return this.userInterestedProject
        ? "interest-filled.svg"
        : "interest.svg";
    },
    userLikedProject() {
      return this.userProfile?.projectLikes?.includes(this.$props.project.id);
    },
    userInterestedProject() {
      return this.userProfile?.projectInterests?.includes(
        this.$props.project.id
      );
    },
    ...mapState(["userProfile"]),
  },
  methods: {
    async handleLike() {
      if (this.userProfile.userERCAddress && window.innerWidth > 1023) {
        if (this.userLikedProject) {
          await this.dislikeProject(this.$props.project.id);
        } else {
          await this.likeProject(this.$props.project.id);
        }
      }
    },
    async handleInterest() {
      if (this.userProfile.userERCAddress && window.innerWidth > 1023) {
        if (this.userInterestedProject) {
          await this.uninterstProject(this.$props.project.id);
        } else {
          await this.interstProject(this.$props.project.id);
        }
      }
    },
    ...mapActions([
      "likeProject",
      "dislikeProject",
      "interstProject",
      "uninterstProject",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.item {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 160px;
  width: 100%;
  background: #fff;
  /*background: #d9d9d9;*/
  filter: drop-shadow(0px 0px 65px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  align-items: center;
  margin: 15px 0;
  @include respond-below(md) {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    height: auto;
    border: 1px solid #75e1db;
    writing-mode: horizontal-tb;
    margin: 30px auto;
  }
  @media (max-width: 380px) {
    padding-bottom: 30px;
  }
  /*padding-right: 50px;*/
  &-image {
    /*background: #eef0f0;*/
    /*mix-blend-mode: overlay;*/
    max-width: 300px;
    height: 160px;
    width: 100%;
    position: relative;
    img {
      max-width: 300px;
      width: 300px;
      height: 100%;
    }
    @include respond-below(md) {
      height: auto;
      max-width: 100%;
      margin-bottom: 20px;

      img {
        max-width: 400px;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
  &-project {
    display: flex;
    flex-direction: column;
    max-width: calc(300px - 2%);
    width: 100%;
    min-width: 210px;
    justify-content: center;
    padding-left: 2%;
    row-gap: 6px;
    @include respond-below(md) {
      justify-content: center;
      height: auto;
      max-width: 380px;
      width: 100%;
      padding-left: 20px;
    }
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 31px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;

      color: #1c1d23;
      @include respond-below(md) {
        font-size: 22px;
        line-height: 28px;
        /* identical to box height */
        max-width: 200px;
        display: flex;
        align-items: center;
        text-align: start;
        letter-spacing: -0.05em;
        padding-left: 10px;
      }
      &-likes {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 2%;
        padding-bottom: 15px;
        .likes-and-interest {
          display: flex;
          color: #000;
          background: #dedede;
          padding: 4px 14px 3px;
          min-width: 75px;
          margin-left: 20px;
          img {
            margin-right: 8px;
          }
          @include respond-below(lg) {
          }
          @include respond-below(md) {
            /* or 17px */
            bottom: 18px;
            right: 7%;
            position: absolute;
            background: none;
          }
          @media (max-width: 380px) {
            bottom: 6px;
          }
        }
        .likes {
          display: inline-block;
          margin-right: 10px;
          cursor: pointer;
        }
        .interests {
          cursor: pointer;
          display: inline-block;
        }
      }
    }
    &-status {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.06em;

      color: #1c1d23;
      span {
        font-weight: bold;
        margin-left: 5px;
      }
      @include respond-below(md) {
        align-items: start;
        max-width: 200px;
      }
    }
    &-stage {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.06em;

      color: #1c1d23;
      &-btn {
        display: flex;
        align-items: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        background: linear-gradient(180deg, #92d0ac 0%, #18b4e9 100%);
        padding: 0 10px;
        margin-left: 10px;
        font-style: normal;
        line-height: 130%;
        /* or 21px */
        height: 21px;

        color: #ffffff;
      }
      @include respond-below(md) {
        display: none;
      }
    }
  }
  &-industry {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 150px;
    width: 100%;
    padding-left: 2%;
    position: relative;
    margin-bottom: -25px;
    @include respond-below(md) {
      justify-content: flex-start;
      flex-direction: row;
      max-width: 380px;
      width: 100%;
      padding-left: 20px;
    }

    &-title {
      padding-bottom: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      text-align: center;
      letter-spacing: -0.06em;

      color: #1c1d23;
      @include respond-below(md) {
        padding-bottom: 35px;
      }
    }
    &-blocks {
      display: flex;
      flex-wrap: wrap;
      max-width: 150px;
      padding-bottom: 10px;
      @include respond-below(md) {
        padding-top: 5px;
        padding-left: 5px;
        max-width: 130px;
      }
    }
  }
  &-networks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 200px;
    width: 100%;
    @include respond-below(md) {
      display: none;
    }
    /*padding-bottom: 10px;*/
    &-icons {
      display: flex;
      justify-content: center;
      img {
        width: 17px;
        height: 15px;
        margin: 5px;
      }
    }
  }
  &-details {
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 210px;
    min-width: 210px;
    width: 100%;
    margin-right: 20px;
    @include respond-below(md) {
      justify-content: end;
      margin-bottom: 10px;
      max-width: 400px;
    }
    a {
      text-decoration: none;
    }
    &-btn {
      background: linear-gradient(180deg, #4f5463 0%, #1e1f23 100%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      height: 50px;
      padding: 15px 25px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      max-width: 260px;
      /* identical to box height, or 29px */
      letter-spacing: 0.105em;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 20px;
      &-text {
        position: relative;
        z-index: 1;
        &-shadow {
          position: absolute;
          top: 1.5px;
          left: 1.5px;
          z-index: -1;
          width: 100%;
        }
      }

      @include respond-below(md) {
        font-weight: 700;
        font-size: 10px;
        line-height: 130%;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        text-align: center;

        color: #fff;
        margin-right: 20px;
        margin-top: -80px;
        max-width: 110px;
        width: 100%;
        padding: 0 10px;
        height: 30px;
      }
      @media (max-width: 380px) {
        margin-top: -10px;
      }
    }
  }
}
.stage-btn {
  display: none;
  @include respond-below(md) {
    position: absolute;

    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    background: linear-gradient(180deg, #92d0ac 0%, #18b4e9 100%);
    padding: 3px 10px;
    margin-left: 10px;
    bottom: 18px;
    right: 14px;
  }
}
:deep(.popper) {
  --popper-theme-background-color: #000000;
  --popper-theme-background-color-hover: #000000;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  width: fit-content !important;
  text-align: center;
}
.tooltip-interest {
  :deep(.popper) {
    min-width: 145px !important;
  }
}
.button-shadow {
  position: absolute;
  max-width: 190px;
  width: 100%;
  height: 50px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  @include respond-below(md) {
    display: none;
  }
}
.card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include respond-below(md) {
      flex-direction: column;
    }
  }
}
.text-shadow {
  position: absolute;
  top: 1px;
  left: 2px;
  z-index: -1;
  width: 100%;
}
</style>
