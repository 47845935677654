<template>
  <div
    class="badge"
    :class="{
      'badge-defi': isDefi,
      'badge-layer-1': isLayer1,
      'badge-layer-2': isLayer2,
      'badge-play-to-earn': isPlayToEarn,
      'badge-default': isDefault,
    }"
  >
    {{ industry.name }}
  </div>
</template>
industry
<script>
export default {
  name: "IndusdtryBadge",
  props: {
    industry: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    isDefi() {
      return this.industry.key === "defi";
    },
    isPlayToEarn() {
      return this.industry.key === "play-to-earn";
    },
    isLayer1() {
      return this.industry.key === "layer-1";
    },
    isLayer2() {
      return this.industry.key === "layer-2";
    },
    isDefault() {
      return !(
        this.isDefi ||
        this.isLayer1 ||
        this.isLayer2 ||
        this.isPlayToEarn
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.badge {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 4px 1px;

  /* or 21px */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  @include respond-below(lg) {
    font-size: 10px;
    height: 12px;
  }
  @include respond-below(md) {
    font-size: 10px;
    height: 12px;
  }

  &-defi {
    border: 1px solid #1EB676;
    color: #1EB676;
  }
  &-layer-1 {
    color: #F46A8E;
    border: 1px solid #F46A8E;
  }
  &-layer-2 {
    border: 1px solid #745C96;
    color: #745C96;
  }
  &-play-to-earn {
    border: 1px solid #ff957d;
    color: #ff957d;
  }
  &-default {
    border: 1px solid #9b9b9b;
    color: #9b9b9b;
  }
}
</style>
