<template>
  <div v-if="modalData && modalData.modalID === 'modal-allowance'" class="overlay" @click.prevent="toggleModal">
    <div id="modal-allowance" class="modal visible" @click.stop="">
      <div class="modal-inner">
        <close-modal-button />
        <h3 class="modal-heading">Approval to spend {{ modalData.options.tokenName ?? newTokenName }}</h3>
        <div class="modal-balance-row">
          <div class="modal-balance-label">{{ modalData.options.tokenName ?? newTokenName }} balance</div>
          <div class="modal-balance-value">{{ modalData.options.atdBalance }} {{ modalData.options.tokenName ?? newTokenName }}</div>
        </div>
        <div class="modal-text-info-block">
          <strong>Spend limit permission</strong>
          <div class="modal-text-info-text">Allow {{domain}} to withdraw and spend up to the following amount</div>
        </div>
        <form id="allow-spending-tokens" class="modal-allowance-form">
          <div class="modal-allowance-variant">
            <input type="radio" id="proposed-limit" ref="proposed" value="proposed" v-model="pickedOption" name="allowance-limit" checked>
            <label for="proposed-limit" class="modal-input-label">Proposed approval limit</label>
            <div class="modal-allowance-variant-info">
              <strong>Unlimited</strong>
            </div>
          </div>
          <div class="modal-allowance-variant">
            <input type="radio" id="custom-limit" ref="custom-limit" value="custom" v-model="pickedOption" name="allowance-limit">
            <label for="custom-limit" class="modal-input-label">Custom spend limit</label>
            <div class="modal-allowance-variant-info">
              <input
                type="number"
                ref="allowance-amount"
                class="modal-allowance-amount"
                id="allowance-amount" step="any"
                :min="swapAmount"
                :value="swapAmount"
                :disabled="pickedOption !== 'custom'"
                @change="adjustAmount">
              <div class="modal-proposed-limit-description">
                Enter max spend limit
              </div>
            </div>
          </div>
          <button-component type="submit" class="button cta-swap cta-approve-modal" id="proceed-allowance" :onclick="approveAllowance">Approve</button-component>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import ButtonComponent from '@/components/Button.vue';
import CloseModalButton from '@/components/buttons/CloseModalButton.vue';
import { useToast } from "vue-toastification";

export default {
  name: 'AllowanceModal',
  components: {
    ButtonComponent,
    CloseModalButton
  },
  data() {
    return {
      pickedOption: 'proposed',
    }
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  computed: {
    ...mapState([
      'modalData',
      'web3',
      'tokenContract',
      'swapAmount',
      'userProfile',
      'newTokenName'
    ]),
    domain() {
      return window.location.hostname;
    },
  },
  methods: {
    ...mapMutations({
      toggleModal: 'TOGGLE_MODAL',
      setSwapAmount: 'SET_SWAP_ALLOWANCE_AMOUNT',
      setAllowanceIncreaseStatus: 'SET_ALLOWANCE_INCREASE_STATUS',
    }),
    adjustAmount(evt) {
      this.setSwapAmount(evt.target.value);
    },
    async approveAllowance(evt) {
      evt.preventDefault();

      const isProposedLimit = this.$refs.proposed.checked;

      try {
        let allowance = 0;

        if (isProposedLimit) {
            allowance = '115792089237316195423570985008687907853269984665640564039458';
        } else {
            allowance = this.web3.utils.toWei(this.swapAmount.toString());
        }

        if (this.modalData.options.atdContract) {
          const gasLimit = await this.modalData.options.atdContract.methods
                  .increaseAllowance(this.modalData.options.currentContractAddress, allowance)
                  .estimateGas({from: this.userProfile.userERCAddress});
          const gasPrice = await this.web3.eth.getGasPrice();
          this.modalData.options.atdContract.methods.increaseAllowance(this.modalData.options.currentContractAddress, allowance).send({
            from: this.userProfile.userERCAddress, gasLimit: Math.round(gasLimit * 1.1), gasPrice: gasPrice
          }).then(() => {
            this.toast.success('Spending tokens approved');
            this.setAllowanceIncreaseStatus('success');
          }).catch((e) => {
            this.toast.error(e.message);
            this.setAllowanceIncreaseStatus('failure');
          });
        } else {
          const gasLimit = await this.tokenContract.methods
                  .increaseAllowance(this.modalData.options.currentContractAddress, allowance)
                  .estimateGas({from: this.userProfile.userERCAddress});
          const gasPrice = await this.web3.eth.getGasPrice();
          this.tokenContract.methods.increaseAllowance(this.modalData.options.currentContractAddress, allowance).send({
            from: this.userProfile.userERCAddress, gasLimit: Math.round(gasLimit * 1.1), gasPrice: gasPrice
          }).then(() => {
            this.toast.success('Spending tokens approved');
            this.setAllowanceIncreaseStatus('success');
          }).catch((e) => {
            this.toast.error(e.message);
            this.setAllowanceIncreaseStatus('failure');
          });
        }

        this.toggleModal();
      } catch (e) {
        console.error(e.message);
        this.toast.error(e.message);
        this.toggleModal();
        this.setAllowanceIncreaseStatus('failure');
      }
    }
  }
}
</script>
<style lang="scss">
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(34, 34, 34, 0.75);
    z-index: 502;
  }

  .modal {
    position: fixed;
    display: block;
    top: 5%;
    left: 50%;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transform: translate(-50%, 0);

    &-inner {
      display: flex;
      flex-direction: column;
      padding: 20px 50px 50px;
      background: radial-gradient(55.22% 90.9% at 50.79% 44.82%, #F2F2F2 0%, #EFEFEF 100%);
      box-shadow: 0 0 65px rgba(0, 0, 0, 0.3);
      position: relative;
      max-width: 600px;
      overflow-x: hidden;
      z-index: 504;

      &-iframe {
        height: 820px;
        background-color: rgba(255, 255, 255, 0);
        padding: 0;
        width: 400px;

        &-wrapper {
          position: absolute;
          top: -75px;
          overflow: unset;
        }
      }
    }

    &-heading {
      text-align: center;
      margin-bottom: 30px;
    }

    &-balance-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
    }

    &-text-info-block {
      margin-top: 30px;
    }

    &-text-info-text {
      margin-top: 5px;
      font-size: 11px;
    }

    &-allowance-form {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    &-input-label {
      cursor: pointer;
      margin-left: 14px;
    }

    &-proposed-limit-description {
      margin-top: 5px;
      font-size: 11px;
    }

    &-allowance-variant-info {
      margin-left: 28px;
      margin-top: 2px;

      strong {
        text-transform: uppercase;
      }
    }

    &-allowance-variant {
      margin-bottom: 20px;

      input[name=allowance-limit] {
        cursor: pointer;
      }
    }

    &-allowance-amount {
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 6px 8px 5px;
      margin-top: 5px;
    }
    &.visible {
      opacity: 1;
      visibility: visible;
      transition: opacity .7s,visibility 0s;
      z-index: 503;
    }
}
</style>
