<template>
  <div
    class="timer-wrapper"
    :class="{ 'without-bg': !(state === 'active' || state === 'upcoming') }"
  >
    <div
      class="state-label-wrapper state-label-wrapper-active"
      v-if="state === 'active' || state === 'upcoming'"
    >
      <span class="state-label" v-if="state === 'active'">
        <FontTitle
          class="state-label-text"
          :text="projectType + ' ' + 'ends in'"
        ></FontTitle>
        <!--        <span class="state-label-text">{{ projectType }} ends in<span class="state-label-text-shadow">{{ projectType }} ends in</span></span>-->
      </span>
      <span class="state-label" v-else-if="state === 'upcoming'">
        <FontTitle
          class="state-label-text"
          :text="projectType + ' ' + 'starts in'"
        ></FontTitle>
      </span>
    </div>
    <span class="ended" v-else>
      Deal closed
      <template v-if="ended.days >= 1">
        {{ ended.days }} day{{ ended.days > 1 ? "s" : "" }}
      </template>
      <template v-else-if="ended.hours >= 1">
        {{ ended.hours }} hour{{ ended.hours > 1 ? "s" : "" }}
      </template>
      <template v-else-if="ended.minutes >= 1">
        {{ ended.minutes }} minute{{ ended.minutes > 1 ? "s" : "" }}
      </template>
      <template v-else-if="ended.seconds >= 1">
        {{ ended.seconds }} second{{ ended.seconds > 1 ? "s" : "" }}
      </template>
      ago
    </span>
    <Countdown
      v-if="state === 'active' || state === 'upcoming'"
      :deadline="timestamp"
      :flip-animation="false"
      numbers-size="24px"
    />
  </div>
</template>

<script>
import CountdownComponent from "@/components/vue3-flip-countdown/CountdownComponent";
import FontTitle from "@/components/FontTitle";

export default {
  name: "ProjectTimer",
  components: {
    FontTitle,
    Countdown: CountdownComponent,
  },
  props: {
    projectType: {
      type: String,
      default: "The event",
    },
    timestamp: {
      type: String,
    },
    ended: {
      type: String,
    },
    state: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.timer-wrapper {
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 510px;

  @include respond-to(xs) {
    box-sizing: border-box;
  }

  &.without-bg {
    background: none;
    box-shadow: none;
    padding-left: 0;

    @include respond-to(xs) {
      padding-right: 0;
      margin-left: 0;
      flex-direction: row;
      padding-top: 10px;
    }
  }

  .ended {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    min-width: 63px;
  }
}

.state-label,
.no-rotate {
  /*background: linear-gradient(72.12deg, #4D4D4D 2.57%, #121212 126.49%);*/
  /*-webkit-background-clip: text;*/
  /*-webkit-text-fill-color: transparent;*/
  /*background-clip: text;*/
  /*text-fill-color: transparent;*/
  /*opacity: 0.7;*/
  &-text {
    position: relative;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    /* or 17% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
    z-index: 1;
    @include respond-to(sm) {
      font-size: 9px;
    }
    @include respond-to(xs) {
      font-size: 9px;
    }
    &-shadow {
      position: absolute;
      top: 1.5px;
      left: 1.5px;
      z-index: -1;
      width: 100%;
    }
  }

  &-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);

    padding: 24px 9px;

    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    width: 63px;
    height: 35px;

    @include respond-to(sm) {
      padding: 10px 2px;
      width: 40px;
      height: 35px;
      align-items: center;
      font-size: 8px;
      line-height: 10px;
    }

    @include respond-below(sm) {
      flex-flow: row;
      padding: 10px 2px;
      width: 40px;
      height: 35px;
      align-items: center;
      font-size: 8px;
      line-height: 10px;
    }
  }
}

.ended {
  color: #000;
  font-size: 24px;

  @include respond-below(md) {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1em;
  }

  &-ago-label {
    font-size: 16px;
  }
}
</style>
