<template>
  <button-component type="button" class="close-modal" :onclick="closeModal">
    Close Modal
  </button-component>
</template>

<script>
import { mapMutations } from 'vuex';
import ButtonComponent from '@/components/Button';

export default {
  name: 'CloseModalButton',
  components: {
    ButtonComponent
  },
  methods: {
    ...mapMutations({
      setAllowanceIncreaseStatus: 'SET_ALLOWANCE_INCREASE_STATUS',
      toggleModal: 'TOGGLE_MODAL',
    }),
    closeModal() {
      this.setAllowanceIncreaseStatus('failure');
      this.toggleModal();
    }
  }
};
</script>

<style scoped lang="scss">

.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0;
  line-height: 0;
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  width: 32px;
  height: 32px;
  transform: scale(1);
  transition: all 0.2 ease-in-out;
  border: none;
  background: none;
  cursor: pointer;
  &-iframe {
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 5;
    &:before, &:after {
      background: #fff !important;
    }
  }
  &:before, &:after {
    content: '';
    display: block;
    width: 23px;
    height: 1px;
    background: #000;
    position: absolute;
    left: 8px;
    transform-origin: left center;
  }
  &:before {
    top: 8px;
    transform: rotate(45deg);
  }
  &:after {
    bottom: 7px;
    transform: rotate(-45deg);
  }
}

</style>
