<template>
  <main-layout>

    <template v-slot:top-section>
      <top-section-wrapper no-bg no-dots-background>
        <top-section-content content-to-left>
          <template v-slot:top-section-heading>
            <FontTitle text="My Investments" class="title"></FontTitle>

          </template>
          <template v-slot:top-section-sub-heading>
          <span class="investments-sub-header">
            This is the section where you can see both completed and active projects you have ever participated in on the A2DAO platform
          </span>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="squares-one"></div>
    <div class="squares-second"></div>
    <div class="coin"></div>

    <investment-table />
  </main-layout>
</template>

<script>
import TopSectionWrapper from '@/components/TopSectionWrapper';
import MainLayout from '@/layouts/main';
import InvestmentTable from '@/components/InvestmentTable';
import TopSectionContent from '@/components/TopSectionContent';
import FontTitle from "@/components/FontTitle";

export default {
  name: 'InvestmentsPage',
  components: {
    FontTitle,
    TopSectionContent,
    TopSectionWrapper,
    MainLayout,
    InvestmentTable
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.investments {
  &-header {
    font-family: 'Press Start 2P', sans-serif;
    display: block;
    font-size: 52px;
    font-weight: 400;
    line-height: 114.5%;
    background: linear-gradient(72.12deg, #4D4D4D 2.57%, #121212 126.49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @include respond-to(sm) {
      font-size: 22px;
      line-height: 50px;
    }

    @include respond-to(xs) {
      font-size: 18px;
      line-height: 50px;
    }
  }

  &-sub-header {
    display: block;
    margin-top: 24px;
    max-width: 665px;
  }
}
.squares-one{
  position: absolute;
  background-image: url("../assets/squares.svg");
  top: -271px;
  right: 202px;
  width: 224px;
  height: 208px;
  z-index: -1;
  @include respond-to(lg) {
    right: 38px;
  }
  @include respond-to(md) {
    right: 36px;
  }
  @include respond-to(sm) {
    display: none;

  }
  @include respond-to(xs) {
    display: none;
  }
}
.squares-second{
  position: absolute;
  background-image: url("../assets/squares.svg");
  top: -275px;
  right: 206px;
  width: 224px;
  height: 208px;
  z-index: -1;
  @include respond-to(lg) {
    right: 36px;
  }
  @include respond-to(md) {
    right: 36px;
  }
  @include respond-to(sm) {
    display: none;

  }
  @include respond-to(xs) {
    display: none;
  }
}
  .coin{
    background: url("../assets/animation-coin.gif") no-repeat center;
    width: 150px;
    height: 150px;
    position: absolute;
    top: -245px;
    right: 250px;
    background-size: cover;
    @include respond-to(lg) {
      right: 80px;
    }
    @include respond-to(md) {
      right: 80px;
    }
    @include respond-to(sm) {
      display: none;

    }
    @include respond-to(xs) {
      display: none;
    }
  }
</style>
