<template>
  <span>
    <slot />
  </span>
</template>

<script>
export default {
  name: 'ColoredText',
  props: {
    fontSize: {
      type: String,
      default: '24px'
    },
    fontFamily: {
      type: String,
      default: 'Space Mono'
    },
    fontWeight: {
      type: String,
      default: '600'
    }
  }
};
</script>

<style scoped lang="scss">
  span {
    font-family: v-bind(fontFamily), sans-serif;
    font-style: normal;
    font-weight: v-bind(fontWeight);
    font-size: v-bind(fontSize);
    line-height: 130%;

    background: linear-gradient(92.82deg, #AC3BFF 0%, #7CCAF7 109.71%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;

    &.usual {
      background: #000;
    }

    &.nft,
    &.swap {
      background: linear-gradient(92.82deg, #FF3F71 0%, #FFAD72 109.71%);
      -webkit-background-clip: text;
      background-clip: text;
    }

    &.ido {
      background: linear-gradient(92.82deg, #FF7F23 0%, #FFF400 109.71%);
      -webkit-background-clip: text;
      background-clip: text;
    }

    &.lockdrop {
      background: linear-gradient(92.82deg, #00B1D2 0%, #1EB676 109.71%);
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
</style>
