<template>
  <div class="input-container">
    <div class="input-container-content">
      <div class="input-container-content-balance" @click="setMaxAtd">
        Balance: {{ balance }}
      </div>
      <div class="input-container-content-input-box">
        <div class="input-container-content-input-box-token">
          {{ atdContractData.symbol }}
        </div>
        <input
          v-model="value"
          type="number"
          placeholder="0"
          @input="getValue(value)"
        />
      </div>
    </div>
    <!--    <button-component-->
    <!--      :disabled="value && value <= balance && !burnLoading ? false : true"-->
    <!--      @click="burnToken"-->
    <!--      class="cta"-->
    <!--      > {{!burnLoading ? 'Burn' : 'Burning...'}}</button-component-->
    <!--    >-->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BurnedInput",
  data() {
    return {
      value: "",
    };
  },
  props: {
    atdContractData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    balance() {
      return (
        Math.trunc(
          this.web3.utils.fromWei(this.atdContractData.balance) * 100
        ) / 100
      );
    },
    ...mapState(["burnLoading", "web3"]),
  },
  methods: {
    setMaxAtd() {
      this.value = this.balance;
      this.getValue(this.value);
    },
    getValue(value) {
      this.$emit("culcPoint", value);
    },
    async burnToken() {
      this.$store.commit("BURN_LOADING", true);
      this.$emit("transferBurnToken", this.value);
      // this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  margin-top: 30px;
  max-width: calc(100% - 46px);
  border-top: 0.7px solid #b9b9b9;
  /*border: 3px solid white;*/
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-content {
    display: flex;
    flex-direction: column;
    &-balance {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */
      cursor: pointer;
      display: flex;
      align-items: center;

      color: #808080;
    }
    &-input-box {
      display: flex;
      &-token {
        opacity: 0.7;
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        /* identical to box height, or 42px */

        display: flex;
        align-items: center;

        /* #353535 */

        color: #353535;
      }
      input {
        font-family: "Space Mono", sans-serif;
        opacity: 0.7;
        font-weight: bold;
        background: transparent;
        border: none;
        font-size: 32px;
        margin-left: 1rem;
        outline: none;
        margin-top: -1px;
        width: 100%;
        -moz-appearance: textfield;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
.cta {
  width: auto;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 124%;
  /* or 25px */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  background: linear-gradient(180deg, #ffd974 0%, #f0b71d 100%);
  color: #fefefe;
  box-shadow: none;
}
</style>
