<template>
  <Popper :content="content" :show="tooltipHover" placement="top"
    @mouseleave="toggleTooltip" @mouseenter="toggleTooltip">
      <slot />
  </Popper>
</template>
<script>
import Popper from "vue3-popper";

export default {
  name: 'TooltipCustom',
  props: {
    content: String
  },
  components: {
    Popper
  },
  data() {
    return {
      tooltipHover: false
    }
  },
  methods: {
    toggleTooltip() {
      this.tooltipHover = !this.tooltipHover;
    },
  },
}
</script>
<style>
  :root {
    --popper-theme-background-color: #000;
    --popper-theme-background-color-hover: radial-gradient(55.22% 90.9% at 50.79% 44.82%, #41444E 0%, #2C3037 100%);
    --popper-theme-text-color: #fff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 10px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }

  :deep(.popper) {
    font-family: 'Space Mono', sans-serif;
      width: fit-content!important;

  }

  .popper {
      letter-spacing: -1px;
      width: fit-content!important;


  }
</style>
