<template>
  <div class="blog-post-meta-info" :class="{'space-after-date': spaceAfterDate}">
    <div class="date">
      <img src="@/assets/icons/calender.svg" alt="account" class="date-icon">
      <span class="date-text">{{ date }}</span>
    </div>
    <div class="likes-and-share">
      <div class="minutes-to-read">
        <img src="@/assets/icons/time.svg" alt="">
        <span class="minutes-to-read-text">{{ minutesToRead }} min</span>
      </div>
      <div class="likes" @click="handleLike">
        <img :src="require(`@/assets/${likeIcon}`)" alt="" class="like-icon">
        <span class="likes-text">{{ likesCurrent }}</span>
      </div>
      <div class="share" @click="handleShare">
        <img src="@/assets/icons/share.svg" alt="" class="share-icon">
        <span class="share-text" v-if="showShareText">Share</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { API } from '@/api';
import { useStore } from 'vuex';
import { SET_USER_PROFILE } from '@/store/mutation-types';

const props = defineProps({
  date: {
    type: String,
    required: true
  },
  minutesToRead: {
    type: Number,
    required: true
  },
  likes: {
    type: Number,
    required: true
  },
  slug: {
    type: String,
    required: true
  },
  postId: {
    type: Number,
    required: true
  },
  spaceAfterDate: Boolean,
  showShareText: Boolean
});

const toast = useToast();
const store = useStore();

const handleShare = () => {
  navigator.clipboard.writeText(`${window.location.origin}/blog/${props.slug}`);
  toast.success('Blog URL was successfully copied to the clipboard!');
};

const userLikedPost = computed(() => {
  return store.state.userProfile.blogPostsLikes.includes(props.postId);
});

const likesCurrent = ref(props.likes);
const likeIcon = ref(userLikedPost.value ? 'like-filled.svg' : 'like.svg');

const userProfile = computed(() => {
  return store.state.userProfile;
});

const like = async () => {
  try {
    const { data } = await API.post('/blog-posts/like', {
      'blog_post_id': props.postId
    });

    likesCurrent.value = data.likes;
    store.commit(SET_USER_PROFILE, {
      ...store.state.userProfile,
      blogPostsLikes: [
        ...store.state.userProfile.blogPostsLikes,
        props.postId
      ]
    });
    likeIcon.value = 'like-filled.svg';
  } catch (e) {
    toast.error(e.response.data.message);
  }
};

const dislike = async () => {
  try {
    const { data } = await API.post('/blog-posts/like', {
      'blog_post_id': props.postId
    });

    likesCurrent.value = data.likes;
    store.commit(SET_USER_PROFILE, {
      ...store.state.userProfile,
      blogPostsLikes: store.state.userProfile.blogPostsLikes.filter(value => value !== props.postId)
    });
    likeIcon.value = 'like.svg';
  } catch (e) {
    toast.error(e.response.data.message);
  }
};

const handleLike = async () => {
  if (userProfile.value?.userERCAddress) {
    if (userLikedPost.value) {
      await dislike();
    } else {
      await like();
    }
  }
};
</script>
<script>
export default {
  name: 'BlogMetaInfo'
};
</script>

<style scoped lang="scss">
.blog-post-meta-info {
  display: flex;

  &.space-after-date {
    justify-content: space-between;
  }

  .date {
    display:flex;
    align-items: center;

    &-icon {
      height: 15px;
      width: 16px;
    }

    &-text {
      margin-left: 6px;
      font-weight: 600;
      font-size: 13px;
      line-height: 17px;
      color: #828282;
    }
  }

  .likes-and-share {
    display: flex;

    .likes,
    .share,
    .minutes-to-read {
      display: flex;
      align-items: center;
      margin-left: 16px;

      &-text {
        margin-left: 6px;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #828282;
      }
    }

    .share,
    .likes {
      cursor: pointer;
    }
  }
}
</style>
