import { createRouter, createWebHistory } from 'vue-router';
import AboutUs from '@/pages/AboutUs';
import HomePage from '@/pages/HomePage';
import InvestmentsPage from '@/pages/InvestmentsPage';
import BlogPostsPage from '@/pages/BlogPostsPage';
import CompletedDealsPage from '@/pages/CompletedDealsPage';
import AccountPage from "@/pages/AccountPage";
//import ProjectPage from '@/pages/ProjectPage';
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage';
import NotFoundPage from '@/pages/NotFoundPage';
import FAQPage from '@/pages/FAQPage';
import KycFinishedPage from '@/pages/KycFinishedPage'
import BlogPostPage from '@/pages/BlogPostPage';
import LeaderboardPage from '@/pages/LeaderboardPage';
import InnovationLabsPage from "@/pages/InnovationLabsPage";
import InnovationProjectPage from "@/pages/InnovationProjectPage";
import OTCPage from "@/pages/OTCPage";
import OTCOnTheMarketPage from "@/pages/OTCOnTheMarketPage";
import MyActivityPage from "@/pages/MyActivityPage";
//import StakingProjectsPage from '@/pages/StakingProjectsPage';

const routes = [
  {
    path: '/active',
    name: 'Active',
    component: HomePage,
    meta: {
      requiresAuth: true,
      title: 'Active | A2DAO',
      inMenu: false
    }
  },
  {
    path: '/completed',
    name: 'Completed',
    component: CompletedDealsPage,
    meta: {
      requiresAuth: true,
      title: 'Completed Deals | Finished private and seed rounds of crypto projects | A2DAO',
      inMenu: false
    }
  },
  {
    path: '/otc',
    name: 'OTCPage',
    component: OTCPage,
    meta: {
      requiresAuth: true,
      title: 'OTC deals | A2DAO',
      inMenu: false
    }
  },
  {
    path: '/otc-on-the-market',
    name: 'OTCOnTheMarketPage',
    component: OTCOnTheMarketPage,
    meta: {
      requiresAuth: true,
      title: 'OTC on the market | A2DAO',
      inMenu: false
    }
  },
  {
    path: "/account",
    name: "Account",
    component: AccountPage,
    meta: {
      requiresAuth: true,
      title: "Account | A2DAO",
      inMenu: false,
    },
  },
  {
    path: '/',
    name: 'About Us',
    component: AboutUs,
    meta: {
      requiresAuth: false,
      title: 'About Us | A2DAO',
      inMenu: false
    }
  },
  /*{
    path: '/world-cup-2022',
    name: 'WC2022',
    component: WorldCupProjectsPage,
    meta: {
      requiresAuth: false,
      title: 'World Cup 2022 Projects | A2DAO'
    }
  },
  {
    path: '/swap',
    name: 'Swap',
    component: SwapPage,
    meta: {
      requiresAuth: false,
      title: 'Swap | Change ATD tokens to CPLT with just one click | A2DAO'
    }
  },*/
  {
    path: "/pipeline",
    name: "Pipeline",
    component: InnovationLabsPage,
    meta: {
      requiresAuth: false,
      title: "Pipeline | A2DAO",
      inMenu: false
    },
  },
  {
    path: '/blog/:slug',
    name: 'Blog Post',
    component: BlogPostPage,
    meta: {
      requiresAuth: false,

      // title:
    }
  },
  {
    path: '/my-profile',
    name: 'My Profile',
    component: InvestmentsPage,
    meta: {
      requiresAuth: true,
      title: 'My Profile | A2DAO'
    }
  },
  {
    path: '/my-investments',
    name: 'Investments',
    component: InvestmentsPage,
    meta: {
      requiresAuth: true,
      title: 'My Investments | A2DAO'
    }
  },
  {
    path: '/my-activity',
    name: 'Activity',
    component: MyActivityPage,
    meta: {
      requiresAuth: true,
      title: 'My Activity | A2DAO'
    }
  },
  {
    path: '/project',
    name: 'Projects',
    meta: {
      requiresAuth: true,
    },
    redirect: () => {
      return {path: '/'}
    }
  },
  {
    path: '/project/:slug',
    name: 'Project',
    component: InnovationProjectPage,
    meta: {
      requiresAuth: true,
      // title:
    }
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: PrivacyPolicyPage,
    meta: {
      requiresAuth: false,
      title: 'Privacy Policy | A2DAO'
    }
  },
  {
    path: '/kyc-finished',
    name: 'KYC Finished',
    component: KycFinishedPage,
    meta: {
      requiresAuth: false,
      title: 'KYC Finished | A2DAO'
    }
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: LeaderboardPage,
    meta: {
      requiresAuth: true,
      title: 'Leaderboard | A2DAO',
      inMenu: true
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQPage,
    meta: {
      requiresAuth: false,
      title: 'FAQ | Frequent questions that may arise when interacting with the platform | A2DAO',
      inMenu: true
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPostsPage,
    meta: {
      requiresAuth: false,
      title: 'Blog Posts | A2DAO',
      inMenu: true
    }
  },
  {
    path: "/pipeline/:slug",
    component: InnovationLabsPage,
    meta: {
      requiresAuth: false,
      title: "Pipeline | A2DAO",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundPage,
    meta: {
      requiresAuth: false,
      title: '404 | A2DAO'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if (to.hash) {
      // BEFORE:
      // return { selector: to.hash }

      return { el: to.hash }
    }

    return { top: 0 }
  },
  routes,
});

router.beforeEach((to) => {
  if (to.name !== 'Project') {
    window.document.title = to.meta && to.meta.title ? to.meta.title : 'Home';
  }

  // instead of having to check every route record with
  const accessToken = localStorage.getItem('access_token');
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !accessToken) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/',
    }
  }
})

export default router;
