<template>
  <div
    class="project-card-bg-wrapper"
    :class="type"
    @click="goToProjectPage(slug)"
  >
    <div class="project-card-content">
      <div
        class="project-card-img"
        :style="{ backgroundImage: img ? `url(${img})` : '' }"
      >
        <div class="project-type">
          <div>{{ typeName }}</div>
          <div v-if="multiplier">{{multiplier}}</div>
        </div>
        <div class="state-label" :class="setClassState(state)">
          <div class="state-label-ball"></div>
          <div class="state-label-ball-shadow"></div>
          <FontTitle
            :text="setState(state)"
            class="state-label-text"
          ></FontTitle>
          <!--          <div class="state-label-text">-->
          <!--            {{ setState(state) }}-->
          <!--          </div>-->
          <!--          <div class="state-label-text-shadow">{{ setState(state) }}</div>-->
        </div>
      </div>
      <div class="project-card-meta">
        <div class="project-card-meta-left-side">
          <div class="project-name">
            {{ trimmedName }}
          </div>
          <!--<template v-if="isActive || isUpcoming">
            <div class="project-dates">
                {{ dateStart }} - {{ dateEnd }}
            </div>
          </template>
          <template v-else>
            <div class="project-dates">
              <span class="ended">
                {{ projectType }} Ended
                <template v-if="ended.days >= 1">
                  {{ ended.days }} day{{ ended.days > 1 ? 's' : '' }}
                </template>
                <template  v-else-if="ended.hours >= 1">
                  {{ ended.hours }} hour{{ ended.hours > 1 ? 's' : '' }}
                </template>
                <template  v-else-if="ended.minutes >= 1">
                  {{ ended.minutes }} minute{{ ended.minutes > 1 ? 's' : '' }}
                </template>
                <template v-else-if="ended.seconds >= 1">
                  {{ ended.seconds }} second{{ ended.seconds > 1 ? 's' : '' }}
                </template>
                ago
              </span>
            </div>
          </template>-->
        </div>
        <div class="project-card-meta-right-side">
          <router-link :to="{ name: 'Project', params: { slug } }">
            <button-component> Details </button-component>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/Button";
import router from "@/router";
import FontTitle from "@/components/FontTitle";

export default {
  name: "ProjectCardSmall",
  components: {
    FontTitle,
    ButtonComponent,
  },
  props: {
    state: String,
    type: String,
    typeName: String,
    name: String,
    dateStart: String,
    dateEnd: String,
    img: String,
    activeDaysLeftParsed: Object,
    isActive: Boolean,
    isUpcoming: Boolean,
    isRefunded: Boolean,
    isFull: Boolean,
    linkToOpen: String,
    multiplier: String,
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ended: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  mounted() {
    this.ended = this.getActiveDaysLeft();
  },
  computed: {
    trimmedName() {
      const nameLength = 15;
      return this.name.length > nameLength
        ? `${this.name.substring(0, nameLength)}...`
        : this.name;
    },
  },
  methods: {
    goToProjectPage(slug) {
      if (this.linkToOpen) {
        window.location.href = this.linkToOpen;
      } else {
        router.push({ name: "Project", params: { slug } });
      }
    },
    getActiveDaysLeft() {
      return this.activeDaysLeftParsed
        ? this.activeDaysLeftParsed
        : {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
    },
    setState(state) {
      if (this.isActive && this.isFull) {
        return "Full";
      }

      if (this.isRefunded) {
        return "Underinvested";
      }

      return state;
    },
    setClassState(state) {
      if (this.isActive && this.isFull) {
        return "full";
      }

      if (this.isRefunded) {
        return "refunded";
      }

      return state;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.project {
  &-name {
    line-height: 130%;
    text-align: left;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -0.035em;

    /* #272727 */

    color: #272727;

    @include respond-to(xs) {
      font-size: 16px;
    }

    @include respond-to(sm) {
      font-size: 18px;
    }

    @include respond-between(xs, sm) {
      font-size: 14px;
    }
  }

  &-dates {
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
    color: #fff;
    padding-top: 5px;
    text-align: left;

    @include respond-to(xs) {
      font-size: 11px;
    }

    @include respond-to(sm) {
      font-size: 11px;
    }
  }

  &-type {
    position: absolute;
    display: flex;
    gap: 10px;
    bottom: 5px;
    left: 15px;

    div{
      display: flex;
      min-height: 22px;
      padding: 0px 12px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      /* identical to box height, or 16px */
      text-align: center;
      align-items: center;

      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      color: #000000;

      background: #b6dc2b;
      border: 0.5px solid #000000;
    }
  }

  &-card {
    &-bg-wrapper {
      box-sizing: border-box;
      width: 412px;
      height: auto;
      box-shadow: 0 0 65px rgba(0, 0, 0, 0.25);
      text-align: center;
      cursor: pointer;
      background: #e5e7eb;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      z-index: 2;

      &:nth-child(2n-1) {
        @include respond-below(sm) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @include respond-below(sm) {
          margin-right: 0;
        }
      }

      @include respond-below(xs-plus) {
        margin-left: 0;
        margin-right: 0;
      }

      @include respond-below(md) {
        width: 340px;
        height: 226px;
      }

      @include respond-below(sm) {
        width: 294px;
        height: 200px;
      }

      @include respond-below(xxs) {
        width: 100%;
      }
      @media (max-width: 660px) and (min-width: 360px){
        width: 100%;
        height: 100%;
      }

      &.nft {
        //background: linear-gradient(90.3deg, #FF5E7E -4.55%, #FFAD72 107.76%);

        .project-name {
          //background: linear-gradient(94.29deg, #FF3F71 -2.43%, #FFAD72 20.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #FF3F71 -7.52%, #FFAD72 107.59%);
        }
      }

      &.true-yield-staking {
        //background: linear-gradient(90.44deg, #AC3BFF -6.21%, #7CCAF7 106.91%);

        .project-name {
          //background: linear-gradient(94.29deg, #AC3BFF -2.43%, #5ECFFF 20.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
        }
      }

      &.ido {
        //background: linear-gradient(90.31deg, #FF7F23 -7.18%, #FFF400 104.61%);

        .project-name {
          //background: linear-gradient(94.29deg, #FF7F23 -2.43%, #FEFF5F 20.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
        }
      }

      &.lockdrop {
        //background: linear-gradient(90.31deg, #00B1D2 -7.18%, #1EB676 104.61%);

        .project-name {
          //background: linear-gradient(94.29deg, #00B1D2 -2.43%, #1EB676 47.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
        }
      }
    }

    &-content {
      position: relative;
      width: 100%;
      height: 100%;

      .state-label {
        margin: 6px 15px;
        position: absolute;
        bottom: 5px;
        right: 0;

        font-family: "Space Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /*line-height: 130%;*/
        align-items: start;
        width: fit-content;
        /* identical to box height, or 29px */
        letter-spacing: 0.105em;
        text-transform: uppercase;
        color: #ffffff;
        text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
        &-text {
          position: relative;
          z-index: 1;
          &-shadow {
            /*position: absolute;*/
            top: 0;
            /*left: 1.5px;*/
            margin-top: -13px;
            margin-left: 3px;
            z-index: 0;
          }
        }
        &-ball {
          position: absolute;
          border-radius: 50px;
          width: 10px;
          height: 10px;
          background: linear-gradient(171.5deg, #15ffff 6.5%, #ffdb1a 129.21%);
          border: 0.5px solid #000000;
          align-items: start;
          margin-left: -15px;
          margin-top: 1px;
          z-index: 1;
          &-shadow {
            position: absolute;
            background: #fff;
            border-radius: 50px;
            width: 10px;
            height: 10px;
            margin-left: -13px;
            margin-top: 2px;
            border: 0.5px solid #000000;
            z-index: 0;
          }
        }

        &.active {
          //background: #FF5E7E;
        }

        &.completed,
        &.full {
          //background: #008DA8;
        }

        &.refunded {
          //background: #555;
        }
      }
    }

    &-img {
      height: 281px;
      background: radial-gradient(
          50% 50% at 50% 50%,
          #3c3d46 1.56%,
          #1a1b1d 100%
        )
        no-repeat center;
      background-size: cover;
      /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
      position: relative;

      @include respond-to(xs) {
        height: 140px;
      }

      @include respond-to(sm) {
        height: 153px;
      }
      @media (max-width: 660px) and (min-width: 360px){
        width: 100%;
        height: 40vw;
      }
    }

    &-meta {
      display: flex;
      justify-content: space-between;
      padding: 25px;

      @include respond-to(xs) {
        align-items: center;
        padding: 16px;
      }

      @include respond-to(sm) {
        padding: 20px;
      }
      @media (max-width: 660px) and (min-width: 360px){
        padding: 4vw 16px;
      }

      &-left-side {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        align-self: center;

        @include respond-to(xs) {
          padding-left: 14px;
        }
      }

      &-right-side {
        align-self: flex-start;

        @include respond-to(xs) {
          padding-right: 13px;
        }

        @include respond-to(sm) {
          padding-right: 13px;
        }

        button {
          background: #272727;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          max-width: 117px;
          max-height: 38px;
          padding: 8px 26px 10px;

          font-family: "Space Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: -0.045em;
          text-transform: uppercase;
          color: #ffffff;

          @include respond-to(xs) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
