const NETWORK_URL = process.env.VUE_APP_NETWORK_URL;
const CHAIN_ID = process.env.VUE_APP_CHAIN_ID;

export const PROJECT_TYPE_IDO = 'ido';
export const PROJECT_TYPE_NFT = 'nft';
export const PROJECT_TYPE_TYS = 'true-yield-staking';
export const PROJECT_TYPE_LOCKDROP = 'lockdrop';

export const projectType = (project) => {
  if (project.isIDO || project.isIDOAdvanced) {
    return PROJECT_TYPE_IDO;
  }
  if (project.isNftDrop || project.isNftWhitelistDrop) {
    return PROJECT_TYPE_NFT;
  }
  if (project.isTrueYieldStaking) {
    return PROJECT_TYPE_TYS;
  }

  return PROJECT_TYPE_LOCKDROP;
};

export const checkChainId = (chainId) => {
  if (Number(chainId) !== Number(CHAIN_ID)) {
    throw new Error('Please connect to BNB Smart Chain');
  }
};

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const getNetwork = () => {
  return {
    url: NETWORK_URL,
    chainId: CHAIN_ID
  };
};
export const b64EncodeUnicode = (str) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
      encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
      )
  );
};
export const b64DecodeUnicode = str => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
};

export const getReferralData = () => {
  let result = {};

  let referralDataFromStore = JSON.parse(localStorage.getItem('referralInfo'));

  if (referralDataFromStore?.inf) {
    result.influencer = referralDataFromStore.inf;
  }

  if (referralDataFromStore?.ref) {
    result.referrer = referralDataFromStore.ref;
  }

  return result;
};

export const scrollToSmoothly = (offsetTop = 0) => {
  window.scrollTo({ top: offsetTop, behavior: 'smooth' });
};

export const calculateEstimation = (userStake, totalDays, launchPrice = 0.0106, isInvestmentsPage = false) => {
  const cpltEstimation = (12 / (365 * 100)) * totalDays * userStake;
  const usdtEstimation = (36 / (365 * 100)) * totalDays * launchPrice * userStake;

  if (isInvestmentsPage) {
    return `${usdtEstimation.toFixed(2)} USDT<br>or ${cpltEstimation.toFixed(2)} CPLT`;
  }

  return `${usdtEstimation.toFixed(2)} USDT or ${cpltEstimation.toFixed(2)} CPLT`;
};

export const trimMiddle = (str) => str.slice(0, 6) + '...' + str.slice(-5);

