import axios from 'axios';
import store from '../store';
import router from '../router';

const API = axios.create({
  baseURL: `${process.env.VUE_APP_API_ENDPOINT}/api/v1`
})
  // const API = axios.create({
  //   baseURL: `https://api.a2dao.com/api/v1`
  // })

API.interceptors.request.use((config) => {
  if (localStorage.getItem('access_token')) {
    config.headers.common = {...config.headers.common, "Authorization": `Bearer ${localStorage.getItem('access_token')}`};
  }

  return config;
});

API.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    store.dispatch('logOut');
    router.push('/');
  }

  return Promise.reject(error)
})

export { API };
