<template>
  <div class="partners-wrapper">
    <FontTitle class="partners-text" text="We are raising funds for"></FontTitle>
    <ul class="partners-types">
      <li v-for="partnerType in partnerTypes" :key="partnerType.name"
        @click="handleType(partnerType.id)" :class="{'active': partnerType.id === selectedPartnerType}">
        {{ partnerType.name }}
      </li>
    </ul>
    <ul class="dates-range">
      <li v-for="date in datesRange" :key="date.text"
        @click="handleDate(date)" :class="{'active': date.text === selectedDate?.text}">
        {{ date.text }}
      </li>
    </ul>
    <ul class="selected-partners">
      <li v-for="partner in selectedPartners" :key="`${partner.id}-key`">
        <img :src="`${staticEndpoint}/${partner.logoPath}`">
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import FontTitle from "@/components/FontTitle";

export default {
  name: 'PartnersList',
  components: {FontTitle},
  data() {
    return {
      datesRange: [
        {
          text: '2024-2023',
          value: ['2023', '2024']
        },
        {
          text: '2022-2021',
          value: ['2021', '2022']
        },
        {
          text: '2020-2019',
          value: ['2019', '2020']
        },
        {
          text: '2018-2017',
          value: ['2017', '2018']
        }
      ],
      selectedPartnerType: null,
      selectedDate: null
    }
  },
  mounted() {
    this.fetchPartners().then(() => {
      this.selectedPartnerType = this.partnerTypes[0]?.id;
      this.selectedDate = this.datesRange[0];
      this.sortPartners();
    });
  },
  methods: {
    ...mapActions([
      'fetchPartners'
    ]),
    sortPartners() {
      this.selectedPartners = this.partners.filter((partner) => {
        return partner.type.id === this.selectedPartnerType && this.selectedDate.value.includes(partner.dateParticipated);
      });
    },
    handleDate(date) {
      this.selectedDate = date;
      this.sortPartners();
    },
    handleType(type) {
      this.selectedPartnerType = type;
      this.sortPartners();
    }
  },
  computed: {
    ...mapState([
      'partnerTypes',
      'partners'
    ]),
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/mixins";
.partners-wrapper {
  text-align: center;
  padding-top: 140px;
  min-height: 695px;

  @include respond-to(xs) {
    padding-bottom: 0;
    padding-top: 60px;
    min-height: 535px;
  }

  @include respond-to(sm) {
    padding-bottom: 0;
    padding-top: 60px;
    min-height: 381px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 71px;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 107px;
    letter-spacing: -0.005em;
    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;


    @include respond-to(xs) {
      font-size: 17px;
      line-height: 159%;
      margin: 0 auto 18px;
      max-width: 270px;
    }

    @include respond-to(sm) {
      font-size: 16px;
      line-height: 97%;
    }
  }

  ul {
    list-style-type: none;
    max-width: 720px;
    padding: 0;
    margin: 0 auto;

    li {
      color: #898D8F;
      cursor: pointer;
      display: inline-block;
      font-family: 'Space Mono';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      letter-spacing: 0.205em;
      line-height: 130%;
      text-transform: uppercase;

      @include respond-to(xs) {
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.105em;
      }

      @include respond-to(sm) {
        font-size: 15px;
        line-height: 101.5%;
        letter-spacing: 0.085em;
      }
    }
  }
  .partners{

    &-text{
      margin-top: 0;
      margin-bottom: 71px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      letter-spacing: -0.005em;
      @include respond-to(sm) {
        font-size: 30px;
      }

      @include respond-to(xs) {
        font-size: 25px;
      }
      &-shadow{
        width: 100%;
        position: absolute;
        top: 2px;
        left: 1.5px;
        z-index: -1;
        text-shadow: 1px 0 grey, -1px 0 grey, 0 1px grey, 0 -1px grey;

      }
    }
  }
  .partners-types,
  .dates-range {
    li {
      margin-top: 22px;
      padding: 3px 13px;

      &.active {
        color: #272727;
        background: #F8F8F8;
        cursor: auto;
      }
    }
  }

  .selected-partners {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-between;
    min-width: 1280px;
    min-height: 180px;
    margin-top: 100px;
    gap: 30px;

    @include respond-below(lg) {
      min-width: 900px;
    }

    @include respond-below(md) {
      min-width: 642px;
      min-height: 100px;
      margin-top: 50px;
      padding-bottom: 50px;
    }

    @include respond-below(sm) {
      grid-template-columns: 1fr 1fr;
      min-width: 300px;
      max-width: 300px;
      margin-top: 31px;
    }

    li {
      margin-left: 0;
      max-width: 175px;
      align-self: center;

      img {
        max-width: 175px;
        max-height: 50px;

        @include respond-below(md) {
          max-width: 100px;
          max-height: 33px;
        }
      }
    }
  }
}
</style>
