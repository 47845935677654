<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper no-bg no-dots-background>
        <top-section-content content-to-left>
          <template v-slot:top-section-heading>
            <FontTitle text="My Activity" class="title"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            <span class="investments-sub-header">
              Here, you can view all your investments, refunds, and details on
              achieved leaderboard points.
            </span>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="ufo-wrapper-1">
      <div class="ufo-1"></div>
      <div class="trace-1"></div>
    </div>
    <div class="ufo-wrapper-2">
      <div class="ufo-2"></div>
      <div class="trace-2"></div>
    </div>
    <div class="ufo-wrapper-3">
      <div class="ufo-3"></div>
      <div class="trace-3"></div>
    </div>

    <div class="table-wrapper">
      <table class="activity-table">
        <thead class="header">
          <tr class="table-header">
            <th style="min-width: 200px;">Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th style="max-width: 100px">Points</th>
          </tr>
        </thead>
        <tbody>
        <template v-if="!isLoading && userActivity.length">
          <tr class="table-body" v-for="item in userActivity" :key="item.id">
            <td style="min-width: 200px;">
              <img v-if="item.isProfit" src="../assets/activity-arrow.svg" alt="">
              <img v-else src="../assets/activity-arrow-grey.svg" alt="">
              [{{ formatDate(item.date) }}]
              <span
                :class="
                  getType(item.action) === 'Stake'
                    ? 'stake'
                    : getType(item.action) === 'Invest'
                    ? 'invest'
                    : getType(item.action) === 'Burn' ? 'burn'
                    : getType(item.action) === 'Full refund'
                    ? 'full-refund'
                    : 'refund'
                "
                >{{ getType(item.action) }}</span
              >
            </td>
            <td>{{ item.description }}</td>
            <td>
              <tooltip-custom :content="item.amount.toString()">
                {{ item.amount }} {{currency(item.amount)}}
              </tooltip-custom>
              </td>
            <td style="max-width: 100px">
              <tooltip-custom :content="item.points.toString()">
                <span :style="item.isProfit ? 'color: #60BB5E;' : 'color:#F56262'"
                >{{ item.isProfit ? "+" : "-" }}{{ item.points }}</span
                >
              </tooltip-custom>

            </td>
          </tr>
        </template>
          <template v-else-if="isLoading">
            <template v-for="value in [1, 2, 3, 4, 5, 6, 7]" :key="value">
              <tr class="project-participated-table-row-loading">
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                          class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="pr-25">
                  <div class="skeleton-loading"></div>
                  <div
                          class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                          class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                          class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr class="project-participated-table-empty">
              <td class="empty-row" colspan="7">
                <div class="empty-row__text">
                  Your activity will appear here
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </main-layout>
</template>

<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import TopSectionContent from "@/components/TopSectionContent";
import FontTitle from "@/components/FontTitle";
import { mapActions, mapState } from "vuex";
import TooltipCustom from "@/components/TooltipCustom";

export default {
  name: "InvestmentsPage",
  components: {
    FontTitle,
    TopSectionContent,
    TopSectionWrapper,
    MainLayout,
    TooltipCustom,
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState(["userActivity"]),

  },
  methods: {
    ...mapActions(["fetchUserActivity"]),
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      };
      return date
        .toLocaleDateString("en-US", options)
        .replace(",", "")
        .replace(/\//g, "-")
        .replace(" ", " ")
        .replace(/-/g, "/");
    },

    getType(action) {
      switch (action) {
        case 'add_boost_points':
        return "Stake"
        case 'add_to_refunded':
          return "Refund"
        case 'burn':
          return "Burn"
        case 'invest':
          return "Invest"
        case 'referral':
          return "Stake"
        case 'refund_full':
          return "Full refund"
        case 'remove_from_refunded':
          return "Refund"
        case 'stake':
          return "Stake"
        case 'stake_bonus':
          return "Stake"
      }

    },
    currency(action){
      return this.getType(action) === 'Invest' || this.getType(action) === 'Refund' || this.getType(action) === 'Full refund' ? 'USDT' : 'ATD'
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchUserActivity();
    this.isLoading = false
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.investments {
  &-header {
    font-family: "Press Start 2P", sans-serif;
    display: block;
    font-size: 52px;
    font-weight: 400;
    line-height: 114.5%;
    background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @include respond-to(sm) {
      font-size: 22px;
      line-height: 50px;
    }

    @include respond-to(xs) {
      font-size: 18px;
      line-height: 50px;
    }
  }

  &-sub-header {
    display: block;
    margin-top: 24px;
    max-width: 665px;
  }
}
.table-wrapper {
  position: relative;
  min-width: auto;
  width: 100%;
  display: block;
  overflow-x: scroll;
}
.activity-table {
  border-collapse: collapse;
  margin: 0 auto;
  min-width: 1280px;
  overflow: hidden;
  z-index: 10;
  position: relative;

 

  thead {
    background: #ffffff;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    height: 51px;

    th {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.05em;
      color: #272727;
      font-style: normal;
      width: 170px;
      text-align: start;
      padding-left: 75px;
      @include respond-to(xs) {
        font-size: 16px;
        padding: 0 25px;
      }
    }
  }

  tbody {
    background: #F2F2F2;
    border: 1px solid #000000;

    @keyframes sweep {
      100% {
        transform: translateX(100%);
      }
    }

    .px-25 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    .skeleton-loading {
      position: relative;
      overflow: hidden;
      background-color: #ccc;
      background-size: 1000%;
      background-position: right;
      border-radius: 20px;
      max-width: 150px;
      width: 100%;
      height: 12px;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: sweep 2s infinite;
        content: "";
      }

      &--secondary {
        width: 70%;
        max-width: 100px;
        margin-top: 10px;
      }

      &--circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    tr.sep {
      hr {
        width: 92%;
        border: 0;
        height: 1px;
        background: #e8e8e8;
      }

      &:last-child {
        display: none;
      }
    }

    tr:not(.sep) {
      height: 60px;

      .project-participated-cell {
        font-size: 18px;
        line-height: 27px;
        text-align: center;

        @include respond-to(xs) {
          font-size: 14px;
        }

        .block {
          display: block;
        }

        &.title {
          padding-left: 35px;
          text-align: left;
        }
      }

      .empty-row {
        &__text {
          font-size: 18px;
          line-height: 27px;
          align-items: center;
          text-align: center;
        }
      }

      .logo-cell {
        &-wrapper {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 130%;
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          margin-left: 47px;
          text-decoration: none;
          position: relative;
          color: initial;

          @include respond-to(xs) {
            font-size: 14px;
          }

          img,
          span {
            display: block;
          }

          span {
            line-height: 23px;
          }
        }
      }

      .actions-cell {
        padding-right: 46px;
        text-align: center;

        .project-participated-button {
          height: 30px;
          width: 100px;
          text-transform: uppercase;
          padding: initial;
          background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
          box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

          &.investment-manage-button {
            //background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
          }

          &.investment-unstake-button {
            //background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
          }

          &.investment-claim-button {
            //background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
          }
        }
      }
      td {
        padding-left: 75px;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.05em;
        text-align: left;
        color: #444444;
        position: relative;
        span {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.01em;
          text-align: left;
        }
      }
    }
  }
}
.ufo-wrapper-1 {
  position: absolute;
  top: -270px;
  right: 170px;
  width: 224px;
  height: 208px;
  z-index: 10;
  .ufo-1 {
    background-image: url("../assets/activity-ufo.png");
    width: 170px;
    background-size: cover;
    height: 165px;
    background-repeat: no-repeat;
    position: absolute;
    right: 43px;
    top: -50px;
  }
  .trace-1 {
    background-image: url("../assets/activity-trace.png");
    width: 250px;
    height: 300px;
    background-repeat: no-repeat;
    position: absolute;
    right: 125px;
    top: 90px;
  }
  @include respond-to(md) {
    top: -230px;
    right: 140px;
  }
  @include respond-to(sm) {
    top: -230px;
    right: 140px;
  }
  @include respond-to(xs) {
    display: none;
  }
}
.ufo-wrapper-2 {
  position: absolute;
  top: -320px;
  right: 90px;
  width: 224px;
  height: 196px;
  z-index: -1;
  .ufo-2 {
    background-image: url("../assets/activity-ufo.png");
    width: 200px;
    height: 195px;
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    top: 0px;
  }
  .trace-2 {
    background-image: url("../assets/activity-trace.png");
    width: 250px;
    height: 300px;
    background-repeat: no-repeat;
    position: absolute;
    right: 125px;
    top: 90px;
  }
  @include respond-to(md) {
    top: -270px;
    right: 50px;
  }
  @include respond-to(sm) {
    top: -270px;
    right: 50px;
  }
  @include respond-to(xs) {
    display: none;
  }
}
.ufo-wrapper-3 {
  position: absolute;
  top: -240px;
  right: 40px;
  width: 224px;
  height: 208px;
  z-index: -1;
  .ufo-3 {
    background-image: url("../assets/activity-ufo.png");
    width: 200px;
    height: 195px;
    background-repeat: no-repeat;
    position: absolute;
    right: 5px;
    top: -25px;
  }
  .trace-3 {
    background-image: url("../assets/activity-trace.png");
    width: 250px;
    height: 300px;
    background-repeat: no-repeat;
    position: absolute;
    right: 125px;
    top: 90px;
  }
  @include respond-to(md) {
    top: -200px;
    right: -5px;
  }
  @include respond-to(sm) {
    top: -200px;
    right: -5px;
  }
  @include respond-to(xs) {
    display: none;
  }
}
.stake {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: center;
  background: #d8f2f1;
  color: cornflowerblue;
  padding: 2px 11px;
  border-radius: 100px;
}
.invest {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: center;
  background: #ddfadc;
  color: #3ea33b;
  padding: 2px 11px;
  border-radius: 100px;
}
.burn {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: center;
  background: #f5edd3;
  color: #fabd05;
  padding: 2px 11px;
  border-radius: 100px;
}
.full-refund {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: center;
  background: #f5e0ff;
  color: #b35fdb;
  padding: 2px 11px;
  border-radius: 100px;
}
.refund {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: center;
  background: #e7e7e7;
  color: #737378;
  padding: 2px 11px;
  border-radius: 100px;
}
img{
  position: absolute;
  left: 30px;
  top: 25px;
}
/*table{*/
/*  width: 100%;*/
/*  border: 1px solid #000000;*/
/*  thead{*/
/*    background: #ffffff;*/
/*    border-top: 1px solid #000000;*/
/*    border-left: 1px solid #000000;*/
/*    border-right: 1px solid #000000;*/
/*    tr{*/
/*      padding-left: 75px;*/
/*      height: 60px;*/
/*      th{*/
/*        text-align: start;*/
/*      }*/
/*    }*/
/*  }*/
/*  tbody{*/
/*    background: #F2F2F2;*/
/*    border: 1px solid #000000;*/
/*    tr{*/
/*      padding-left: 75px;*/
/*      height: 60px;*/
/*      td{*/
/*        text-align: start;*/
/*      }*/
/*    }*/
/*  }*/

/*}*/
</style>
