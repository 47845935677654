<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper>
        <top-section-content show-right-side>
          <template v-slot:top-section-heading>
            <FontTitle text="Blog" class="blog-posts-header"></FontTitle>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="blogposts-main">
      <template v-if="blogPosts && blogPosts.length">
        <blog-post-categories />
        <blog-post-list />
      </template>
      <template v-else>
        <h1 class="no-blogs">Blog posts will appear here soon.</h1>
      </template>
    </div>
  </main-layout>
</template>

<script>
  import TopSectionWrapper from '@/components/TopSectionWrapper';
  import MainLayout from '@/layouts/main';
  import TopSectionContent from '@/components/TopSectionContent';
  import BlogPostCategories from '@/components/BlogPostCategories';
  import BlogPostList from '@/components/BlogPostList';
  import { mapActions, mapState } from 'vuex';
  import FontTitle from "@/components/FontTitle";

  export default {
    name: 'BlogPostsPage',
    components: {
      FontTitle,
      TopSectionContent,
      TopSectionWrapper,
      MainLayout,
      BlogPostCategories,
      BlogPostList
    },
    created() {
      this.fetchBlogCategories();
      this.fetchBlogPosts();
    },
    computed: {
      ...mapState([
        'blogPosts'
      ])
    },
    methods: {
      ...mapActions([
        'fetchBlogPosts',
        'fetchBlogCategories',
      ]),
    }
  };
</script>

<style scoped lang="scss">
  :deep(.top-section-footer) {
    display: none;
  }

  .no-blogs {
    top: -200px;
    position: relative;
    text-align: center;
    color: #9B9B9B;
    font-weight: 500;
  }
</style>
