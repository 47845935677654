<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper class="pb-40">
        <top-section-content show-right-side>
          <template v-slot:top-section-heading>
            <FontTitle :text="i18n['completed-deals-heading']"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            <div class="sub-heading">
              Here you can find deals in private and seed rounds that have already been completed
            </div>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="completed-deals-page-content">
      <generic-pagination
        :current-page="pagination.current_page"
        :total-pages="pagination.last_page"
        :url="{
          name: 'Completed', params: ['page']
        }"
      />
      <div class="completed-deals-projects-wrapper">
        <project-card-small
            v-for="project in projects.completed"
            :key="project.id"
            state="completed"
            :type="projectType(project)"
            :type-name="project.type.name"
            :name="project.name"
            :date-start="project.dateStart"
            :date-end="project.dateEnd"
            :img="`${staticEndpoint}/${project.cardBannerPath}`"
            :slug="project.slug || String(project.id)"
            :activeDaysLeftParsed="project.activeDaysLeftParsed"
            :isActive="project.isActive"
            :isUpcoming="project.isUpcoming"
            :isRefunded="project.isRefunded"
            :isFull="project.isFull"
            :linkToOpen="project.linkToOpen"
            :multiplier="project.multiplier"
        />
      </div>
      <generic-pagination
          :current-page="pagination.current_page"
          :total-pages="pagination.last_page"
          :url="{
          name: 'Completed', params: ['page']
        }"
      />
    </div>
  </main-layout>
</template>

<script>
import TopSectionWrapper from '@/components/TopSectionWrapper';
import MainLayout from '@/layouts/main';
import GenericPagination from '@/components/GenericPagination.vue';
import ProjectCardSmall from '@/components/ProjectCardSmall';
import { mapActions, mapGetters, mapState } from 'vuex';
import TopSectionContent from '@/components/TopSectionContent';
import { projectType } from '@/helpers';
import FontTitle from "@/components/FontTitle";

export default {
  name: 'CompletedDealsPage',
  components: {
    FontTitle,
    TopSectionContent,
    TopSectionWrapper,
    MainLayout,
    GenericPagination,
    ProjectCardSmall
  },
  head: {
    meta: [
      {
        name: 'description',
        content: 'Private and seed round deals of various crypto projects that have already been completed.'
      },
    ],
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    ...mapGetters([
      'projects',
      'i18n'
    ]),
    ...mapState([
      'pagination'
    ])
  },
  methods: {
    projectType,
    ...mapActions([
      'fetchCompletedProjects'
    ])
  },
  watch: {
    $route(to, from) {
      if (from.name === to.name) {
        this.fetchCompletedProjects(to.query.page);
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('resetProjects');
    })
  },
  created() {
    const pageInd = this.$route.query.page;

    this.fetchCompletedProjects(pageInd);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.completed-deals-projects-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* flex-wrap: wrap; */
  margin-top: 40px;
  min-width: 100%;
  width: 100%;
  gap: 16px;

  @include respond-below(xs-plus) {
    flex-direction: column;
  }

  @include respond-between(xs, sm) {
    flex-wrap: wrap;
  }

  @include respond-to(xs) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 660px) {
    grid-template-columns: 1fr!important;
  }

  @include respond-between(xs, lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.heading {
  font-size: 88px;
  line-height: 80px;
  max-width: 500px;
  display: inline-block;
  position: relative;
  margin-top: -40px;

  @include respond-to(xs) {
    font-size: 48px;
    line-height: 50px;
  }

  @include respond-to(sm) {
    font-size: 48px;
    max-width: 400px;
    line-height: 50px;
    margin-top: 0;
  }
}

.sub-heading {
  max-width: 663px;
  margin-top: 11px;

  @include respond-to(sm) {
    max-width: 400px;
  }
}

</style>
