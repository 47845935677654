<template>
  <div class="investments-wrapper">
    <!--    <div class="behind-table-bg"></div>-->
    <div class="table-wrapper">
      <table
        class="investments-table"
        border="0"
        cellpadding="0"
        cellspacing="0"
      >
        <thead class="header">
          <tr>
            <th v-for="(column, index) in columns" :key="index">
              <label>{{ column.name }}</label>
            </th>
          </tr>
        </thead>
        <tbody class="table-body" ref="table_body">
          <template
            v-if="
              requestStatus === 'loaded' &&
              ((userInvestments && userInvestments.length) ||
                (userOldInvestments && userOldInvestments.length))
            "
          >
            <template v-for="(project, index) in userInvestments" :key="index">
              <tr>
                <td class="logo-cell">
                  <router-link
                    class="logo-cell-wrapper"
                    v-if="project.slug"
                    :to="{ name: 'Project', params: { slug: project.slug } }"
                  >
                    <LogoCell
                      :logo-src="staticEndpoint + project.logoPath"
                      :project-name="project.name"
                      :project-blockchain="project.blockchain.value"
                    />
                  </router-link>
                  <div class="logo-cell-wrapper" v-else>
                    <LogoCell
                      :logo-src="staticEndpoint + project.logoPath"
                      :project-name="project.name"
                      :project-blockchain="project.blockchain.value"
                    />
                  </div>
                </td>
                <td class="project-participated-cell">
                  <template v-if="!project.isActive">
                    <template v-if="project.dateNextClaim">
                      <template v-if="project.ableToClaim">
                        <span>{{
                          i18n["investments-claiming-available"]
                        }}</span>
                      </template>
                      <template v-else>
                        <span
                          class="block project-participated-additional-info"
                        >
                          {{ i18n["investments-next-claiming-in"] }}
                        </span>
                        <span>{{ project.nextClaimDaysLeft }}</span>
                      </template>
                    </template>
                    <template v-else>
                      <span class="block project-participated-additional-info">
                        {{ i18n["investments-ended"] }}
                      </span>
                      <span>{{ project.endDateRaw }}</span>
                    </template>
                  </template>
                  <template v-else>
                    <span class="block project-participated-additional-info">
                      {{ i18n["investments-ends-in"] }}
                    </span>
                    <span>{{ project.activeDaysLeft }}</span>
                  </template>
                </td>
                <td
                  class="project-participated-cell project-participated-stake"
                >
                  <div
                    class="stake-wrapper-locked"
                    :class="{
                      hide: !parseFloat(
                        Number(project.contractInfo?.yourStake)
                      ),
                    }"
                  >
                    <span class="block">
                      <span class="your-stake">{{
                        parseFloat(
                          Number(project.contractInfo?.yourStake).toFixed(2)
                        ).toLocaleString() ?? "0"
                      }}</span>
                      {{ project.currency ?? newTokenName }}
                    </span>
                    <span>
                      <span
                        v-if="
                          parseFloat(Number(project.contractInfo?.refunded))
                        "
                        class="staking-limit project-participated-additional-info"
                        style="font-size: 14px;"
                      >
                       (<a
                          style="color: #000000; line-height: 35px; font-size: 14px;"
                          :href="`${project.refundLink}`"
                          target="_blank"
                          >{{
                            parseFloat(
                              Number(project.contractInfo?.refunded).toFixed(2)
                            ).toLocaleString() ?? "0"
                          }} refunded</a>)
                      </span>
                    </span>
                  </div>
                  <template
                    v-if="!parseFloat(Number(project.contractInfo?.yourStake))"
                  >
                    <div class="stake-wrapper-unlocked">
                      <span
                        class="project-participated-additional-info"
                        v-html="i18n['investments-all-atd-unlocked']"
                      >
                      </span>
                    </div>
                  </template>
                </td>
                <td class="project-participated-cell">
                  <template v-if="project.isNftWhitelistDrop">
                    <span class="nft-drop-wl-amount-ticker">1 WL</span>
                  </template>
                  <template v-else-if="project.isNftDrop">
                    <span>1 NFT</span>
                  </template>
                  <template
                    v-else-if="
                      project.isTrueYieldStaking &&
                      Number(project.contractInfo?.yourStake)
                    "
                  >
                    <span
                      v-html="
                        calculateEstimation(
                          parseFloat(
                            Number(project.contractInfo.yourStake).toFixed(2)
                          ),
                          project.totalDaysFromStart,
                          parseFloat(project.launchPrice),
                          true
                        )
                      "
                    ></span>
                  </template>
                  <template v-else-if="!project.isRefunded">
                    <span class="estimated-allocation">
                      {{
                        !project.contractInfo?.earned ||
                        project.contractInfo?.earned === "--"
                          ? "TBA"
                          : parseFloat(
                              Number(project.contractInfo?.earned).toFixed(2)
                            ).toLocaleString() ?? "0"
                      }}
                    </span>
                    {{
                      !project.contractInfo?.earned ||
                      project.contractInfo?.earned === "--"
                        ? ""
                        : project.tickerMetric
                    }}
                  </template>
                  <template
                    v-if="project.isActive && !project.isTrueYieldStaking"
                  >
                    <span class="block project-participated-additional-info">
                      {{
                        !project.contractInfo?.earned ||
                        project.contractInfo?.earned === "--"
                          ? ""
                          : i18n["investments-table-estimated-label"]
                      }}
                    </span>
                  </template>
                  <template v-else-if="project.isRefunded">
                    <span
                      class="block project-participated-additional-info refunded-label"
                    >
                      {{ i18n["investments-table-protected-label"] }}
                    </span>
                  </template>
                  <template v-else-if="!project.isTrueYieldStaking">
                    <span
                      class="block project-participated-additional-info earned-label"
                    >
                      {{
                        !project.contractInfo?.earned ||
                        project.contractInfo?.earned === "--"
                          ? ""
                          : i18n["investments-table-earned-label"]
                      }}
                    </span>
                  </template>
                </td>
                <td class="project-participated-cell">
                  <template v-if="!project.isActive">
                    <template v-if="project.isNftWhitelistDrop">
                      <span class="block">
                        <template v-if="project.tokenSentPercentage">
                          <span class="nft-drop-wl-amount-claimed">1 </span>
                          <span class="nft-drop-wl-amount-claimed-ticker"
                            >WL</span
                          >
                        </template>
                        <template v-else>
                          <span class="nft-drop-wl-amount-claimed">-</span>
                        </template>
                      </span>
                    </template>
                    <template v-else-if="project.isNftDrop">
                      <span class="block">
                        <template v-if="project.tokenSentPercentage">
                          <span class="nft-drop-wl-amount-claimed">1 </span>
                          <span class="nft-drop-wl-amount-claimed-ticker"
                            >NFT</span
                          >
                        </template>
                        <template v-else>
                          <span class="nft-drop-wl-amount-claimed">-</span>
                        </template>
                      </span>
                    </template>
                    <template v-else-if="project.isRefunded">
                      {{ i18n["investments-table-protected-label"] }}
                    </template>
                    <template v-else>
                      <span class="block">
                        <span class="your-claimed">{{
                          calculateClaimed(project)
                        }}</span>
                        <span
                          class="your-claimed-ticker"
                          :class="{
                            hide: !(
                              Number(project.contractInfo?.earned).toFixed(2) >
                                0 && project.tokenSentPercentage > 0
                            ),
                          }"
                        >
                          {{ project.tickerMetric }}
                        </span>
                      </span>
                    </template>
                    <template
                      v-if="project.tokenSentPercentage && !project.isRefunded"
                    >
                      ({{ project.tokenSentPercentage }}%)
                    </template>
                  </template>
                  <template v-else> - </template>
                </td>
                <td
                  class="project-participated-cell project-participated-percentage"
                >
                  <template
                    v-if="project.tokenSentPercentage && project.dateNextClaim"
                  >
                    <span class="block"
                      >{{ project.nextClaimPercentage }}% at</span
                    >
                    <span class="block">{{
                      project.dateNextClaimFormatted
                    }}</span>
                  </template>
                  <template v-else> - </template>
                </td>
                <td class="actions-cell">
                  <template v-if="project.showClaimButton">
                    <template v-if="project.externalClaimLink">
                      <button-component
                        class="project-participated-button investment-manage-button external-claim-link"
                        font-size="12px"
                        font-weight="600"
                        @click="navigateToClaimLink($event, project)"
                      >
                        {{ i18n["claim-button-label"] }}
                      </button-component>
                    </template>
                    <template
                      v-else-if="
                        project.claimProofs &&
                        project.ableToClaim &&
                        project.isUserAbleToCLaim &&
                        project.addressCanClaim
                      "
                    >
                      <button-component
                        class="project-participated-button investment-claim-button"
                        @click="handleClaim($event, project)"
                        font-size="12px"
                        font-weight="600"
                      >
                        {{ i18n["claim-button-label"] }}
                      </button-component>
                    </template>
                  </template>
                  <template
                    v-else-if="
                      unstakeIsEnabled &&
                      !project.isActive &&
                      !project.isIDO &&
                      Number(project.contractInfo?.yourStake)
                    "
                  >
                    <button-component
                      class="project-participated-button investment-unstake-button"
                      @click="handleUnstake($event, project)"
                      :disabled="isUnstakeBtnDisabled"
                      font-size="12px"
                      font-weight="600"
                    >
                      {{ unstakeBtnTxt }}
                    </button-component>
                  </template>
                  <template v-else-if="project.isActive">
                    <router-link
                      v-if="project.slug"
                      :to="{ name: 'Project', params: { slug: project.slug } }"
                    >
                      <button-component
                        class="project-participated-button investment-manage-button"
                        font-size="12px"
                        font-weight="600"
                      >
                        {{ i18n["manage-button-label"] }}
                      </button-component>
                    </router-link>
                  </template>
                  <template v-if="project.chatLink">
                    <a
                      style="color: #000000; line-height: 35px"
                      :href="`${project.chatLink}`"
                      target="_blank"
                      >Join chat</a
                    >
                  </template>
                  <template v-else> </template>
                  <!--
                    @elseif ($project->claim_proofs && $project->ableToClaim())
                        @if (auth()->user()->projectClaimed()->where('project_id', $project->id)->count() === 0)
                            @php($proofsArray = array_change_key_case(json_decode($project->claim_proofs, true)['proofs'] ?? []))
                            @php($addressCanClaim = isset($proofsArray[auth()->user()->erc20_address]))
                            @if ($addressCanClaim)
                                @php($proofs = $proofsArray[auth()->user()->erc20_address])
                                <button class="project-participated-button investment-claim-button hide" data-claim-contract-address="{{ $project->claim_contract_address }}"
                                        data-claim-pool-id="{{ $project->claim_pool_id }}"
                                        data-amount="{{ $proofs['amount'] }}" data-proofs="{{ json_encode($proofs['proof']) }}"
                                        data-claim-url="{{ route('users.claim') }}"
                                        data-project-id="{{ $project->id }}">
                                    {{ i18n['claim-button-label'] }}
                                </button>
                            @endif
                        @endif
                    @endif -->
                </td>
              </tr>
              <tr class="sep">
                <td colspan="7">
                  <hr />
                </td>
              </tr>
            </template>
            <template v-if="userOldInvestments && userOldInvestments.length">
              <template
                v-for="oldInvestment in userOldInvestments"
                :key="oldInvestment.id + '-old'"
              >
                <tr class="old-project-row">
                  <td class="project-participated-cell title">
                    {{ oldInvestment.name }}
                  </td>
                  <td class="project-participated-cell">Legacy</td>
                  <td class="project-participated-cell">
                    {{
                      Intl.NumberFormat("en-US").format(oldInvestment.invested)
                    }}
                    USDT
                  </td>
                  <td class="project-participated-cell">
                    <template v-if="oldInvestment.earned !== 'TBA'">
                      {{ oldInvestment.earned }} {{ oldInvestment.token }}
                    </template>
                    <template v-else>
                      {{ oldInvestment.earned }}
                    </template>
                  </td>
                  <td class="project-participated-cell">
                    <template v-if="oldInvestment.claimed !== '-'">
                      {{
                        new Intl.NumberFormat("en-US").format(
                          oldInvestment.claimed
                        )
                      }}
                      {{ oldInvestment.token }}
                      <br />
                      ({{ oldInvestment.percentage_sent }}%)
                    </template>
                    <template v-else>
                      {{ oldInvestment.claimed }}
                    </template>
                  </td>
                  <td
                    class="project-participated-cell"
                    v-html="oldInvestment.next_batch"
                  ></td>
                  <td class="project-participated-cell">-</td>
                </tr>
              </template>
            </template>
          </template>
          <template v-else-if="requestStatus === 'loaded'">
            <tr class="project-participated-table-empty">
              <td class="empty-row" colspan="7">
                <div class="empty-row__text">
                  Your investments will appear here
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-for="value in [1, 2, 3, 4, 5, 6, 7]" :key="value">
              <tr class="project-participated-table-row-loading">
                <td class="px-25">
                  <div class="skeleton-loading skeleton-loading--circle"></div>
                </td>
                <td class="pr-25">
                  <div class="skeleton-loading"></div>
                  <div
                    class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                    class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                    class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                    class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                    class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
                <td class="px-25">
                  <div class="skeleton-loading"></div>
                  <div
                    class="skeleton-loading skeleton-loading--secondary"
                  ></div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import ButtonComponent from "@/components/Button";
import LogoCell from "@/components/investment-table/LogoCell";
import { calculateEstimation } from "@/helpers";

export default {
  name: "InvestmentsTable",
  components: {
    LogoCell,
    ButtonComponent,
  },
  data() {
    return {
      columns: [
        {
          name: "Pool",
        },
        {
          name: "Status",
        },
        {
          name: "Invested",
        },
        {
          name: "Earned",
        },
        {
          name: "Claimed",
        },
        {
          name: "Next Claim",
        },
        {},
      ],
      items: [],
      unstakeBtnDefaultTxt: "Unstake",
      unstakeBtnTxt: "Unstake",
      isUnstakeBtnDisabled: false,
      unstakeIsEnabled: false
    };
  },
  setup() {
    const toast = useToast();
    return { toast, calculateEstimation };
  },
  created() {
    this.fetchUserInvestments();
  },
  computed: {
    ...mapState([
      "web3",
      "requestStatus",
      "i18n",
      "investments",
      "oldInvestments",
      "userProfile",
      "newTokenName",
    ]),
    ...mapGetters(["userInvestments", "userOldInvestments"]),
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    i18n(newVal, oldVal) {
      const local = newVal || oldVal;
      this.unstakeBtnDefaultTxt = local["unstake-button-label"];
      this.unstakeBtnTxt = local["unstake-button-label"];
    },
  },
  methods: {
    ...mapActions(["fetchUserInvestments", "updateInvestmentProjectPoolInfo"]),
    async handleUnstake(evt, project) {
      evt.preventDefault();

      let currentContractAddress = project.contractAddress;
      let poolId = project.poolId;
      const isIDO = project.isIDO;
      let currentContractAbi = JSON.parse(project.contractAbi);
      let currentContract = new this.web3.eth.Contract(
        currentContractAbi,
        currentContractAddress
      );
      const currentAccount = this.userProfile.userERCAddress;
      let yourStake = project.contractInfo.yourStake;

      yourStake = yourStake.replace(/,/g, "");

      if (currentAccount) {
        await this.$store.dispatch(
          "changeChain",
          await this.web3.eth.getChainId()
        );

        try {
          this.isUnstakeBtnDisabled = true;
          this.unstakeBtnTxt = "Unstaking...";

          if (isIDO) {
            await currentContract.methods.withdraw(poolId).send({
              from: currentAccount,
            });
          } else {
            await currentContract.methods
              .withdraw(poolId, this.web3.utils.toWei(yourStake))
              .send({
                from: currentAccount,
              });
          }
          this.unstakeBtnTxt = this.unstakeBtnDefaultTxt;
          this.toast.success("Unstaking transaction completed");

          await this.updateInvestmentProjectPoolInfo(project);
        } catch (e) {
          this.isUnstakeBtnDisabled = false;
          this.unstakeBtnTxt = this.unstakeBtnDefaultTxt;
          this.toast.error(e.message);
        }
      } else {
        this.toast.error(this.i18n["wallet-is-not-connected"]);
      }
    },
    navigateToClaimLink(evt, project) {
      evt.preventDefault();

      window.open(project.externalClaimLink, "_blank");
    },
    async handleClaim(evt, project) {
      evt.preventDefault();

      const claimBtn = evt.target;
      const currentClaimContractAddress = project.claimContractAddress;
      const claimPoolId = project.claimPoolId;
      const currentClaimContractAbi = project.claimContractAbi;
      const currentClaimContract = new this.web3.eth.Contract(
        currentClaimContractAbi,
        currentClaimContractAddress
      );
      const proofs = project.claimProofs;
      const amount = proofs[this.userProfile.userERCAddress];
      const defaultText = "Claim";

      try {
        claimBtn.disable = true;
        claimBtn.innerText = "Claiming...";
        await currentClaimContract.methods
          .claim(claimPoolId, amount.toString(), proofs)
          .send({
            from: this.userProfile.userERCAddress,
          });
        /*await axios.post('/users/claim', {
            project_id: project.id,
        });*/
        claimBtn.innerText = "Claimed";
        this.toast.success("Claim successful!");
      } catch (e) {
        this.toast.error(e.message);
        claimBtn.disable = false;
        claimBtn.innerText = defaultText;
        console.error(e);
      }
    },
    calculateClaimed(project) {
      if (
        Number(project.contractInfo?.earned) > 0 &&
        project.tokenSentPercentage > 0
      ) {
        return `${parseFloat(
          (
            (Number(project.contractInfo.earned) *
              project.tokenSentPercentage) /
            100
          ).toFixed(2)
        )} `;
      }

      return "- ";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.investments-wrapper {
  position: relative;
  width: 100%;
  max-width: 1280px;

  .behind-table-bg {
    position: absolute;
    top: -72px;
    right: 210px;
    z-index: 5;
    background-image: url("../assets/investments_behind_table_bg.svg");
    width: 237px;
    height: 219px;

    @include respond-to(sm) {
      background-image: url("../assets/investments_behind_table_bg_pad.svg");
      width: 181px;
      height: 181px;
      top: -60px;
      right: 60px;
    }

    @include respond-to(xs) {
      background-image: url("../assets/investments_behind_table_bg_mobile.svg");
      width: 136px;
      height: 136px;
      top: -45px;
      right: 45px;
    }
  }
}

.table-wrapper {
  position: relative;
  min-width: auto;
  width: 100%;
  display: block;
  overflow-x: scroll;
}

.investments-table {
  border-collapse: collapse;
  margin: 0 auto;
  min-width: 1280px;
  overflow: hidden;
  z-index: 10;
  position: relative;

  @include respond-to(xs) {
    min-width: initial;
    width: 100%;
    display: block;
    overflow-x: scroll;
  }

  thead {
    background: #ffffff;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    height: 51px;

    th {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.05em;
      color: #272727;
      font-style: normal;
      width: 170px;

      @include respond-to(xs) {
        font-size: 16px;
        padding: 0 25px;
      }
    }
  }

  tbody {
    background: #e5e7eb;
    border: 1px solid #000000;

    @keyframes sweep {
      100% {
        transform: translateX(100%);
      }
    }

    .px-25 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    .skeleton-loading {
      position: relative;
      overflow: hidden;
      background-color: #ccc;
      background-size: 1000%;
      background-position: right;
      border-radius: 20px;
      max-width: 150px;
      width: 100%;
      height: 12px;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: sweep 2s infinite;
        content: "";
      }

      &--secondary {
        width: 70%;
        max-width: 100px;
        margin-top: 10px;
      }

      &--circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    tr.sep {
      hr {
        width: 92%;
        border: 0;
        height: 1px;
        background: #e8e8e8;
      }

      &:last-child {
        display: none;
      }
    }

    tr:not(.sep) {
      height: 122px;

      .project-participated-cell {
        font-size: 18px;
        line-height: 27px;
        text-align: center;

        @include respond-to(xs) {
          font-size: 14px;
        }

        .block {
          display: block;
        }

        &.title {
          padding-left: 35px;
          text-align: left;
        }
      }

      .empty-row {
        &__text {
          font-size: 18px;
          line-height: 27px;
          align-items: center;
          text-align: center;
        }
      }

      .logo-cell {
        &-wrapper {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 130%;
          align-items: center;
          display: flex;
          flex-wrap: nowrap;
          margin-left: 47px;
          text-decoration: none;
          position: relative;
          color: initial;

          @include respond-to(xs) {
            font-size: 14px;
          }

          img,
          span {
            display: block;
          }

          span {
            line-height: 23px;
          }
        }
      }

      .actions-cell {
        padding-right: 46px;
        text-align: center;

        .project-participated-button {
          height: 30px;
          width: 100px;
          text-transform: uppercase;
          padding: initial;
          background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
          box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

          &.investment-manage-button {
            //background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
          }

          &.investment-unstake-button {
            //background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
          }

          &.investment-claim-button {
            //background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
          }
        }
      }
    }
  }
}

.hide {
  display: none !important;
}
</style>
