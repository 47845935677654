import { API } from "@/api";
// import { web3Modal, mainnetRpc } from "@/services/web3modal";
// import { mainnetRpc } from "@/services/web3modal";
import { EthereumProvider } from "@walletconnect/ethereum-provider";

import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { getReferralData } from "@/helpers";
import {RESET_PROJECTS, SET_LEADERBOARD_DATA, SET_USER_STAKES} from "@/store/mutation-types";
import ATD_TOKEN_ABI from "@/util/constants/contractsABI/atdToken.json";
import etherium from "@/util/constants/networks/etherium.json";
import binance from "@/util/constants/networks/binance.json";

import STAKING_ABI from "@/util/constants/contractsABI/staking.json";
// const  ATD_ADDRESS = process.env.VUE_APP_MAIN_TOKEN_CONTRACT_ADDRESS;

const providers = {
  metaMask: "metamask",
  walletConnect: "walletConnect",
};

const actions = {
  async refreshToken({ commit, dispatch }) {
    const tokenResponse = await API.post("/auth/refresh");

    if (tokenResponse?.data?.access_token) {
      //saveToStore('access_token', tokenResponse?.data?.access_token);
      commit("SET_ACCESS_TOKEN", tokenResponse.data.access_token);
      commit("SET_TOKEN_EXPIRATION", Date.now() + tokenResponse.data.expires_in * 1000);
      await dispatch("fetchUserProfile");
    }
  },
  async connectWallet({ commit, dispatch, state }) {
    await dispatch("determineWeb3Provider");

    const { data } = await API.get("/auth/signature");
    let web3 = state.web3;
    const referralPayload = getReferralData();
    await window.ethereum.enable();
    const address = (await web3.eth.getAccounts())[0];
    const signature = await web3.eth.personal.sign(data.message, address);
    let requestPayload = {
      nonce: data.code,
      address,
      signature,
    };

    if (referralPayload) {
      requestPayload = {
        ...requestPayload,
        ...referralPayload,
      };

      localStorage.removeItem("referralInfo");
    }

    const tokenResponse = await API.post("/auth/authenticate", requestPayload);

    if (tokenResponse?.data?.access_token) {
      //saveToStore('access_token', tokenResponse?.data?.access_token);
      commit("SET_ACCESS_TOKEN", tokenResponse.data.access_token);
      commit("SET_TOKEN_EXPIRATION", Date.now() + tokenResponse.data.expires_in * 1000);
      await dispatch("setTokenContract");
      await dispatch("fetchUserProfile");
    }
  },
  async connectWalletConnect({ commit, dispatch, state }) {
    await dispatch("determineWeb3ProviderWalletConnect");

    const { data } = await API.get("/auth/signature");

    let web3 = state.web3;
    const referralPayload = getReferralData();

    const address = (await web3.eth.getAccounts())[0];
    setTimeout(async () => {
      //const signature = await web3.eth.personal.sign(data.message, address);

      let requestPayload = {
        nonce: data.code,
        address: address,
        signature: null,
        providerName: 'walletconnect'
      };

      if (referralPayload) {
        requestPayload = {
          ...requestPayload,
          ...referralPayload,
        };

        localStorage.removeItem("referralInfo");
      }

      const tokenResponse = await API.post(
        "/auth/authenticate",
        requestPayload
      );

      if (tokenResponse?.data?.access_token) {
        //saveToStore('access_token', tokenResponse?.data?.access_token);
        commit("SET_ACCESS_TOKEN", tokenResponse.data.access_token);
        commit("SET_TOKEN_EXPIRATION", Date.now() + tokenResponse.data.expires_in * 1000);
        await dispatch("setTokenContract");
        await dispatch("fetchUserProfile");
      }
    }, 100);
  },
  async logOut({ commit }) {
    // TODO !!!
    //await API.post('/auth/logout');
    commit("SET_ACCESS_TOKEN", '');
    commit("SET_TOKEN_EXPIRATION", '');
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_expiration");
    localStorage.removeItem("provider");
    // state.walletConnectProvider.disconnect();

    localStorage.clear();
    commit("RESET_STATE");
  },
  async fetchUserProfile({ dispatch, commit }) {
    try {
      const response = await API.get("/user-profile");
      commit("SET_USER_PROFILE", response.data);
    } catch (error) {
      dispatch("logOut");
      console.error(error);
    }
  },
  async resetProjects({ commit }) {
    commit(RESET_PROJECTS);
  },
  async initWeb3({ commit }) {
    let provider;
    let providerName = localStorage.getItem("provider");

    if (providerName === providers.walletConnect) {
      provider = await EthereumProvider.init({
        projectId: process.env.VUE_APP_PROJECT_ID,
        chains: [56],
        showQrModal: true,
        optionalMethods: [
          "eth_signTypedData",
          "eth_signTypedData_v4",
          "eth_sign",
        ],
      });
      await provider.enable();
    } else if (providerName === providers.metaMask) {
      provider = Web3.givenProvider;
    }

    const web3 = new Web3(provider);
    commit("SET_WEB3", {
      web3: web3,
      providerName,
      provider,
    });

    const blockchainInfoWeb3 = new Web3(process.env.VUE_APP_NETWORK_URL);
    commit("SET_BLOCKCHAIN_INFO_WEB3", {
      blockchainInfoWeb3,
    });
  },
  async determineWeb3Provider({ commit }) {
    try {
      // const provider = await web3Modal.connect();
      const provider = await detectEthereumProvider();

      let providerName = providers.metaMask;

      if (provider.isWalletConnect) {
        providerName = providers.walletConnect;
      }

      localStorage.setItem("provider", providerName);
      commit("SET_WEB3", {
        web3: new Web3(provider),
        providerName,
        provider,
      });

      const blockchainInfoWeb3 = new Web3(process.env.VUE_APP_NETWORK_URL);
      commit("SET_BLOCKCHAIN_INFO_WEB3", {
        blockchainInfoWeb3,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async determineWeb3ProviderWalletConnect({ commit }) {
    try {
      const provider = await EthereumProvider.init({
        projectId: process.env.VUE_APP_PROJECT_ID,
        chains: [56],
        showQrModal: true,
        optionalMethods: [
          "eth_signTypedData",
          "eth_signTypedData_v4",
          "eth_sign",
        ],
      });
      commit("WALLETCONNECT_PROVIDER", provider);
      await provider.connect();
      let providerName = providers.metaMask;
      if (provider.isWalletConnect) {
        providerName = providers.walletConnect;
      }

      localStorage.setItem("provider", providerName);
      commit("SET_WEB3", {
        web3: new Web3(provider),
        providerName,
        provider,
      });

      const blockchainInfoWeb3 = new Web3(process.env.VUE_APP_NETWORK_URL);
      commit("SET_BLOCKCHAIN_INFO_WEB3", {
        blockchainInfoWeb3,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async setTokenContract({ state, commit }) {
    commit(
      "SET_TOKEN_CONTRACT",
      new state.web3.eth.Contract(
        state.CPLTContractAbi,
        state.CPLTContractAddress
      )
    );
    commit(
      "SET_TOKEN_CONTRACT_INFO",
      new state.blockchainInfoWeb3.eth.Contract(
        state.CPLTContractAbi,
        state.CPLTContractAddress
      )
    );
    commit(
      "SET_OLD_TOKEN_CONTRACT_INFO",
      new state.blockchainInfoWeb3.eth.Contract(
        state.ATDContractAbi,
        state.ATDContractAddress
      )
    );
  },
  async fetchProjectContractInfo({ state }, project) {
    try {
      const web3 = state.blockchainInfoWeb3;
      const currentAccount = state.userProfile.userERCAddress;
      const {
        contractAbi,
        contractAddress,
        isIDO,
        isOTC,
        isOTCOnMarket,
        isIDOAdvanced,
        poolId,
        tokenPrice,
        isTrueYieldStaking,
      } = project;

      let currentContract = new web3.eth.Contract(
        JSON.parse(contractAbi),
        contractAddress
      );
      const poolInfo = await currentContract.methods.poolInfo(poolId).call();
      // let subProjectPoolInfo;
      // if (project.subProject && project.subProject.poolId !== poolId) {
      //   subProjectPoolInfo = await currentContract.methods
      //     .poolInfo(project.subProject.poolId)
      //     .call();
      // }
      let tokenContract = state.tokenContractInfo;
      const info = {
        // totalStaked: web3.utils.fromWei(
        //   project.subProject && project.subProject.poolId !== poolId
        //     ? (
        //         +poolInfo.totalStaked + +subProjectPoolInfo.totalStaked
        //       ).toString()
        //     : poolInfo.totalStaked.toString()
        // ),
        totalStaked:
           project.totalInvestments,

        yourStake: "--",
        earned: "--",
        atdBalance: 0,
        userParticipated: false,
        idoTokenAddress: "",
        refunded: "--",
      };

      if (project.currency === "ATD") {
        tokenContract = state.oldTokenContractInfo;
      }
      if (currentAccount) {
        // info.yourStake = web3.utils.fromWei(await currentContract.methods.userStaked(poolId, currentAccount).call());
        info.yourStake = project.userProjectInfo.invested || 0;
        info.refunded = project.userProjectInfo.refunded || 0;
        if (
          !isIDO &&
          !isOTC &&
          !isOTCOnMarket &&
          !isIDOAdvanced &&
          !isTrueYieldStaking
        ) {
          // info.earned = web3.utils.fromWei(await currentContract.methods.earned(poolId, currentAccount).call());
          info.earned = project.userProjectInfo.invested;
        } else {
          if (isIDOAdvanced) {
            const {
              contractAddress: IDOCurrentAddress,
              // poolId: IDOPoolId,
              contractAbi: IDOCurrentContractAbi,
            } = project.subProject;
            const IDOCurrentContract = new web3.eth.Contract(
              IDOCurrentContractAbi,
              IDOCurrentAddress
            );

            // const yourInvested = web3.utils.fromWei(await IDOCurrentContract.methods.userStaked(IDOPoolId, currentAccount).call());
            const yourInvested = project.userProjectInfo.invested;

            info.atdBalance = web3.utils.fromWei(
              await IDOCurrentContract.methods.balanceOf(currentAccount).call()
            );

            if (tokenPrice) {
              info.earned =
                yourInvested /
                parseFloat(tokenPrice.toString().match(/\d+[\\.,]?\d+/));
            }
          } else {
            const IDOCurrentContract = new web3.eth.Contract(
              state.CPLTContractAbi,
              poolInfo.token
            );

            info.atdBalance = web3.utils.fromWei(
              await IDOCurrentContract.methods.balanceOf(currentAccount).call()
            );
            info.idoTokenAddress = poolInfo.token;

            if (tokenPrice) {
              info.earned =
                info.yourStake /
                parseFloat(tokenPrice.toString().match(/\d+[\\.,]?\d+/));
            }
          }
        }
        try {
          if (
            !isIDO &&
            !isOTC &&
            !isOTCOnMarket &&
            !isIDOAdvanced &&
            !isTrueYieldStaking
          ) {
            info.atdBalance = web3.utils.fromWei(
              await tokenContract.methods.balanceOf(currentAccount).call()
            );
          }
        } catch (e) {
          console.error(e.message);
        }
      }

      if (Number(info.earned) !== 0 && info.earned !== "--") {
        info.userParticipated = true;
      }
      // info.yourStake = 300000
      // let tokenPrices = 1.5
      // if (tokenPrices) {
      //   info.earned = info.yourStake / parseFloat(tokenPrices.toString().match(/\d+[\\.,]?\d+/));
      // }
      // info.refunded = 100000
      // info.userParticipated = true;
      return info;
    } catch (error) {
      console.error(error);
    }
  },
  async isUserAbleToClaimProject(_, id) {
    try {
      const response = await API.get("/user-able-claim", {
        params: {
          project_id: id,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateInvestmentProjectPoolInfo({ dispatch, commit }, project) {
    project.contractInfo = await dispatch("fetchProjectContractInfo", project);
    commit("UPDATE_INVESTMENT_PROJECT_CONTRACT_INFO", project);
  },
  async fetchUserInvestments({ dispatch, commit, state }) {
    commit("SET_PENDING_REQUEST_STATUS");
    try {
      const response = await API.get("/investments");
      let projectContractInfos;
      let result;
      await dispatch("initWeb3");

      if (!state.userAccountAddress) {
        await dispatch("fetchUserProfile");
      }

      if (response.data) {
        dispatch("setTokenContract");
        projectContractInfos = await Promise.allSettled(
          response.data.projects.map(async (project) => {
            project.contractInfo = await dispatch(
              "fetchProjectContractInfo",
              project
            );

            if (project.claimProofs) {
              project.isUserAbleToCLaim = await dispatch(
                "isUserAbleToClaimProject",
                project.id
              );
              project.claimProofs = JSON.parse(project.claimProofs);
              Object.keys(project.claimProofs).map((key) => {
                if (key.toLowerCase() != key) {
                  project.claimProofs[key.toLowerCase()] =
                    project.claimProofs[key];
                  delete project.claimProofs[key];
                }
              });
              project.addressCanClaim =
                project.claimProofs[state.userProfile.userERCAddress];
            }
            return project;
          })
        );
        result = projectContractInfos.map((promiseData) => {
          return promiseData.value;
        });

        commit("SET_USER_OLD_INVESTMENTS", response.data.oldProjects);
      }

      commit("SET_USER_INVESTMENTS", result);
      commit("SET_LOADED_REQUEST_STATUS");
    } catch (error) {
      //alert(error);
      console.error(error);
    }
  },
  async fetchUserActivity({ commit }) {
    try {
      const response = await API.get("/user-activity");
      let sortData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date))
      commit("SET_USER_ACTIVITY", sortData);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchProjectsForHomePage({ commit }) {
    try {
      const response = await API.get("/projects/home-page");

      commit("SET_PROJECTS", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCompletedProjects({ commit }, page = 1) {
    try {
      const response = await API.get("/projects/completed", {
        params: {
          page: page,
        },
      });

      commit("SET_PROJECTS", { completed: response.data.data });
      commit("SET_PAGINATION_META", response.data.meta);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchOTCProjects({ commit }, {page = 1, state = 'active'}) {
    try {
      const response = await API.get("/projects/otc", {
        params: {
          page: page,
          state: state
        },
      });
      commit("SET_PROJECTS_OTC_PAGE", response.data.data);
      commit("SET_PAGINATION_META", response.data.meta);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchOTCOnMarketProjects({ commit }, {page = 1, state = 'active'}) {
    try {
      const response = await API.get("/projects/otcOnMarket", {
        params: {
          page: page,
          state: state
        },
      });
      commit("SET_PROJECTS_OTC_PAGE", response.data.data);
      commit("SET_PAGINATION_META", response.data.meta);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchStakingProjects({ commit }, page = 1) {
    try {
      const response = await API.get("/projects/staking", {
        params: {
          page: page,
        },
      });

      commit("SET_PROJECTS", { staking: response.data.data });
      commit("SET_PAGINATION_META", response.data.meta);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchWC2022Projects({ commit }, page = 1) {
    try {
      const response = await API.get("/projects/wc2022", {
        params: {
          page: page,
        },
      });

      commit("SET_PROJECTS", { wc2022: response.data.data });
      commit("SET_PAGINATION_META", response.data.meta);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchI18N({ commit }) {
    try {
      const response = await API.get("/i18n/all");

      commit("SET_I18N", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchSocialNetworks({ commit }) {
    try {
      const response = await API.get("/social-networks");

      commit("SET_SOCIAL_NETWORKS", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchLeaderboard({ commit }) {
    try {
      const response = await API.get("/leaderboard");

      commit(SET_LEADERBOARD_DATA, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line
  async setProjectForUser({ commit }, { projectId, stakeAmount }) {
    try {
      await API.post("/user/participated", {
        projectId,
        stakeAmount,
      });
    } catch (error) {
      console.error(error);
    }
  },
  changeChain({ dispatch, state }, chainId) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch("initWeb3");
      let chainName = "BNB Smart Chain Mainnet";
      let currencySymbol = "BNB";

      if (state.provider) {
        let params = [];

        if (process.env.NODE_ENV === "development") {
          chainName = "BNB Smart Chain Testnet";
          currencySymbol = "tBNB";
        }

        if (+chainId !== +process.env.VUE_APP_CHAIN_ID) {
          params = [
            {
              chainId: process.env.VUE_APP_CHAIN_ID_HEX,
              chainName: chainName,
              nativeCurrency: {
                name: currencySymbol,
                symbol: currencySymbol,
                decimals: 18,
              },
              rpcUrls: [process.env.VUE_APP_NETWORK_URL],
              blockExplorerUrls: [process.env.VUE_APP_BLOCKEXPLORER_URL],
            },
          ];
        }

        if (params.length > 0) {
          try {
            await state.provider.request({
              method: "wallet_addEthereumChain",
              params,
            });
            resolve(true);
          } catch (e) {
            reject(e);
          }
        }

        resolve(true);
      } else {
        reject("Browser is not compatible.");
      }
    });
  },
  changeChainToETH({ dispatch, state, commit }, chainId) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch("initWeb3");

      // let chainName = "Ethereum Mainnet";
      // let currencySymbol = "ETH";

      if (state.provider) {
        let params = [];
        commit("SET_CURRENT_NETWORK_DROPDOWN", "ETH");

        // if (process.env.NODE_ENV === "development") {
        //   chainName = "BNB Smart Chain Testnet";
        //   currencySymbol = "tBNB";
        // }

        if (+chainId !== 1) {
          params = [
            {
              chainId: "0x1",
            },
          ];
        }

        if (params.length > 0) {
          try {
            await state.provider.request({
              method: "wallet_switchEthereumChain",
              params,
            });
            resolve(true);
          } catch (e) {
            commit("SET_CURRENT_NETWORK_DROPDOWN", "BNB");

            reject(e);
          }
        }

        resolve(true);
      } else {
        reject("Browser is not compatible.");
      }
    });
  },
  changeChainToBNB({ dispatch, state, commit }, chainId) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch("initWeb3");

      // let chainName = "Ethereum Mainnet";
      // let currencySymbol = "ETH";
      if (state.provider) {
        commit("SET_CURRENT_NETWORK_DROPDOWN", "BNB");

        let params = [];

        // if (process.env.NODE_ENV === "development") {
        //   chainName = "BNB Smart Chain Testnet";
        //   currencySymbol = "tBNB";
        // }

        if (+chainId !== 56) {
          params = [
            {
              chainId: "0x38",
            },
          ];
        }

        if (params.length > 0) {
          try {
            await state.provider.request({
              method: "wallet_switchEthereumChain",
              params,
            });
            resolve(true);
          } catch (e) {
            commit("SET_CURRENT_NETWORK_DROPDOWN", "ETH");
            reject(e);
          }
        }

        resolve(true);
      } else {
        reject("Browser is not compatible.");
      }
    });
  },
  async fetchBlogCategories({ commit }) {
    try {
      const response = await API.get("/blog-categories");
      commit("SET_BLOG_CATEGORIES", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchBlogPosts({ commit }, category = null) {
    try {
      const response = await API.get("/blog-posts", {
        params: {
          category: category,
        },
      });

      commit("SET_BLOG_POSTS_BY_CATEGORY", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line
  async updateProjectEndTime({ commit }, slug) {
    try {
      await API.post(`/projects/${slug}/update-end-time`);
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line
  async getBanners({ commit }) {
    try {
      const response = await API.get("/banners");

      commit("SET_BANNERS", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line
  async fetchBlogPostsHomePage({ commit }) {
    try {
      const response = await API.get("/blogs-homepage");

      commit("SET_BLOG_POSTS_HOME_PAGE", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchIndustriesInnovationPage({ commit }) {
    try {
      const { data } = await API.get(`/innovation-industries`);
      commit("SET_INDUSTRIES_INNOVATION_PAGE", data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchProjectsInnovationPage({ commit }, slug) {
    try {
      const { data } = await API.get("/innovation-projects", {
        params: {
          industry: slug,
        },
      });
      commit("SET_PROJECTS_INNOVATION_PAGE", data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchPartners({ commit }) {
    try {
      const response = await API.get("/partners");

      commit("SET_PARTNERS_DATA", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line
  async setExpressInterest({ commit }, payload) {
    try {
      await API.post(`/user/set-interest`, payload);
    } catch (error) {
      console.error(error);
    }
  },
  async getReadMethods({ state, commit }, network) {
    let web3 = state.web3;
    const currentAccount = state.userProfile.userERCAddress;
    let contract;

    if (network === "ETH") {
      contract = new web3.eth.Contract(ATD_TOKEN_ABI, etherium.atdToken);
    } else if (network === "BNB") {
      contract = new web3.eth.Contract(ATD_TOKEN_ABI, binance.atdToken);
    }
    const balanceOf = await contract.methods.balanceOf(currentAccount).call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    commit("SET_ATD_TOKEN_DATA", {
      balance: balanceOf,
      symbol: symbol,
      decimals: decimals,
    });
  },
  async getAtdToken({ state, commit }, { value, coefficient, network }) {
    let points = value / coefficient;
    let web3 = state.web3;
    let success = false;
    let amount = web3.utils.toWei(value.toString());
    const currentAccount = state.userProfile.userERCAddress;
    let contract;
    if (network === "ETH") {
      contract = new web3.eth.Contract(ATD_TOKEN_ABI, etherium.atdToken);
    } else if (network === "BNB") {
      contract = new web3.eth.Contract(ATD_TOKEN_ABI, binance.atdToken);
    }
    try {
      const gasLimit = await contract.methods
        .burn(amount)
        .estimateGas({ from: currentAccount });
      const gasPrice = await web3.eth.getGasPrice();

      await contract.methods
        .burn(amount.toString())
        .send({
          from: currentAccount,
          gasLimit: Math.round(gasLimit * 1.1),
          gasPrice: gasPrice,
        });

      commit("BURN_LOADING", false);
      success = true;
    } catch (e) {
      commit("BURN_LOADING", false);

      console.log(e);
    }
    if (success) {
      await API.post("/set-atd-boost", { atdBoostPoints: points });
    }
  },
  async stakeAtdToken(
    { state, commit },
    { value, coefficient, poolId, network }
  ) {
    let web3 = state.web3;
    let success = false;
    let amount = web3.utils.toWei(value.toString());

    let amountMonth;
    if (poolId === 1) {
      amountMonth = 12;
    } else if (poolId === 2) {
      amountMonth = 6;
    } else if (poolId === 3) {
      amountMonth = 3;
    }
    let points = (value / coefficient / 12) * amountMonth;
    let initialBonusPoints =
      points * (amountMonth === 3 ? 0 : amountMonth === 12 ? 0.25 : 0.1);
    initialBonusPoints = initialBonusPoints.toFixed(4);

    const currentAccount = state.userProfile.userERCAddress;
    let contract;

    if (network === "ETH") {
      contract = new web3.eth.Contract(STAKING_ABI, etherium.stakingAddress);
    } else if (network === "BNB") {
      contract = new web3.eth.Contract(STAKING_ABI, binance.stakingAddress);
    }

    try {
      const gasLimit = await contract.methods
        .stake(poolId.toString(), amount)
        .estimateGas({ from: currentAccount });
      const gasPrice = await web3.eth.getGasPrice();
      await contract.methods
        .stake(poolId.toString(), amount)
        .send({
          from: currentAccount,
          gasLimit: Math.round(gasLimit * 1.1),
          gasPrice: gasPrice,
        });
      commit("STAKE_LOADING", false);
      success = true;
    } catch (e) {
      commit("STAKE_LOADING", false);

      console.log(e);
    }
    if (success) {
      await API.post("/user/leaderboard-stake", {
        initialBonusPoints: initialBonusPoints,
        stakeAmount: value,
        period: amountMonth,
        coefficient: coefficient,
      });
    }
  },
  async likeProject({ state, commit }, projectId) {
    try {
      const { data } = await API.post("/innovation-labs/like", {
        project_id: projectId,
      });

      commit("SET_USER_PROFILE", {
        ...state.userProfile,
        projectLikes: [...state.userProfile.projectLikes, projectId],
      });
      commit("SET_СURRENT_LIKES_FOR_PROJECT", {
        likes: data.likes,
        projectId: projectId,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async dislikeProject({ state, commit }, projectId) {
    try {
      const { data } = await API.post("/innovation-labs/like", {
        project_id: projectId,
      });

      commit("SET_USER_PROFILE", {
        ...state.userProfile,
        projectLikes: state.userProfile.projectLikes.filter(
          (value) => value !== projectId
        ),
      });
      commit("SET_СURRENT_LIKES_FOR_PROJECT", {
        likes: data.likes,
        projectId: projectId,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async interestProject({ state, commit }, projectId) {
    try {
      const { data } = await API.post("/innovation-labs/interest", {
        project_id: projectId,
      });

      commit("SET_USER_PROFILE", {
        ...state.userProfile,
        projectInterests: [...state.userProfile.projectInterests, projectId],
      });
      commit("SET_СURRENT_INTERESTS_FOR_PROJECT", {
        interests: data.interests,
        projectId: projectId,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async uninterestProject({ state, commit }, projectId) {
    try {
      const { data } = await API.post("/innovation-labs/interest", {
        project_id: projectId,
      });

      commit("SET_USER_PROFILE", {
        ...state.userProfile,
        projectInterests: state.userProfile.projectInterests.filter(
          (value) => value !== projectId
        ),
      });
      commit("SET_СURRENT_INTERESTS_FOR_PROJECT", {
        interests: data.interests,
        projectId: projectId,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async getReadMethodsStake({ state, commit }, network)
  {
    let web3 = state.web3;
    const currentAccount = state.userProfile.userERCAddress;
    let contract;

    if (network === "ETH") {
      contract = new web3.eth.Contract(STAKING_ABI, etherium.stakingAddress);
    } else if (network === "BNB") {
      contract = new web3.eth.Contract(STAKING_ABI, binance.stakingAddress);
    }
    let userStakes = {}
    for (let i = 1; i <= 3; i++) {
      const userStakesAndPenalties = await contract.methods.userStakesAndPenalties(currentAccount,i).call();
      userStakes[i] = {
        penalties: userStakesAndPenalties.penalties,
        stakeAmounts: userStakesAndPenalties.stakeAmounts,
        stakeStarts: userStakesAndPenalties.stakeStarts,
        period: (12 / i) === 4 ? 3 : 12/i,
        poolId: i,
      }
    }
    commit(SET_USER_STAKES, userStakes)

  },
  async unStakeAtdToken(
      { state, commit },
      { poolId, stakeId, amount, network }
  ) {
    // console.log(poolId, stakeId, amount, network)
    let web3 = state.web3;
    // let success = false;
    let amountContract = web3.utils.toWei(amount.toString());


    const currentAccount = state.userProfile.userERCAddress;
    let contract;
    //
    if (network === "ETH") {
      contract = new web3.eth.Contract(STAKING_ABI, etherium.stakingAddress);
    } else if (network === "BNB") {
      contract = new web3.eth.Contract(STAKING_ABI, binance.stakingAddress);
    }

    try {
      const gasLimit = await contract.methods
          .unstakeExactStake(poolId.toString(),stakeId.toString(), amountContract)
          .estimateGas({ from: currentAccount });
      const gasPrice = await web3.eth.getGasPrice();
      await contract.methods
          .unstakeExactStake(poolId.toString(),stakeId.toString(), amountContract)
          .send({
            from: currentAccount,
            gasLimit: Math.round(gasLimit * 1.1),
            gasPrice: gasPrice,
          });
      commit("UNSTAKE_LOADING", false);
      // success = true;
    } catch (e) {
      commit("UNSTAKE_LOADING", false);

      console.log(e);
    }
  },
};



export default actions;
