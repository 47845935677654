<template>
  <slot name="top-section" />
  <div class="content" :class="{ 'about-us': aboutUs }">
    <div class="app-content-wrapper">
      <slot />
    </div>
  </div>
  <div class="footer-wrapper no-gutters" :class="{ 'about-us': aboutUs }">
    <h2 v-if="!aboutUs" class="footer-wrapper-text">
      See you in the A2DAO community!
      <h2 class="footer-wrapper-text-shadow">See you in the A2DAO community!</h2>
    </h2>
    <span class="footer-wrapper-txt" :class="{ 'about-us': aboutUs }"

      >Feel free to contact us at <b>info@a2dao.com</b></span
    >
    <div v-if="!aboutUs" class="cloud-bg" :class="{ 'about-us': aboutUs }">
    </div>
    <div class="footer-bg" :class="{ 'about-us': aboutUs }">
    </div>
    <img class="logo" src="../assets/logo-a.svg"  alt="" :class="{ 'about-us': aboutUs }">

<!--    <div class="footer-upper-txt" :class="{ 'about-us': aboutUs }">-->
<!--    <span-->
<!--      >See you in the A2DAO community! See you in the A2DAO community! See you in-->
<!--      the A2DAO community!</span-->
<!--    >-->
<!--    </div>-->
    <div class="footer-content-wrapper" :class="{ 'about-us': aboutUs }">
      <footer-component />
    </div>
  </div>
  <profile-modal />
  <slot name="modals" />
</template>

<script>
import FooterComponent from "@/components/Footer";
import ProfileModal from "@/components/ProfileModal";
import { mapState } from "vuex";

export default {
  name: "MainLayout",
  components: {
    FooterComponent,
    ProfileModal,
  },
  props: {
    aboutUs: Boolean,
  },
  computed: {
    ...mapState(["modalData"]),
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    modalData(newVal, oldVal) {
      if (newVal?.modalID) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.content {
  position: relative;
  z-index: 12;
  display: flex;
  justify-content: center;
  background: #f3f3f3;
  padding-bottom: 700px;

  @include respond-to(xs) {
    padding-bottom: 350px;
  }

  @include respond-to(sm) {
    padding-bottom: 400px;
  }

  &.about-us {
    padding-bottom: 138px;
  }
}

.meteors-bg {
  background-image: url("../assets/meteors-body.svg");
  width: 457px;
  height: 295px;
  position: absolute;
  right: 14px;
  top: 1105px;

  @include respond-to(xs) {
    width: 220px;
    height: 133px;
    background-size: contain;
    top: 1388px;
    right: 0;
    background-position: 37px 0;
    background-repeat: no-repeat;
  }

  @include respond-to(sm) {
    width: 336px;
    height: 245px;
    right: 0;
    top: 733px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.footer-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #e5e7eb;
  position: relative;
  padding-bottom: 200px;
  z-index: 12;
  @include respond-to(xs) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include respond-to(sm) {
    padding-top: 0px;
    padding-bottom: 90px;
  }


  &.about-us {
  }
  h2 {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 113.5%;
    letter-spacing: -0.045em;
    color: #fff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    @include respond-to(xs) {
      font-size: 26px;
    }
  }
  &-text {
    position: relative;
    top: -350px;
    z-index: 100;
    @include respond-to(xs) {
      top: -200px;
      font-size: 18px;
      max-width: 300px;
      margin-top: 60px;
      letter-spacing: 0.045em;
    }

    @include respond-to(sm) {
      top: -280px;

      font-size: 24px;
      max-width: 400px;
      margin-top: 25px;
      letter-spacing: 0.045em;
    }
    &-shadow {
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 2px;
      left: 1.5px;
      z-index: -1;


    }
    @include respond-to(xs) {
      font-size: 18px;
      max-width: 300px;
      margin-top: 60px;
      letter-spacing: 0.045em;
    }

    @include respond-to(sm) {
      font-size: 24px;
      max-width: 400px;
      margin-top: 25px;
      letter-spacing: 0.045em;
    }
  }
  &-txt {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 139.5%;
    letter-spacing: -0.05em;
    color: #000000;
    z-index: 20;
    top: -233px;
    left: 50%;
    transform: translate(-50%, 0);
    /*max-width: 434px;*/
    text-align: center;
    position: absolute;

    @include respond-to(sm) {
      font-size: 22px;
      top: -180px;
    }

    @include respond-to(xs) {
      font-size: 14px;
      top: -120px;
    }

    &.about-us {
      display: none;
    }
  }
  .cloud-bg{
    background-image: url("../assets/clouds.svg");
    background-size: cover;
    height: 326px;
    position: absolute;
    top: -590px;
    width: 100%;
    z-index: 17;
    background-position-x: 50%;
    background-position-y: -19px;
    overflow: hidden;
    white-space: nowrap;


    @include respond-to(xs) {
      height: 142px;
      top: -213px;
    }

    @include respond-to(sm) {
      height: 142px;
      top: -313px;
    }
  }
  .footer-bg {
    background-image: url("../assets/footer-mount-img.svg");
    background-size: cover;
    height: 242px;
    position: absolute;
    top: -221px;
    width: 100%;
    z-index: 17;
    background-position-x: 50%;
    background-position-y: -19px;
    overflow: hidden;
    white-space: nowrap;
    @media (min-width: 1850px) {
      max-height: 221px;
      height: 100%;
    }



    &.about-us {
      display: none;
      background-image: url("../assets/footer-top-img.svg");
      width: 100%;
      z-index: 17;
      height: 258px;
      position: absolute;
      top: -55px;

      @include respond-to(xs) {
        background-image: url("../assets/footer-top-img_mobile.svg");
        top: -20px;
        height: 102px;
      }

      @include respond-to(sm) {
        background-image: url("../assets/footer-top-img_pad.svg");
        top: -33px;
        height: 154px;
      }
    }

  }

  .logo {
    height: 396px;
    width: 400px;
    animation: spin 3s steps(3, start) 0s infinite normal;

    position: absolute;
    top: -300px;
    left: 60%;
    z-index: 15;
    overflow: hidden;

    @include respond-to(sm) {
      width: 25vw;
      top: -90%;
    }
    @include respond-to(xs) {
      width: 20vw;
      left: 70%;
      top: -45%;
    }
    &.about-us {
      display: none;
    }

    img {
      @keyframes spin {
        0%, 2% {
          transform: translate(0, 0);
        }
        50% {
          transform: translate(0, 2%);
        }
      }
    }
  }
  .pixel {
    height: 250px;
    width: 250px;
    position: absolute;
    top: -190px;
    left: 75%;
    z-index: 14;

    @include respond-to(sm) {
      width: 15vw;

    }
    @include respond-to(xs) {
      width: 15vw;
      left: 80%;
    }

    &.about-us {
      display: none;
    }
  }

  .footer-upper-txt {
    z-index: 20;
    display: flex;
    overflow: hidden;
    height: 56px;
    background: #c4c4c4;
    text-align: center;
    margin-bottom: 50px;

    span {
      font-family: "Press Start 2P";
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 182%;
      letter-spacing: -0.005em;
      color: #ffffff;
      white-space: nowrap;
      animation: text 11s infinite linear;
      @keyframes text {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(-34%, 0);
        }
      }
    }

    @include respond-to(sm) {
      font-size: 18px;
      line-height: 45px;
      margin-bottom: 45px;
    }

    @include respond-to(xs) {
      font-size: 12px;
      line-height: 27px;
    }

    &.about-us {
      display: none;
    }
  }

  .footer-content-wrapper {
    @include respond-above(sm) {
      padding: 0 30px;
    }

    &.about-us {
      margin-top: 75px;

      @include respond-to(sm) {
        margin-top: 45px;
      }

      @include respond-below(sm) {
        margin-top: 25px;
      }
    }
  }
}
</style>
