<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper class="pb-40">
        <top-section-content show-right-side>
          <template v-slot:top-section-heading>
            <FontTitle text="OTC Deals"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            <div class="sub-heading">
              Explore our OTC market to buy or sell allocations securely
            </div>
            <a
              v-if="userProfile.userERCAddress"
              href="https://form.typeform.com/to/GvlfwH5I"
              target="_blank"
              class="details-button"
            >
              <button-component class="secondary">
                <FontTitle text="WANT TO SELL?"></FontTitle>
                <div class="button-shadow back"></div>
              </button-component>
            </a>
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="completed-deals-page-content">
      <generic-pagination
        v-if="pagination"
        :current-page="pagination.current_page"
        :total-pages="pagination.last_page"
        :url="{
          name: 'OTCPage',
          params: ['page'],
        }"
      />

      <div class="list">
        <div class="list-header">
          <ul class="list-header-menu">
            <li
              :class="state === 'active' ? 'active' : ''"
              @click="state = 'active'"
            >
              Active
            </li>
            <li
              :class="state === 'completed' ? 'active' : ''"
              @click="state = 'completed'"
            >
              Completed
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isLoading" class="empty-result">Loading...</div>
      <div
        class="completed-deals-projects-wrapper"
        v-else-if="projectsOTCPage.length"
      >
        <project-card-small
          v-for="project in sortProjects"
          :key="project.id"
          :state="
            project.isActive
              ? 'active'
              : project.isUpcoming
              ? 'upcoming'
              : project.isRefunded
              ? 'refunded'
              : project.isFull
              ? 'completed'
              : 'completed'
          "
          :type="projectType(project)"
          :type-name="project.type.name"
          :name="project.name"
          :date-start="project.dateStart"
          :date-end="project.dateEnd"
          :img="`${staticEndpoint}/${project.cardBannerPath}`"
          :slug="project.slug || String(project.id)"
          :activeDaysLeftParsed="project.activeDaysLeftParsed"
          :isActive="project.isActive"
          :isUpcoming="project.isUpcoming"
          :isRefunded="project.isRefunded"
          :isFull="project.isFull"
          :linkToOpen="project.linkToOpen"
          :multiplier="project.multiplier"
        />
      </div>
      <div v-else class="empty-result">
        No {{ state }} OTC projects at the moment.
      </div>
      <generic-pagination
        v-if="pagination"
        :current-page="pagination.current_page"
        :total-pages="pagination.last_page"
        :url="{
          name: 'OTCPage',
          params: ['page'],
        }"
      />
    </div>
  </main-layout>
</template>

<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import GenericPagination from "@/components/GenericPagination.vue";
import ProjectCardSmall from "@/components/OTCProjectCardSmall";
import { mapActions, mapGetters, mapState } from "vuex";
import TopSectionContent from "@/components/TopSectionContent";
import { projectType } from "@/helpers";
import FontTitle from "@/components/FontTitle";
import ButtonComponent from "@/components/Button";

export default {
  name: "OTCPage",
  data() {
    return {
      state: "active",
      isLoading: false,
    };
  },
  components: {
    FontTitle,
    TopSectionContent,
    TopSectionWrapper,
    MainLayout,
    GenericPagination,
    ProjectCardSmall,
    ButtonComponent,
  },
  head: {
    meta: [
      {
        name: 'description',
        content: 'Private and seed round deals of various crypto projects that have already been completed.'
      },
    ],
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    sortProjects() {
      let array = this.projectsOTCPage;
      if (this.state === "active") {
        array = array.filter((item) => item.isActive).sort((a, b) => new Date(a.endDateRaw) - new Date(b.endDateRaw));
      }
      if (this.state === "completed") {
        array = array.filter((item) => !item.isActive).sort((a, b) => new Date(b.endDateRaw) - new Date(a.endDateRaw));
      }
      // return array
      //   .sort((a, b) => (a.multiplier > b.multiplier ? 1 : -1))
      //   .sort((a, b) => (a.name > b.name ? 1 : -1))
      //   .sort((b, a) => a.isActive - b.isActive)
      //   .filter((item) => item.type.id === 2);

      return array;
    },
    ...mapGetters(["i18n"]),
    ...mapState(["projectsOTCPage", "pagination", "userProfile"]),
  },
  methods: {
    projectType,
    ...mapActions(["fetchOTCProjects"]),
  },
  watch: {
    async $route(to, from) {
      if (from.name === to.name) {
        this.isLoading = true;
        await this.fetchOTCProjects({
          page: to.query.page,
          state: this.state,
        });
        this.isLoading = false;

      }
    },
    async state(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isLoading = true;
        await this.fetchOTCProjects({
          page: this.$route.query.page,
          state: newVal,
        });
        this.isLoading = false;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("resetProjects");
    });
  },
  async created() {
    const pageInd = this.$route.query.page;
    this.isLoading = true;
    await this.fetchOTCProjects({
      page: pageInd,
      state: this.state,
    });
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.completed-deals-projects-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* flex-wrap: wrap; */
  margin-top: 40px;
  min-width: 100%;
  width: 100%;
  gap: 16px;

  @include respond-below(xs-plus) {
    flex-direction: column;
  }

  @include respond-between(xs, sm) {
    flex-wrap: wrap;
  }

  @include respond-to(xs) {
    grid-template-columns: 1fr 1fr;
  }
  @include respond-to(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1335px) and (min-width: 1279px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include respond-to(sm) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }

  /*<!--@include respond-between(xs, lg) {-->*/
  /*<!--  grid-template-columns: 1fr 1fr;-->*/
  /*<!--}-->*/
}

.heading {
  font-size: 88px;
  line-height: 80px;
  max-width: 500px;
  display: inline-block;
  position: relative;
  margin-top: -40px;

  @include respond-to(xs) {
    font-size: 48px;
    line-height: 50px;
  }

  @include respond-to(sm) {
    font-size: 48px;
    max-width: 400px;
    line-height: 50px;
    margin-top: 0;
  }
}

.sub-heading {
  max-width: 663px;
  margin-top: 11px;

  @include respond-to(sm) {
    max-width: 400px;
  }
}
.details-button button {
  position: relative;
  margin-top: 41px;
  background: linear-gradient(180deg, #6affce 0%, #79f6b6 100%);
  border: 0.7px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  max-height: none;
  padding: 15px 50px;

  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  /* identical to box height, or 29px */

  letter-spacing: 0.105em;
  text-transform: uppercase;

  color: #ffffff;

  @include respond-to(xs) {
    font-size: 16px;
    padding: 13px 30px;
  }

  @include respond-to(sm) {
    font-size: 15px;
    padding: 11px 25px;
    margin-top: 27px;
  }
}
.button-shadow {
  position: absolute;
  max-width: 320px;
  width: 100%;
  height: 58px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  @include respond-to(xs) {
    height: 48px;
  }

  @include respond-to(sm) {
    height: 44px;
  }
}
.back {
  background: linear-gradient(180deg, #6affce 0%, #79f6b6 100%);
}
.list {
  @include respond-above(md) {
    max-width: 1280px;
    margin: 0 auto;
  }

  @include respond-above(sm) {
    height: auto;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-menu {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding-left: 0;
      li {
        text-decoration: none;
        list-style-type: none;
        margin-right: 20px;
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        align-items: center;
        color: #9b9b9b;
      }

      :hover {
        color: #000;
        cursor: pointer;
      }
      .active {
        color: #000;
      }
    }

    &-search {
      height: 30px;
      display: inline-flex;
      border-radius: 20px;
      padding: 5px 5px 5px 10px;
      background: #d9d9d9;
      align-items: center;
      margin-right: 20px;
    }

    &-search-icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
      order: -1;
    }

    &-search-field {
      width: 200px;
      border: 0;
      background: #d9d9d9;
      color: #ffffff;
      font-size: 22px;
    }

    &-search-field:focus {
      outline: none;
    }

    &-search-field:focus + .search-icon {
      /* стили для иконки при фокусе поля */
    }

    &-filter {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      align-items: center;
      color: #9b9b9b;
      min-width: 70px;
      cursor: pointer;

      img {
        margin: auto 5px;
      }

      &-angle {
        width: 15px;
        height: 15px;
        transform: rotate(90deg);
      }
    }
  }
}
.empty-result {
  margin-top: 20px;
  font-size: 16px;
  color: #000000;
}
</style>