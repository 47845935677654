<template>
  <ul>
    <blog-category-label v-for="blogCategory in categories" :key="blogCategory.key">
      {{ blogCategory.name }}
    </blog-category-label>
  </ul>
</template>

<script setup>
import BlogCategoryLabel from '@/components/BlogCategoryLabel';
</script>

<script>
export default {
  name: 'BlogCategoriesList',
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
ul {
  margin: 0 0 11px;
  padding: 0;
}
</style>
